import { useNavigate, useLocation } from 'react-router-dom';
import {
  BlogBtn,
  BlogListContent,
  BlogListContentBox_Mobile,
  BlogListCotentBox,
  BlogListCotentContainer,
  BlogListImage,
  BlogListLeft,
  BlogListPageBox,
  BlogListPageContainer,
  BlogListRight,
  BlogListTitle,
  BlogPageCategory,
  BlogPageLayout1,
} from './BlogListStyle';
import logo from '../../img/teachernote-logo/teachernote-logo-green.png';
import { useMediaQuery } from 'react-responsive';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import TruncateText from '../../commonFunction/TruncateText';
import UserContext from '../../contexts/userContext';
import RegisterModalComponent from '../registerModal/RegisterModalComponent';
import LoginModalComponent from '../loginModal/LoginModalComponent';
import LazyLoadComponent from '../../components/LazyLoadComponent';
import { GrayBadge, YellowBadge } from '../../styles/BasicStyle';
import { MdAttachFile } from 'react-icons/md';
import { margin, padding } from '../../styles/commonStyle';

const BlogList = ({ blogs }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  const navigate = useNavigate();
  const location = useLocation();

  const getCategoryName = () => {
    if (
      location.pathname ===
      '/education-resources-sharing/classroom-learning-activities'
    )
      return '수업활동';
    if (
      location.pathname ===
      '/education-resources-sharing/student-recommendations'
    )
      return '아이템추천';
    if (location.pathname === '/education-resources-sharing/work-materials')
      return '업무정보';
    if (location.pathname === '/education-resources-sharing/education-magazine')
      return 'TALK';
    if (location.pathname === '/education-resources-sharing/announcement')
      return '공지사항';
    if (location.pathname === '/education-resources-sharing') return '전체보기';
    return '카테고리';
  };

  const handleClick = () => {
    if (userId) {
      navigate('/post-education-resource');
    } else {
      alert('로그인 후 이용가능합니다.');
      // navigate('/sign-up');
      setRegisterModalOpen(true);
    }
  };
  //--------------------- 로그인/회원가입 --------------------//
  //회원가입 모달
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('bottombutton'); //menubar,bottombutton

  const handleAuthComplete = async (userId) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  const openRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const openLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const closeAuthModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  //--------------------- 스크롤 이벤트 핸들러 --------------------//
  // 스크롤 이벤트 핸들러
  const initialVisiblePosts = isMobile ? 2 : 4;
  const [visiblePosts, setVisiblePosts] = useState(initialVisiblePosts); // 초기 4개의 포스트만 보이도록 설정
  const loader = useRef(null);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 4); // 스크롤 될 때마다 10개의 포스트를 추가로 로드
    }
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [handleObserver]);

  const goBlogView = (postId, postCategory) => {
    navigate(`/education-resources-sharing/${postCategory}/${postId}`);
  };

  //desktop, tablet, mobile여부에 따라 보여줄 글자 수 다르게
  let textLimit = 100;
  if (isDesktop) {
    textLimit = 200;
  } else if (isTablet) {
    textLimit = 100;
  } else if (isMobile) {
    textLimit = 50;
  }

  const isNew = (date) => {
    const currentDate = new Date();
    const articleDate = new Date(date);
    const diffTime = Math.abs(currentDate - articleDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 7;
  };

  //블로그에서 이미지 추출
  const extractImageUrlFromContent = (content) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const imgTag = tempDiv.querySelector('img');
    return imgTag ? imgTag.src : logo; // 이미지가 있으면 그 URL, 없으면 로고
  };

  return (
    <>
      <RegisterModalComponent
        isRegisterModalOpen={isRegisterModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        openRegisterModal={openRegisterModal}
        openLoginModal={openLoginModal}
        closeModal={closeAuthModal}
        handleAuthComplete={handleAuthComplete}
        modalSource={modalSource}
      />
      {isLoginModalOpen ? (
        <LoginModalComponent
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          openRegisterModal={openRegisterModal}
          openLoginModal={openLoginModal}
          closeAuthModal={closeAuthModal}
          handleAuthComplete={handleAuthComplete}
          modalSource={modalSource}
        />
      ) : (
        <></>
      )}
      <BlogListPageContainer>
        <BlogListPageBox>
          {/* {!isMobile && ( */}
          <BlogPageLayout1>
            {!isMobile && (
              <>
                <BlogPageCategory>{getCategoryName()}</BlogPageCategory>
                <BlogBtn onClick={handleClick}>글쓰기</BlogBtn>
              </>
            )}
          </BlogPageLayout1>
          {/* )} */}

          <BlogListCotentContainer>
            {blogs.slice(0, visiblePosts).map((post) => (
              // {blogs.map((post) => (
              <>
                {!isMobile ? (
                  <>
                    {/* <LazyLoadComponent key={`blog-${post.id}`}> */}
                    <BlogListCotentBox
                      key={`blog-${post.id}`}
                      onClick={() => goBlogView(post.id, post.category)}
                    >
                      <BlogListLeft>
                        {/* <BlogListImage src={logo}></BlogListImage> */}
                        {/* post.content에서 첫 번째 이미지를 추출하여 표시 */}
                        <LazyLoadComponent>
                          <BlogListImage
                            src={extractImageUrlFromContent(post.content)}
                            alt={post.title}
                          />
                        </LazyLoadComponent>
                      </BlogListLeft>
                      <BlogListRight>
                        <BlogListTitle>
                          {TruncateText(post.title, textLimit)}{' '}
                          {post.fileUrl && (
                            <>
                              <YellowBadge
                                style={{ marginRight: `${margin.small}` }}
                              >
                                <MdAttachFile />
                                공유
                              </YellowBadge>
                            </>
                          )}
                          {isNew(post.createdAt) && (
                            <span style={{ color: 'red' }}>new</span>
                          )}
                        </BlogListTitle>
                        <BlogListContent>
                          {TruncateText(
                            post.content.replace(/<\/?[^>]+(>|$)/g, ''),
                            textLimit,
                          )}
                        </BlogListContent>
                      </BlogListRight>
                    </BlogListCotentBox>
                    {/* </LazyLoadComponent> */}
                  </>
                ) : (
                  // <LazyLoadComponent key={`blog-${post.id}`}>
                  <BlogListContentBox_Mobile
                    key={`blog-${post.id}`}
                    onClick={() => goBlogView(post.id, post.category)}
                  >
                    <LazyLoadComponent>
                      <BlogListImage
                        src={extractImageUrlFromContent(post.content)}
                        alt={post.title}
                      />{' '}
                    </LazyLoadComponent>
                    <BlogListTitle>
                      {TruncateText(post.title, textLimit)}{' '}
                      {post.fileUrl && (
                        <YellowBadge>
                          <MdAttachFile />
                          공유
                        </YellowBadge>
                      )}{' '}
                      {isNew(post.createdAt) && (
                        <span style={{ color: 'red' }}>new</span>
                      )}
                    </BlogListTitle>
                  </BlogListContentBox_Mobile>
                  // </LazyLoadComponent>
                )}
              </>
            ))}
            <div ref={loader} />
          </BlogListCotentContainer>
        </BlogListPageBox>
      </BlogListPageContainer>
    </>
  );
};

export default BlogList;
