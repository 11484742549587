import styled from 'styled-components';
import { fontSize, fontWeight, padding } from '../../../styles/commonStyle';

export const MobileSubTitle = styled.div`
  padding: ${padding.mediumLarge} 0 ${padding.small} ${padding.small};
`;

export const MobileSubTitleBold = styled.div`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
`;
export const MobileGifImg = styled.img`
  width: 100%;
`;
