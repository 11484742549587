import { useCallback, useEffect, useRef, useState } from 'react';
import SeatSetting from './SeatDrawMap';

const SeatDrawMapContainer = ({
  setSeats,
  setBlockedSeats,
  setPairMode,
  pairMode,
  setGenderMode,
  genderMode,
  seats,
  blockedSeats,
  preAssigningSeats,
  genderMap,
  genderSeatRule,
  //   handleSeatClick,
  //   handlePreAssignInput,
  setGenderSeatRule,
  setPreAssigningSeats,
  numberList,
  notUsingNumbers,
  saveSeatInfo,
  loadSeatInfo,
  setNumberList,
  savePreAssignment,
  // isChangeSeatMode,
  // setIsChangeSeatMode,
  completedNum,
  setCompletedNum,
  numberSeatMap,
  isDrawSettingOpen,
  setIsDrawSettingOpen,
  currentNumIndex,
  isReversed,
  setIsReversed,
  setNumberSeatMap,
  clickedJebiNums,
  setClickedJebiNums,
  jebiPositions,
  setJebiPositions,
  setCurrentNumIndex,

  setDrawingResult,
  drawingResult,
  setIsDrawOk,
  isDrawOk,
  isLargeResultMode,
  setIsLargeResultMode,
  handleCompleteRemaining,
  setIsRevealAll,
  isRevealAll,

  isClickedByJebi,
  setIsClickedByJebi,
  localGenderMap,
  frontNums,
  students,
  showNameMode,
  downloadExcel,
}) => {
  const [isPreAssignMode, setIsPreAssignMode] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState(null);
  // const [selectedSeatNumbers, setSelectedSeatNumbers] = useState([]); // 선택된 좌석 번호 저장

  const [isEditResultMode, setIsEditResultMode] = useState(false); // 자리 수정 모드 상태
  const [selectedSeats, setSelectedSeats] = useState([]); // 선택된 좌석 저장
  const [isMoreSeatResultSettingOpen, setIsMoreSeatResultSettingOpen] =
    useState(false);
  const inputRef = useRef(null);
  const [highlight, setHighlight] = useState(true); // 하이라이트 상태 관리

  const validNumbers = numberList.filter(
    (num) => !notUsingNumbers.includes(num),
  );

  useEffect(() => {}, [numberSeatMap]);

  //메뉴창 닫기
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setSelectedSeat(null);
      }
    };

    if (selectedSeat) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSeat]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSelectedSeat(null);
        // inputRef.current이 존재하고 selectedSeat이 있을 때 handlePreAssignInput 호출
        if (selectedSeat) {
          handlePreAssignInput({ target: inputRef.current });
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedSeat]);

  // --------------------  자리 결과 수정  -------------------- //

  const handleSeatClick = (rowIdx, colIdx) => {
    if (isEditResultMode) {
      const seatKey = { row: rowIdx, col: colIdx };
      const seatNumber = seats[rowIdx][colIdx];

      setSelectedSeats((prevSelectedSeats) => {
        const seatAlreadySelected = prevSelectedSeats.some(
          (seat) => seat.row === rowIdx && seat.col === colIdx,
        );

        if (seatAlreadySelected) {
          return prevSelectedSeats.filter(
            (seat) => seat.row !== rowIdx || seat.col !== colIdx,
          );
        }

        const newSelectedSeats = [...prevSelectedSeats, seatKey];

        if (newSelectedSeats.length === 2) {
          // 자리 교환 전 잠시 대기
          setTimeout(() => {
            swapSeats(newSelectedSeats);
            setSelectedSeats([]); // 자리 교환 후 선택된 좌석 초기화
          }, 300); // 300ms 지연 시간

          return newSelectedSeats;
        }

        return newSelectedSeats;
      });
    }
  };

  //미리 번호 배치
  const handlePreAssignInput = (event) => {
    const { value } = event.target;
    // console.log('value:' + value);
    if (value.trim() === '') {
      return;
    }

    if (selectedSeat) {
      const [rowIdx, colIdx] = selectedSeat.split('-').map(Number);
      const seatGender = genderMap[value];

      const isGenderMismatch = genderSeatRule
        ? (seatGender === '여' && colIdx % 2 === 0) ||
          (seatGender === '남' && colIdx % 2 !== 0)
        : (seatGender === '여' && colIdx % 2 !== 0) ||
          (seatGender === '남' && colIdx % 2 === 0);

      const isAlreadyAssigned =
        Object.values(preAssigningSeats).includes(value);

      const isNotUsingNumber = notUsingNumbers.includes(parseInt(value));

      if (seatGender && isGenderMismatch) {
        alert('선택한 번호의 성별과 자리가 맞지 않습니다.');
      } else if (isNotUsingNumber) {
        alert('선택한 번호는 사용할 수 없는 번호입니다.');
      } else if (isAlreadyAssigned) {
        if (window.confirm('이미 배치된 번호입니다. 자리를 바꾸시겠습니까?')) {
          const updatedPreAssignedSeats = Object.fromEntries(
            Object.entries(preAssigningSeats).map(([key, val]) =>
              val === value ? [key, null] : [key, val],
            ),
          );
          setPreAssigningSeats({
            ...updatedPreAssignedSeats,
            [selectedSeat]: value,
          });
          setSelectedSeat(null);
        }
      } else {
        setPreAssigningSeats((prev) => ({
          ...prev,
          [selectedSeat]: value,
        }));
        setSelectedSeat(null);
      }
    }
  };
  // --------------------  자리 다시 뽑기  -------------------- //
  // 자리 배치 초기화 함수 구현
  const resetDrawing = () => {
    setIsDrawOk(false);
    const initialSeats = seats.map((row) => row.map(() => null)); // 모든 좌석을 null로 초기화
    setSeats(initialSeats);
    setCompletedNum([]); // 완료된 번호 초기화

    setCurrentNumIndex(0);
    setSelectedSeat(null);
    setClickedJebiNums([]);
    setJebiPositions(
      numberList.map(() => ({
        top: Math.random() * 80,
        left: Math.random() * 80,
      })),
    );
    setIsMoreSeatResultSettingOpen(false);
    setIsRevealAll(false);
  };

  // --------------------  자리 교환  -------------------- //

  // 자리 교환
  const swapSeats = (selectedSeats) => {
    const [firstSeat, secondSeat] = selectedSeats;

    // console.log('selectedSeats:' + JSON.stringify(selectedSeats));

    setSeats((prevSeats) => {
      const newSeats = prevSeats.map((row) => [...row]);
      const temp = newSeats[firstSeat.row][firstSeat.col];
      newSeats[firstSeat.row][firstSeat.col] =
        newSeats[secondSeat.row][secondSeat.col];
      newSeats[secondSeat.row][secondSeat.col] = temp;

      return newSeats;
    });
  };

  return (
    <SeatSetting
      setPairMode={setPairMode}
      pairMode={pairMode}
      setGenderMode={setGenderMode}
      genderMode={genderMode}
      setIsPreAssignMode={setIsPreAssignMode}
      isPreAssignMode={isPreAssignMode}
      isEditResultMode={isEditResultMode}
      setIsEditResultMode={setIsEditResultMode}
      seats={seats}
      blockedSeats={blockedSeats}
      preAssigningSeats={preAssigningSeats}
      genderSeatRule={genderSeatRule}
      selectedSeat={selectedSeat}
      handleSeatClick={handleSeatClick}
      handlePreAssignInput={handlePreAssignInput}
      setGenderSeatRule={setGenderSeatRule}
      // confirmSetting={confirmSetting}
      saveSeatInfo={saveSeatInfo}
      loadSeatInfo={loadSeatInfo}
      savePreAssignment={savePreAssignment}
      // isChangeSeatMode={isChangeSeatMode}
      // setIsChangeSeatMode={setIsChangeSeatMode}
      inputRef={inputRef}
      completedNum={completedNum}
      setCompletedNum={setCompletedNum}
      numberSeatMap={numberSeatMap}
      isDrawSettingOpen={isDrawSettingOpen}
      setIsDrawSettingOpen={setIsDrawSettingOpen}
      numberList={numberList}
      notUsingNumbers={notUsingNumbers}
      currentNumIndex={currentNumIndex}
      isReversed={isReversed}
      setIsReversed={setIsReversed}
      resetDrawing={resetDrawing}
      handleCompleteRemaining={handleCompleteRemaining}
      isMoreSeatResultSettingOpen={isMoreSeatResultSettingOpen}
      setIsMoreSeatResultSettingOpen={setIsMoreSeatResultSettingOpen}
      selectedSeats={selectedSeats}
      // selectedSeatNumbers={selectedSeatNumbers}
      isLargeResultMode={isLargeResultMode}
      setIsLargeResultMode={setIsLargeResultMode}
      highlight={highlight}
      setIsRevealAll={setIsRevealAll}
      isRevealAll={isRevealAll}
      setCurrentNumIndex={setCurrentNumIndex}
      isDrawOk={isDrawOk}
      isClickedByJebi={isClickedByJebi}
      setIsClickedByJebi={setIsClickedByJebi}
      localGenderMap={localGenderMap}
      frontNums={frontNums}
      students={students}
      showNameMode={showNameMode}
      downloadExcel={downloadExcel}
    />
  );
};
export default SeatDrawMapContainer;
