import React, { useContext, useState } from 'react';
import {
  ArticleMenuItemsContainer,
  Left_MainMenu,
  MainMenuItemsContainer,
  Middle_MainMenu,
  MobileMenuBar,
  Right_MainMenu,
} from './MobileTopMenuBarStyle';

import logoHorizontalSmall from '../img/teachernote-logo/teachernote-logo-horizontal-small.png';
import UserContext from '../contexts/userContext';
import styled from 'styled-components';
import { colors, fontSize, padding } from '../styles/commonStyle';
import { useNavigate } from 'react-router-dom';

import { SlMenu } from 'react-icons/sl';
import MobileMenu from '../pages/mobilePage/mobileMenu/MobileMenuPage';
import { NowInfo } from '../pageLayout/HeaderMenuBarStyle';
import LocationContext from '../contexts/LocationContext';
import WeatherContext from '../contexts/WeatherContext';
import { BorderButton, GreenButton } from '../styles/MobileBasicStyle';

const Circle = styled.div`
  width: 20px;
  height: 20px;
  padding: ${padding.extraSmall};
  font-size: ${fontSize.small};
  background-color: ${colors.lightGreen}; /* 원하는 색상 */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  // margin-right: 8px;
`;
const Main_MobileTopMenuBar = ({}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const firstLetter = userId ? userId.charAt(0).toUpperCase() : ''; // 첫 글자를 대문자로 변환
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const { location, city } = useContext(LocationContext);
  const { weather, icon, status } = useContext(WeatherContext);
  return (
    <>
      <MobileMenuBar>
        <MainMenuItemsContainer>
          <Left_MainMenu
            onClick={() => setIsOpen(true)}
            style={{ cursor: 'pointer' }}
          >
            <SlMenu size={20} />
          </Left_MainMenu>
          <Middle_MainMenu
            onClick={() => {
              navigate('/');
              window.scrollTo({ top: 0 }); // 페이지 이동 후 상단으로 스크롤
            }}
          >
            <div>
              <img
                src={logoHorizontalSmall}
                style={{ height: '20px' }}
                alt="티처노트 가로형 로고 소형"
              />
            </div>
          </Middle_MainMenu>
          <Right_MainMenu
          // onClick={() => {
          //   navigate('/mobileUserSetting');
          // }}
          >
            {userId ? (
              // <Circle>{firstLetter}</Circle>
              <NowInfo>
                {location.lat && location.lon ? <>{city}</> : <></>} &nbsp; |
                &nbsp;
                {weather ? (
                  <>
                    {weather.fcstValue}°C {icon}
                  </>
                ) : (
                  <></>
                )}
              </NowInfo>
            ) : (
              <>
                {' '}
                <span onClick={() => navigate('/m-login')}>로그인</span>
                <BorderButton
                  onClick={() => navigate('/m-sign-up')}
                  style={{ marginLeft: '5px' }}
                >
                  회원가입
                </BorderButton>
              </>
            )}
          </Right_MainMenu>
        </MainMenuItemsContainer>
      </MobileMenuBar>
      {isOpen && <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

export default Main_MobileTopMenuBar;
