import styled from 'styled-components';
import { Link } from '../../node_modules/react-router-dom/dist/index';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gap,
  padding,
  ratio,
} from '../styles/commonStyle';

export const GridPageContainer = styled.div`
  width: 100%;
  background-color: white;
`;

export const GridPageBox = styled.div`
  padding: 70px;
`;

export const GridPageLayout1 = styled.div`
  padding-bottom: ${padding.large};

  font-size: ${fontSize.categoryLarge};
  font-weight: ${fontWeight.bold};

  display: flex;
  justify-content: space-between;
`;
export const GridPageLayout2 = styled.div`
  width: 100%;
  grid-gap: ${gap.medium};

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;
export const GridPageCategory = styled.div``;
export const GridPageWriteBtn = styled.button`
  width: ${ratio.small};
  padding: ${padding.medium} ${padding.mediumLarge};

  background-color: ${colors.primary};
  color: ${colors.background};
  border: none;
  border-radius: ${borderRadius.small};
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.primarydark};
  }
`;

export const PostCard = styled(Link)`
  padding: ${padding.mediumLarge};
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
`;

export const BlogGridThumbnailImg = styled.img`
  width: 100%;
  height: 165px;
  background-color: white;
  height: ${({ imgWidth }) => imgWidth}px;
`;

export const BlogGridTitle = styled.div`
  color: black;
  font-weight: 600;
  padding-top: ${padding.mediumLarge};
  &:hover {
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: -10px;
`;
