import { useNavigate } from 'react-router-dom';
import TruncateText from '../../commonFunction/TruncateText';
import styled from 'styled-components';
import logo from '../../img/teachernote-logo/teachernote-logo-green.png';
import { BlogListImage } from '../blogList/BlogListStyle';
import {
  borderRadius,
  gap,
  imgSize,
  margin,
  padding,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';
import { useEffect } from 'react';

// Styled Components
const BlogListContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: ${gap.small};
  justify-content: space-between;
  overflow-x: auto;
  padding-top: ${padding.small};
`;

const BlogCard = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  flex: 1 1 30%;
  max-width: 30%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  ${media.tablet`
    flex: 1 1 45%;
    max-width: 45%;
  `}

  ${media.mobile`
    flex: 1 1 100%;
    max-width: 100%;
  `}
`;

const BlogTitle = styled.h4`
  font-size: 1.1em;
  margin-bottom: ${margin.medium};
`;

const BlogContent = styled.p`
  font-size: 0.9em;
  color: #555;
`;

const BlogImage = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: ${borderRadius.medium};

  ${media.mobile`
    // height: ${imgSize.small};
  `}
`;

const BottomBlogList = ({ blogs }) => {
  const navigate = useNavigate();

  // 페이지 이동 후 스크롤을 최상단으로 설정하는 함수
  const handleNavigate = (id, category) => {
    navigate(`/education-resources-sharing/${category}/${id}`);
    window.scrollTo(0, 0); // 페이지 이동 후 스크롤을 최상단으로 설정
  };

  const extractImageUrlFromContent = (content) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const imgTag = tempDiv.querySelector('img');
    return imgTag ? imgTag.src : logo;
  };

  return (
    <BlogListContainer>
      {blogs.map((post) => (
        <BlogCard
          key={post.id}
          onClick={() => handleNavigate(post.id, post.category)}
        >
          <BlogImage
            src={extractImageUrlFromContent(post.content)}
            alt={post.title}
          />
          <BlogTitle>{post.title}</BlogTitle>
          <BlogContent>
            {TruncateText(post.content.replace(/<\/?[^>]+(>|$)/g, ''), 50)}
          </BlogContent>
        </BlogCard>
      ))}
    </BlogListContainer>
  );
};

export default BottomBlogList;
