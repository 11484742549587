// WeatherContext.js
import React, { createContext, useEffect, useState } from 'react';
import {
  WiDaySunny,
  WiCloud,
  WiRain,
  WiDayFog,
  WiSnow,
} from 'weather-icons-react';

const WeatherContext = createContext({
  weather: null,
  icon: null,
  status: '',
});

const WeatherProvider = ({ children }) => {
  const [weather, setWeather] = useState(null);
  const [icon, setIcon] = useState(null);
  const [status, setStatus] = useState(null);

  function dfs_xy_conv(v1, v2) {
    const RE = 6371.00877; // 지구 반경(km)
    const GRID = 5.0; // 격자 간격(km)
    const SLAT1 = 30.0; // 투영 위도1(degree)
    const SLAT2 = 60.0; // 투영 위도2(degree)
    const OLON = 126.0; // 기준점 경도(degree)
    const OLAT = 38.0; // 기준점 위도(degree)
    const XO = 43; // 기준점 X좌표 (격자)
    const YO = 136; // 기준점 Y좌표 (격자)

    const DEGRAD = Math.PI / 180.0;
    const RADDEG = 180.0 / Math.PI;

    const re = RE / GRID;
    const slat1 = SLAT1 * DEGRAD;
    const slat2 = SLAT2 * DEGRAD;
    const olon = OLON * DEGRAD;
    const olat = OLAT * DEGRAD;

    let sn =
      Math.tan(Math.PI * 0.25 + slat2 * 0.5) /
      Math.tan(Math.PI * 0.25 + slat1 * 0.5);
    sn = Math.log(Math.cos(slat1) / Math.cos(slat2)) / Math.log(sn);
    let sf = Math.tan(Math.PI * 0.25 + slat1 * 0.5);
    sf = (Math.pow(sf, sn) * Math.cos(slat1)) / sn;
    let ro = Math.tan(Math.PI * 0.25 + olat * 0.5);
    ro = (re * sf) / Math.pow(ro, sn);
    let rs = {};

    let ra = Math.tan(Math.PI * 0.25 + v1 * DEGRAD * 0.5);
    ra = (re * sf) / Math.pow(ra, sn);
    let theta = v2 * DEGRAD - olon;
    if (theta > Math.PI) theta -= 2.0 * Math.PI;
    if (theta < -Math.PI) theta += 2.0 * Math.PI;
    theta *= sn;
    rs['nx'] = Math.floor(ra * Math.sin(theta) + XO + 0.5);
    rs['ny'] = Math.floor(ro - ra * Math.cos(theta) + YO + 0.5);
    return rs;
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const grid = dfs_xy_conv(latitude, longitude);

          const now = new Date();
          const year = now.getFullYear();
          const month = String(now.getMonth() + 1).padStart(2, '0');
          const day = String(now.getDate()).padStart(2, '0');
          const hours = now.getHours();

          let baseTime;
          if (hours >= 2 && hours < 5) baseTime = '0200';
          else if (hours >= 5 && hours < 8) baseTime = '0500';
          else if (hours >= 8 && hours < 11) baseTime = '0800';
          else if (hours >= 11 && hours < 14) baseTime = '1100';
          else if (hours >= 14 && hours < 17) baseTime = '1400';
          else if (hours >= 17 && hours < 20) baseTime = '1700';
          else if (hours >= 20 && hours < 23) baseTime = '2000';
          else baseTime = '2300';

          const baseDate = `${year}${month}${day}`;
          const serviceKey =
            'toyZPQfiu3KOkQ%2Fn25UFQuCnt1Bs1kgakn8A24RP3DdqyRDIj3rRCVbaJwkyYF5q85fzwINdnRcQzvPb%2BQZRbQ%3D%3D';
          const apiUrl = `http://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getVilageFcst?serviceKey=${serviceKey}&numOfRows=10&pageNo=1&base_date=${baseDate}&base_time=${baseTime}&nx=${grid.nx}&ny=${grid.ny}&dataType=JSON`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              if (data.response?.body?.items?.item) {
                const weatherData = data.response.body.items.item;
                const temperatureData = weatherData.find(
                  (item) => item.category === 'TMP',
                );
                setWeather(temperatureData);

                const skyData = weatherData.find(
                  (item) => item.category === 'SKY',
                );
                const ptyData = weatherData.find(
                  (item) => item.category === 'PTY',
                );

                updateIcon(skyData?.fcstValue, ptyData?.fcstValue);
              }
            })
            .catch((error) => {
              console.error('날씨 정보를 가져오는 중 오류 발생:', error);
            });
        },
        (error) => console.error('위치 정보를 가져오는 중 오류 발생:', error),
      );
    } else {
      alert('이 브라우저에서는 위치 정보가 지원되지 않습니다.');
    }
  }, []);

  const updateIcon = (skyCode, ptyCode) => {
    if (ptyCode === '1') {
      setIcon(<WiRain size={20} color="#3498db" />);
      setStatus('비');
    } else if (ptyCode === '3') {
      setIcon(<WiSnow size={20} color="#ecf0f1" />);
      setStatus('눈');
    } else {
      switch (skyCode) {
        case '1':
          setIcon(<WiDaySunny size={20} color="#f39c12" />);
          setStatus('맑음');
          break;
        case '3':
          setIcon(<WiCloud size={20} color="#95a5a6" />);
          setStatus('구름 많음');
          break;
        case '4':
          setIcon(<WiDayFog size={20} color="#7f8c8d" />);
          setStatus('흐림');
          break;
        default:
          setIcon(<WiCloud size={20} color="#95a5a6" />);
          setStatus('');
          break;
      }
    }
  };

  return (
    <WeatherContext.Provider value={{ weather, icon, status }}>
      {children}
    </WeatherContext.Provider>
  );
};

export { WeatherProvider };
export default WeatherContext;
