import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules'; // Pagination 모듈을 제거
import styled from 'styled-components';
import { colors } from '../../styles/commonStyle';
import {
  CardContainer_RoundCard,
  CardDescription,
  CardDescription_SmallRoundCard,
  CardImage_RoundCard,
  CarouselWrapper,
  StyledSwiper,
} from './CardCarouselStyle';
import { useMediaQuery } from 'react-responsive';

const RoundCardCarousel = ({ cards }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const showRoundCardNum = isMobile ? 4 : 5;

  return (
    <CarouselWrapper>
      <StyledSwiper
        modules={[Navigation]} // Pagination 모듈 제거
        navigation
        spaceBetween={1}
        slidesPerView={showRoundCardNum}
        slidesPerGroup={showRoundCardNum} // 한 번에 이동할 슬라이드 수
        loop={false}
      >
        {cards.map((card) => (
          <SwiperSlide key={card.id} onClick={card.onClick}>
            <CardContainer_RoundCard>
              <CardImage_RoundCard src={card.imageUrl} alt={card.description} />
              <CardDescription_SmallRoundCard>
                {card.description}
              </CardDescription_SmallRoundCard>
            </CardContainer_RoundCard>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </CarouselWrapper>
  );
};

export default RoundCardCarousel;
