import { Helmet } from 'react-helmet';

export const MainHelmetContent = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '티처노트 - 교사를 위한 자리뽑기, 진도 관리, 숫자뽑기, 수업 자료 플랫폼',
    description:
      '자리뽑기, 수업 진도 관리, 숫자뽑기, 교육 자료 공유까지 교사에게 필요한 다양한 기능을 제공합니다.',
    publisher: {
      '@type': 'Organization',
      name: '티처노트',
    },
  };
  return (
    <Helmet>
      <title>
        티처노트 - 교사를 위한 자리뽑기, 진도 관리, 숫자뽑기, 수업 자료 플랫폼
      </title>
      <meta
        name="description"
        content="자리뽑기, 수업 진도 관리, 숫자뽑기, 교육 자료 공유까지 교사에게 필요한 다양한 기능을 제공합니다. 수업을 효율적이고 재미있게 관리하고 꾸밀 수 있는 티처노트를 지금 사용해보세요."
      />
      <meta
        name="keywords"
        content="자리뽑기, 수업 진도 관리, 숫자뽑기, 교육 자료 공유, 학습 자료, 교사 도구, 학급 관리, 티처노트"
      />

      <meta
        property="og:title"
        content="티처노트 - 자리뽑기, 진도 관리, 숫자뽑기와 교육 자료 플랫폼"
      />
      <meta
        property="og:description"
        content="교사를 위한 자리뽑기, 진도 관리, 랜덤 숫자뽑기, 수업 자료 공유 플랫폼 티처노트! 교실과 학급 관리에 꼭 필요한 도구로 효율적인 수업을 계획해보세요."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta property="og:url" content="https://teachernote.me" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://teachernote.me" />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export const ProgressTrackerHelmetContent = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '효율적인 수업 진도표 관리 | 티처노트',
    description:
      '교사와 학급을 위한 진도 관리 프로그램입니다. 효율적인 수업 진도표 작성과 진도 체크 기능으로 학급 학습 관리에 도움을 드립니다.',
    url: 'https://teachernote.me/lesson-progress-tracker',
    publisher: {
      '@type': 'Organization',
      name: '티처노트',
      logo: {
        '@type': 'ImageObject',
        url: 'https://teachernote.me/logo512.png',
      },
    },
  };
  return (
    <Helmet>
      <title>효율적인 수업 진도표 관리 | 티처노트</title>
      <meta
        name="description"
        content="교사와 학급을 위한 진도 관리 프로그램입니다. 효율적인 수업 진도표 작성과 진도 체크 기능으로 학급 학습 관리에 도움을 드립니다."
      />
      <meta
        name="keywords"
        content="수업 진도표, 진도 관리, 학급 진도표, 학습 진도표, 교과 진도표, 학습 관리, 교사 진도표, 학습 진도 관리, 진도 체크, 진도표 앱, 교과 학습 관리, 진도 관리 프로그램"
      />
      <meta
        property="og:title"
        content="효율적인 수업 진도표 관리 - 학급과 교과 진도 체크 | 티처노트"
      />
      <meta
        property="og:description"
        content="교사와 학급을 위한 진도 관리 프로그램으로, 효율적인 수업 진도표 작성과 진도 체크 기능을 제공합니다."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta
        property="og:url"
        content="https://teachernote.me/lesson-progress-tracker"
      />
      <meta property="og:type" content="website" />
      <link
        rel="canonical"
        href="https://teachernote.me/lesson-progress-tracker"
      />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export const ClassSettingHelmetContent = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '학급 설정 - 티처노트',
    description:
      '효율적인 학급 관리를 위한 설정 페이지입니다. 학급 구성과 진도 관리를 편리하게 설정하세요.',
    url: 'https://teachernote.me/class-management',
    publisher: {
      '@type': 'Organization',
      name: '티처노트',
      logo: {
        '@type': 'ImageObject',
        url: 'https://teachernote.me/logo512.png',
      },
    },
  };
  return (
    <Helmet>
      <title>학급 설정 - 티처노트</title>
      <meta
        name="description"
        content="효율적인 학급 관리를 위한 설정 페이지입니다. 학급 구성과 진도 관리를 편리하게 설정하세요."
      />
      <meta name="keywords" content="학급 설정, 학급 관리, 티처노트" />
      <meta property="og:title" content="학급 설정 - 티처노트" />
      <meta
        property="og:description"
        content="효율적인 학급 관리를 위한 설정 페이지입니다. 학급 구성과 진도 관리를 편리하게 설정하세요."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta
        property="og:url"
        content="https://teachernote.me/class-management"
      />
      <link rel="canonical" href="https://teachernote.me/class-management" />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export const TopicSettingHelmetContent = () => {
  return (
    <Helmet>
      <title>주제 설정 - 티처노트</title>
      <meta
        name="description"
        content="수업 주제와 관련된 설정을 통해 효과적인 학습 관리를 돕습니다."
      />
      <meta name="keywords" content="주제 설정, 수업 주제 관리, 티처노트" />
      <meta property="og:title" content="주제 설정 - 티처노트" />
      <meta
        property="og:description"
        content="수업 주제와 관련된 설정을 통해 효과적인 학습 관리를 돕습니다."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta
        property="og:url"
        content="https://teachernote.me/topic-management"
      />
      <link rel="canonical" href="https://teachernote.me/topic-management" />
    </Helmet>
  );
};

export const ProgressNoteHelmetContent = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '주제 설정 - 티처노트',
    description:
      '수업 주제와 관련된 설정을 통해 효과적인 학습 관리를 돕습니다.',
    url: 'https://teachernote.me/topic-management',
    publisher: {
      '@type': 'Organization',
      name: '티처노트',
      logo: {
        '@type': 'ImageObject',
        url: 'https://teachernote.me/logo512.png',
      },
    },
  };
  return (
    <Helmet>
      {/* 페이지 제목 */}
      <title>진도 노트 - 학급 및 교과 진도 관리 | 티처노트</title>

      {/* 메타 설명 */}
      <meta
        name="description"
        content="효율적인 학급 및 교과 진도 관리를 위한 진도 노트입니다. 수업 진도표 작성 및 관리 도구로 교사의 학습 진도 관리와 기록을 지원합니다."
      />

      {/* 키워드 */}
      <meta
        name="keywords"
        content="진도 노트, 수업 진도표, 학급 진도 관리, 교과 진도 기록, 진도 체크, 학습 진도 관리, 교사 진도표, 수업 관리, 교사 도구, 학급 관리"
      />

      {/* Open Graph 메타 태그 */}
      <meta
        property="og:title"
        content="진도 노트 - 학급 및 교과 진도 관리 | 티처노트"
      />
      <meta
        property="og:description"
        content="효율적인 학급 및 교과 진도 관리 프로그램, 수업 진도표와 기록 도구로 교사의 학습 진도 관리를 지원합니다."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta
        property="og:url"
        content="https://teachernote.me/progress-notes/by-class"
      />
      <meta property="og:type" content="website" />

      {/* Canonical 태그 */}
      <link
        rel="canonical"
        href="https://teachernote.me/progress-notes/by-class"
      />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};
export const EducationResourcesSharingHelmetContent = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '교육 자료 공유 - 수업 자료, 학습자료 다운로드 | 티처노트',
    description:
      '교사와 학생을 위한 다양한 교육 자료와 수업 자료를 공유하고 다운로드하세요. 학습 자료 업로드와 열람이 가능한 교육 자료 플랫폼입니다.',
    url: 'https://teachernote.me/education-resources-sharing',
    publisher: {
      '@type': 'Organization',
      name: '티처노트',
      logo: {
        '@type': 'ImageObject',
        url: 'https://teachernote.me/logo512.png',
      },
    },
  };
  return (
    <Helmet>
      <title>교육 자료 공유 - 수업 자료, 학습자료 다운로드 | 티처노트</title>
      <meta
        name="description"
        content="교사와 학생을 위한 다양한 교육 자료와 수업 자료를 공유하고 다운로드하세요. 학습 자료 업로드와 열람이 가능한 교육 자료 플랫폼입니다."
      />
      <meta
        name="keywords"
        content="교육 자료 공유, 수업 자료, 학습 자료, 교사 자료, 교육 자료 사이트, 자료실, 학습 자료 다운로드, 무료 교육 자료"
      />

      <meta
        property="og:title"
        content="교육 자료 공유 - 수업 자료, 학습자료 다운로드 | 티처노트"
      />
      <meta
        property="og:description"
        content="교사와 학생을 위한 다양한 수업 자료와 학습 자료를 제공하고 공유하는 교육 자료 플랫폼입니다."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta
        property="og:url"
        content="https://teachernote.me/education-resources-sharing"
      />
      <meta property="og:type" content="website" />
      <link
        rel="canonical"
        href="https://teachernote.me/education-resources-sharing"
      />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export const BlogWriteHelmetContent = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: '글쓰기 - 교육 자료 공유 | 티처노트',
    description:
      '교사와 학생을 위한 교육 자료를 공유하고, 새로운 글을 작성해보세요. 수업 자료와 학습 정보를 쉽게 기록하고 나눌 수 있습니다.',
    url: 'https://teachernote.me/post-education-resource',
    image: 'https://teachernote.me/logo512.png',
    author: {
      '@type': 'Organization',
      name: '티처노트',
    },
    publisher: {
      '@type': 'Organization',
      name: '티처노트',
      logo: {
        '@type': 'ImageObject',
        url: 'https://teachernote.me/logo512.png',
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://teachernote.me/post-education-resource',
    },
  };

  return (
    <Helmet>
      <title>글쓰기 - 교육 자료 공유 | 티처노트</title>
      <meta
        name="description"
        content="교사와 학생을 위한 교육 자료를 공유하고, 새로운 글을 작성해보세요. 수업 자료와 학습 정보를 쉽게 기록하고 나눌 수 있습니다."
      />
      <meta
        name="keywords"
        content="글쓰기, 교육 자료, 학습 자료 작성, 교육 정보 공유, 교사 블로그, 티처노트"
      />

      <meta property="og:title" content="글쓰기 - 교육 자료 공유 | 티처노트" />
      <meta
        property="og:description"
        content="교사와 학생을 위한 교육 자료를 티처노트에 작성하고 공유해보세요."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta
        property="og:url"
        content="https://teachernote.me/post-education-resource"
      />
      <meta property="og:type" content="website" />
      <link
        rel="canonical"
        href="https://teachernote.me/post-education-resource"
      />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export const BlogViewHelmetContent = ({
  title,
  paramCategory,
  paramBlogNum,
}) => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: `${title} | 교육 자료 | 티처노트`,
    description: `${title} - 교사와 학생을 위한 교육 자료와 학습 정보 공유.`,
    url: `https://teachernote.me/education-resources-sharing/${paramCategory}/${paramBlogNum}`,
    image: 'https://teachernote.me/logo512.png',
    author: {
      '@type': 'Organization',
      name: '티처노트',
    },
    publisher: {
      '@type': 'Organization',
      name: '티처노트',
      logo: {
        '@type': 'ImageObject',
        url: 'https://teachernote.me/logo512.png',
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://teachernote.me/education-resources-sharing/${paramCategory}/${paramBlogNum}`,
    },
    articleSection: `${paramCategory}`,
    articleBody: `${title} - 교사와 학생을 위한 교육 자료와 학습 정보 공유.`,
  };
  return (
    <Helmet>
      <title>{`${title} | 교육 자료 | 티처노트`}</title>
      <meta
        name="description"
        content={`${title} - 교사와 학생을 위한 교육 자료와 학습 정보 공유.`}
      />
      <meta
        name="keywords"
        content="교육 자료, 학습 정보, 교사 자료, 교육 자료 공유, 학습자료, 티처노트"
      />

      <meta property="og:title" content={`${title} | 교육 자료 | 티처노트`} />
      <meta
        property="og:description"
        content={`${title} - 교사와 학생을 위한 교육 자료와 학습 정보를 공유합니다.`}
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta
        property="og:url"
        content={`https://teachernote.me/education-resources-sharing/${paramCategory}/${paramBlogNum}`}
      />
      <meta property="og:type" content="article" />
      <link
        rel="canonical"
        href={`https://teachernote.me/education-resources-sharing/${paramCategory}/${paramBlogNum}`}
      />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export const RandomSeatHelmetContent = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: '랜덤 자리뽑기 프로그램 | 티처노트',
    description:
      '쉽고 편하고 재미있는 자리뽑기 프로그램입니다. 자리 설정, 학생설정, 자리뽑기, 배치 결과 기능을 제공하여 학급 관리를 도와드립니다.',
    applicationCategory: 'Education',
    operatingSystem: 'Web',
    softwareVersion: '1.0',
    author: {
      '@type': 'Organization',
      name: '티처노트',
    },
    offers: {
      '@type': 'Offer',
      price: '0',
      priceCurrency: 'KRW',
    },
    url: 'https://teachernote.me/random-seat-assignment',
    image: 'https://teachernote.me/logo512.png',
  };
  return (
    <Helmet>
      <title>랜덤 자리뽑기 프로그램 | 티처노트</title>
      <meta
        name="description"
        content="쉽고 편하고 재미있는 자리뽑기 프로그램입니다. 자리 설정(짝꿍 여부, 성별), 학생설정(전학생, 앞에 앉을 학생, 이름으로 보기), 자리뽑기(제비 섞기, 직접 뽑기, 한번에 뽑기), 배치 결과(자리 교환, 엑셀 저장)기능을 쉽고 편하게 이용해보세요. "
      />
      <meta
        name="keywords"
        content="자리뽑기 프로그램, 랜덤 자리배치, 랜덤 배정, 뽑기 사이트, 자리배치, 자리배치 프로그램, 랜덤 자리 뽑기, 랜덤 뽑기, 뽑기, 학급, 학교, 교실"
      />

      <meta
        property="og:title"
        content="랜덤 자리뽑기 프로그램 - 교실 학급 무작위 자리배치 | 티처노트"
      />
      <meta
        property="og:description"
        content="쉽고 편하고 재미있는 자리뽑기 프로그램입니다. 자리 설정(짝꿍 여부, 성별), 학생설정(전학생, 앞에 앉을 학생, 이름으로 보기), 자리뽑기(제비 섞기, 직접 뽑기, 한번에 뽑기), 배치 결과(자리 교환, 엑셀 저장)기능을 쉽고 편하게 이용해보세요."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta
        property="og:url"
        content="https://teachernote.me/random-seat-assignment"
      />
      <meta property="og:type" content="website" />
      <link
        rel="canonical"
        href="https://teachernote.me/random-seat-assignment"
      />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export const RandomNumberHelmetContent = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: '랜덤 숫자 뽑기 | 티처노트',
    description:
      '랜덤 숫자 추첨을 위한 재미있고 다양한 기능을 제공하는 숫자 추첨기입니다. 회전 룰렛, 젤리 뽑기, 눈사람 랜덤 번호 뽑기를 통해 무작위로 숫자를 추첨해 보세요.',
    applicationCategory: 'Education',
    operatingSystem: 'Web',
    softwareVersion: '1.0',
    author: {
      '@type': 'Organization',
      name: '티처노트',
    },
    offers: {
      '@type': 'Offer',
      price: '0',
      priceCurrency: 'KRW',
    },
    url: 'https://teachernote.me/random-number-picker',
    image: 'https://teachernote.me/logo512.png',
  };

  return (
    <Helmet>
      <title>랜덤 숫자 뽑기 | 티처노트</title>
      <meta
        name="description"
        content="랜덤 숫자, 번호 추첨을 위한 쉽고 재미있는 숫자 추첨기입니다. 회전 룰렛, 젤리 뽑기, 눈사람 랜덤 번호 뽑기를 통해 무작위로 숫자를 선택해 보세요."
      />
      <meta
        name="keywords"
        content="엑셀 랜덤 숫자, 숫자 추첨기, 숫자 추첨, 랜덤번호, 번호 랜덤 뽑기, 네이버 랜덤 숫자 뽑기, 랜덤번호추첨기, 랜덤뽑기, 랜덤숫자뽑기, 무작위 숫자 뽑기, 룰렛"
      />

      <meta
        property="og:title"
        content="랜덤 숫자 뽑기 - 무작위 숫자 추첨기 | 티처노트"
      />
      <meta
        property="og:description"
        content="랜덤 숫자, 번호 추첨을 위한 쉽고 재미있는 숫자 추첨기입니다. 회전 룰렛, 젤리 뽑기, 눈사람 랜덤 번호 뽑기를 통해 무작위로 숫자를 선택해 보세요."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta
        property="og:url"
        content="https://teachernote.me/random-number-picker"
      />
      <meta property="og:type" content="website" />
      <link
        rel="canonical"
        href="https://teachernote.me/random-number-picker"
      />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export const MyPageHelmetContent = () => {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '마이페이지 - 교사 맞춤 정보 관리 | 티처노트',
    description:
      '티처노트 마이페이지에서 나의 계정 정보와 맞춤 설정을 관리하세요. 개인화된 서비스와 교사를 위한 다양한 교육 자료를 활용할 수 있습니다.',
    url: 'https://teachernote.me/my-page',
    inLanguage: 'ko',
    about: {
      '@type': 'EducationalAudience',
      educationalRole: 'Teacher',
    },
    primaryImageOfPage: {
      '@type': 'ImageObject',
      url: 'https://teachernote.me/logo512.png',
      width: 512,
      height: 512,
    },
    potentialAction: {
      '@type': 'ReadAction',
      target: 'https://teachernote.me/my-page',
    },
  };
  return (
    <Helmet>
      <title>마이페이지 - 교사 맞춤 정보 관리 | 티처노트</title>
      <meta
        name="description"
        content="티처노트 마이페이지에서 나의 계정 정보와 맞춤 설정을 관리하세요. 개인화된 서비스와 교사를 위한 다양한 교육 자료를 활용할 수 있습니다."
      />
      <meta
        name="keywords"
        content="마이페이지, 나의 계정, 맞춤 설정, 교사 정보 관리, 티처노트, 교사용 페이지, 교육 자료 관리, 계정 설정"
      />
      <meta
        property="og:title"
        content="마이페이지 - 교사 맞춤 정보 관리 | 티처노트"
      />
      <meta
        property="og:description"
        content="교사 전용 마이페이지에서 계정 정보와 설정을 관리하고, 맞춤 교육 자료와 정보를 확인해보세요."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta property="og:url" content="https://teachernote.me/my-page" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://teachernote.me/my-page" />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
  );
};

export const SignUpHelmetContent = () => {
  const signUpSchemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '회원가입 - 교사 전용 가입하기 | 티처노트',
    description:
      '교사를 위한 티처노트에 회원가입하세요. 맞춤형 자리 뽑기, 진도 관리, 교육 자료 공유 기능을 무료로 이용하실 수 있습니다.',
    url: 'https://teachernote.me/sign-up',
    inLanguage: 'ko',
    about: {
      '@type': 'EducationalAudience',
      educationalRole: 'Teacher',
    },
    primaryImageOfPage: {
      '@type': 'ImageObject',
      url: 'https://teachernote.me/logo512.png',
      width: 512,
      height: 512,
    },
    potentialAction: {
      '@type': 'RegisterAction',
      target: 'https://teachernote.me/sign-up',
    },
  };
  return (
    <Helmet>
      <title>회원가입 - 교사 전용 가입하기 | 티처노트</title>
      <meta
        name="description"
        content="교사를 위한 티처노트에 회원가입하세요. 맞춤형 자리 뽑기, 진도 관리, 교육 자료 공유 기능을 무료로 이용하실 수 있습니다."
      />
      <meta
        name="keywords"
        content="티처노트 회원가입, 교사 전용 가입, 무료 회원가입, 교육 플랫폼, 자리 뽑기, 진도 관리"
      />
      <meta
        property="og:title"
        content="회원가입 - 교사 전용 가입하기 | 티처노트"
      />
      <meta
        property="og:description"
        content="티처노트에서 교사 전용 회원가입으로 다양한 교육 도구와 자료를 활용하세요."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta property="og:url" content="https://teachernote.me/sign-up" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://teachernote.me/sign-up" />
      <script type="application/ld+json">
        {JSON.stringify(signUpSchemaData)}
      </script>
    </Helmet>
  );
};

export const LoginHelmetContent = () => {
  const loginSchemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '로그인 - 티처노트 | 교사 전용 로그인',
    description:
      '티처노트에 로그인하여 교사 전용 기능을 이용해보세요. 자리 뽑기, 진도표 작성, 교육 자료 등을 관리할 수 있습니다.',
    url: 'https://teachernote.me/login',
    inLanguage: 'ko',
    about: {
      '@type': 'EducationalAudience',
      educationalRole: 'Teacher',
    },
    primaryImageOfPage: {
      '@type': 'ImageObject',
      url: 'https://teachernote.me/logo512.png',
      width: 512,
      height: 512,
    },
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://teachernote.me/login',
    },
  };
  return (
    <Helmet>
      <title>로그인 - 티처노트 | 교사 전용 로그인</title>
      <meta
        name="description"
        content="티처노트에 로그인하여 교사 전용 기능을 이용해보세요. 자리 뽑기, 진도표 작성, 교육 자료 등을 관리할 수 있습니다."
      />
      <meta
        name="keywords"
        content="티처노트 로그인, 교사 로그인, 교육 자료 관리, 자리 뽑기, 진도 관리"
      />
      <meta
        property="og:title"
        content="로그인 - 티처노트 | 교사 전용 로그인"
      />
      <meta
        property="og:description"
        content="티처노트에 로그인하여 교사만의 맞춤 교육 도구를 활용하세요."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta property="og:url" content="https://teachernote.me/login" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://teachernote.me/login" />
      <script type="application/ld+json">
        {JSON.stringify(loginSchemaData)}
      </script>
    </Helmet>
  );
};

export const PrivacyPolicyHelmetContent = () => {
  const privacyPolicySchema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '개인정보처리방침 - 티처노트',
    description:
      '티처노트의 개인정보처리방침을 확인하세요. 회원 정보 보호와 안전한 교육 자료 이용을 위해 노력합니다.',
    url: 'https://teachernote.me/privacy-policy',
    inLanguage: 'ko',
    about: {
      '@type': 'EducationalAudience',
      educationalRole: 'Teacher',
    },
  };
  return (
    <Helmet>
      <title>개인정보처리방침 - 티처노트</title>
      <meta
        name="description"
        content="티처노트의 개인정보처리방침을 확인하세요. 회원 정보 보호와 안전한 교육 자료 이용을 위해 노력합니다."
      />
      <meta
        name="keywords"
        content="개인정보처리방침, 티처노트, 정보 보호, 개인 정보 정책, 데이터 보안"
      />
      <meta property="og:title" content="개인정보처리방침 - 티처노트" />
      <meta
        property="og:description"
        content="티처노트는 교사와 학생의 개인정보 보호를 최우선으로 합니다."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta property="og:url" content="https://teachernote.me/privacy-policy" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://teachernote.me/privacy-policy" />
      <script type="application/ld+json">
        {JSON.stringify(privacyPolicySchema)}
      </script>
    </Helmet>
  );
};

export const FeedbackHelmetContent = () => {
  const feedbackSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '사용자 피드백 - 교사 의견 반영 | 티처노트',
    description:
      '티처노트에 대한 교사들의 소중한 의견을 남겨주세요. 더 나은 교육 도구 개발을 위해 노력하겠습니다.',
    url: 'https://teachernote.me/feedback',
    inLanguage: 'ko',
    about: {
      '@type': 'EducationalAudience',
      educationalRole: 'Teacher',
    },
    potentialAction: {
      '@type': 'CommentAction',
      target: 'https://teachernote.me/feedback',
    },
  };

  return (
    <Helmet>
      <title>사용자 피드백 - 교사 의견 반영 | 티처노트</title>
      <meta
        name="description"
        content="티처노트에 대한 교사들의 소중한 의견을 남겨주세요. 더 나은 교육 도구 개발을 위해 노력하겠습니다."
      />
      <meta
        name="keywords"
        content="사용자 피드백, 교사 의견, 교육 도구 개선, 티처노트 피드백"
      />
      <meta
        property="og:title"
        content="사용자 피드백 - 교사 의견 반영 | 티처노트"
      />
      <meta
        property="og:description"
        content="티처노트에 대한 교사들의 의견을 듣고 더욱 향상된 교육 플랫폼을 만들어갑니다."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta property="og:url" content="https://teachernote.me/feedback" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://teachernote.me/feedback" />
      <script type="application/ld+json">
        {JSON.stringify(feedbackSchema)}
      </script>
    </Helmet>
  );
};

export const NotFoundHelmetContent = () => {
  const notFoundSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '페이지를 찾을 수 없습니다 - 티처노트',
    description:
      '죄송합니다. 요청하신 페이지를 찾을 수 없습니다. 티처노트의 다른 유용한 교육 도구와 자료를 확인해보세요.',
    url: 'https://teachernote.me/404',
    inLanguage: 'ko',
  };
  return (
    <Helmet>
      <title>페이지를 찾을 수 없습니다 - 티처노트</title>
      <meta
        name="description"
        content="죄송합니다. 요청하신 페이지를 찾을 수 없습니다. 티처노트의 다른 유용한 교육 도구와 자료를 확인해보세요."
      />
      <meta
        name="keywords"
        content="404 에러, 페이지 없음, 티처노트 오류, 페이지를 찾을 수 없음"
      />
      <meta
        property="og:title"
        content="페이지를 찾을 수 없습니다 - 티처노트"
      />
      <meta
        property="og:description"
        content="요청하신 페이지를 찾을 수 없습니다. 티처노트의 교육 자료와 도구를 이용해보세요."
      />
      <meta property="og:image" content="https://teachernote.me/logo512.png" />
      <meta property="og:url" content="https://teachernote.me/404" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href="https://teachernote.me/404" />
      <script type="application/ld+json">
        {JSON.stringify(notFoundSchema)}
      </script>
    </Helmet>
  );
};
// export const HelmetContent = () => {
//   return (
//     <Helmet>
//       <meta
//         name="description"
//         content="교실 자리뽑기, 진도기록 프로그램- 하루를 함께하는 티처노트"
//       />
//       <meta
//         property="og:title"
//         content="티처노트 - 하루를 함께하는 선생님 노트"
//       />
//       <meta
//         property="og:description"
//         content="교실 자리뽑기, 진도기록 프로그램- 하루를 함께하는 티처노트"
//       />
//       <meta property="og:image" content="https://teachernote.me/logo512.png" />
//     </Helmet>
//   );
// };
