import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import PageContainer from '../../pageLayout/PageContainer';
import BlogListContainer from './BlogListContainer';
import {
  PageBox,
  PageBox_Blog,
  PageSubheader,
} from '../../pageLayout/PageContainerStyle';
import ArticleMenuBar from '../../topSubMenuBar/ArticleMenuBar';
import { useParams } from '../../../node_modules/react-router-dom/dist/index';
import { EducationResourcesSharingHelmetContent } from '../../components/HelmetContent';

//수업, 평가 외에 모든 블로그 보여주는 페이지
//모바일에서 TALK 부분에 나오는 것
const MobileTalkBlogListPage = () => {
  const [blogDB, setBlogDB] = useState([]);
  const { paramCategory } = useParams(); // URL 파라미터로부터 카테고리 가져오기

  const fetchBlogs = useCallback(async () => {
    try {
      const res = await axios.get('https://teachernote.me/api/blogs/');
      setBlogDB(res.data);
      // console.log('blogDB:' + JSON.stringify(res.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 스크롤을 맨 위로 이동
  }, [paramCategory]); // paramCategory가 변경될 때마다 실행

  // 'activities'와 'materials' 카테고리를 제외한 블로그 필터링
  const filteredBlogs = blogDB.filter(
    (blog) =>
      blog.category !== 'classroom-learning-activities' &&
      blog.category !== 'lesson-materials',
  );

  // 업데이트 날짜에 따라 내림차순 정렬
  const sortedBlogs = [...filteredBlogs].sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  );

  return (
    <>
      <EducationResourcesSharingHelmetContent />
      <PageContainer>
        <PageSubheader>
          <ArticleMenuBar />
        </PageSubheader>

        <PageBox_Blog>
          <BlogListContainer blogs={sortedBlogs} />
        </PageBox_Blog>
      </PageContainer>
    </>
  );
};

export default MobileTalkBlogListPage;
