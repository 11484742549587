import { useMediaQuery } from 'react-responsive';
import KoreanDate from '../../commonFunction/KoreanDate';
import {
  AttachedFile,
  BlogContent,
  BlogInfo,
  BlogInfoLeft,
  BlogInfoMobile,
  BlogInfoRight,
  BlogTitle,
  BlogViewBox,
  BlogViewContainer,
  BlogViewTitleHr,
  GoToList,
} from './BlogViewStyle';
import { OneByOneButton } from '../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import { colors, fontWeight, gray } from '../../styles/commonStyle';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaArrowUp } from 'react-icons/fa';
import {
  DisplayColumn,
  DisplayRowSpaceBetween,
  YellowBadge,
} from '../../styles/BasicStyle';

export const ScrollTopButton = styled.button`
  position: fixed;
  bottom: 160px;

  right: 20px;
  padding: 10px;
  border: none;
  border-radius: 50%;
  background-color: ${colors.primary}; // 주 색상으로 설정
  color: white;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 10;
`;

const categoryLabels = {
  'talk-community': 'TALK',
  announcement: '공지사항',
  'lesson-materials': '수업자료',
  'classroom-learning-activities': '수업활동',
  'performance-evaluation': '수행평가',
  'classroom-group-activities': '학급활동',
  'classroom-decoration': '교실꾸미기',
  'student-recommendations': '학생 아이템 추천',
  'teacher-recommendations': '교사 아이템 추천',
  'work-materials': '업무자료',
  'teacher-training': '교원연수',
  'education-magazine': '교육 매거진',
};

const BlogView = ({ selectedBlog }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const navigate = useNavigate();

  const categoryLabel =
    categoryLabels[selectedBlog.category] || selectedBlog.category;

  const fileName = selectedBlog.fileUrl
    ? selectedBlog.fileUrl.split('/').pop()
    : '';

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <BlogViewContainer>
      <BlogViewBox>
        {isMobile && (
          <BlogInfoMobile>
            {/* {selectedBlog.category} */}
            {categoryLabel}
            <BlogTitle>{selectedBlog.title}</BlogTitle>
            <div>
              {KoreanDate(selectedBlog.updatedAt)} {selectedBlog.userId}
            </div>
          </BlogInfoMobile>
        )}
        {isTablet && (
          <BlogInfo>
            <DisplayColumn>
              <DisplayRowSpaceBetween>
                <div>
                  {/* {selectedBlog.category} */}
                  {categoryLabel}
                </div>
                <GoToList onClick={handleGoBack}>목록보기</GoToList>
              </DisplayRowSpaceBetween>

              <BlogTitle>{selectedBlog.title}</BlogTitle>
              <div>
                {KoreanDate(selectedBlog.updatedAt)} &nbsp;{selectedBlog.userId}
              </div>
            </DisplayColumn>
          </BlogInfo>
        )}
        {isDesktop && (
          <BlogInfo>
            <BlogInfoLeft>
              {/* {selectedBlog.category} */}
              {categoryLabel}
              <BlogTitle>{selectedBlog.title}</BlogTitle>
            </BlogInfoLeft>
            <BlogInfoRight>
              <GoToList onClick={handleGoBack}>목록보기</GoToList>
              <div>
                {KoreanDate(selectedBlog.updatedAt)} &nbsp;{selectedBlog.userId}
              </div>
            </BlogInfoRight>
          </BlogInfo>
        )}
        {/* {isMobile ? (
          <BlogInfoMobile>
            {selectedBlog.category}
            <BlogTitle>{selectedBlog.title}</BlogTitle>
            <div>
              {KoreanDate(selectedBlog.updatedAt)} {selectedBlog.userId}
            </div>
          </BlogInfoMobile>
        ) : (
          <BlogInfo>
            <BlogInfoLeft>
              {selectedBlog.category}
              <BlogTitle>{selectedBlog.title}</BlogTitle>
            </BlogInfoLeft>
            <BlogInfoRight>
              <GoToList onClick={handleGoBack}>목록보기</GoToList>
              <div>
                {KoreanDate(selectedBlog.updatedAt)} &nbsp;{selectedBlog.userId}
              </div>
            </BlogInfoRight>
          </BlogInfo>
        )} */}

        <BlogViewTitleHr />

        <BlogContent
          dangerouslySetInnerHTML={{ __html: selectedBlog.content }}
        ></BlogContent>
        {selectedBlog.fileUrl && (
          <AttachedFile>
            <a
              href={`https://teachernote.me/api/download/${encodeURIComponent(
                fileName,
              )}`}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <OneByOneButton
                style={{
                  backgroundColor: `${colors.yellow}`,
                  color: 'black',
                  border: 'none',
                }}
              >
                공유 자료
              </OneByOneButton>

              <span>{fileName}</span>
            </a>
          </AttachedFile>
        )}
      </BlogViewBox>
    </BlogViewContainer>
  );
};

export default BlogView;
