import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { NotFoundHelmetContent } from '../../components/HelmetContent';
import avatar036 from '../../../src/img/avatar/036.png';
import { colors, gray, margin, padding } from '../../styles/commonStyle';
import { useMediaQuery } from 'react-responsive';
import { media } from '../../styles/media';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const imgWidth = !isMobile ? '300px' : '40vw';
  const textSize = !isMobile ? '1rem' : '0.8rem';
  // 애니메이션 효과 정의
  const floating = keyframes`
    0% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
  `;

  // 스타일 컴포넌트
  const Container = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: ${colors.darkGray};
    // background-color: ${colors.lightGreenBackground};
    background: linear-gradient(
      135deg,
      ${colors.lightGreenBorder} 30%,
      ${gray.light}
    );
  `;

  const Image = styled.img`
    width: ${imgWidth};
    animation: ${floating} 2s ease-in-out infinite;
    margin-bottom: ${margin.medium};
  `;

  const Title = styled.h1`
    font-size: 4rem;
    margin: 0;
  `;

  const Subtitle = styled.p`
    font-size: ${textSize};
    color: ${colors.secondary};
    margin: ${margin.small} 0;
    margin-bottom: ${margin.mediumlarge};

    line-height: 1.5;
  `;

  const BackButton = styled.button`
    background-color: ${colors.primary};
    color: white;
    border: none;
    padding: 10px 20px;
    ${media.mobile`
      // padding:${padding.small} ${padding.medium};
      font-size: 0.8rem;
      `}
    font-size: 1rem;
    cursor: pointer;
    border-radius: 8px;
    // transition: background-color 0.3s ease;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    &:hover {
      background-color: ${colors.secondary};
      // transform: scale(1.05);
    }
  `;

  // 돌아가기 버튼 클릭 이벤트
  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <>
      <NotFoundHelmetContent />
      <Container>
        <Image src={avatar036} alt="캐릭터" />
        <Title>404</Title>
        <Subtitle>여긴 아무것도 없어요... 길을 잃으셨나요?</Subtitle>
        <BackButton onClick={handleGoBack}>홈으로 돌아가기</BackButton>
      </Container>
    </>
  );
};

export default NotFoundPage;
