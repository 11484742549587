import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdAdd } from 'react-icons/md';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import KoreanDate from '../../commonFunction/KoreanDate';
import GradeContext, { GradeConsumer } from '../../contexts/grade';
import UserContext from '../../contexts/userContext';
import {
  StyledTable,
  StyledThead,
  StyledTbody,
  StyledTh,
  StyledTr,
  StyledTd,
  TopicCell,
  StyledLink,
  TopicCellTitle,
  StyledTopicInput,
  SubmitIcon,
  InputContainer,
  CheckNoteDiv,
  StyledFirstTd,
  CheckProgressDiv,
  HaveProgressDiv,
  NoProgressDiv,
  CheckProgressCell,
  ProgressTableSelectMode,
  CheckDateDivNoData,
  CheckDateDivHaveData,
  ProgressTableClass,
  ProgressTableClassDiv,
  DateShown,
  FirstTr,
  ProgressTableSelectShowTitle,
  TableContainer,
  StyledFirstTh,
} from './ProgressTableStyle';
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';
import { MdStickyNote2 } from 'react-icons/md';
import { StyledCard } from '../../components/StyledCard';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { EmptyTopicList } from '../topic/TopicStyle';
import { PrimaryText } from './ProgressNoteStyle';
import { colors, gray } from '../../styles/commonStyle';
import Tooltip from '../../components/Tooltip';
import LeftTooltip from '../../components/LeftTooltip';
import TooltipRight from '../../components/TooltipRight';
import TooltipBottom from '../../components/TooltipBottom';
import MobileDatePickerModal from './MobileDatePickerModal';

export default function ProgressTable({
  topics,
  classInfoByGrade,
  progressByTopicAndGrade,
  checkProgress,
  updateTopicTitle,
  handleProgressNote,
  progressNoteByGradeDB,
}) {
  //현재 학년 받아오기
  const { state: gradeState } = useContext(GradeContext);
  const grade = gradeState.currentGrade;
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;

  const [showTopicStart, setShowTopicStart] = useState(0);
  const [numOfShowTopic, setNumOfShowTopic] = useState(5);

  const [showPast, setShowPast] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedProgress, setSelectedProgress] = useState([]);

  const [showTopicTitle, setShowTopicTitle] = useState(true);
  const isMobile = window.innerWidth <= 768;

  //모바일이면 showTopicTitle의 기본값을 false로

  useEffect(() => {
    // 화면의 크기를 감지하여 모바일인지 여부를 확인
    const isMobile = window.innerWidth <= 768; // 768px 이하를 모바일로 간주
    setShowTopicTitle(!isMobile);
  }, []);

  // 학년 변경 시 스크롤 맨 위로
  const tableRef = useRef(null);
  const scrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [userId, grade]);

  //---------------- 현재 진도, 전체 진도보기 설정----------------//
  //현재 진도만 보기(default)
  useEffect(() => {
    if (!showPast) {
      showNowTopic();
    }
  }, [userId, topics, progressByTopicAndGrade, grade, showPast]);

  //전체 진도 보기
  const showAllTopic = () => {
    setShowPast(true);
    setSelectedTopics(topics);
    setSelectedProgress(progressByTopicAndGrade);
    setShowTopicStart(0);
    setNumOfShowTopic(topics.length);
  };

  //현재 진도 보기
  const showNowTopic = () => {
    setShowPast(false);
    //현재 학년의 class 전부 확인 (classInfo)
    const classList = classInfoByGrade.map((obj) => obj.class);
    const classNameList = classList.map((number) => 'class' + number);

    //현재 학년의 topic 중 class가 전부 null이 아닌 topic 찾기
    const doneTopics = progressByTopicAndGrade
      .filter((row) => {
        return classNameList.every((column) => row[column] !== null);
      })
      .map((row) => row.topicNumber);

    if (doneTopics.length > 0) {
      doneTopics.sort((a, b) => a - b); //숫자 순서대로
      doneTopics.pop(); //마지막 항목 삭제

      //topics 데이터 필터
      setSelectedTopics(
        topics.filter((obj) => !doneTopics.includes(obj.topicId)),
      );
      //progressbyclass 데이터 필터
      setSelectedProgress(
        progressByTopicAndGrade.filter(
          (obj) => !doneTopics.includes(obj.topicNumber),
        ),
      );
    } else {
      setSelectedTopics(topics);
      setSelectedProgress(progressByTopicAndGrade);
    }
    setShowTopicStart(0);
    setNumOfShowTopic(5);
  };

  //---------------- 기본 진도(날짜) 데이터 세팅 ----------------//
  const [selectedDates, setSelectedDates] = useState(new Map());

  //진도 데이터 화면에 보여주기
  const findProgress = (classNum, topicId) => {
    // 학년이 'currentGrade'이고 수업순서가 'topicId'인 행 찾기(없을수도 있음)
    const targetRow = progressByTopicAndGrade.find(
      (row) => row.topicNumber === topicId,
    );
    //targetRow
    let progressValue = null;
    let progressValueDate = null;

    if (targetRow) {
      const classColumn = 'class' + classNum;
      if (targetRow[classColumn]) {
        progressValue = targetRow[classColumn];
        progressValueDate = KoreanDate(progressValue);
      }
    }

    return progressValue;
  };

  // 부모 컴포넌트에서 호출하는 코드
  const updateSelectedDates = () => {
    const newSelectedDates = new Map();
    // 모든 클래스와 토픽에 대해 findProgress 함수 호출하여 selectedDates 업데이트
    topics.forEach((topic) => {
      classInfoByGrade.forEach((classData) => {
        const classNum = classData.class;
        const topicId = topic.topicId;
        const progressValueDate = findProgress(classNum, topicId);
        if (progressValueDate) {
          const key = JSON.stringify([classNum, topicId]);
          newSelectedDates.set(key, progressValueDate);
        }
      });
    });
    setSelectedDates(newSelectedDates);
  };

  // useEffect를 통해 컴포넌트가 마운트될 때 한 번 호출하거나, topics 또는 classInfoByGrade가 변경될 때 호출
  useEffect(() => {
    updateSelectedDates();
  }, [userId, topics, classInfoByGrade]);

  //---------------- 날짜 선택  ----------------//

  //React date picker 포멧 변경
  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <DateShown className="custom-date-input" onClick={onClick} ref={ref}>
      {' '}
      {value ? value : '날짜선택'}
    </DateShown>
  ));

  const HaveDateCustomInput = forwardRef(
    ({ value, onClick, prevValue }, ref) => {
      const prevValueKor = KoreanDate(prevValue);

      return (
        <DateShown className="custom-date-input" onClick={onClick} ref={ref}>
          {value ? value : prevValueKor}
        </DateShown>
      );
    },
  );

  // 날짜 선택 핸들러
  const handleDateSelect = (classNum, topicId, date) => {
    const key = JSON.stringify([classNum, topicId]);
    setSelectedDates(new Map(selectedDates.set(key, date)));
  };

  const saveDate = (classNum, topicId, date) => {
    //datepicker로 날짜 선택 시 하루 전 날짜로 출력되는 것 방지
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
    );
    handleDateSelect(classNum, topicId, utcDate);
    const fullClassNum = 'class' + classNum;
    checkProgress(grade, fullClassNum, topicId, utcDate);
  };

  //---------------- 클릭시 topicTitle 변경  ----------------//

  const [editable, setEditable] = useState(null);
  const [value, setValue] = useState();

  const editOn = (event, topicId, topicTitle) => {
    event.stopPropagation(); // 이벤트 전파 막음
    setEditable(topicId);
    setValue(topicTitle);
  };

  const onChange = useCallback((e) => {
    const titleValue = e.target.value;
    if (titleValue.length <= 50) {
      setValue(titleValue);
    } else {
    }
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      // input 요소를 포함하지 않은 곳을 클릭했을 때
      if (editable && !event.target.closest('input')) {
        setEditable(null);
      }
    },
    [editable],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleKeyDown = (e, topicId) => {
    if (e.key === 'Enter') {
      updateTopicTitle(topicId, value);
      setValue('');
      setEditable(null);
      e.preventDefault();
    } else if (e.key === 'Escape') {
      setEditable(null);
      e.preventDefault();
    }
  };
  const submitTopic = (topicId) => {
    updateTopicTitle(topicId, value);
    setValue('');
    setEditable(null);
  };

  //---------------- 진도 노트 ----------------//

  //학년이 currentGrade, 학급이 classNum, 주제가 topicNum인 행 찾기
  const findProgressNote = (classNum, topicNum) => {
    let targetRow = null;
    if (!userId) {
      targetRow = progressNoteByGradeDB.find(
        (row) => row.classNum == classNum && row.topicNum == topicNum,
      );
    } else {
      targetRow = progressNoteByGradeDB.find(
        (row) =>
          row.grade == gradeState.currentGrade &&
          row.classNum == classNum &&
          row.topicNum == topicNum,
      );
    }
    let showValue = null;
    if (targetRow) {
      if (targetRow['record']) {
        showValue = '노트보기';
      }
    }
    return showValue;
  };
  // console.log('progressNoteByGradeDB:' + JSON.stringify(progressNoteByGradeDB));
  // console.log('selectedTopics:' + JSON.stringify(selectedTopics));

  const navigate = useNavigate();

  //페이지 높이 구하기
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize); // 윈도우 크기가 변경될 때마다 실행됩니다.
    return () => {
      window.removeEventListener('resize', handleResize); // 컴포넌트가 unmount될 때 cleanup
    };
  }, []);

  return (
    <GradeConsumer>
      {({ gradeState, actions }) => (
        <>
          {/* <MobileDatePickerModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        classNum={modalClassNum}
        topicId={modalTopicId}
        selectedDate={modalSelectedDate}
        onDateChange={handleModalDateChange}
        onSave={handleSaveDateInModal}
      /> */}
          {selectedTopics.length === 0 ? (
            <>
              <EmptyTopicList>
                수업주제 설정에서 수업 주제를 추가해보세요!
              </EmptyTopicList>
              <PrimaryText
                onClick={() => {
                  navigate(`/topic-management`);
                }}
              >
                추가하기{' '}
              </PrimaryText>
            </>
          ) : (
            <StyledCard
            // style={
            //   isMobile
            //     ? { margin: '-20px', width: '100%', boxShadow: 'none' }
            //     : {}
            // }
            >
              <TableContainer
                height={viewportHeight}
                width={viewportWidth}
                ref={tableRef}
              >
                <StyledTable style={{ border: '1px solid black' }}>
                  <StyledThead>
                    <FirstTr>
                      <StyledFirstTh className="fixed-column">
                        {showTopicTitle ? (
                          <ProgressTableSelectShowTitle
                            onClick={() => {
                              setShowTopicTitle(false);
                            }}
                          >
                            <TooltipRight text="수업명 없이 순서만 보려면 클릭!">
                              수업명 생략
                            </TooltipRight>
                          </ProgressTableSelectShowTitle>
                        ) : (
                          <ProgressTableSelectShowTitle
                            onClick={() => {
                              setShowTopicTitle(true);
                            }}
                          >
                            {' '}
                            <TooltipRight text="수업명을 표시하려면 클릭!">
                              수업명 <br />
                              보기
                            </TooltipRight>
                          </ProgressTableSelectShowTitle>
                        )}
                      </StyledFirstTh>

                      {/* 첫 row 학급 정보 */}
                      {classInfoByGrade &&
                        classInfoByGrade.map((infoData, index) => (
                          <StyledTh key={index}>
                            <ProgressTableClass
                            // to={`/progress-notes/by-class/${infoData.class}`}
                            >
                              <ProgressTableClassDiv>
                                {' '}
                                {infoData.grade}-{infoData.class}{' '}
                              </ProgressTableClassDiv>
                            </ProgressTableClass>
                          </StyledTh>
                        ))}
                    </FirstTr>
                  </StyledThead>
                  <StyledTbody>
                    {showPast ? (
                      <StyledTr>
                        <StyledFirstTd className="fixed-column"></StyledFirstTd>
                        <td colSpan={classInfoByGrade.length}>
                          <ProgressTableSelectMode onClick={showNowTopic}>
                            <LeftTooltip text="현재 진행중인 5개 진도만 보려면 클릭!">
                              현재 진도 보기
                            </LeftTooltip>
                          </ProgressTableSelectMode>
                        </td>
                      </StyledTr>
                    ) : (
                      <>
                        <StyledTr>
                          <StyledFirstTd className="fixed-column"></StyledFirstTd>
                          <td
                            colSpan={classInfoByGrade.length}
                            onClick={showAllTopic}
                            style={{ borderRight: `1px solid ${gray.light}` }}
                          >
                            <ProgressTableSelectMode>
                              <LeftTooltip text="이미 완료된 진도 및 앞으로 남은 진도를 보려면 클릭!">
                                이전 진도 보기
                              </LeftTooltip>
                            </ProgressTableSelectMode>
                          </td>
                        </StyledTr>
                      </>
                    )}

                    {/* row 개수: topic 수에 맞춰 생성  */}
                    {selectedTopics &&
                      selectedTopics
                        .slice(showTopicStart, numOfShowTopic)
                        .map((topic, topicIndex) => (
                          <StyledTr key={topicIndex}>
                            {/* 맨 왼쪽 column : 수업 순서 */}
                            <StyledFirstTd
                              className="fixed-column"
                              $showTopicTitle={showTopicTitle}
                            >
                              <TopicCell>
                                <StyledLink
                                // onClick={() => {
                                //   setShowTopicTitle(!showTopicTitle);
                                // }}
                                // to={`/progress-notes/by-topic/${topic.topicId}`}
                                >
                                  {topic.topicId}
                                </StyledLink>
                                {showTopicTitle ? (
                                  editable === topic.topicId ? (
                                    <InputContainer>
                                      <StyledTopicInput
                                        placeholder={topic.topicTitle}
                                        type="text"
                                        value={value !== null ? value : ''}
                                        onBlur={handleClickOutside}
                                        onChange={onChange}
                                        onKeyDown={(e) =>
                                          handleKeyDown(e, topic.topicId)
                                        }
                                      />
                                      <SubmitIcon
                                        onClick={() =>
                                          submitTopic(topic.topicId)
                                        }
                                      >
                                        <FaCheckCircle size={20} />
                                      </SubmitIcon>
                                    </InputContainer>
                                  ) : (
                                    <TopicCellTitle
                                      onClick={(event) =>
                                        editOn(
                                          event,
                                          topic.topicId,
                                          topic.topicTitle,
                                        )
                                      }
                                    >
                                      <TooltipRight text="수업명을 수정하려면 클릭!'수업주제 설정'메뉴에서 한번에 관리 가능!">
                                        {topic.topicTitle === '' ||
                                        topic.topicTitle == null
                                          ? `수업${topic.topicId}`
                                          : topic.topicTitle}
                                      </TooltipRight>
                                    </TopicCellTitle>
                                  )
                                ) : (
                                  <>
                                    {/* showTopicTitle이 false일 때 반환할 JSX */}
                                  </>
                                )}
                              </TopicCell>
                            </StyledFirstTd>

                            {/* 그 다음 column들 : 학급수에 맞춰 개수 생성 */}
                            {classInfoByGrade &&
                              classInfoByGrade.map((classData, classIndex) => (
                                <StyledTd key={`class-${classIndex}`}>
                                  <CheckProgressCell>
                                    <CheckProgressDiv>
                                      {findProgress(
                                        classData.class,
                                        topic.topicId,
                                      ) ? (
                                        <HaveProgressDiv>
                                          <div
                                            onClick={() =>
                                              checkProgress(
                                                grade,
                                                `class${classData.class}`,
                                                topic.topicId,
                                              )
                                            }
                                          >
                                            <FaCheckCircle size={27} />
                                          </div>
                                          <CheckDateDivHaveData>
                                            <DatePicker
                                              dateFormat="MM/dd(eee)"
                                              selected={selectedDates.get(
                                                JSON.stringify([
                                                  classData.class,
                                                  topic.topicId,
                                                ]),
                                              )}
                                              onChange={(date) =>
                                                saveDate(
                                                  classData.class,
                                                  topic.topicId,
                                                  date,
                                                )
                                              }
                                              customInput={
                                                <HaveDateCustomInput
                                                  prevValue={findProgress(
                                                    classData.class,
                                                    topic.topicId,
                                                  )}
                                                />
                                              }
                                              locale="ko"
                                              showPopperArrow={false} // 팝업 화살표 감추기
                                              popperPlacement="auto" // 팝업 위치 자동 조정
                                              popperModifiers={{
                                                // popper가 테이블의 다른 요소 위에 위치하도록 설정
                                                preventOverflow: {
                                                  enabled: true,
                                                  boundariesElement: 'viewport',
                                                },
                                              }}
                                              withPortal
                                            />
                                          </CheckDateDivHaveData>
                                        </HaveProgressDiv>
                                      ) : (
                                        <NoProgressDiv>
                                          <div
                                            onClick={() =>
                                              checkProgress(
                                                grade,
                                                `class${classData.class}`,
                                                topic.topicId,
                                              )
                                            }
                                          >
                                            <FaRegCheckCircle size={27} />
                                          </div>
                                          <CheckDateDivNoData>
                                            <DatePicker
                                              dateFormat="MM/dd(eee)"
                                              selected={selectedDates.get(
                                                JSON.stringify([
                                                  classData.class,
                                                  topic.topicId,
                                                ]),
                                              )}
                                              onChange={(date) =>
                                                saveDate(
                                                  classData.class,
                                                  topic.topicId,
                                                  date,
                                                )
                                              }
                                              customInput={<DateCustomInput />}
                                              locale="ko"
                                              showPopperArrow={false} // 팝업 화살표 감추기
                                              popperPlacement="auto" // 팝업 위치 자동 조정
                                              popperModifiers={{
                                                // popper가 테이블의 다른 요소 위에 위치하도록 설정
                                                preventOverflow: {
                                                  enabled: true,
                                                  boundariesElement: 'viewport',
                                                },
                                              }}
                                              withPortal
                                            />
                                          </CheckDateDivNoData>
                                        </NoProgressDiv>
                                      )}
                                    </CheckProgressDiv>{' '}
                                    <CheckNoteDiv
                                      onClick={() =>
                                        handleProgressNote(
                                          grade,
                                          classData.class,
                                          topic.topicId,
                                          topic.topicTitle,
                                        )
                                      }
                                    >
                                      {findProgressNote(
                                        classData.class,
                                        topic.topicId,
                                      ) ? (
                                        <MdStickyNote2
                                          size={20}
                                          color={`${colors.primary}`}
                                        />
                                      ) : (
                                        <MdAdd size={20} />
                                      )}
                                    </CheckNoteDiv>{' '}
                                  </CheckProgressCell>
                                </StyledTd>
                              ))}
                          </StyledTr>
                        ))}
                  </StyledTbody>
                </StyledTable>
              </TableContainer>
            </StyledCard>
          )}
        </>
      )}
    </GradeConsumer>
  );
}
