import { useEffect, useRef, useState } from 'react';
import ModalExample from '../../../components/Modal';
import {
  AppContainer,
  CurrentNumber,
  DrawBox_Bottom,
  DrawBox_Title,
  DrawBox_Main,
  DrawContainer_Top,
  NumberDisplayContainer,
  OneByOneButton,
  DrawBox_Main_Color,
  HightlightNumber,
  HightLightNumberContainer,
  DrawModalInner,
  DrawModal,
  ModalButtons,
  ModalText,
  StartDraw,
  StartDrawButton,
  TextEmphasis,
  CompletionMessage,
  setIsRevealAll,
  isRevealAll,
  ModalName,
} from '../OneByOneStyle';
import Draw from './Draw';
import Tooltip from '../../../components/Tooltip';
import { MenuItem } from '../../../components/DropdownMenu';
import logo_horizontal from '../../../img/teachernote-logo/teachernote-logo-horizontal.png';
import logo_sound from '../../../sound/teachernote.mp3';
import { DisplayRow, NewBadge } from '../../../styles/BasicStyle';
const OneByOne = ({
  numberList,
  notUsingNumbers,
  numberSeatMap,
  completedNum,
  setCompletedNum,
  isDrawOk,
  setIsDrawSettingOpen,
  isDrawSettingOpen,
  confirmIsDrawOk,
  currentNumIndex,
  setCurrentNumIndex,
  clickedJebiNums,
  setClickedJebiNums,
  jebiPositions,
  setJebiPositions,
  setIsDrawOk,
  isLargeResultMode,
  setIsLargeResultMode,
  remainingNumbers,
  setIsRevealAll,
  isRevealAll,

  isClickedByJebi,
  setIsClickedByJebi,
  seatDrawingCompleted,
  students,
  showNameMode,
  downloadExcel,
}) => {
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [shuffling, setShuffling] = useState(false);
  const [seats, setSeats] = useState({}); // 각 번호별 선택된 좌석 기록
  const [drawingResult, setDrawingResult] = useState({});
  const [showModal, setShowModal] = useState(true);

  const validNumbers = numberList.filter(
    (num) => !notUsingNumbers.includes(num),
  );

  // -------------------- 뽑을 순서 번호  -------------------- //
  const getCurrentNumber = () => {
    // numberList에서 notUsingNumbers를 제외한 리스트를 생성
    return validNumbers[currentNumIndex];
  };

  //뽑을 차례에 notUsingNumber가 포함되었을 경우
  const getNextAvailableNumber = () => {
    let index = currentNumIndex;
    return index < validNumbers.length ? validNumbers[index] : undefined;
  };
  const nextNumber = getNextAvailableNumber();

  // -------------------- N번째줄 표시  -------------------- //

  // 내 자리 알려주기
  useEffect(() => {
    if (
      currentNumIndex > 0 &&
      numberSeatMap[validNumbers[currentNumIndex - 1]]
    ) {
      const totalRows = new Set(
        Object.values(numberSeatMap).map((seat) => seat.row),
      ).size;

      const currentSeat = numberSeatMap[validNumbers[currentNumIndex - 1]];
      const rowFromTop = currentSeat.row + 1;
      const reversedRow = totalRows - currentSeat.row;
      setReversedRow(reversedRow);

      // 좌석의 열(column)을 계산하여 상태 업데이트
      const columnFromLeft = currentSeat.col + 1;
      setSeatColumn(columnFromLeft);
    } else {
      setReversedRow(null);
      setSeatColumn(null);
    }
  }, [currentNumIndex, numberSeatMap, numberList]);

  // -------------------- 제비 이미지 클릭 시  -------------------- //
  // useEffect(() => {
  //   console.log('currentNumIndex:' + currentNumIndex);
  //   console.log('numberList[currentNumIndex]:' + numberList[currentNumIndex]);
  // }, [currentNumIndex]);
  // useEffect(() => {
  //   // console.log('currentNumIndex-1:' + JSON.stringify(currentNumIndex - 1));
  //   console.log(
  //     '표시:' + JSON.stringify(numberSeatMap[numberList[currentNumIndex - 1]]),
  //   );
  // }, [currentNumIndex]);
  // useEffect(() => {
  //   console.log('numberSeatMap:' + JSON.stringify(numberSeatMap));
  // }, [numberSeatMap]);

  const audioRef = useRef(null);
  const handleJebiSelect = async (JebiNum) => {
    // if (notUsingNumbers.includes(num)) {
    //   // handleNext();
    //   console.log('notusingNumbers:' + num);
    //   setCurrentNumIndex((prevIndex) => {
    //     let newIndex = prevIndex + 1;
    //     while (
    //       newIndex < numberList.length &&
    //       notUsingNumbers.includes(numberList[newIndex])
    //     ) {
    //       newIndex++;
    //     }
    //     return newIndex;
    //   });
    // }

    //음향 효과
    if (audioRef.current) {
      audioRef.current.play();
    }

    //기존 코드
    const num = validNumbers[currentNumIndex];

    setClickedJebiNums((prevClickedNums) => [...prevClickedNums, JebiNum]);
    setSeats((prevSeats) => ({
      ...prevSeats,
      [num]: numberSeatMap[num], // 선택된 좌석 기록
    }));
    setDrawingResult((prevResult) => ({
      ...prevResult,
      [num]: numberSeatMap[num],
    }));
    setCompletedNum((prevNums) => [...prevNums, num]);

    if (showModal) {
      openModal();
    }
    handleNext(); // 자동으로 다음 번호로 넘어감
    setIsClickedByJebi(true);
  };
  const handleNext = () => {
    setIsClickedByJebi(false);
    setCurrentNumIndex((prevIndex) => {
      let newIndex = prevIndex + 1;
      return newIndex;
    });
  };

  const revealAllRemainingNumbers = () => {
    // 모든 JebiNum 추가
    validNumbers.forEach((num) => {
      const JebiNum = num; // 여기서는 num을 JebiNum으로 사용
      setClickedJebiNums((prevClickedNums) => [...prevClickedNums, JebiNum]);
    });
    // 모든 번호에 대해 setSeats, setDrawingResult, setCompletedNum 실행
    validNumbers.forEach((num) => {
      setSeats((prevSeats) => ({
        ...prevSeats,
        [num]: numberSeatMap[num], // 선택된 좌석 기록
      }));
      setDrawingResult((prevResult) => ({
        ...prevResult,
        [num]: numberSeatMap[num],
      }));
      setCompletedNum((prevNums) => [...prevNums, num]);
    });
  };

  // useEffect로 isRevealAll 업데이트
  useEffect(() => {
    if (completedNum.length === numberList.length) {
      setIsRevealAll(true);
    }
  }, [completedNum]);
  useEffect(() => {
    // console.log('isrevealall: ' + isRevealAll);
    if (isRevealAll) {
      revealAllRemainingNumbers();
    }
  }, [isRevealAll]);

  useEffect(() => {
    const totalRequired = numberList.length - notUsingNumbers.length;
    if (completedNum.length === totalRequired) {
      setIsRevealAll(true);
    }
  }, [completedNum, notUsingNumbers]);

  // -------------------- 제비 섞기 -------------------- //
  const shuffleJebi = () => {
    setShuffling(true);
    setTimeout(() => {
      setJebiPositions(
        numberList.map(() => ({
          top: Math.random() * 80,
          left: Math.random() * 80,
        })),
      );
      setShuffling(false);
    }, 1500);
  };

  // -------------------- 앞, 뒤 번호로 이동 -------------------- //
  // const handlePrevious = () => {
  //   setCurrentNumIndex((prevIndex) => {
  //     let newIndex = prevIndex - 1;
  //     while (
  //       newIndex >= 0 &&
  //       (notUsingNumbers.includes(numberList[newIndex]) ||
  //         completedNum.includes(numberList[newIndex]))
  //     ) {
  //       newIndex--;
  //     }
  //     if (newIndex < 0) {
  //       alert(
  //         '앞번호는 전부 뽑기가 완료되었습니다! 완료되지 않은 번호가 있을 때에만 돌아갈 수 있습니다.',
  //       );
  //       return prevIndex; // 이동하지 않음
  //     }
  //     return newIndex;
  //   });
  // };

  // -------------------- 모달 -------------------- //

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleDisableModal = () => {
    setShowModal(false);
    closeModal();
  };

  //엔터 눌러도 모달 사라지게

  // 모달 키보드 이벤트 감지 추가
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((modalIsOpen && event.key === 'Enter') || event.key === 'Escape') {
        closeModal(); // 엔터 키를 눌렀을 때 모달 닫기
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalIsOpen]);

  const [reversedRow, setReversedRow] = useState(null);
  const [seatColumn, setSeatColumn] = useState(null);

  // useEffect(() => {
  //   console.log('currentNumIndex:' + currentNumIndex);
  //   console.log('validNumbers:' + JSON.stringify(validNumbers));
  // }, [currentNumIndex, validNumbers]);
  return (
    <>
      <audio ref={audioRef} src={logo_sound} style={{ display: 'none' }} />
      <ModalExample
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        openModal={openModal}
        closeModal={closeModal}
        currentNumIndex={currentNumIndex}
      >
        <>
          {numberSeatMap[validNumbers[currentNumIndex - 1]] ? (
            <DrawModal>
              <img
                src={logo_horizontal}
                width="100px"
                style={{ opacity: 0.5 }}
                alt="티처노트 가로형 로고"
              />
              <ModalName>
                {showNameMode
                  ? `${validNumbers[currentNumIndex - 1]}번 ${
                      students.find(
                        (student) =>
                          student.number === validNumbers[currentNumIndex - 1],
                      )?.name || ''
                    }`
                  : `${validNumbers[currentNumIndex - 1]}번`}
              </ModalName>
              <ModalText>
                <TextEmphasis>{reversedRow}</TextEmphasis>번째줄{' '}
                <TextEmphasis>
                  {/* {numberSeatMap[currentNumIndex - 1].col + 1} */}
                  {seatColumn}
                </TextEmphasis>
                번째 자리 당첨!🎉
              </ModalText>{' '}
              <ModalButtons>
                <OneByOneButton onClick={closeModal}>확인</OneByOneButton>
                <OneByOneButton onClick={handleDisableModal}>
                  확인창 띄우지 않음
                </OneByOneButton>
              </ModalButtons>
            </DrawModal>
          ) : (
            <>
              {/* currentNumIndex-1: {currentNumIndex - 1}
              <br />
              validNumbers[currentNumIndex-1] :{' '}
              {validNumbers[currentNumIndex - 1]}
              <br />
              numberSeatMap[validNumbers[currentNumIndex-1]]:{' '}
              {numberSeatMap[validNumbers[currentNumIndex - 1]]} */}
            </>
          )}
          {/* <img src="paper2.png" alt="펴진 제비 이미지" /> */}
        </>
      </ModalExample>
      {isDrawOk ? (
        <>
          {currentNumIndex < validNumbers.length ? (
            <>
              {' '}
              <DrawBox_Title>
                <CurrentNumber>
                  <OneByOneButton
                    onClick={() => {
                      setIsRevealAll(true);
                      setCurrentNumIndex();
                    }}
                  >
                    남은 번호 한번에 뽑기
                  </OneByOneButton>
                  <HightLightNumberContainer>
                    뽑을 차례
                    <HightlightNumber>
                      {/* {nextNumber}번 */}
                      {showNameMode
                        ? `${nextNumber}번 ${
                            students.find(
                              (student) => student.number === nextNumber,
                            )?.name || ''
                          }`
                        : `${nextNumber}번`}
                    </HightlightNumber>
                  </HightLightNumberContainer>
                </CurrentNumber>{' '}
              </DrawBox_Title>{' '}
              <DrawContainer_Top>
                {' '}
                종이를 뽑아주세요(원하는 종이 클릭){' '}
                <div>
                  <Tooltip text="제비의 위치를 섞으려면 클릭!">
                    {' '}
                    <OneByOneButton onClick={shuffleJebi}>
                      {' '}
                      종이 섞기{' '}
                    </OneByOneButton>
                  </Tooltip>
                </div>
              </DrawContainer_Top>
              <DrawBox_Main_Color>
                {jebiPositions.length > 0 && (
                  <Draw
                    numberList={numberList}
                    onJebiSelect={handleJebiSelect}
                    positions={jebiPositions}
                    shuffling={shuffling}
                    clickedJebiNums={clickedJebiNums}
                    shuffleJebi={shuffleJebi}
                    notUsingNumbers={notUsingNumbers}
                  />
                )}
              </DrawBox_Main_Color>
            </>
          ) : (
            <StartDraw>
              {/* 🎉 */}
              <div style={{ fontSize: '100px' }}>👏</div>

              <CompletionMessage>자리뽑기 완료!</CompletionMessage>
              <DisplayRow>
                <OneByOneButton
                  onClick={() => {
                    setIsLargeResultMode(true);
                  }}
                >
                  배치 결과 크게 보기
                </OneByOneButton>
                <OneByOneButton
                  onClick={() => {
                    downloadExcel();
                  }}
                >
                  결과 엑셀 다운로드<NewBadge>NEW</NewBadge>
                </OneByOneButton>
              </DisplayRow>
            </StartDraw>
          )}

          {/* <OneByOneButton onClick={resetState}>리셋</OneByOneButton> */}
        </>
      ) : (
        <>
          {completedNum.length ===
          numberList.length - notUsingNumbers.length ? (
            <StartDraw>
              <CompletionMessage>자리뽑기 완료!</CompletionMessage>
              <OneByOneButton
                onClick={() => {
                  setIsLargeResultMode(true);
                }}
              >
                배치 결과 크게 보기
              </OneByOneButton>
            </StartDraw>
          ) : (
            <StartDraw>
              <StartDrawButton
                onClick={() => {
                  if (
                    showNameMode &&
                    students.some((student) => !student.name)
                  ) {
                    alert(
                      '이름이 비어있습니다. 이름을 추가하거나 이름으로 보기 모드를 해제해주세요',
                    );
                  } else {
                    confirmIsDrawOk();
                    setShowModal(true);
                  }
                }}
              >
                티처노트 자리뽑기
                <br />
                START!
              </StartDrawButton>
            </StartDraw>
          )}
        </>
      )}
    </>
  );
};

export default OneByOne;
