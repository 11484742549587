import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { HelmetContent } from '../components/HelmetContent';
import HeaderMenuBar from './HeaderMenuBar';
import {
  PageMain,
  PageContainerBox,
  PageHeader,
  PageFooter,
  FeedbackButton,
  FeedbackIcon,
  IconContainer,
  SNSIcon,
  InstagramIcon,
} from './PageContainerStyle';
import Footer from './Footer';
import HeaderMenuBarMobile from './HeaderMenuBarMobile';
import MobileBottomMenuBar_ver2 from './MobileBottomMenuBar_ver2';
import MobileTopMenuBar from '../topMenuBarMobile/MobileTopMenuBar';
import {
  useLocation,
  useNavigate,
} from '../../node_modules/react-router-dom/dist/index';
import RegisterModalComponent from '../pages/registerModal/RegisterModalComponent';
import LoginModalComponent from '../pages/loginModal/LoginModalComponent';
import { FaArrowUp } from 'react-icons/fa';
import { ScrollTopButton } from '../pages/blogView/BlogView';
import Main_MobileTopMenuBar from '../topMenuBarMobile/Main_MobileTopMenuBar';

const PageContainer = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const location = useLocation();

  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('bottombutton');

  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  // -------------------- 설정 저장->회원가입/로그인 모달  -------------------- //

  // register 창을 띄우고 가입 완료 시 콜백을 실행하는 함수

  const openRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const openLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const closeAuthModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  const handleAuthComplete = async (userId) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  // -------------------- 유저 코멘트 아이콘  -------------------- //

  const navigate = useNavigate();

  const goToInsta = () => {
    navigate('/education-resources-sharing/insta');
  };
  const goToUserComment = () => {
    navigate('/feedback');
  };
  // -------------------- '위로'버튼   -------------------- //

  // 최상단으로 스크롤하는 함수
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      // 특정 스크롤 위치에서 버튼 표시 (예: 300px 이상 스크롤할 경우)
      setShowScrollTopButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <PageContainerBox>
      <PageHeader>
        {!isMobile && (
          <HeaderMenuBar
            isRegisterModalOpen={isRegisterModalOpen}
            setRegisterModalOpen={setRegisterModalOpen}
            isLoginModalOpen={isLoginModalOpen}
            setLoginModalOpen={setLoginModalOpen}
            modalSource={modalSource}
            setModalSource={setModalSource}
          />
        )}
      </PageHeader>
      <RegisterModalComponent
        isRegisterModalOpen={isRegisterModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        openRegisterModal={openRegisterModal}
        openLoginModal={openLoginModal}
        closeModal={closeAuthModal}
        handleAuthComplete={handleAuthComplete}
        modalSource={modalSource}
      />
      {isLoginModalOpen ? (
        <LoginModalComponent
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          openRegisterModal={openRegisterModal}
          openLoginModal={openLoginModal}
          closeAuthModal={closeAuthModal}
          handleAuthComplete={handleAuthComplete}
          modalSource={modalSource}
        />
      ) : (
        <></>
      )}
      {isMobile && <Main_MobileTopMenuBar />}
      <PageMain>{children}</PageMain>
      {isMobile && <MobileBottomMenuBar_ver2 />}
      {location.pathname !== '/login' &&
        location.pathname !== '/sign-up' &&
        location.pathname !== '/lesson-progress-tracker' &&
        location.pathname !== '/random-number-picker' &&
        location.pathname !== '/feedback' &&
        location.pathname !== '/post-education-resource' && (
          <PageFooter>
            <Footer />
          </PageFooter>
        )}
      {!isMobile && (
        <IconContainer>
          {/* 최상단으로 스크롤하는 버튼 */}
          <ScrollTopButton onClick={scrollToTop} show={showScrollTopButton}>
            <FaArrowUp size={38} />
          </ScrollTopButton>
          <FeedbackButton
            // onClick={goToInsta}
            onClick={() =>
              window.open(
                'http://instagram.com/ourteachernote',
                '_blank',
                'noopener',
              )
            }
          >
            <InstagramIcon />
          </FeedbackButton>
          <FeedbackButton onClick={goToUserComment}>
            <FeedbackIcon />
          </FeedbackButton>
        </IconContainer>
      )}
    </PageContainerBox>
  );
};

export default PageContainer;
