import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { colors } from '../styles/commonStyle';
import { Overlay, VisitorCounterContainer } from './VisitorCounterStyle';
import { useMediaQuery } from 'react-responsive';

const VisitorCounter = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [displayCount, setDisplayCount] = useState(0);

  const [totalVisitors, setTotalVisitors] = useState(0);
  const [todayVisitors, setTodayVisitors] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]; // 오늘의 날짜를 'YYYY-MM-DD' 형식으로 가져옴
    const lastVisitDate = localStorage.getItem('lastVisitDate');

    if (lastVisitDate !== today) {
      // 오늘 처음 방문하는 경우
      localStorage.setItem('lastVisitDate', today); // 현재 날짜를 저장
      // 방문자 수 데이터를 가져오는 API 호출
      fetch('https://teachernote.me/api/visitors')
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch visitor data');
          }
          return response.json();
        })
        .then((data) => {
          setTotalVisitors(data.totalVisitors);
          setTodayVisitors(data.todayVisitors);

          // 동적으로 숫자가 올라가는 애니메이션
          const finalCount = data.totalVisitors;
          let start = 0;
          const duration = 500; // 1초 동안 애니메이션 진행
          const incrementTime = 40; // 애니메이션 프레임 속도 (밀리초)
          const increment = finalCount / (duration / incrementTime);

          const counter = setInterval(() => {
            start += increment;
            if (start >= finalCount) {
              start = finalCount;
              clearInterval(counter);
            }
            setDisplayCount(Math.floor(start));
          }, incrementTime);
          // 카운터 표시
          setIsVisible(true);
          // 2초 후에 카운터를 숨김
          setTimeout(() => {
            setIsVisible(false);
          }, 1000);
        })
        .catch((error) => {
          console.error('Error fetching visitor data:', error);
        });
    }

    // 방문자 기록 API 호출
    fetch('https://teachernote.me/api/record-visitor', {
      method: 'POST',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to record visitor');
        }
        // console.log('Visitor recorded successfully');
      })
      .catch((error) => {
        console.error('Error recording visitor:', error);
      });
  }, []);

  if (!isVisible) {
    return null;
  }
  return (
    <>
      {' '}
      {/* <Overlay />
      <VisitorCounterContainer>
        {isMobile ? (
          <p>
            티처노트를 <br /> {displayCount}명이 방문했어요!🎉
          </p>
        ) : (
          <p>{displayCount}명이 티처노트를 방문했어요!🎉</p>
        )}
      </VisitorCounterContainer> */}
    </>
  );
};

export default VisitorCounter;
