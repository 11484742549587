import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import axios from '../../../node_modules/axios/index';
import GradeContext from '../../contexts/grade';
import {
  TopicCard,
  TopicInputBox1,
  TopicInputBox2,
  TopicListItemLayout1,
  TopicListItemLayout2,
  TopicListStyledItem,
  TopicStyledInput,
  TopicStyledInput1,
  TopicStyledInput2,
} from './TopicStyle';
import { CardTitle } from '../../components/StyledCard';
import { FaCheck } from 'react-icons/fa';
import UserContext from '../../contexts/userContext';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { useMediaQuery } from 'react-responsive';

const TopicInput = ({
  topicNumber,
  fetchTopicsByGrade,
  topics,
  setShowNotification,
  setRegisterModalOpen,
}) => {
  const { state: gradeState } = useContext(GradeContext);
  const { state: userState } = useContext(UserContext);
  const cursorRef = useRef(null);
  const userId = userState && userState.user ? userState.user.userId : null;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // useEffect(() => {
  //   if (cursorRef.current) {
  //     cursorRef.current.focus();
  //   }
  // }, []);

  //DB에 업로드
  const [newTopic, setNewTopic] = useState({
    userId: userId,
    grade: gradeState.currentGrade,
    topicId: '',
    topicTitle: '',
  });

  // placeholder를 관리할 상태 정의
  const [placeholder, setPlaceholder] = useState({
    topicId: '순서',
    topicTitle: '수업주제',
  });

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setNewTopic((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    } else {
    }
  };

  //수업 주제 저장

  const navigate = useNavigate();
  const saveTopic = async (e) => {
    e.preventDefault(); // 폼 제출 기본 동작 방지
    // Validate topicId and topicTitle
    if (!userId) {
      alert('로그인 후 이용 가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      if (newTopic.topicId < 1 || newTopic.topicId > 36) {
        alert('순서는 1에서 36 사이의 값이어야 합니다.');
        return;
      }
      if (!newTopic.topicTitle.trim()) {
        // setPlaceholder((prev) => ({ ...prev, topicTitle: '수업 주제' }));
        alert('수업주제를 입력해주세요.');
        return;
      }
      const existingTopic = topics.find(
        (topic) => topic.topicId == newTopic.topicId,
      );

      if (existingTopic) {
        const result = window.confirm(
          '수업 주제가 존재합니다. 업데이트하시겠습니까?',
        );
        if (result) {
          try {
            await axios
              .put('https://teachernote.me/api/topics/' + existingTopic.id, {
                value: newTopic.topicTitle,
              })
              .then(() => {
                fetchTopicsByGrade();
              });
            setNewTopic((prev) => ({ ...prev, topicId: '', topicTitle: '' }));
            setPlaceholder({ topicId: '순서', topicTitle: '수업주제' });
          } catch (err) {
            console.log(err);
          }
        } else {
        }
      } else {
        try {
          await axios
            .post('https://teachernote.me/api/topics', newTopic)
            .then(() => {
              fetchTopicsByGrade();
            });

          setNewTopic((prev) => ({ ...prev, topicId: '', topicTitle: '' }));
          setPlaceholder({ topicId: '순서', topicTitle: '수업주제' });
        } catch {
          console.error(e);
        }
      }
    }
  };
  return (
    <TopicCard>
      <CardTitle>수업 주제 추가</CardTitle>

      <form className="form" onSubmit={saveTopic}>
        <TopicListStyledItem>
          <TopicListItemLayout1>
            <TopicInputBox1>
              <TopicStyledInput1
                type="number"
                placeholder={placeholder.topicId}
                onChange={handleChange}
                name="topicId"
                value={newTopic.topicId}
                min={1}
                max={36}
              />
            </TopicInputBox1>
            <TopicInputBox2>
              <TopicStyledInput2
                type="text"
                placeholder={placeholder.topicTitle}
                onChange={handleChange}
                name="topicTitle"
                ref={cursorRef}
                value={newTopic.topicTitle}
                autoComplete="off"
              />
            </TopicInputBox2>
          </TopicListItemLayout1>
          <button type="submit" style={{ display: 'none' }} />
          <TopicListItemLayout2 onClick={(e) => saveTopic(e)}>
            <FaCheck />
          </TopicListItemLayout2>
        </TopicListStyledItem>
      </form>
    </TopicCard>
  );
};

export default TopicInput;
