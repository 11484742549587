import styled from 'styled-components';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gray,
  margin,
  objHeight,
  padding,
  pageWidth,
  ratio,
} from '../styles/commonStyle';
import { media } from '../styles/media';

export const CardBottomMargin = styled.div`
  margin-bottom: ${margin.large};

  ${media.mobile`
  margin-bottom: ${margin.mediumlarge};
  `}
`;
//사용: progress.js, progressNote.js
export const StyledCard = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${margin.mediumlarge};
  overflow-x: auto;

  border: 1px solid ${gray.light};
  border-radius: ${borderRadius.large};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  background-color: ${colors.background};
  // background-color: yellow;
  font-size: ${fontSize.medium};
`;

export const CardBox = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CardTitle = styled.div`
  height: ${objHeight.large};

  ${media.mobile`
    height: ${objHeight.medium};
    padding: ${padding.medium};
    position: relative;
    font-size:${fontSize.small};
  `}

  font-weight: ${fontWeight.bold};

  border-radius: ${borderRadius.large} ${borderRadius.large} 0px 0px;
  // background-color: ${gray.light};
  background-color: ${colors.lightGreenBackground};

  color: ${gray.darktext};

  cursor: default;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardTitleInner = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// export const CardTitleInner1 = styled.div`
//   width: 100%;

//   display: flex;
//   justify-content: center;
//   // background-color: red;
// `;
// export const CardTitleInner2 = styled.div`
//   // width: 100px;
//   position: absolute;
//   right: ${padding.mediumLarge};
//   z-index: 2;
//   // background-color: yellow;
// `;

export const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const CardBottom = styled.div`
  width: 100%;
  height: ${objHeight.large};
  ${media.mobile`
    height: ${objHeight.medium};
        padding: ${padding.medium} 0;

  `}
  // background-color: ${gray.light};
  background-color: ${colors.lightGreenBackground};

  display: flex;
  flex-direction: row;
`;
export const CardBottomDivide = styled.div`
  width: 50%;

  border-right: 1px solid ${colors.background};

  &:hover {
    // background-color: ${gray.medium};
    background-color: ${colors.lightGreenBorder};
    cursor: pointer;
  }

  &:hover svg {
    transform: scale(1.1);
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;
