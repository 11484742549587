import styled from 'styled-components';
import {
  borderRadius,
  colors,
  fontColors,
  fontSize,
  fontWeight,
  gap,
  gray,
  margin,
  objHeight,
  objWidth,
  padding,
  pageWidth,
  ratio,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const BlogWriteBox = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BlogSubmitContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BlogSubmitContainerLayout1 = styled.div`
  width: 80%;

  font-color: ${gray.darktext};
  // font-weight: ${fontWeight.bold};

  display: flex;
  flex-wrap: wrap;
`;
export const BlogSubmitContainerLayout2 = styled.div`
  width: 20%;
  display: flex;
  justify-content: right;
`;

export const SelectBlogCategory = styled.div`
  padding-right: ${padding.medium};
  // cursor: pointer !important;
  // background-color: yellow;
`;

export const BlogTitleInput = styled.input`
  width: 100%;
  padding: ${padding.large} 0;

  ${media.mobile`
  padding: 0 0 ${padding.medium} 0;
  font-size: ${fontSize.largeMore};
  `}

  font-size: ${fontSize.extraLarge};
  border: none;
  outline: none;

  transition: border-color 0.3s;
  &::placeholder {
    color: ${gray.medium};
  }
`;

export const QuillBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid ${gray.light};

  .react-quill {
    border-radius: ${borderRadius.small};
  }

  .ql-toolbar {
    ${media.desktop`
    width: ${pageWidth.desktop};
  `}

    ${media.tablet`
    width: ${pageWidth.tablet};
  `}

  ${media.mobile`
    // width: ${pageWidth.mobile};
    width: 100%;
  `}
    background-color: ${gray.light};
    border: none;
  }

  .ql-container {
    border: none;
    height: auto;
  }

  .ql-editor {
    max-height: 300px;
    overflow-y: auto; /* 세로 스크롤 */
    margin: ${padding.medium} 0;
    font-size: ${fontSize.large};
    height: auto;
    min-height: ${objHeight.mobileChill};
    // background-color: yellow;
    ${media.desktop`
    width: ${pageWidth.desktop};
    overflow-y: auto;
    padding: ${padding.large} 0 0 0 ;

  `}

    ${media.tablet`
    width: ${pageWidth.tablet};
    overflow-y: auto;    
    padding: ${padding.large} 0 0 0 ;

  `}
    ${media.mobile`
    width: ${pageWidth.mobile};
    overflow-y: auto;
    padding : ${padding.medium} 0;
    font-size: ${fontSize.medium};
    min-height: 40vh;
    max-height: 99999px;
    // background-color: red;
  `}
  }
`;

// -------------------- 파일 첨부 및 태그 설정 디자인  --------------------//

export const AdditionalSection = styled.div`
  width: 100%;
  height: 100%;
  padding: ${padding.mediumLarge} 0;
  border-bottom: 1px solid ${gray.light};
  display: flex;
  align-items: center;
  gap: ${gap.medium};
  ${media.mobile`   
      padding: ${padding.small} 0;
      font-size: ${fontSize.medium};
  `}
`;

export const AdditionalSectionTitle = styled.div`
  min-width: ${objWidth.medium};
`;
// -------------------- 태그 입력  --------------------//

// Styled Components
export const TagInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  // margin-bottom: 8px;
`;

export const TagInput = styled.input`
  width: 100%;
  padding: ${padding.extraSmall};
  margin: 0 0 0 ${margin.small};
  border: 1px solid ${gray.dark};
  border-radius: ${borderRadius.small};
  font-size: ${fontSize.medium};
  flex: 1;
  &:focus {
    outline: none;
  }
`;

export const AddTagButton = styled.button`
  padding: 8px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #45a049;
  }
`;

export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

export const Tag = styled.span`
  background-color: #f1f1f1;
  color: #333;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 13px;
  display: flex;
  align-items: center;
`;

export const RemoveTagButton = styled.button`
  background: none;
  border: none;
  color: #888;
  margin-left: 6px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    color: #e74c3c;
  }
`;

// -------------------- 파일 첨부 --------------------//

export const ToolbarContainer = styled.div``;

export const FileButton = styled.button`
  // font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  // color: #4caf50;
  transition: color 0.2s ease;

  &:hover {
    color: #388e3c;
  }
`;
