import { useContext } from 'react';
import PageContainer from '../../../pageLayout/PageContainer';
import {
  CenterAlign,
  DisplayColumn,
  DisplayRowSpaceBetween,
  PageBox_Padding,
  TitleContainer,
  TitleDetail,
  TitleText,
} from '../../../styles/BasicStyle';
import { gap } from '../../../styles/commonStyle';
import UserContext from '../../../contexts/userContext';
import MobileUserSetting_User from './MobileUserSetting_User';
import MobileUserSetting_Guest from './MobileUserSetting_Guest';
import {
  PageBox_Blog,
  PageContainerBox,
  PageMain,
} from '../../../pageLayout/PageContainerStyle';
import { MyPageHelmetContent } from '../../../components/HelmetContent';
import Main_MobileTopMenuBar from '../../../topMenuBarMobile/Main_MobileTopMenuBar';
import MobileBottomMenuBar_ver2 from '../../../pageLayout/MobileBottomMenuBar_ver2';
import { useMediaQuery } from 'react-responsive';

const MobileUserSettingPage = () => {
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <MyPageHelmetContent />
      {isMobile ? (
        <PageContainerBox>
          <Main_MobileTopMenuBar />
          <PageMain>
            <PageBox_Blog style={{ width: '100%' }}>
              {userId ? (
                <MobileUserSetting_User />
              ) : (
                <MobileUserSetting_Guest />
              )}{' '}
            </PageBox_Blog>
          </PageMain>
          <MobileBottomMenuBar_ver2 />
        </PageContainerBox>
      ) : (
        <CenterAlign style={{ height: '100vh' }}>
          이 페이지는 모바일에서 이용가능합니다.
        </CenterAlign>
      )}
    </>
  );
};

export default MobileUserSettingPage;
