import { useNavigate } from '../../../../node_modules/react-router-dom/dist/index';
import { AuthStyledLink } from '../../../components/AuthPageStyle';
import KakaoLoginButton from '../../../components/KakaoLoginButton';
import LinkTo from '../../../components/LinkTo';
import MainButton from '../../../components/MainButton';
import Input from '../../../components/StyledInput';
import PageContainer from '../../../pageLayout/PageContainer';
import { GreenBadge } from '../../../styles/BasicStyle';
import {
  Mobile_AuthBox,
  Mobile_AuthLayout1,
  Mobile_AuthLayout2,
} from '../mobileRegister/MobileRegisterStyle';
import logo_horizontal from '../../../img/teachernote-logo/teachernote-logo-horizontal.png';

const MobileLogin = ({
  handleChange,
  handleLogin,
  loginInfo,
  rememberMe,
  handleRememberMeChange,
}) => {
  const navigate = useNavigate();
  const handleAuthComplete = async (userId) => {
    navigate('/login-confirm');
  };

  return (
    // <PageContainer>
    <Mobile_AuthBox>
      <Mobile_AuthLayout1>
        <div>
          <img
            src={logo_horizontal}
            style={{ width: '180px' }}
            alt="티처노트 로고 가로형"
          />
        </div>
        자리뽑기<GreenBadge>Beta</GreenBadge>와 다양한 기능을 사용해보세요
      </Mobile_AuthLayout1>{' '}
      <Mobile_AuthLayout2>
        <form className="form" onSubmit={handleLogin}>
          <div>
            <Input
              type="id"
              placeholder="아이디"
              onChange={handleChange}
              name="userId"
              value={loginInfo.userId}
            />
          </div>

          <div>
            <Input
              type="password"
              placeholder="비밀번호"
              onChange={handleChange}
              name="password"
              value={loginInfo.password}
            />
          </div>

          <div style={{ margin: '5px 0' }}>
            <label style={{ cursor: 'pointer' }}>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={handleRememberMeChange}
                style={{ cursor: 'pointer' }}
              />
              로그인 정보 저장
            </label>
          </div>
          <MainButton onClick={handleLogin}>로그인</MainButton>
          {/* <KakaoLoginButton handleAuthComplete={handleAuthComplete} /> */}
          <LinkTo>
            <AuthStyledLink to="/m-sign-up">
              아직 아이디가 없으신가요?
            </AuthStyledLink>
          </LinkTo>
        </form>
      </Mobile_AuthLayout2>
    </Mobile_AuthBox>
    // </PageContainer>
  );
};

export default MobileLogin;
