import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from '../../../node_modules/axios/index';
import ProgressTableContainer from './ProgressTableContainer';
import GradeContext from '../../contexts/grade';
import UserContext from '../../contexts/userContext';
import ProgressNoteContainer from './ProgressNoteContainer';
import PageContainer from '../../pageLayout/PageContainer';
import {
  PageBox,
  PageSubheader,
  ProgressPageBox,
} from '../../pageLayout/PageContainerStyle';
import SelectGradeMenuBar from '../../topSubMenuBar/SelectGradeMenuBar';
import { useMediaQuery } from 'react-responsive';
import MobileTopMenuBar from '../../topMenuBarMobile/MobileTopMenuBar';
import ScrollToTop from '../../components/ScrollToTop';
import Notification from '../../components/Notification';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import {
  DisplayColumn,
  DisplayRow,
  DisplayRowSpaceBetween,
  TitleContainer,
  TitleDetail,
  TitleText,
} from '../../styles/BasicStyle';
import { OneByOneButton } from '../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import RegisterModalComponent from '../registerModal/RegisterModalComponent';
import LoginModalComponent from '../loginModal/LoginModalComponent';
import VisitorCounter from '../../components/VisitorCounter';
import { ProgressTrackerHelmetContent } from '../../components/HelmetContent';
import MobileDatePickerModal from './MobileDatePickerModal';

const ProgressPage = () => {
  //현재 학년, user context 받아오기
  const { state: gradeState } = useContext(GradeContext);
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const grade = gradeState.currentGrade;

  //progress노트 관련
  const [isProgressNoteVisible, setIsProgressNoteVisible] = useState(false);
  const [selectedProgress, setSelectedProgress] = useState({
    grade: null,
    classNum: null,
    topicNum: null,
  });
  // console.log(userId);

  const [showNotification, setShowNotification] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  //---------------- 기본 데이터 세팅  ----------------//

  //classinfo 데이터 받아오기 (학년 별 classinfo) by user
  const [classInfoByGradeDB, setClassInfoclassInfoByGradeDB] = useState([]);
  useEffect(() => {
    const fetchClassInfoByGrade = async () => {
      if (!userId) {
        setClassInfoclassInfoByGradeDB([
          { id: 1706, userId: 'teachernote', grade: '2', class: 1 },
          { id: 1700, userId: 'teachernote', grade: '2', class: 2 },
          { id: 1701, userId: 'teachernote', grade: '2', class: 3 },
          { id: 1702, userId: 'teachernote', grade: '2', class: 4 },
          { id: 1712, userId: 'teachernote', grade: '2', class: 5 },
        ]);
      } else {
        try {
          const res = await axios.get(
            'https://teachernote.me/api/classinfobygrade/' + userId,
            {
              params: { grade: grade },
            },
          );
          setClassInfoclassInfoByGradeDB(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchClassInfoByGrade();
  }, [userId, grade]);

  //topics 데이터 받아오기- 학년별 수업 주제 by user
  const [topicsDB, setTopicsDB] = useState([]);
  const fetchTopicsByGrade = useCallback(async () => {
    if (!userId) {
      setTopicsDB([
        {
          id: 6025,
          userId: 'teachernote',
          grade: 2,
          topicId: 1,
          topicTitle: '비유와 상징의 표현 효과',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6026,
          userId: 'teachernote',
          grade: 2,
          topicId: 2,
          topicTitle: '갈등의 진행과 해결 과정',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6027,
          userId: 'teachernote',
          grade: 2,
          topicId: 3,
          topicTitle: '문학과 삶의 성찰',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6028,
          userId: 'teachernote',
          grade: 2,
          topicId: 4,
          topicTitle: '언어의 본질과 국어의 어휘 체계',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6029,
          userId: 'teachernote',
          grade: 2,
          topicId: 5,
          topicTitle: '국어의 품사와 특성',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6030,
          userId: 'teachernote',
          grade: 2,
          topicId: 6,
          topicTitle: '예측하며 읽기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6031,
          userId: 'teachernote',
          grade: 2,
          topicId: 7,
          topicTitle: '요약하며 읽기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6032,
          userId: 'teachernote',
          grade: 2,
          topicId: 8,
          topicTitle: '자료 활용하며 읽기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6033,
          userId: 'teachernote',
          grade: 2,
          topicId: 9,
          topicTitle: '경험을 담은 글과 통일성을 갖춘 글 쓰기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6034,
          userId: 'teachernote',
          grade: 2,
          topicId: 10,
          topicTitle: ' 매체 특성에 따른 표현하기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6035,
          userId: 'teachernote',
          grade: 2,
          topicId: 11,
          topicTitle: '면담하기와 배려하는 말하기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6036,
          userId: 'teachernote',
          grade: 2,
          topicId: 12,
          topicTitle: '토의하기와 판단하며 듣기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
      ]);
    } else {
      try {
        const res = await axios.get(
          'https://teachernote.me/api/topicsbygrade/' + userId,
          {
            params: { grade: grade },
          },
        );
        setTopicsDB(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  }, [gradeState.currentGrade, userId]);
  useEffect(() => {
    fetchTopicsByGrade();
  }, [fetchTopicsByGrade, userId]);

  //progress by topic 데이터 받아오기 - 학년에 따른 토픽별 학급 진도 by user
  const [progressByTopicAndGradeDB, setProgressByTopicAndGradeDB] = useState(
    [],
  );
  const fetchAllprogressByTopicAndGrade = useCallback(async () => {
    if (!userId) {
      setProgressByTopicAndGradeDB([
        {
          id: 125,
          userId: 'teachernote',
          grade: 2,
          topicNumber: 1,
          class1: '2024-07-01T15:00:00.000Z',
          class2: '2024-07-02T15:00:00.000Z',
          class3: '2024-07-03T15:00:00.000Z',
          class4: '2024-06-30T15:00:00.000Z',
          class5: '2024-07-04T15:00:00.000Z',
          class6: '2024-07-01T15:00:00.000Z',
          class7: null,
          class8: null,
          class9: null,
          class10: null,
          class11: null,
          class12: null,
          class13: null,
          class14: null,
          class15: null,
          createdAt: '2024-07-02T03:41:01.000Z',
          updatedAt: '2024-07-02T03:41:01.000Z',
        },
        {
          id: 129,
          userId: 'teachernote',
          grade: 2,
          topicNumber: 2,
          class1: '2024-07-10T15:00:00.000Z',
          class2: '2024-07-09T15:00:00.000Z',
          class3: '2024-07-10T15:00:00.000Z',
          class4: '2024-07-07T15:00:00.000Z',
          class5: '2024-07-11T15:00:00.000Z',
          class6: null,
          class7: '2024-07-07T15:00:00.000Z',
          class8: null,
          class9: null,
          class10: null,
          class11: null,
          class12: null,
          class13: null,
          class14: null,
          class15: null,
          createdAt: '2024-07-08T01:56:07.000Z',
          updatedAt: '2024-07-08T01:56:07.000Z',
        },
      ]);
    } else {
      try {
        const res = await axios.get(
          'https://teachernote.me/api/progressbytopicandgrade/' + userId,
          {
            params: { grade: gradeState.currentGrade },
          },
        );
        await setProgressByTopicAndGradeDB(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  }, [gradeState.currentGrade]);
  useEffect(() => {
    fetchAllprogressByTopicAndGrade();
  }, [fetchAllprogressByTopicAndGrade]);

  const navigate = useNavigate();
  const updateTopicTitle = async (topicId, value) => {
    if (!userId) {
      alert('로그인 후 이용가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      try {
        await axios
          .put('https://teachernote.me/api/updatetopictitle/' + userId, {
            grade: grade,
            topicId: topicId,
            value: value,
          })
          .then(() => {
            fetchTopicsByGrade();
          });
        setShowNotification(true); // 알림 상태를 true로 변경
        setTimeout(() => {
          setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    }
  };

  //progressNote 데이터 받아오기 : 학년별 진도 노트 데이터 가져오기
  const [progressNoteByGradeDB, setProgressNoteByGradeDB] = useState([]);
  const fetchProgressNoteByGrade = useCallback(async () => {
    if (!userId) {
      setProgressNoteByGradeDB([
        {
          id: 204,
          userId: 'teachernote',
          grade: 2,
          classNum: 1,
          topicNum: 1,
          record:
            '이번 수업에서는 비유와 상징을 활용한 문학 작품을 분석하며 그 표현 효과를 탐구했다. 학생들은 비유와 상징의 의미를 이해하고 이를 통해 작품의 깊이와 풍부함을 체험했다. 특히 시에서 사용된 다양한 비유 표현을 통해 감정과 분위기를 어떻게 전달하는지에 대한 이해가 깊어졌다. 학생들은 자신들이 작성한 글에서도 비유와 상징을 활용해 보는 활동을 즐겼다. 앞으로는 학생들이 좀 더 창의적으로 비유와 상징을 사용할 수 있도록 다양한 예시를 제공할 예정이다.',
          createdAt: '2024-07-02T03:41:09.000Z',
          updatedAt: '2024-07-01T18:41:09.000Z',
        },
        {
          id: 205,
          userId: 'teachernote',
          grade: 2,
          classNum: 2,
          topicNum: 2,
          record:
            '수업에서는 문학 작품 속 갈등의 종류와 해결 과정을 분석하며, 갈등의 중요성을 이해하는 시간을 가졌다. 학생들은 갈등이 이야기 전개에 어떻게 기여하는지에 대해 토론하고 발표했다. 특히 인물 간의 갈등과 내적 갈등을 비교하며 서로 다른 해결 방법에 대해 고민했다. 학생들은 작품 속 갈등 상황을 재현해 보며 보다 생생하게 이해할 수 있었다. 다음 수업에서는 학생들이 직접 갈등 상황을 설정하고 해결하는 글쓰기를 통해 창의성을 발휘할 수 있도록 할 계획이다.',
          createdAt: '2024-07-08T00:53:33.000Z',
          updatedAt: '2024-07-07T15:53:26.000Z',
        },
        {
          id: 206,
          userId: 'teachernote',
          grade: 2,
          classNum: 3,
          topicNum: 3,
          record:
            '이번 수업에서는 문학 작품을 통해 삶을 성찰하는 방법에 대해 학습했다. 학생들은 작품 속 인물의 경험과 자신의 경험을 비교하며 삶의 의미를 깊이 생각해보았다. 특히 특정 사건이 인물에게 미친 영향을 분석하면서 자신에게도 비슷한 상황이 있었는지 돌아보는 시간을 가졌다. 수업 후반부에는 자신의 삶을 문학적으로 표현해보는 활동을 진행했다. 앞으로는 다양한 문학 작품을 통해 학생들이 더 많은 삶의 교훈을 얻을 수 있도록 할 계획이다.',
          createdAt: '2024-07-08T00:53:42.000Z',
          updatedAt: '2024-07-07T15:53:36.000Z',
        },
        {
          id: 207,
          userId: 'teachernote',
          grade: 2,
          classNum: 4,
          topicNum: 4,
          record:
            '이번 수업에서는 언어의 본질과 국어의 어휘 체계에 대해 배웠다. 학생들은 국어의 다양한 어휘가 어떻게 구성되고 사용되는지에 대해 깊이 이해했다. 특히 동일한 단어가 문맥에 따라 다르게 해석될 수 있음을 배우며 언어의 유연성을 체험했다. 학생들은 다양한 어휘를 활용해 자신의 생각을 표현하는 활동을 통해 어휘력 향상의 중요성을 깨달았다. 앞으로는 학생들이 더욱 다양한 어휘를 접하고 사용할 수 있는 기회를 제공할 예정이다.',
          createdAt: '2024-07-08T00:53:50.000Z',
          updatedAt: '2024-07-07T15:53:48.000Z',
        },
        {
          id: 208,
          userId: 'teachernote',
          grade: 2,
          classNum: 5,
          topicNum: 5,
          record:
            '이번 수업에서는 국어의 품사와 그 특성에 대해 학습했다. 학생들은 각 품사가 문장에서 어떤 역할을 하는지에 대해 이해했다. 특히 명사, 동사, 형용사의 활용법을 집중적으로 학습하며 문장을 구성하는 기본 원리를 깨달았다. 학생들은 예문을 통해 다양한 품사를 활용해 문장을 구성하는 활동을 즐겼다. 앞으로는 더욱 복잡한 문장 구조를 다루며 학생들의 문장 구성 능력을 향상시킬 계획이다.',
          createdAt: '2024-07-08T00:53:57.000Z',
          updatedAt: '2024-07-07T15:53:55.000Z',
        },
        {
          id: 211,
          userId: 'teachernote',
          grade: 2,
          classNum: 1,
          topicNum: 6,
          record:
            '이번 수업에서는 텍스트를 읽으면서 앞으로의 내용을 예측하는 방법에 대해 배웠다. 학생들은 단서와 맥락을 통해 다음 전개를 추측하며 읽는 활동을 진행했다. 특히 예측을 통해 독해력을 향상시키는 방법을 체험하며 독서의 즐거움을 느꼈다. 수업 후반부에는 각자 예측한 내용을 토대로 이야기를 창작하는 활동을 했다. 앞으로는 더욱 다양한 텍스트를 활용해 예측하며 읽는 능력을 키울 예정이다.',
          createdAt: '2024-07-08T00:54:51.000Z',
          updatedAt: '2024-07-07T15:54:46.000Z',
        },
        {
          id: 213,
          userId: 'teachernote',
          grade: 2,
          classNum: 2,
          topicNum: 7,
          record:
            '이번 수업에서는 텍스트의 핵심 내용을 요약하는 방법에 대해 학습했다. 학생들은 주요 내용을 파악하고 이를 간결하게 정리하는 방법을 연습했다. 특히 긴 글을 읽고 중요한 정보를 추려내는 활동을 통해 요약 능력을 향상시켰다. 학생들은 각자 읽은 내용을 친구들과 공유하며 요약의 중요성을 체험했다. 앞으로는 더욱 복잡한 텍스트를 요약하는 연습을 통해 학생들의 독해력을 높일 계획이다.',
          createdAt: '2024-07-08T00:55:14.000Z',
          updatedAt: '2024-07-07T15:55:11.000Z',
        },
        {
          id: 214,
          userId: 'teachernote',
          grade: 2,
          classNum: 3,
          topicNum: 8,
          record:
            '이번 수업에서는 다양한 자료를 활용해 읽는 방법에 대해 배웠다. 학생들은 그래프, 표, 그림 등 시각적 자료를 통해 정보를 얻는 방법을 학습했다. 특히 자료를 통해 텍스트의 내용을 보완하고 이해하는 활동을 진행했다. 학생들은 자료를 분석하고 이를 토대로 자신의 의견을 작성하는 연습을 즐겼다. 앞으로는 다양한 자료를 활용해 읽기 능력을 더욱 향상시킬 예정이다.',
          createdAt: '2024-07-08T00:55:22.000Z',
          updatedAt: '2024-07-07T15:55:19.000Z',
        },
        {
          id: 215,
          userId: 'teachernote',
          grade: 2,
          classNum: 4,
          topicNum: 9,
          record:
            '이번 수업에서는 자신의 경험을 바탕으로 글을 쓰는 방법에 대해 배웠다. 학생들은 자신의 경험을 생생하게 전달하기 위해 구체적인 사례와 감정을 담아 글을 작성했다. 특히 글의 통일성을 유지하는 방법을 학습하며 일관된 주제로 글을 전개하는 연습을 했다. 학생들은 서로의 글을 읽고 피드백을 주고받으며 글쓰기 능력을 향상시켰다. 앞으로는 더욱 다양한 주제로 글을 쓰며 학생들의 표현력을 키울 계획이다.',
          createdAt: '2024-07-08T00:55:34.000Z',
          updatedAt: '2024-07-07T15:55:29.000Z',
        },
        {
          id: 216,
          userId: 'teachernote',
          grade: 2,
          classNum: 5,
          topicNum: 10,
          record:
            '이번 수업에서는 다양한 매체의 특성에 맞춰 표현하는 방법을 배웠다. 학생들은 글, 영상, 음성 등 여러 매체를 활용해 메시지를 전달하는 방법을 학습했다. 특히 각 매체의 장단점을 비교하며 상황에 맞는 매체를 선택하는 능력을 길렀다. 학생들은 자신이 선택한 매체로 프로젝트를 완성하며 창의성을 발휘했다. 앞으로는 더욱 다양한 매체를 활용한 표현 활동을 통해 학생들의 표현력을 키울 예정이다.',
          createdAt: '2024-07-08T00:55:49.000Z',
          updatedAt: '2024-07-07T15:55:44.000Z',
        },
      ]);
    } else {
      try {
        const res = await axios.get(
          'https://teachernote.me/api/progressnote/' + userId,
          {
            params: { grade: gradeState.currentGrade },
          },
        );
        setProgressNoteByGradeDB(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  }, [userId]);
  useEffect(() => {
    fetchProgressNoteByGrade();
  }, [userId, fetchProgressNoteByGrade]);

  // console.log('progressNoteByGradeDB:' + JSON.stringify(progressNoteByGradeDB));

  //----------------진도노트  ----------------//

  const [topicTitle, setTopicTitle] = useState();
  //progressNote 보이는지 여부 컨트롤
  const handleProgressNote = async (grade, classNum, topicNum, topicTitle) => {
    setTopicTitle(topicTitle);
    //같은 topicNote를 다시 누르면 topicnote 숨김
    if (
      grade === selectedProgress.grade &&
      classNum === selectedProgress.classNum &&
      topicNum === selectedProgress.topicNum
    ) {
      setIsProgressNoteVisible(!isProgressNoteVisible);
    } else {
      //다른 topicNote를 누르면 topicnote 계속 보임, 다시 설정
      setIsProgressNoteVisible(true);
      setSelectedProgress({ grade, classNum, topicNum });
      ScrollToTop();
    }
  };

  const afterSaveNote = () => {
    // alert('저장되었습니다');

    setShowNotification(true); // 알림 상태를 true로 변경
    setTimeout(() => {
      setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
    }, 1000);
    setIsProgressNoteVisible(false);
  };

  //---------------- esc, 다른 곳 클릭 하면 진도노트 사라지게  ----------------//
  // ProgressNote 컨테이너 감지용 ref
  const progressNoteRef = useRef(null);

  // esc 키와 바깥 클릭 이벤트 핸들러
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setIsProgressNoteVisible(false);
    }
  }, []);

  const handleClickOutside = useCallback((e) => {
    if (
      progressNoteRef.current &&
      !progressNoteRef.current.contains(e.target)
    ) {
      setIsProgressNoteVisible(false);
    }
  }, []);

  useEffect(() => {
    if (isProgressNoteVisible) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('click', handleClickOutside);
      };
    }
  }, [isProgressNoteVisible, handleKeyDown, handleClickOutside]);

  //학년 바뀌면 진도노트 끄기
  useEffect(() => {
    setIsProgressNoteVisible(false);
  }, [userId, grade]);

  // //--------------------- 로그인/회원가입 --------------------//
  // //회원가입 모달
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('bottombutton'); //menubar,bottombutton

  const handleAuthComplete = async (userId) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  const openRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const openLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const closeAuthModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };
  return (
    <>
      <ProgressTrackerHelmetContent />
      {/* pagecontainer의 모달은 상단 메뉴바 클릭시 생성,
      여기의 modal은 작동 후 로그인 필요시 생성되는 modal */}
      <RegisterModalComponent
        isRegisterModalOpen={isRegisterModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        openRegisterModal={openRegisterModal}
        openLoginModal={openLoginModal}
        closeModal={closeAuthModal}
        handleAuthComplete={handleAuthComplete}
        modalSource={modalSource}
      />
      {isLoginModalOpen ? (
        <LoginModalComponent
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          openRegisterModal={openRegisterModal}
          openLoginModal={openLoginModal}
          closeAuthModal={closeAuthModal}
          handleAuthComplete={handleAuthComplete}
          modalSource={modalSource}
        />
      ) : (
        <></>
      )}

      <PageContainer>
        <PageSubheader>
          <SelectGradeMenuBar />
        </PageSubheader>{' '}
        {isMobile && <MobileTopMenuBar />}
        <ProgressPageBox>
          <Notification
            message="변경사항 저장 완료"
            isVisible={showNotification}
          />
          {/* <VisitorCounter /> */}
          <TitleContainer>
            <DisplayRowSpaceBetween>
              <DisplayColumn style={{ width: 'auto' }}>
                {' '}
                <TitleText>수업 진도표</TitleText>{' '}
                <TitleDetail>
                  담당하는 학급별 수업 진도 체크 및 날짜 기록, 학급별 수업 진도
                  노트 작성을 할 수 있습니다.
                </TitleDetail>
              </DisplayColumn>
              {isDesktop && (
                <div>
                  <OneByOneButton
                    onClick={() => {
                      navigate('/class-management');
                    }}
                  >
                    담당하는 학급 설정 바로가기
                  </OneByOneButton>
                  <OneByOneButton
                    onClick={() => {
                      navigate('/topic-management');
                    }}
                  >
                    수업주제 설정 바로가기
                  </OneByOneButton>
                </div>
              )}
            </DisplayRowSpaceBetween>
          </TitleContainer>
          {isProgressNoteVisible && (
            <ProgressNoteContainer
              {...selectedProgress}
              afterSaveNote={afterSaveNote}
              topicTitle={topicTitle}
              handleProgressNote={handleProgressNote}
              // ref={progressNoteRef}
              // setIsProgressNoteVisible={setIsProgressNoteVisible}
              fetchProgressNoteByGrade={fetchProgressNoteByGrade}
              setIsProgressNoteVisible={setIsProgressNoteVisible}
              setRegisterModalOpen={setRegisterModalOpen}
            />
          )}{' '}
          <ProgressTableContainer
            topics={topicsDB}
            classInfoByGrade={classInfoByGradeDB}
            progressByTopicAndGrade={progressByTopicAndGradeDB}
            //진도기록 저장 후 화면 다시 리렌더링
            afterSaveNote={afterSaveNote}
            updateTopicTitle={updateTopicTitle}
            handleProgressNote={handleProgressNote}
            progressNoteByGradeDB={progressNoteByGradeDB}
            // setIsProgressNoteVisible={setIsProgressNoteVisible}
            fetchAllprogressByTopicAndGrade={fetchAllprogressByTopicAndGrade}
            setShowNotification={setShowNotification}
            setRegisterModalOpen={setRegisterModalOpen}
          />
        </ProgressPageBox>
      </PageContainer>
    </>
  );
};

export default ProgressPage;
