import React, { useState } from 'react';
import snowman from '../../../img/random-number-picker-3snowman/random-number-picker-snowman-2.png';
import {
  PresenterNumber,
  PresenterWrapper,
  SnowmanImage,
  SnowManContainer,
  SnowmanWrapper,
  SnowViewContainer,
  SunContainer,
  Snowflake,
  Sun,
} from './SnowManStyle';

const SnowmanMelt = ({ presenter, pickPresenter }) => {
  const [isRestart, setIsRestart] = useState(false);
  const [isMelted, setIsMelted] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isTextHidden, setIsTextHidden] = useState(false);

  const handleMelt = () => {
    setClicked(true);
    pickPresenter(); // 프리젠터(숫자) 표시
    // setIsTextHidden(true); // 텍스트 숨김
    setIsMelted(true); // 눈사람이 녹았음을 표시
    setTimeout(() => {
      setClicked(false);
      setIsRestart(true); // 버튼이 restart로 변경되도록 설정
      // setIsTextHidden(false); // 3초 후 텍스트 표시
    }, 3000); // 3초 후 restart 버튼 텍스트 표시
  };

  // 눈사람을 다시 생성하는 함수
  const handleRestart = () => {
    setIsMelted(false); // 눈사람이 다시 생성됨
    setIsRestart(false); // 버튼이 start로 돌아옴
  };

  //눈 내리는 효과
  const snowflakes = Array.from({ length: 15 }).map((_, index) => ({
    id: index,
    left: Math.random() * 100, // 랜덤 위치
    duration: Math.random() * 5 + 2, // 랜덤 속도
  }));

  return (
    <SnowViewContainer>
      {snowflakes.map((snowflake) => (
        <Snowflake
          key={snowflake.id}
          left={snowflake.left}
          duration={snowflake.duration}
        />
      ))}
      <SunContainer>
        <Sun onClick={isRestart ? handleRestart : handleMelt}>
          {!isTextHidden && (isRestart ? 'RESTART' : 'START')}
        </Sun>
      </SunContainer>
      <SnowManContainer>
        <SnowmanWrapper>
          <SnowmanImage src={snowman} alt="Snowman" melt={isMelted} />
        </SnowmanWrapper>
        <PresenterWrapper>
          <PresenterNumber>{presenter}</PresenterNumber>
        </PresenterWrapper>
      </SnowManContainer>{' '}
    </SnowViewContainer>
  );
};

export default SnowmanMelt;
