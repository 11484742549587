import styled from 'styled-components';
import { padding, margin } from '../../styles/commonStyle';

export const PrivacyPolicy_Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const PrivacyPolicy_ContentWrapper = styled.div`
  max-width: ${({ isMobile }) => (isMobile ? '90vw' : '80vw')};
  height: ${({ isMobile }) => (isMobile ? '90vh' : '80vh')};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;

  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// padding: ${({ isMobile }) =>
//     isMobile ? `${padding.small}` : `${padding.large}`};

export const PrivacyPolicy_Title = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? '1.5rem' : '2rem')};
  margin-top: ${margin.large};
  //   background-color: red;
`;

export const PrivacyPolicy_PreContent = styled.pre`
  max-height: ${({ isMobile }) => (isMobile ? '70vh' : '60vh')};
  margin: ${margin.large};
  padding: ${({ isMobile }) =>
    isMobile ? '15px' : '20px'}; // 모바일에서 내부 여백 축소
  overflow-y: auto;

  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.6;

  font-size: ${({ isMobile }) =>
    isMobile ? '0.7rem' : '0.7rem'}; // 모바일에서 글자 크기 조정
  color: #333;
  background-color: #ffffff;

  border-radius: 8px;
  overflow-x: auto;
`;
