import React from 'react';
import styled from 'styled-components';
import { borderRadius, fontSize, padding } from '../styles/commonStyle';
import { media } from '../styles/media';
// SubButton 스타일 컴포넌트 정의
const StyledSubButton = styled.button`
  width: 100%;
  margin: 5px 0px;
  padding: ${padding.medium} ${padding.large};
  background-color: #ddd;
  color: #333;
  border: none;
  border-radius: ${borderRadius.small};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease; /* 배경색 전환 효과 */

  /* 호버 효과 */
  &:hover {
    background-color: #ccc;
  }

  /* 클릭 효과 */
  &:active {
    background-color: #bbb;
  }
  ${media.mobile`
    width: 100%;
    
    padding:${padding.small}  ${padding.large} ;
    font-size: ${fontSize.small};
  `}
`;
const SubButton = (props) => {
  return <StyledSubButton {...props} />;
};

export default SubButton;
