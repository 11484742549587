import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import GradeContext from '../contexts/grade';
import {
  colors,
  fontSize,
  fontWeight,
  height,
  margin,
  padding,
  pageWidth,
} from '../styles/commonStyle';
import { media } from '../styles/media';
import {
  MenuItemsContainer,
  MobileTopSubMenubar,
} from '../topMenuBarMobile/MobileTopMenuBarStyle';
import { useMediaQuery } from 'react-responsive';

const ClassInfoMenuBox = styled.div`
  background-color: ${colors.background};

  ${media.desktop`
    height: ${height.header};
    width: ${pageWidth.desktop};
  `}

  ${media.tablet`
    height: ${height.headerTablet};    
    width: ${pageWidth.tablet};
  `}

  ${media.mobile`
    height: ${height.headerMobile};    
    width: ${pageWidth.mobile};
    display: none;
  `}
  height: ${height.header};
  padding: 0 ${padding.large};
  display: flex;
  align-items: center;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
`;

const GradeMenu = styled.div`
  cursor: pointer;
  margin-right: ${margin.large};
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    color: ${colors.primary};
    font-weight: 600;
  }
`;

const ClassInfoMenuBar = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      {!isMobile ? (
        <ClassInfoMenuBox>
          <GradeMenu>전체보기</GradeMenu>
        </ClassInfoMenuBox>
      ) : (
        <MobileTopSubMenubar>
          <MenuItemsContainer>
            {' '}
            <GradeMenu>전체보기</GradeMenu>
          </MenuItemsContainer>
        </MobileTopSubMenubar>
      )}
    </>
  );
};

export default ClassInfoMenuBar;
