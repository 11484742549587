import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import PageContainer from '../../pageLayout/PageContainer';
import BlogListContainer from './BlogListContainer';
import {
  PageBox,
  PageBox_Blog,
  PageSubheader,
} from '../../pageLayout/PageContainerStyle';
import ArticleMenuBar from '../../topSubMenuBar/ArticleMenuBar';
import { useParams } from '../../../node_modules/react-router-dom/dist/index';
import { EducationResourcesSharingHelmetContent } from '../../components/HelmetContent';

const BlogListPage = () => {
  const [blogDB, setBlogDB] = useState([]);
  const { paramCategory } = useParams(); // URL 파라미터로부터 카테고리 가져오기

  const fetchBlogs = useCallback(async () => {
    try {
      const res = await axios.get('https://teachernote.me/api/blogs/');
      setBlogDB(res.data);
      // console.log('blogDB:' + JSON.stringify(res.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 이동 시 스크롤을 맨 위로 이동
  }, [paramCategory]); // paramCategory가 변경될 때마다 실행

  const filteredBlogs = (() => {
    if (!paramCategory) {
      return blogDB;
    }
    if (
      paramCategory === 'student-recommendations' ||
      paramCategory === 'teacher-recommendations'
    ) {
      return blogDB.filter(
        (blog) =>
          blog.category === 'student-recommendations' ||
          blog.category === 'teacher-recommendations',
      ); // "student-recommendations"과 "teacher-recommendations"을 모두 포함
    }
    if (
      paramCategory === 'classroom-learning-activities' ||
      paramCategory === 'lesson-materials'
    ) {
      return blogDB.filter(
        (blog) =>
          blog.category === 'classroom-learning-activities' ||
          blog.category === 'lesson-materials',
      ); // "student-recommendations"과 "teacher-recommendations"을 모두 포함
    }
    return blogDB.filter((blog) => blog.category === paramCategory);
  })();

  const sortedBlogs = [...filteredBlogs].sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  );

  // useEffect(() => {
  //   console.log('sortedBlogs:' + JSON.stringify(sortedBlogs));
  // }, [sortedBlogs]);
  return (
    <>
      <EducationResourcesSharingHelmetContent />
      <PageContainer>
        <PageSubheader>
          <ArticleMenuBar />
        </PageSubheader>

        <PageBox_Blog>
          <BlogListContainer blogs={sortedBlogs} />
        </PageBox_Blog>
      </PageContainer>
    </>
  );
};

export default BlogListPage;
