import { useContext } from 'react';
import UserContext from '../../../contexts/userContext';
import {
  AvatarContainer,
  MobileUserSetting_BottomArea,
  MobileUserSetting_Img,
  MobileUserSetting_LogoutContainer,
  MobileUserSetting_Menu,
  MobileUserSetting_MenuContainer,
  MobileUserSetting_TopArea,
  MobileUserSettingContainer,
  Nickname,
} from './MobileUserSettingStyle';
import { ColorPalette, fontSize, padding } from '../../../styles/commonStyle';
import { CenterAlign } from '../../../styles/BasicStyle';
import LogoutComponent from '../../../components/Logout';
import dart from '../../../img/mobile-my-page/dart.png';
import classroom from '../../../img/mobile-my-page/classroom.png';
import community from '../../../img/mobile-my-page/community.png';
import friday from '../../../img/mobile-my-page/friday.png';
import school from '../../../img/mobile-my-page/school.png';
import sketchbook from '../../../img/mobile-my-page/sketchbook.png';
import idea from '../../../img/mobile-my-page/idea.png';
import folder from '../../../img/mobile-my-page/folder.png';
import coffee from '../../../img/mobile-my-page/coffee.png';
import { useNavigate } from '../../../../node_modules/react-router-dom/dist/index';

const MobileUserSetting_User = () => {
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  // 1~50 사이의 랜덤 숫자 생성
  const randomAvatarNumber = String(
    Math.floor(Math.random() * 37) + 1,
  ).padStart(3, '0');
  const randomAvatar = require(`../../../img/avatar/${randomAvatarNumber}.png`);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // 페이지 이동 후 상단으로 스크롤
  };

  return (
    <>
      {' '}
      <MobileUserSetting_LogoutContainer
        style={{ fontSize: `${fontSize.extraSmall}` }}
      >
        <LogoutComponent />
      </MobileUserSetting_LogoutContainer>
      <MobileUserSettingContainer>
        <MobileUserSetting_TopArea>
          {' '}
          <AvatarContainer>
            <img
              src={randomAvatar}
              alt="랜덤 아바타"
              style={{ width: '100%', height: '100%' }}
            />
          </AvatarContainer>
          <Nickname>{userState.user.userId}</Nickname>
          <div>{userState.user.email}</div>
          {/* <div style={{ margin: `${padding.small}` }}>
          <LogoutComponent />
        </div> */}
        </MobileUserSetting_TopArea>
        <MobileUserSetting_BottomArea>
          <MobileUserSetting_MenuContainer
            // onClick={() => navigate('/m-random-number-picker')}
            onClick={() => handleNavigate('/m-random-number-picker')}
          >
            <MobileUserSetting_Menu>
              <MobileUserSetting_Img src={dart} style={{ width: '58%' }} />
            </MobileUserSetting_Menu>
            랜덤 숫자뽑기
          </MobileUserSetting_MenuContainer>
          <MobileUserSetting_MenuContainer
            // onClick={() => navigate('/m-random-seat-assignment')}
            onClick={() => handleNavigate('/m-random-seat-assignment')}
          >
            <MobileUserSetting_Menu>
              {' '}
              <MobileUserSetting_Img src={classroom} />
            </MobileUserSetting_Menu>
            자리뽑기
          </MobileUserSetting_MenuContainer>

          <MobileUserSetting_MenuContainer
            // onClick={() => navigate('/m-talk-education-sharing')}
            onClick={() => handleNavigate('/m-talk-education-sharing')}
          >
            <MobileUserSetting_Menu>
              {' '}
              <MobileUserSetting_Img src={coffee} style={{ width: '58%' }} />
            </MobileUserSetting_Menu>
            TALK
          </MobileUserSetting_MenuContainer>

          <MobileUserSetting_MenuContainer
            onClick={() =>
              handleNavigate(
                '/education-resources-sharing/classroom-learning-activities',
              )
            }
          >
            <MobileUserSetting_Menu>
              {' '}
              <MobileUserSetting_Img src={folder} />
            </MobileUserSetting_Menu>
            수업·평가
          </MobileUserSetting_MenuContainer>
        </MobileUserSetting_BottomArea>{' '}
        <MobileUserSetting_BottomArea style={{ marginTop: '0px' }}>
          <MobileUserSetting_MenuContainer
            // onClick={() => navigate('/lesson-progress-tracker')}
            onClick={() => handleNavigate('/lesson-progress-tracker')}
          >
            <MobileUserSetting_Menu>
              <MobileUserSetting_Img src={friday} />
            </MobileUserSetting_Menu>
            진도표
          </MobileUserSetting_MenuContainer>
          <MobileUserSetting_MenuContainer
            // onClick={() => navigate('/progress-notes/by-class')}
            onClick={() => handleNavigate('/progress-notes/by-class')}
          >
            <MobileUserSetting_Menu>
              {' '}
              <MobileUserSetting_Img src={sketchbook} />
            </MobileUserSetting_Menu>
            진도노트
          </MobileUserSetting_MenuContainer>
          <MobileUserSetting_MenuContainer
            // onClick={() => navigate('/topic-management')}
            onClick={() => handleNavigate('/topic-management')}
          >
            <MobileUserSetting_Menu>
              {' '}
              <MobileUserSetting_Img src={idea} style={{ width: '58%' }} />
            </MobileUserSetting_Menu>
            수업 주제 설정
          </MobileUserSetting_MenuContainer>
          <MobileUserSetting_MenuContainer
            // onClick={() => navigate('/class-management')}
            onClick={() => handleNavigate('/class-management')}
          >
            <MobileUserSetting_Menu>
              {' '}
              <MobileUserSetting_Img src={school} style={{ width: '58%' }} />
            </MobileUserSetting_Menu>
            학급 설정
          </MobileUserSetting_MenuContainer>
        </MobileUserSetting_BottomArea>{' '}
      </MobileUserSettingContainer>
    </>
  );
};

export default MobileUserSetting_User;
