import {
  AllWidthBox,
  MainPageWidthBox,
} from '../../pageLayout/PageContainerStyle';
import CardCarousel from '../../components/carousel/CardCarousel';
import { MainContentBox, MainContentTitle, SeeMore } from './MainStyle';
import RoundCardCarousel from '../../components/carousel/RoundCardCarousel';
import { DisplayRowSpaceBetween } from '../../styles/BasicStyle';
import OneCarousel from '../../components/carousel/OneCarousel';
import LargeCardCarousel from '../../components/carousel/LargeCardCarousel';
import { colors, gray } from '../../styles/commonStyle';
import SmallRoundCardCarousel from '../../components/carousel/SmallRoundCardCarousel';
import logo from '../../img/teachernote-logo/teachernote-logo-green.png';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { useContext, useEffect, useState } from 'react';
import LocationContext from '../../contexts/LocationContext';
import neis from '../../img/education-info-icons/neis.png';
import edufin from '../../img/education-info-icons/edufin.png';
import evpn from '../../img/education-info-icons/evpn.png';
import liferecordportal from '../../img/education-info-icons/liferecordportal.png';
import workportal from '../../img/education-info-icons/workportal.png';
import schoolsafe from '../../img/education-info-icons/schoolsafe.png';
import afterschool from '../../img/education-info-icons/afterschool.png';
import { FaArrowUp } from 'react-icons/fa';
import { ScrollTopButton } from '../blogView/BlogView';
import { useMediaQuery } from 'react-responsive';

// 블로그 content에서 이미지를 추출하는 함수
export const extractImageUrlFromContent = (content) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = content;
  const imgTag = tempDiv.querySelector('img');
  return imgTag ? imgTag.src : logo; // 이미지가 있으면 해당 URL, 없으면 로고 이미지 반환
};

const getCombinedCarouselData = (cardsByCategory, categories) => {
  return categories.reduce((combinedData, category) => {
    const categoryCards = cardsByCategory[category];
    if (categoryCards && categoryCards.length > 0) {
      return [...combinedData, ...categoryCards];
    }
    return combinedData;
  }, []);
};

const Main = ({ cards, cardsByCategory }) => {
  const { city } = useContext(LocationContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const cardViewNum = isMobile ? 2 : 3;

  // -------------------- '위로'버튼   -------------------- //

  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  // 최상단으로 스크롤하는 함수
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      // 특정 스크롤 위치에서 버튼 표시 (예: 300px 이상 스크롤할 경우)
      setShowScrollTopButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // -------------------- 여러 카테고리 한번에 보여주기  -------------------- //

  const combinedCategories = [
    'student-recommendations',
    'teacher-recommendations',
  ];
  const combinedCarouselData = getCombinedCarouselData(
    cardsByCategory,
    combinedCategories,
  );

  const handleSeeMoreClick = (category) => {
    navigate(`/education-resources-sharing/${category}`);
  };

  // -------------------- 원클릭 접속 -------------------- //

  //기초학력진단보정시스템, 학교안전공제회
  const services = [
    '나이스',
    '업무포털',
    '에듀파인',
    'EVPN',
    '생활기록부지원포털',
    '학교안전공제회',
    '방과후학교지원포털',
  ];

  const serviceImages = {
    나이스: neis,
    업무포털: workportal,
    에듀파인: edufin,
    EVPN: evpn,
    생활기록부지원포털: liferecordportal,
    학교안전공제회: schoolsafe,
    방과후학교지원포털: afterschool,
  };
  const generateImgCards = () => {
    const commonUrl = 'https://teachernote.me';

    return services.map((service) => {
      const serviceUrl =
        serviceUrls[service]?.[city] ||
        serviceUrls[service]?.default ||
        commonUrl;

      return {
        id: service,
        imageUrl: serviceImages[service] || logo,
        title: service,
        description: service,
        link: serviceUrl,
      };
    });
  };

  const imgCards = generateImgCards();
  const handleCardClick = (serviceName, link) => {
    // 특정 서비스들 클릭 시 경고 메시지를 띄웁니다
    const restrictedServices = ['나이스', '업무포털', '에듀파인'];
    if (restrictedServices.includes(serviceName)) {
      alert('학교 네트워크 내에서 접속 가능합니다.');
    }
    window.open(link, '_blank', 'noopener');
  };

  return (
    <>
      {' '}
      {/* 흰색 배경 */}
      <AllWidthBox>
        <MainContentBox>
          <SmallRoundCardCarousel />
        </MainContentBox>
        {/* <MainContentBox>
          <DisplayRowSpaceBetween>
            <MainContentTitle>주간 베스트 TOP20</MainContentTitle>
            <div>더 보기</div>
          </DisplayRowSpaceBetween>
          <OneCarousel cards={cardsByCategory['work-materials'] || []} />
        </MainContentBox> */}
        <MainContentBox>
          <DisplayRowSpaceBetween>
            <MainContentTitle>🏅실시간 랭킹 TOP20</MainContentTitle>
            <div
              onClick={() => handleSeeMoreClick('')}
              style={{ cursor: 'pointer' }}
            >
              더 보기
            </div>
          </DisplayRowSpaceBetween>

          <CardCarousel cards={cards} viewNum={cardViewNum} />
        </MainContentBox>{' '}
      </AllWidthBox>
      {/* 검정색 배경 */}
      <AllWidthBox
        style={{
          backgroundColor: `${colors.lightGreenBackground}`,
          color: 'black',
        }}
      >
        <MainContentBox>
          <DisplayRowSpaceBetween>
            <MainContentTitle>🔥HOT 과목별 활동·수행평가</MainContentTitle>
            <div
              onClick={() =>
                handleSeeMoreClick('classroom-learning-activities')
              }
              style={{ cursor: 'pointer' }}
            >
              더 보기
            </div>
          </DisplayRowSpaceBetween>{' '}
          <CardCarousel
            cards={cardsByCategory['classroom-learning-activities'] || []}
            viewNum={cardViewNum}
          />
          {/* <CardCarousel cards={combinedActivitiesData} viewNum={3} /> */}
        </MainContentBox>
        {/* 이런 학급 활동은 어떠세요? classroom-group-activities 카테고리 추가해야함.  */}
        <MainContentBox>
          <DisplayRowSpaceBetween>
            <MainContentTitle>⭐️교실·학급 꿀템 추천</MainContentTitle>
            <div
              onClick={() => handleSeeMoreClick('student-recommendations')}
              style={{ cursor: 'pointer' }}
            >
              더 보기
            </div>
          </DisplayRowSpaceBetween>
          {/* <CardCarousel cards={cardsByCategory['student-recommendations'] || []} /> */}
          <CardCarousel cards={combinedCarouselData} viewNum={cardViewNum} />
        </MainContentBox>
      </AllWidthBox>
      {/* 흰색 배경 */}
      <AllWidthBox>
        <MainContentBox>
          <DisplayRowSpaceBetween>
            <MainContentTitle>👩🏻‍🏫실시간 교무실 TALK</MainContentTitle>
            <div
              onClick={() => handleSeeMoreClick('work-materials')}
              style={{ cursor: 'pointer' }}
            >
              더 보기
            </div>
          </DisplayRowSpaceBetween>
          <CardCarousel
            cards={cardsByCategory['work-materials'] || []}
            viewNum={cardViewNum}
          />
        </MainContentBox>
      </AllWidthBox>
      {/* 연두 배경 */}
      <AllWidthBox style={{ backgroundColor: `${gray.light}` }}>
        <MainContentBox>
          <DisplayRowSpaceBetween>
            <MainContentTitle>💡ISSUE 교육 매거진</MainContentTitle>
            <div
              onClick={() => handleSeeMoreClick('education-magazine')}
              style={{ cursor: 'pointer' }}
            >
              더 보기
            </div>
          </DisplayRowSpaceBetween>
          <LargeCardCarousel
            cards={cardsByCategory['education-magazine'] || []}
          />
        </MainContentBox>{' '}
      </AllWidthBox>
      {/* 흰색 배경 */}
      <AllWidthBox>
        <MainContentBox>
          <MainContentTitle>👉🏻원클릭 접속</MainContentTitle>
          <RoundCardCarousel
            cards={imgCards.map((card) => ({
              ...card,
              onClick: () => handleCardClick(card.title, card.link),
            }))}
          />
        </MainContentBox>
      </AllWidthBox>
      {/* 최상단으로 스크롤하는 버튼 */}
      {!isMobile && (
        <ScrollTopButton onClick={scrollToTop} show={showScrollTopButton}>
          <FaArrowUp size={38} />
        </ScrollTopButton>
      )}
    </>
  );
};
export default Main;

//https://baro.sen.go.kr/fus/MI000000000000000152/html/cont0010v.do
// https://blog.naver.com/sohn3926/222489431093
// 도시별 URL 매핑 객체

const serviceUrls = {
  나이스: {
    서울: 'https://sen.neis.go.kr/',
    경기: 'https://goe.neis.go.kr/',
    경남: 'https://gne.neis.go.kr/',
    부산: 'https://pen.neis.go.kr/',
    대구: 'https://dge.neis.go.kr/',
    대전: 'https://dje.neis.go.kr/',
    경북: 'https://dbe.neis.go.kr/',
    세종: 'https://sje.neis.go.kr/',
    울산: 'https://use.neis.go.kr/',
    인천: 'https://ice.neis.go.kr/',
    광주: 'https://gen.neis.go.kr/',
    전남: 'https://jne.neis.go.kr/',
    전북: 'https://jbe.neis.go.kr/',
    충남: 'https://cne.neis.go.kr/',
    충북: 'https://cbe.neis.go.kr/',
    강원: 'https://kwe.neis.go.kr/',
    제주: 'https://jje.neis.go.kr/',
  },
  업무포털: {
    서울: 'https://sen.eduptl.kr',
    경기: 'https://goe.eduptl.kr',
    경남: 'https://gne.eduptl.kr',
    부산: 'https://pen.eduptl.kr',
    대구: 'https://dge.eduptl.kr',
    대전: 'https://dje.eduptl.kr',
    경북: 'https://gbe.eduptl.kr',
    세종: 'https://sje.eduptl.kr',
    울산: 'https://use.eduptl.kr',
    인천: 'https://ice.eduptl.kr',
    광주: 'https://gen.eduptl.kr',
    전남: 'https://jne.eduptl.kr',
    전북: 'https://jbe.eduptl.kr',
    충남: 'https://cne.eduptl.kr',
    충북: 'https://cbe.eduptl.kr',
    강원: 'https://kwe.eduptl.kr',
    제주: 'https://jje.eduptl.kr',
  },
  에듀파인: {
    서울: 'https://klef.sen.go.kr/',
    경기: 'https://klef.goe.go.kr/',
    경남: 'https://klef.gne.go.kr/',
    부산: 'https://klef.pen.go.kr/',
    대구: 'https://klef.dge.go.kr/',
    대전: 'https://klef.dje.go.kr/',
    경북: 'https://klef.gbe.go.kr/',
    세종: 'https://klef.sje.go.kr/',
    울산: 'https://klef.use.go.kr/',
    인천: 'https://klef.ice.go.kr/',
    광주: 'https://klef.gen.go.kr/',
    전남: 'https://klef.jne.go.kr/',
    전북: 'https://klef.jbe.go.kr/',
    충남: 'https://klef.cne.go.kr/',
    충북: 'https://klef.cbe.go.kr/',
    강원: 'https://klef.kwe.go.kr/',
    제주: 'https://klef.jje.go.kr/',
  },
  EVPN: {
    서울: 'https://evpn.sen.go.kr',
    경기: 'https://evpn.goe.go.kr',
    경남: 'https://evpn.gne.go.kr',
    부산: 'https://evpn.pen.go.kr',
    대구: 'https://evpn.dge.go.kr',
    대전: 'https://evpn.dje.go.kr',
    경북: 'https://evpn.gbe.go.kr',
    세종: 'https://evpn.sje.go.kr',
    울산: 'https://evpn.use.go.kr',
    인천: 'https://evpn.ice.go.kr',
    광주: 'https://evpn.gen.go.kr',
    전남: 'https://evpn.jne.go.kr',
    전북: 'https://evpn.jbe.go.kr',
    충남: 'https://evpn.cne.go.kr',
    충북: 'https://evpn.cbe.go.kr',
    강원: 'https://evpn.kwe.go.kr',
    제주: 'https://evpn.jje.go.kr',
  },
  생활기록부지원포털: { default: 'https://star.moe.go.kr/' },
  학교안전공제회: { default: 'https://www.schoolsafe.or.kr/' },
  방과후학교지원포털: { default: 'https://www.afterschool.go.kr/' },
};
