import { useCallback, useContext, useEffect, useState } from 'react';
import SeatSettingContainer from './SeatDrawMapContainer';
import UserContext from '../../../contexts/userContext';
import axios from 'axios';

const SeatDrawMapComponent = ({
  numberList,
  setNumberList,
  notUsingNumbers,

  seats,
  setSeats,
  pairMode,
  setPairMode,
  genderMode,
  setGenderMode,
  blockedSeats,
  setBlockedSeats,

  genderMap,

  genderSeatRule,
  setGenderSeatRule,

  preAssigningSeats,
  setPreAssigningSeats,
  savePreAssignment,
  numberSeatMap,
  completedNum,
  setCompletedNum,
  isDrawSettingOpen,
  setIsDrawSettingOpen,
  currentNumIndex,
  isReversed,
  setIsReversed,
  setNumberSeatMap,
  clickedJebiNums,
  setClickedJebiNums,
  jebiPositions,
  setJebiPositions,
  setCurrentNumIndex,

  setDrawingResult,
  drawingResult,
  setIsDrawOk,
  isDrawOk,
  isLargeResultMode,
  setIsLargeResultMode,
  handleCompleteRemaining,
  setIsRevealAll,
  isRevealAll,

  isClickedByJebi,
  setIsClickedByJebi,
  localGenderMap,
  frontNums,
  students,
  showNameMode,
  downloadExcel,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  // -------------------- 자리 설정 -------------------- //

  // //자리 설정 불러오기
  // const loadSeatInfo = useCallback(async () => {
  //   try {
  //     const response = await axios.get(
  //       'https://teachernote.me/api/getSeatInfo/' + userId,
  //     );
  //     const data = response.data;
  //     // console.log('data:' + JSON.stringify(data[0].seats));

  //     setSeats(data[0].seats);
  //     setBlockedSeats(new Set(data[0].blockedSeats));
  //     setPairMode(data[0].pairMode);
  //     setGenderMode(data[0].genderMode);
  //     setGenderSeatRule(data[0].genderSeatRule);
  //   } catch (error) {
  //     console.error('Error retrieving preferences:', error);
  //   }
  // }, [userId]);

  // useEffect(() => {
  //   loadSeatInfo();
  //   // console.log('preAssigningSeats:' + JSON.stringify(preAssigningSeats));
  // }, [loadSeatInfo]);

  return (
    <SeatSettingContainer
      setSeats={setSeats}
      setBlockedSeats={setBlockedSeats}
      setPairMode={setPairMode}
      pairMode={pairMode}
      setGenderMode={setGenderMode}
      genderMode={genderMode}
      seats={seats}
      blockedSeats={blockedSeats}
      preAssigningSeats={preAssigningSeats}
      genderMap={genderMap}
      genderSeatRule={genderSeatRule}
      setGenderSeatRule={setGenderSeatRule}
      setPreAssigningSeats={setPreAssigningSeats}
      numberList={numberList}
      notUsingNumbers={notUsingNumbers}
      // saveSeatInfo={saveSeatInfo}
      // loadSeatInfo={loadSeatInfo}
      setNumberList={setNumberList}
      savePreAssignment={savePreAssignment}
      // isChangeSeatMode={isChangeSeatMode}
      // setIsChangeSeatMode={setIsChangeSeatMode}
      numberSeatMap={numberSeatMap}
      completedNum={completedNum}
      setCompletedNum={setCompletedNum}
      isDrawSettingOpen={isDrawSettingOpen}
      setIsDrawSettingOpen={setIsDrawSettingOpen}
      currentNumIndex={currentNumIndex}
      isReversed={isReversed}
      setIsReversed={setIsReversed}
      setNumberSeatMap={setNumberSeatMap}
      clickedJebiNums={clickedJebiNums}
      setClickedJebiNums={setClickedJebiNums}
      jebiPositions={jebiPositions}
      setJebiPositions={setJebiPositions}
      setCurrentNumIndex={setCurrentNumIndex}
      setDrawingResult={setDrawingResult}
      drawingResult={drawingResult}
      setIsDrawOk={setIsDrawOk}
      isDrawOk={isDrawOk}
      isLargeResultMode={isLargeResultMode}
      setIsLargeResultMode={setIsLargeResultMode}
      handleCompleteRemaining={handleCompleteRemaining}
      setIsRevealAll={setIsRevealAll}
      isRevealAll={isRevealAll}
      isClickedByJebi={isClickedByJebi}
      setIsClickedByJebi={setIsClickedByJebi}
      localGenderMap={localGenderMap}
      frontNums={frontNums}
      students={students}
      showNameMode={showNameMode}
      downloadExcel={downloadExcel}
    />
  );
};
export default SeatDrawMapComponent;
