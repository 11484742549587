import { useContext, useEffect, useState } from 'react';
import StudentSetting from './StudentSetting';
import UserContext from '../../../contexts/userContext';

const StudentSettingContainer = ({
  localGenderMap,
  setLocalGenderMap,
  genderSelectionMode,
  notUsingNumbers,
  setNotUsingNumbers,
  numberList,
  setNumberList,
  setGenderSelectionMode,
  saveStudentInfo,
  fetchStudentInfo,
  frontNums,
  setFrontNums,
  setIsDrawSettingOpen,
  genderMode,
  setGenderMode,
  seats,
  blockedSeats,
  maleSeatsNum,
  femaleSeatsNum,
  availableSeats,
  actualStudentCount,
  femaleCount,
  maleCount,
  adjacentNums,
  cornerNums,
  // preAssignment,
  setAdjacentNums,
  setCornerNums,
  // setPreAssignment,
  preAssigningSeats,
  setPreAssigningSeats,
  setSeatPreAssignments,
  seatPreAssignments,
  setMaleCount,
  setFemaleCount,
  openRegisterModal,
  loadFrontNums,
  setShowNameMode,
  showNameMode,
  setStudents,
  students,
  isSelectingStudent,
  setIsSelectingStudent,
  duplicateStudents,
  setDuplicateStudents,
  selectedStudentNum,
  setSelectedStudentNum,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  // 성별 하나씩 바꾸기
  const handleGenderClick = (number) => {
    if (number !== '' && !notUsingNumbers.includes(number)) {
      setLocalGenderMap((prevMap) => {
        const currentGender = prevMap[number];
        let newGender = '';

        // '남' -> '여', '여' -> '남', '성별없음' -> '여'
        if (currentGender === '남') {
          newGender = '여';
        } else if (currentGender === '여') {
          newGender = '남';
        } else {
          newGender = '여';
        }

        return {
          ...prevMap,
          [number]: newGender,
        };
      });
    }
  };

  // 성별 전체 채우기 함수
  const fillGender = () => {
    const midIndex = Math.floor(numberList.length / 2); // 반으로 나눌 인덱스 계산
    const newGenderMap = {}; // 새로운 성별 맵 생성

    numberList.forEach((number, index) => {
      newGenderMap[number] = index < midIndex ? '여' : '남'; // 앞 절반은 '여', 뒤 절반은 '남' 설정
    });

    setLocalGenderMap(newGenderMap); // 성별 맵 업데이트
  };
  //전체 성별 바꾸기
  const toggleAllGenders = () => {
    setLocalGenderMap((prevMap) => {
      const newMap = {};

      Object.keys(prevMap).forEach((number) => {
        const currentGender = prevMap[number];
        if (currentGender === '남') {
          newMap[number] = '여';
        } else if (currentGender === '여') {
          newMap[number] = '남';
        } else {
          newMap[number] = currentGender; // '성별없음'은 그대로 유지
        }
      });

      return newMap;
    });
  };
  //--------------------  --------------------//

  const handleMouseEnter = (number) => {
    if (genderSelectionMode && !notUsingNumbers.includes(number)) {
      setLocalGenderMap((prev) => ({
        ...prev,
        [number]: genderSelectionMode,
      }));
    }
  };

  // --------------------  번호 사용 여부 변경  -------------------- //

  const handleNumberClick = (number) => {
    if (genderSelectionMode && !notUsingNumbers.includes(number)) {
      setLocalGenderMap((prev) => ({
        ...prev,
        [number]: genderSelectionMode,
      }));
    }
  };

  // --------------------  번호 추가  -------------------- //

  //학급 번호 추가
  const addNumber = () => {
    const maxNumber = Math.max(...numberList);
    const newNumber = maxNumber + 1;
    setNumberList((prevList) => [...prevList, newNumber]);
    setLocalGenderMap((prev) => ({
      ...prev,
      [newNumber]: null, // 성별이 정해지지 않은 상태로 추가
    }));
  };

  //학급 번호 삭제
  const removeLastNumber = () => {
    setNumberList((prevList) => {
      if (prevList.length <= 1) {
        alert('남은 번호가 1개일 때는 삭제할 수 없습니다.');
        return prevList; // 삭제를 막고 기존 리스트 반환
      }

      const newList = [...prevList];
      const lastNumber = newList.pop(); // 마지막 번호 제거

      // 앞 번호 확인 및 사용자 확인 메시지 추가
      if (frontNums.includes(lastNumber)) {
        const confirmMessage =
          '앞에 앉을 학생들로 설정되어 있는 번호입니다. 해당 번호를 앞에 앉을 학생들에서 삭제하고 사용하지 않는 번호로 설정하시겠습니까?';
        if (!window.confirm(confirmMessage)) {
          return prevList; // 사용자가 취소하면 기존 리스트 반환
        }
        // 사용자가 확인한 경우 frontNums에서 삭제
        setFrontNums((prev) => prev.filter((num) => num !== lastNumber));
      }

      setLocalGenderMap((prev) => {
        const updatedMap = { ...prev };
        const lastGender = updatedMap[lastNumber]; // 마지막 번호의 성별 저장
        delete updatedMap[lastNumber]; // localGenderMap에서 해당 번호 제거

        // 성별 수 업데이트 로직 추가
        if (lastGender === '남') {
          setMaleCount((prevCount) => prevCount - 1); // 남학생 수 감소
        } else if (lastGender === '여') {
          setFemaleCount((prevCount) => prevCount - 1); // 여학생 수 감소
        }

        return updatedMap;
      });

      // notUsingNumbers에서도 해당 번호 제거
      setNotUsingNumbers((prevNotUsing) => {
        if (prevNotUsing.includes(lastNumber)) {
          return prevNotUsing.filter((number) => number !== lastNumber);
        }
        return prevNotUsing;
      });
      return newList;
    });
  };

  // --------------------  사용 안할 번호 삭제 혹은  X 표시  -------------------- //

  //학급 번호 사용 여부 결정
  // --------------------  번호 사용 여부 변경  -------------------- //
  // useEffect(() => {
  //   console.log('seatPreAssignments:' + JSON.stringify(seatPreAssignments));
  //   console.log('preAssigningSeats:' + JSON.stringify(preAssigningSeats));
  // }, [seatPreAssignments, preAssigningSeats]);

  const toggleNumberUsage = (num) => {
    // frontNums에 해당 번호가 포함되어 있는지 확인
    if (frontNums.includes(num)) {
      const confirmMessage =
        '앞에 앉을 학생들로 설정되어 있는 번호입니다. 해당 번호를 앞에 앉을 학생들에서 삭제하고 사용하지 않는 번호로 설정하시겠습니까?';
      if (!window.confirm(confirmMessage)) {
        return; // 사용자가 취소한 경우 함수 종료
      }
      // 사용자가 확인한 경우 frontNums에서 삭제
      setFrontNums((prev) => prev.filter((n) => n !== num));
    }
    // adjacentNums와 cornerNums에 이미 등록되어 있는지 확인
    const isInAdjacentNums = adjacentNums.some((pair) => pair.includes(num));
    const isInCornerNums = cornerNums.includes(num);

    // 미리 배치된 자리에 포함되어 있는지 확인
    const isInSeatPreAssignment = Object.values(
      seatPreAssignments || {},
    ).includes(String(num));
    const isInPreAssigningSeats = Object.values(
      preAssigningSeats || {},
    ).includes(String(num));

    if (isInSeatPreAssignment || isInPreAssigningSeats) {
      const confirmMessage =
        '기존 등록 내용을 삭제하고 사용하지 않는 번호로 설정하시겠습니까?';
      if (window.confirm(confirmMessage)) {
        // seatPreAssignments에서 해당 번호 삭제
        setSeatPreAssignments((prev) => {
          const updatedAssignments = { ...prev };
          for (const [key, value] of Object.entries(updatedAssignments)) {
            if (value === String(num)) {
              delete updatedAssignments[key];
            }
          }
          return updatedAssignments;
        });

        // preAssigningSeats에서 해당 번호 삭제
        setPreAssigningSeats((prev) => {
          const updatedPreAssigningSeats = { ...prev };
          for (const [key, value] of Object.entries(updatedPreAssigningSeats)) {
            if (value === String(num)) {
              delete updatedPreAssigningSeats[key];
            }
          }
          return updatedPreAssigningSeats;
        });
      } else {
        return; // 사용자가 취소한 경우 함수 종료
      }
    }

    if (isInAdjacentNums || isInCornerNums) {
      const confirmMessage =
        '기존 등록 내용을 삭제하고 사용하지 않는 번호로 설정하시겠습니까?';
      if (window.confirm(confirmMessage)) {
        // adjacentNums에서 num을 포함한 쌍 제거
        if (isInAdjacentNums) {
          const updatedAdjacentNums = adjacentNums.filter(
            (pair) => !pair.includes(num),
          );
          setAdjacentNums(updatedAdjacentNums);
        }

        // cornerNums에서 num 제거
        if (isInCornerNums) {
          setCornerNums(cornerNums.filter((n) => n !== num));
        }

        // 전학생으로 설정 (notUsingNumbers에 추가)
        setNotUsingNumbers((prev) => [...prev, num]);

        // 성별 정보 삭제
        setLocalGenderMap((prev) => {
          const updatedMap = { ...prev };
          delete updatedMap[num];
          return updatedMap;
        });

        return; // 함수 실행 종료
      } else {
        return;
      }
    }

    const maxNumber = Math.max(...numberList);
    // 만약 해당 번호가 가장 큰 숫자라면 숫자 자체 삭제
    if (num == maxNumber) {
      setNumberList(numberList.filter((n) => n !== num));

      // 성별 정보 삭제
      setLocalGenderMap((prev) => {
        const updatedMap = { ...prev };
        delete updatedMap[num];
        return updatedMap;
      });
      setNotUsingNumbers((prevNotUsing) => {
        if (prevNotUsing.includes(num)) {
          return prevNotUsing.filter((number) => number !== num);
        }
        return prevNotUsing;
      });
    } else {
      if (notUsingNumbers.includes(num)) {
        // 사용하지 않는 번호 목록에서 제거
        setNotUsingNumbers(notUsingNumbers.filter((n) => n !== num));
      } else {
        // 사용하지 않는 번호 목록에 추가
        setNotUsingNumbers([...notUsingNumbers, num]);

        // 성별 정보 삭제
        setLocalGenderMap((prev) => {
          const updatedMap = { ...prev };
          delete updatedMap[num];
          return updatedMap;
        });
      }
    }
  };

  // --------------------  초기 성별 설정 및 학생 정보 리셋  -------------------- //

  useEffect(() => {
    const initializeSettings = async () => {
      if (!userId) {
        await setNumberList(initialNumberList); // setNumberList가 비동기 함수라면 await 사용
        setLocalGenderMap(initialLocalGenderMap);
        setNotUsingNumbers(initialNotUsingNumbers);
      }
    };

    initializeSettings();
  }, []);

  const initialNumberList = Array.from({ length: 30 }, (_, i) =>
    (i + 1).toString(),
  );
  const initialLocalGenderMap = {
    1: '여',
    2: '여',
    3: '여',
    4: '여',
    5: '여',
    6: '여',
    7: '여',
    8: '여',
    9: '여',
    10: '여',
    11: '여',
    12: '여',
    13: '여',
    14: '여',
    15: '여',
    16: '남',
    17: '남',
    18: '남',
    19: '남',
    20: '남',
    21: '남',
    22: '남',
    23: '남',
    24: '남',
    25: '남',
    26: '남',
    27: '남',
    28: '남',
    29: '남',
    30: '남',
  };
  const initialNotUsingNumbers = [];

  const resetStudentInfo = () => {
    setNumberList(initialNumberList);
    setLocalGenderMap(initialLocalGenderMap);
    setNotUsingNumbers(initialNotUsingNumbers);
    setFrontNums([]);
  };

  return (
    <StudentSetting
      setGenderSelectionMode={setGenderSelectionMode}
      numberList={numberList}
      toggleNumberUsage={toggleNumberUsage}
      addNumber={addNumber}
      handleMouseEnter={handleMouseEnter}
      handleNumberClick={handleNumberClick}
      notUsingNumbers={notUsingNumbers}
      setNotUsingNumbers={setNotUsingNumbers}
      localGenderMap={localGenderMap}
      saveStudentInfo={saveStudentInfo}
      fetchStudentInfo={fetchStudentInfo}
      resetStudentInfo={resetStudentInfo}
      frontNums={frontNums}
      setFrontNums={setFrontNums}
      setIsDrawSettingOpen={setIsDrawSettingOpen}
      genderMode={genderMode}
      setGenderMode={setGenderMode}
      removeLastNumber={removeLastNumber}
      seats={seats}
      blockedSeats={blockedSeats}
      handleGenderClick={handleGenderClick}
      toggleAllGenders={toggleAllGenders}
      maleSeatsNum={maleSeatsNum}
      femaleSeatsNum={femaleSeatsNum}
      availableSeats={availableSeats}
      actualStudentCount={actualStudentCount}
      femaleCount={femaleCount}
      maleCount={maleCount}
      adjacentNums={adjacentNums}
      cornerNums={cornerNums}
      setAdjacentNums={setAdjacentNums}
      setCornerNums={setCornerNums}
      preAssigningSeats={preAssigningSeats}
      setPreAssigningSeats={setPreAssigningSeats}
      seatPreAssignments={seatPreAssignments}
      setSeatPreAssignments={setSeatPreAssignments}
      openRegisterModal={openRegisterModal}
      loadFrontNums={loadFrontNums}
      setShowNameMode={setShowNameMode}
      showNameMode={showNameMode}
      setStudents={setStudents}
      students={students}
      setNumberList={setNumberList}
      isSelectingStudent={isSelectingStudent}
      setIsSelectingStudent={setIsSelectingStudent}
      duplicateStudents={duplicateStudents}
      setDuplicateStudents={setDuplicateStudents}
      selectedStudentNum={selectedStudentNum}
      setSelectedStudentNum={setSelectedStudentNum}
      fillGender={fillGender}
    />
  );
};

export default StudentSettingContainer;
