import { useEffect, useState } from 'react';
import { PrivacyPolicyHelmetContent } from '../../components/HelmetContent';
import { margin, padding } from '../../styles/commonStyle';
import {
  PrivacyPolicy_Container,
  PrivacyPolicy_ContentWrapper,
  PrivacyPolicy_PreContent,
  PrivacyPolicy_Title,
} from './PrivacyPolicyStyle';

const PrivacyPolicy = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 모바일 기준 너비 설정
    };

    handleResize(); // 첫 렌더링 시 확인
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <PrivacyPolicy_Container>
      <PrivacyPolicy_ContentWrapper isMobile={isMobile}>
        <PrivacyPolicyHelmetContent />
        <PrivacyPolicy_Title isMobile={isMobile}>
          개인정보 처리방침
        </PrivacyPolicy_Title>
        <PrivacyPolicy_PreContent isMobile={isMobile}>
          {`
제1조(목적)

티처노트(이하 ‘회사'라고 함)는 회사가 제공하는 다양한 서비스(이하 ‘회사 서비스’)를 이용하는 개인(이하 ‘이용자’ 또는 ‘개인’)의 정보(이하 ‘개인정보’)를 보호하기 위해, 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 '정보통신망법') 등 관련 법령을 준수하고 있습니다. 본 방침은 이용자의 개인정보가 수집 및 이용되는 방법과 목적을 설명하며, 이용자의 개인정보 보호 권리를 명확히 알리고자 작성되었습니다.


제2조(개인정보의 처리 원칙)

회사는 개인정보 관련 법령 및 본 방침에 따라 이용자의 개인정보를 수집하고, 이를 이용자의 동의에 따라 처리하며, 필요한 경우 제3자에게 제공할 수 있습니다. 단, 법령에 근거한 요구가 있을 경우, 개인의 동의 없이 제3자에게 제공할 수 있습니다.


제3조(본 방침의 공개)

회사는 이용자가 언제든지 쉽게 본 방침을 확인할 수 있도록 회사 홈페이지 첫 화면 또는 연결화면에 본 방침을 게시하고 있습니다. 본 방침을 게시할 때, 글자 크기와 색상을 활용하여 이용자가 쉽게 인지할 수 있도록 하고 있습니다.


제4조(본 방침의 변경)

본 방침은 법령이나 회사 서비스의 정책 변경에 따라 개정될 수 있습니다. 방침 개정 시 회사는 홈페이지 공지사항, 전자우편 등을 통해 이용자에게 개정 내용을 안내합니다. 중요한 변경 사항이 있는 경우 최소 30일 전에 공지합니다.


제5조(수집하는 개인정보 항목)

회사는 회원 가입 및 서비스 제공을 위해 다음과 같은 개인정보를 수집합니다.

1. 필수 정보
   - 회원가입 시: 아이디, 이메일 주소
   - 소셜 로그인 시: 카카오, 구글, 네이버 로그인 계정 정보(이름, 프로필 사진, 이메일)
   - 광고 및 마케팅: 이메일, 카카오톡 ID를 통해 광고 메시지, 문자, 이메일 발송 시 활용

   
2. 추가 수집 정보 (선택 사항)
   - 프로필 정보: 학교급(초등, 중등, 고등 등), 학교명, 과목, 이름, 소셜 미디어 ID(SNS ID)
   - 서비스 이용 시: 자리 배치, 수업 진도표 등 사용자가 입력하는 정보


제6조(개인정보 수집 방법)

회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다.
1. 이용자가 직접 홈페이지나 어플리케이션을 통해 입력하는 방식
2. 소셜 로그인 이용 시 해당 플랫폼을 통해 전달받는 방식
3. 이용자가 고객센터 상담, 게시판에서 활동하면서 제공하는 방식


제7조(개인정보의 이용 목적)

회사는 수집한 개인정보를 다음과 같은 목적으로 이용합니다.
1. 서비스 제공 및 관리: 랜덤 자리 뽑기, 랜덤 숫자 뽑기, 수업 진도표 작성, 교육 자료 공유 기능 제공
2. 계정 관리 및 인증: 이용자 본인 확인, 로그인, 서비스 이용 시 인증
3. 맞춤형 서비스 제공: AI 기반의 개인 맞춤형 콘텐츠 추천 및 학습 정보를 제공하기 위해, 사용자의 진도 및 자리 배치 데이터를 활용
4. 마케팅 및 광고: 이메일과 카카오톡 ID를 통한 광고 메시지, 문자, 이메일 발송
5. 빅데이터 분석 및 서비스 개선: 이용자가 제공한 자리 배치, 진도 기록 데이터를 분석하여 서비스 개선 및 신규 서비스 개발에 활용


제8조(개인정보의 보유 및 이용기간)

회사는 이용자의 개인정보를 수집 목적 달성 시까지 보유하며, 관련 법령에서 정한 일정 기간 동안 개인정보를 보관해야 할 경우 해당 기간 동안 보관합니다. 이용자가 탈퇴를 요청하거나 보관 기간이 경과한 경우, 개인정보를 즉시 파기합니다.


제9조(법령에 따른 개인정보 보유 및 이용기간)

회사는 관계 법령에 따라 다음과 같은 기간 동안 개인정보를 보유 및 이용합니다.
- 계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
- 대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
- 소비자 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래 등에서의 소비자보호에 관한 법률)
- 웹사이트 접속 기록: 3개월 (통신비밀보호법)


제10조(개인정보 파기 원칙 및 방법)

회사는 개인정보의 보유 기간이 경과하거나 수집 목적이 달성된 경우 지체 없이 개인정보를 파기합니다. 전자 파일 형태의 정보는 복구할 수 없도록 기술적 방법을 통해 삭제하며, 종이로 출력된 정보는 분쇄기로 파쇄하거나 소각하여 파기합니다.


제13조(광고성 정보의 전송)

회사는 이용자의 사전 동의를 받아 광고성 정보를 발송할 수 있으며, 이용자가 이를 원하지 않을 경우 언제든지 수신 거부를 요청할 수 있습니다. 광고성 정보 전송 시 수신자가 쉽게 인지하고 수신을 거부할 수 있는 방법을 함께 안내합니다.


제14조(개인정보 제공 및 공유)

회사는 수집한 개인정보를 이용자의 동의 없이 제3자에게 제공하지 않습니다. 단, 다음의 경우 예외로 합니다.
1. 법령에 근거하여 수사 목적으로 요청된 경우
2. 통계작성, 학술연구, 시장조사 등을 위해 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우


제20조(비밀번호 관리)

회사는 이용자의 비밀번호를 안전하게 보호하기 위해 일방향 암호화를 적용하고 있으며, 비밀번호는 이용자 본인만이 알 수 있습니다.


제26조(개인정보 자동 수집 장치의 설치 및 운영)

회사는 개별 맞춤형 서비스를 제공하기 위해 쿠키를 이용할 수 있습니다. 이용자는 웹 브라우저 설정을 통해 쿠키 수집을 거부할 수 있으며, 쿠키 설정 방법은 브라우저별로 제공하는 가이드를 참고하여 변경할 수 있습니다.


제28조(개인정보 보호 책임자 지정)

회사는 이용자의 개인정보 보호와 관련된 불만 처리를 위해 개인정보 보호 책임자를 지정하고 있으며, 관련 사항은 아래와 같습니다.
- 개인정보 보호 책임자: 박진현, CTO
- 연락처: teachernoteofficial@gmail.com


제30조(정보주체와 법정대리인의 권리)

이용자는 언제든지 개인정보 조회, 수정, 삭제, 수집 동의 철회를 요청할 수 있으며, 회사는 이를 신속히 처리합니다. 또한, 이용자가 만 14세 미만 아동인 경우, 법정 대리인이 아동의 개인정보 보호 권리를 행사할 수 있습니다.


제31조(개인정보의 국외 이전)

회사는 이용자의 개인정보를 국외로 이전하는 경우, 해당 개인정보의 이전 목적과 수신자의 정보를 사전에 고지하고 동의를 받습니다. 다만, 법률에서 예외적으로 허용된 경우 별도의 동의 절차 없이 이전할 수 있습니다.


제32조(개인정보 유출 등에 대한 조치)

회사는 개인정보 유출이 발생할 경우 지체 없이 해당 사실을 이용자에게 고지하고, 피해 최소화를 위한 조치를 마련합니다. 만약 유출 사실 통지가 어려운 경우, 홈페이지에 공지하는 것으로 갈음할 수 있습니다.


부칙

본 방침은 2024년 11월 3일부터 시행됩니다.
        `}
        </PrivacyPolicy_PreContent>
      </PrivacyPolicy_ContentWrapper>
    </PrivacyPolicy_Container>
  );
};

export default PrivacyPolicy;
