import { useNavigate } from 'react-router-dom';
import {
  HeaderLeft,
  HeaderMenuBarContainer,
  HeaderRight,
  MenuItem,
  NowInfo,
  StyledNavLink,
  SubheaderBarContainer,
  TopMenuDownload,
  TopMenuLogo,
} from './HeaderMenuBarStyle';
import { NavLink } from '../../node_modules/react-router-dom/dist/index';
import LogoutComponent from '../components/Logout';
import { useContext, useEffect, useReducer, useState } from 'react';
import DownloadProgramDiv from '../components/DownloadProgramMenu';
import {
  CenterAlign,
  DisplayColumn,
  GreenBadge,
  NewBadge,
  YellowBadge,
} from '../styles/BasicStyle';
import UserContext from '../contexts/userContext';
import {
  SubHeaderMenubar_Progress,
  SubHeaderMenubar_SeatDraw,
  SubHeaderMenubar_User,
} from './SubHeaderMenuBar';

import LocationContext from '../contexts/LocationContext';
import WeatherContext from '../contexts/WeatherContext';
import { useMediaQuery } from 'react-responsive';
import logoHorizontalSmall from '../img/teachernote-logo/teachernote-logo-horizontal-small.png';
import { BorderButton, GreenButton } from '../styles/MobileBasicStyle';
import { fontSize, padding } from '../styles/commonStyle';

const HeaderMenuBar = ({
  isRegisterModalOpen,
  setRegisterModalOpen,
  isLoginModalOpen,
  setLoginModalOpen,
  setModalSource,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;

  const [, forceUpdate] = useReducer((x) => x + 1, 0); // 리렌더링 강제

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSubheader_Progress, setShowSubheader_Progress] = useState(false);
  const [showSubheader_SeatDraw, setShowSubheader_SeatDraw] = useState(false);
  const [showSubheader_User, setShowSubheader_User] = useState(false);

  const { location, city } = useContext(LocationContext);
  const { weather, icon, status } = useContext(WeatherContext);
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const navigate = useNavigate();
  const goMain = () => {
    navigate('/');
    window.scrollTo({ top: 0 }); // 페이지 이동 후 상단으로 스크롤
  };
  const goLogin = () => {
    navigate('/login');
  };

  //-------------------- subHeader 관련  --------------------//
  const handleMouseEnter_Progress = () => {
    setShowSubheader_Progress(true);
  };

  const handleMouseLeave_Progress = () => {
    setShowSubheader_Progress(false);
  };
  const handleMouseEnter_SeatDraw = () => {
    setShowSubheader_SeatDraw(true);
  };

  const handleMouseLeave_SeatDraw = () => {
    setShowSubheader_SeatDraw(false);
  };
  const handleMouseEnter_User = () => {
    setShowSubheader_User(true);
  };

  const handleMouseLeave_User = () => {
    setShowSubheader_User(false);
  };

  return (
    <>
      <HeaderMenuBarContainer>
        <HeaderLeft>
          <TopMenuLogo onClick={goMain}>
            {/* 티처노트1.2 */}
            <img
              src={logoHorizontalSmall}
              style={{ width: '90px' }}
              alt="티처노트 로고 - 가로형 소형"
            />
          </TopMenuLogo>

          <MenuItem>
            <StyledNavLink
              to="/education-resources-sharing"
              className={
                window.location.pathname.startsWith(
                  '/education-resources-sharing',
                )
                  ? 'active'
                  : ''
              }
            >
              ON교무실 <YellowBadge>UP</YellowBadge>
              {/* <NewBadge>N</NewBadge> */}
            </StyledNavLink>
          </MenuItem>
          <MenuItem
          // onMouseEnter={handleMouseEnter_SeatDraw}
          // onMouseLeave={handleMouseLeave_SeatDraw}
          >
            <StyledNavLink
              to="/random-number-picker"
              className={
                window.location.pathname.startsWith('/presenterpicker')
                  ? 'active'
                  : ''
              }
            >
              랜덤뽑기<NewBadge>N</NewBadge>
            </StyledNavLink>
            {/* {showSubheader_SeatDraw && <SubHeaderMenubar_SeatDraw />} */}
          </MenuItem>
          <MenuItem
            onMouseEnter={handleMouseEnter_SeatDraw}
            onMouseLeave={handleMouseLeave_SeatDraw}
          >
            <StyledNavLink
              to="/random-seat-assignment"
              className={
                window.location.pathname.startsWith('/draw') ? 'active' : ''
              }
            >
              자리뽑기
              <YellowBadge>UP</YellowBadge>
              {/* <GreenBadge>UP</GreenBadge> */}
            </StyledNavLink>
            {showSubheader_SeatDraw && <SubHeaderMenubar_SeatDraw />}
          </MenuItem>
          <MenuItem
            onMouseEnter={handleMouseEnter_Progress}
            onMouseLeave={handleMouseLeave_Progress}
          >
            <StyledNavLink
              to="/lesson-progress-tracker"
              className={
                window.location.pathname.startsWith(
                  '/lesson-progress-tracker',
                ) ||
                window.location.pathname.startsWith('/class-management') ||
                window.location.pathname.startsWith('/topic-management')
                  ? 'active'
                  : ''
              }
            >
              진도관리
            </StyledNavLink>
            {showSubheader_Progress && <SubHeaderMenubar_Progress />}
          </MenuItem>
        </HeaderLeft>
        <HeaderRight>
          {!isTablet && (
            <NowInfo>
              {location.lat && location.lon ? <>{city}</> : <></>} &nbsp; |
              &nbsp;
              {weather ? (
                <>
                  {weather.fcstValue}°C {icon}
                </>
              ) : (
                <></>
              )}
            </NowInfo>
          )}
          {userId ? (
            <>
              <MenuItem
                onMouseEnter={handleMouseEnter_User}
                onMouseLeave={handleMouseLeave_User}
                style={{ marginRight: '0px' }}
              >
                <div>{userId}님</div>
                {showSubheader_User && <SubHeaderMenubar_User />}
              </MenuItem>
            </>
          ) : (
            <div>
              {' '}
              <span
                onClick={() => {
                  setModalSource('menubar');
                  setLoginModalOpen(true);
                }}
                style={{
                  paddingRight: `${padding.medium}`,
                  fontSize: `${fontSize.medium}`,
                  color: 'black',
                }}
              >
                로그인
              </span>
              <span
                onClick={() => {
                  setModalSource('menubar');
                  setRegisterModalOpen(true);
                }}
              >
                <BorderButton>회원가입</BorderButton>
              </span>
            </div>
          )}
        </HeaderRight>
      </HeaderMenuBarContainer>
    </>
  );
};

export default HeaderMenuBar;
