import { useContext, useEffect, useState } from 'react';
import Login2 from './Login2Confirm';
import UserContext from '../../contexts/userContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Login2Confirm from './Login2Confirm';

const Login2ConfirmContainer = () => {
  //userContext
  const { state, actions } = useContext(UserContext);
  const navigate = useNavigate();

  //로그인 정보 정의
  const [loginInfo, setLoginInfo] = useState({
    userId: '',
    password: '',
  });

  // 로그인 정보를 저장할지 여부를 위한 상태 추가
  const [rememberMe, setRememberMe] = useState(false);

  //-------------------- 로그인 정보 로드 --------------------//

  // 자동 입력 기능: 저장된 로그인 정보가 있을 경우 로드
  useEffect(() => {
    const savedUserId = localStorage.getItem('savedUserId');
    const savedPassword = localStorage.getItem('savedPassword');

    if (savedUserId || savedPassword) {
      setLoginInfo({
        userId: savedUserId || '',
        password: savedPassword || '',
      });
      setRememberMe(true); // 저장된 정보가 있다면 체크박스도 체크된 상태로 설정
    }
  }, []);

  //-------------------- 로그인 정보 저장 --------------------//

  //input입력 시 값 설정
  const handleChange = (e) => {
    setLoginInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // 체크박스 상태 변경 시 호출되는 함수
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  //-------------------- 로그인 --------------------//

  //가입 정보 DB 저장(암호화는 backend에서)
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://teachernote.me/api/login',
        loginInfo,
      );
      // 토큰 저장 (예: localStorage)
      const token = response.data.token;
      localStorage.setItem('token', token);

      //받아온 user data 확인
      const user = response.data.user;
      // console.log('user:' + JSON.stringify(user));
      localStorage.setItem('user', JSON.stringify(user));

      // localStorage.setItem('user', user);

      //userContext에 받아온 user data 넣기
      actions.setUser(user);

      // 로그인 정보 저장 (체크박스가 체크된 경우에만)
      if (rememberMe) {
        localStorage.setItem('savedUserId', loginInfo.userId);
        localStorage.setItem('savedPassword', loginInfo.password);
      } else {
        // 체크되지 않은 경우 저장된 로그인 정보 삭제
        localStorage.removeItem('savedUserId');
        localStorage.removeItem('savedPassword');
      }

      // drawsetting 페이지로 이동하면서 isLoginConfirmed를 전달
      navigate('/random-seat-assignment-setup', {
        state: { isLoginConfirmed: true },
      }); // window.location.href = '/';
    } catch (error) {
      console.error('Error logging in:', error);
      alert('로그인에 실패하였습니다.');
    }
  };
  return (
    <Login2Confirm
      handleLogin={handleLogin}
      handleChange={handleChange}
      loginInfo={loginInfo} // loginInfo를 전달
      rememberMe={rememberMe}
      handleRememberMeChange={handleRememberMeChange}
    />
  );
};

export default Login2ConfirmContainer;
