import PageContainer from '../../../pageLayout/PageContainer';
import {
  DisplayColumn,
  DisplayRowSpaceBetween,
  GrayBadge,
  GreenBadge,
  NewBadge,
  PageBox_Padding,
  TitleContainer,
  TitleDetail,
  TitleDetail_Mobile,
  TitleText,
} from '../../../styles/BasicStyle';
import jellypicker from '../../../img/mobile-random-number-picker/jelly-random-number-picker-mobile.gif';
import roulette from '../../../img/mobile-random-number-picker/roulette-random-selector-mobile.gif';
import snowmanpicker from '../../../img/mobile-random-number-picker/snowman-random-number-picker-mobile.gif';
import {
  MobileGifImg,
  MobileSubTitle,
  MobileSubTitleBold,
} from './MobilePickerPageStyle';
import { gap } from '../../../styles/commonStyle';
import { RandomNumberHelmetContent } from '../../../components/HelmetContent';
import { GreenButton } from '../../../styles/MobileBasicStyle';
import MainButton from '../../../components/MainButton';

const MobileNumberPickerPage = () => {
  const handleShareClick = async () => {
    const shareData = {
      title: '랜덤 숫자 뽑기',
      text: '다양한 방법으로 랜덤 숫자를 뽑아보세요!',
      url: window.location.href, // 현재 페이지의 URL
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData); // 네이티브 공유 기능 호출 (모바일)
      } catch (error) {
        console.error('공유에 실패했습니다:', error);
      }
    } else {
      // 모달이나 다른 UI를 통해 다양한 공유 옵션 제공
      alert('현재 URL이 복사되었습니다.');
      navigator.clipboard.writeText(shareData.url); // URL 클립보드에 복사
    }
  };
  return (
    <>
      <RandomNumberHelmetContent />
      <PageContainer>
        <PageBox_Padding>
          <TitleContainer>
            <DisplayRowSpaceBetween>
              <DisplayColumn>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: `${gap.extraSmall}`,
                  }}
                >
                  <TitleText>랜덤 숫자 뽑기</TitleText>{' '}
                  <GrayBadge>Desktop&Tablet ONLY</GrayBadge>
                </div>
                <TitleDetail_Mobile>
                  회전 룰렛, 100% 당첨 젤리뽑기, 눈사람 녹이기 등 다양한
                  방법으로 랜덤 숫자(발표자)를 뽑을 수 있습니다.
                </TitleDetail_Mobile>
                <MainButton
                  onClick={handleShareClick}
                  style={{ cursor: 'pointer' }}
                >
                  📢선생님들에게 알리기
                </MainButton>
              </DisplayColumn>
            </DisplayRowSpaceBetween>
          </TitleContainer>
          <div>
            {' '}
            <MobileSubTitle>
              <MobileSubTitleBold>📗회전 룰렛</MobileSubTitleBold>
            </MobileSubTitle>
            <MobileGifImg src={roulette} />{' '}
            <MobileSubTitle>
              <MobileSubTitleBold>
                📗100% 당첨 젤리뽑기<NewBadge>인기</NewBadge>
              </MobileSubTitleBold>
            </MobileSubTitle>
            <MobileGifImg src={jellypicker} />{' '}
            <MobileSubTitle>
              <MobileSubTitleBold>📗눈사람 녹이기</MobileSubTitleBold>
            </MobileSubTitle>
            <MobileGifImg src={snowmanpicker} />
          </div>
          <MainButton onClick={handleShareClick} style={{ cursor: 'pointer' }}>
            📢선생님들에게 알리기
          </MainButton>
        </PageBox_Padding>
      </PageContainer>
    </>
  );
};

export default MobileNumberPickerPage;
