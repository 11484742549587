import styled from 'styled-components';
import { fontSize, fontWeight, padding } from '../../../styles/commonStyle';

export const PickerModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6); // 반투명 배경
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; // 모달이 가장 위에 표시되도록
`;

export const PickerModalContent = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2); // 부드러운 그림자 효과
  width: 90%;
  max-width: 400px;
  text-align: center;
  animation: slideDown 0.4s ease forwards;

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const CongratText = styled.h1`
  // padding: ${padding.medium};
  // font-size: ${fontSize.extraLarge};
  // font-weight: ${fontWeight.bold};
  height: 100%;
  gap: 5px
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PickerModalCloseButton = styled.button`
  margin-top: 1.5rem;
  background-color: #00704a;
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005f3c; // 호버 시 색상 변경
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 8px rgba(0, 112, 74, 0.5); // 포커스 시 그림자 효과
  }
`;
