import styled from 'styled-components';
import {
  borderRadius,
  colors,
  fontSize,
  gap,
  gray,
  margin,
  objHeight,
  objWidth,
  padding,
} from '../../../styles/commonStyle';

export const SeatMapContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: start;
  align-items: start;
  // background-color: red;
`;
export const SeatMapBox = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: blue;
`;

export const SeatGrid = styled.div`
  width: 100%;
  gap: ${gap.small}; /* seat 좌석간 좌우 간격 */

  display: grid;

  grid-template-columns: repeat(
    ${(props) => props.$columns},
    ${objWidth.small}
  ); /* seat 좌석간 좌우 간격 */

  // background-color: yellow;
`;

export const Seat = styled.div`
  width: ${objWidth.small};
  height: ${objWidth.small};
  border-radius: ${borderRadius.small};
  margin-left: ${(props) =>
    props.$pairmode && props.$col % 2 === 0 ? '10px' : '0px'};
  background-color: ${({ $genderMode, $col, $genderSeatRule, $blocked }) => {
    if ($blocked) return `${gray.medium}`;
    if (!$genderMode) return `${gray.medium}`;
    if ($genderSeatRule) {
      return $col % 2 === 0 ? '#ccccff' : '#ffcccc';
    } else {
      return $col % 2 !== 0 ? '#ccccff' : '#ffcccc';
    }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ $blocked }) => ($blocked ? '1.5em' : 'inherit')};

  .blocked-text {
    font-size: 1.5em; // 글자 크기 조정
    color: ${colors.background};
  }
`;

export const Blackboard = styled.div`
  width: 100%;
  height: ${objWidth.small};
  margin-top: ${margin.mediumlarge};

  border-radius: ${borderRadius.medium};

  color: white;
  background-color: #00704a;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SeatDrawSettingButtonContainer = styled.div`
  width: 100%;
  margin: 0 0 ${margin.medium} 0;
  gap: 10px;

  color: ${gray.darktext};
  display: flex;
  justify-content: start;
`;

export const SeatDrawSettingButton = styled.div`
  width: ${objWidth.mediumLarge};
  padding: 10px 20px;
  margin: ${margin.mediumlarge};
  text-align: center;
  font-size: ${fontSize.medium};

  border-radius: ${borderRadius.small};

  background-color: ${gray.medium};
  color: ${colors.background};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${gray.dark};
  }
`;

export const DrawingBox = styled.div``;

export const SeatDrowBottomButtons = styled.div`
  width: 100%;
  margin-top: ${margin.mediumlarge};
  gap: ${margin.medium};

  display: flex;
  justify-content: center;
`;
export const SeatDrowRightButtons = styled.div`
  margin-left: ${margin.mediumlarge};
  margin-bottom: ${margin.large};
  gap: ${margin.medium};
  display: flex;
  flex-direction: column;
`;

export const SeatMapEditButton = styled.div`
  width: ${objWidth.extraSmall};
  height: ${objWidth.extraSmall};

  padding: ${padding.small};
  // margin: ${margin.mediumlarge} ${margin.mediumlarge} 0 ${margin.mediumlarge};

  border-radius: ${borderRadius.small};

  font-size: ${fontSize.categoryLarge};
  text-align: center;

  background-color: ${colors.primary};
  color: ${colors.background};
  // background-color: ${gray.medium};
  // color: ${colors.background};

  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${gray.dark};
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SeatmapNumberInput = styled.input`
  width: 40px;
  height: ${objHeight.medium};
  padding: ${padding.medium};

  font-size: ${fontSize.medium};
  border: 1px solid ${gray.medium};
  border-radius: ${borderRadius.small};
  &:focus {
    border: 1px solid #26c6da;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    outline: none;
  }
`;
