import styled from 'styled-components';
import {
  borderRadius,
  breakpoints,
  colors,
  fontSize,
  gap,
  gray,
  margin,
  objHeight,
  objWidth,
  padding,
} from '../../../styles/commonStyle';

export const SeatMapContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // justify-content: space-around;
  // background-color: red;
`;
export const SeatMapBox = styled.div`
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SeatGridContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
`;

export const SeatGrid = styled.div`
  padding-top: ${padding.mediumLarge};
  display: grid;

  gap: ${(props) => (props.$isLargeResultMode ? gap.medium : gap.small)};

  grid-template-columns: repeat(
    ${(props) => props.$columns},
    ${(props) =>
      props.$isLargeResultMode ? objWidth.seatLarge : objWidth.small}
  );
`;
// width: ${({ $isLargeResultMode, $showNameMode }) =>
//   $isLargeResultMode
//     ? $showNameMode
//       ? objWidth.seatLargeMore // isLargeResultMode와 showNameMode가 모두 true일 때 크기 1.2배로
//       : objWidth.seatLarge // isLargeResultMode만 true일 때 기본 크기
//     : $showNameMode
//     ? objWidth.seatLarge // showNameMode만 true일 때 크기 1.2배로
//     : objWidth.small};
// height: ${({ $isLargeResultMode, $showNameMode }) =>
//   $isLargeResultMode
//     ? $showNameMode
//       ? objWidth.seatLargeMore
//       : objWidth.seatLarge
//     : $showNameMode
//     ? objWidth.seatLarge
//     : objWidth.small};

export const Seat = styled.div`
  width: ${({ $isLargeResultMode }) =>
    $isLargeResultMode ? objWidth.seatLarge : objWidth.small};
  height: ${({ $isLargeResultMode }) =>
    $isLargeResultMode ? objWidth.seatLarge : objWidth.small};
  border-radius: ${borderRadius.small};
  margin-left: ${(props) =>
    props.$pairmode && props.$col % 2 === 0
      ? props.$isLargeResultMode
        ? '15px'
        : '10px'
      : '0px'};
  color: ${({ $highlighted, $selected, $highlight }) => {
    if ($selected) return `${colors.background}`;

    if ($highlighted && $highlight) {
      return `${colors.background}`;
    } else {
      return `${gray.darktext}`;
    } // 강조할 색상
  }};

  background-color: ${({
    $genderMode,
    $col,
    $genderSeatRule,
    $blocked,
    $highlight,
    $highlighted,
    $selected,
    $isClickedByJebi,
    $isReversed,
  }) => {
    const adjustedCol = $isReversed ? $col * -1 : $col; // 컬럼 값 반전
    const effectiveGenderSeatRule = $isReversed
      ? !$genderSeatRule
      : $genderSeatRule;

    if ($blocked) return `${gray.light}`;
    if ($selected) return `${colors.lightGreen}`;

    if ($highlighted && $highlight && $isClickedByJebi)
      return `${colors.lightGreen}`; // 방금 뽑은 번호
    if (!$genderMode) return `${colors.lightGreenBackground}`;
    if (effectiveGenderSeatRule) {
      return adjustedCol % 2 === 0
        ? `${colors.maleLight}`
        : `${colors.femaleLight}`;
    } else {
      return adjustedCol % 2 !== 0
        ? `${colors.maleLight}`
        : `${colors.femaleLight}`;
    }
  }};

  border: ${({
    $genderMode,
    $col,
    $genderSeatRule,
    $blocked,
    $highlight,
    $highlighted,
    $selected,
    $isReversed,
  }) => {
    const adjustedCol = $isReversed ? $col * -1 : $col; // 컬럼 값 반전
    const effectiveGenderSeatRule = $isReversed
      ? !$genderSeatRule
      : $genderSeatRule;

    if ($blocked) return `1px solid ${gray.medium}`;
    if ($selected) return `${colors.lightGreen}`;

    // if ($highlighted && $highlight) return `${colors.lightGreenBorder}`; // 강조할 색상
    if (!$genderMode) return `1px solid ${colors.lightGreenBorder}`;
    if (effectiveGenderSeatRule) {
      return adjustedCol % 2 === 0
        ? `1px solid ${colors.male}`
        : `1px solid ${colors.female}`;
    } else {
      return adjustedCol % 2 !== 0
        ? `1px solid ${colors.male}`
        : `1px solid ${colors.female}`;
    }
  }};

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ $blocked, $isLargeResultMode, $showNameMode }) => {
    if ($showNameMode) return $isLargeResultMode ? '1.5em' : '0.8em'; // showNameMode가 true일 때 폰트 크기 조정
    if ($blocked) return $isLargeResultMode ? '2em' : '1.5em';
    return $isLargeResultMode ? '2em' : 'inherit';
  }};
  text-align: ${({ $showNameMode }) =>
    $showNameMode
      ? 'center'
      : 'inherit'}; // showNameMode가 true일 때 가운데 정렬

  .blocked-text {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.5em;
    color: ${gray.medium};
  }
  cursor: pointer;

  // cursor: ${({ $isEditResultMode }) =>
    $isEditResultMode ? 'pointer' : 'default'};
`;
// font-size: ${({ $blocked, $isLargeResultMode }) => {
//   if ($blocked) return $isLargeResultMode ? '2em' : '1.5em';
//   return $isLargeResultMode ? '2em' : 'inherit';
// }};

export const Blackboard = styled.div`
  width: 100%;
  height: ${({ $isLargeResultMode }) =>
    $isLargeResultMode ? objWidth.seatLarge : objWidth.small};
  margin-top: ${({ $isLargeResultMode }) =>
    $isLargeResultMode ? margin.large : margin.mediumlarge};
  margin-bottom: ${({ $isLargeResultMode }) =>
    $isLargeResultMode ? margin.large : margin.medium};

  font-size: ${({ $isLargeResultMode }) =>
    $isLargeResultMode ? fontSize.extraLarge : 'inherit'};
  border-radius: ${borderRadius.medium};

  color: ${gray.darktext};
  background-color: ${gray.light};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SeatDrawSettingButtonContainer = styled.div`
  width: 100%;
  margin: 0 0 ${margin.medium} 0;
  gap: ${gap.medium};

  color: ${gray.darktext};
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
`;

export const SeatDrawSettingContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${gap.extraSmall};
  cursor: pointer;
`;

export const SeatDrawSettingButton = styled.div`
  width: ${objWidth.mediumLarge};
  padding: 10px 20px;
  margin: ${margin.mediumlarge};
  text-align: center;
  font-size: ${fontSize.medium};

  border-radius: ${borderRadius.small};

  background-color: ${gray.medium};
  color: ${colors.background};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${gray.dark};
  }
`;

export const DrawingBox = styled.div``;

export const SeatDrowBottomButtons = styled.div`
  width: 100%;
  margin-top: ${margin.mediumlarge};
  gap: ${margin.medium};

  display: flex;
  justify-content: center;
`;
export const SeatDrowRightButtons = styled.div`
  margin-left: ${margin.mediumlarge};
  margin-bottom: ${margin.large};
  gap: ${margin.medium};
  display: flex;
  flex-direction: column;
`;

export const SeatMapEditButton = styled.div`
  width: ${objWidth.small};
  height: ${objWidth.small};

  border: 1px solid ${colors.lightGreen};
  border-radius: ${borderRadius.small};
  background-color: ${colors.background};

  font-size: ${fontSize.categoryLarge};
  text-align: center;

  color: ${colors.lightGreen};
  cursor: pointer;
  &:hover {
    background-color: ${gray.dark};
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SeatmapNumberInput = styled.input`
  width: 100%;
  height: 100%;
  // height: ${objHeight.medium};
  // padding: ${padding.medium};

  // font-size: ${fontSize.medium};
  border: 1px solid ${gray.medium};
  border-radius: ${borderRadius.small};
  &:focus {
    // border: 1px solid #26c6da;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    outline: none;
  }
`;

export const DisplayRight = styled.div`
  width: 100%;
  height: 100%;
  padding: ${padding.medium} 0;
  display: flex;
  justify-content: flex-end; //10.31
  // justify-content: space-between;
  // background-color: yellow;
`;

export const LargeResultModeDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  cursor: pointer;
  // background-color: yellow;
`;

export const SeatInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SeatmapNumberButton = styled.div`
  width: 100%;
  // padding: 10px 20px;

  border-radius: ${borderRadius.small};

  background-color: ${colors.lightGreen};
  color: ${colors.background};
  // border: 1px solid ${gray.darktext};
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: ${fontSize.medium};
  &:hover {
    background-color: ${colors.lightGreen};
    border: none;
    color: ${colors.background};
    cursor: pointer;
  }
`;

export const CenterVertical = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
