// Notification.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  borderRadius,
  colors,
  fontColors,
  fontSize,
  gray,
  padding,
} from '../styles/commonStyle';
import { media } from '../styles/media';

const NotificationWrapper = styled.div`
  padding: ${padding.medium} ${padding.mediumLarge};
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  ${media.desktop`
    top:150px;
  // bottom: 250px;
  `}
  ${media.tablet`
        top:150px;

  // bottom: 250px;
  `}
  ${media.mobile`
    display: flex;
    justify-content: center;  
    bottom: 100px; 
    font-size : ${fontSize.medium};
  `}
  font-size: ${fontSize.categoryLarge};
  background-color: ${gray.extralight};
  color: ${colors.primary};
  border-radius: ${borderRadius.small};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Notification = ({ message, isVisible }) => {
  return isVisible ? (
    <NotificationWrapper>{message}</NotificationWrapper>
  ) : null;
};

export default Notification;
