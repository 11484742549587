import Main from './Main';

const MainContainer = ({ cards, cardsByCategory }) => {
  return (
    <>
      <Main cards={cards} cardsByCategory={cardsByCategory} />
    </>
  );
};
export default MainContainer;
