import Modal from 'react-modal';
import RegisterModalContainer from './RegisterModalContainer';
import { borderRadius } from '../../styles/commonStyle';

Modal.setAppElement('#root'); // 애플리케이션의 루트 엘리먼트를 설정

const AuthModalCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // 모달 외부 배경의 투명도 설정 (50% 투명도)
    zIndex: 1000, // overlay의 z-index 설정
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    // height: 'auto',
    // width: 'auto',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // backgroundColor: 'red',
    border: 'none',
    // overflow: 'auto', // 콘텐츠가 많을 경우 스크롤을 허용
    // maxHeight: '50vh', // 최대 높이 제한
  },
};
const RegisterModalComponent = ({
  isRegisterModalOpen,
  setRegisterModalOpen,
  openRegisterModal,
  openLoginModal,
  closeModal,
  handleAuthComplete,
  modalSource,
}) => {
  return (
    <Modal
      isOpen={isRegisterModalOpen}
      onRequestClose={closeModal}
      style={AuthModalCustomStyles}
      contentLabel="Auth Modal"
    >
      <RegisterModalContainer
        handleAuthComplete={handleAuthComplete}
        openLoginModal={openLoginModal}
        modalSource={modalSource}
      />
    </Modal>
  );
};

export default RegisterModalComponent;
