import { useEffect, useState } from 'react';

import StudentSeatSetting from './StudentSeatSetting';
import {
  ModalBlackButton,
  ModalButton,
  ModalContent,
  ModalDetail,
  ModalSubTitle,
  ModalTitle,
  ModalWrapper,
} from '../../../styles/BasicStyle';

const StudentSeatSettingContainer = ({
  // resetSeats,
  //붙을 학생
  adjacentNums,
  setAdjacentNums,
  saveAdjacentNums,
  //떨어질 학생
  separateNums,
  setSeparateNums,
  saveSeparateNums,
  //구석 자리
  cornerNums,
  setCornerNums,
  saveCornerNums,
  preAssigningSeats,
  setPreAssigningSeats,
  fetchAdjacentNums,
  fetchCornerNums,
  numberList,
  notUsingNumbers,
  seatPreAssignments,
  setSeatPreAssignments,
  students,
  showNameMode,
  setShowNameMode,
  frontNums,
  setFrontNums,

  // isSelectingStudent,
  // setIsSelectingStudent,
  // duplicateStudents,
  // setDuplicateStudents,
  // selectedStudentNum,
  // setSelectedStudentNum,
}) => {
  // -------------------- 집중 학생 이름 중복처리 -------------------- //

  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태
  const [duplicateStudents, setDuplicateStudents] = useState([]); // 중복된 학생 목록
  const [selectedStudentNum, setSelectedStudentNum] = useState(null); // 선택된 학생 번호

  // -------------------- 가까이 앉을 학생들 이름 중복처리-------------------- //

  const [isAdjacentModalOpen, setIsAdjacentModalOpen] = useState(false); // 모달 상태

  const [duplicateStudents1, setDuplicateStudents1] = useState([]); // 첫 번째 학생 중복 목록
  const [duplicateStudents2, setDuplicateStudents2] = useState([]); // 두 번째 학생 중복 목록
  const [selectedNum1, setSelectedNum1] = useState(null); // 첫 번째 학생 선택된 번호
  const [selectedNum2, setSelectedNum2] = useState(null); // 두 번째 학생 선택된 번호
  const [isConfirmationNeeded, setIsConfirmationNeeded] = useState(false); // 확인 버튼 필요 여부

  // -------------------- 가까이 앉을 학생들 -------------------- //
  const [num1, setNum1] = useState('');
  const [num2, setNum2] = useState('');
  const [modalInfo, setModalInfo] = useState(null);
  // console.log('preAssigningSeats:' + JSON.stringify(preAssigningSeats));
  // console.log('seatPreAssignments:' + JSON.stringify(seatPreAssignments));
  // useEffect(() => {
  //   console.log('frontNums:' + JSON.stringify(frontNums));
  // }, [frontNums]);

  // 이름을 사용하여 해당 학생의 번호를 반환하는 유틸리티 함수
  const findStudentNumberByName = (name) => {
    const student = students.find((student) => student.name === name);
    return student ? student.number : null;
  };

  //기본 가까이 앉을 학생들 추가 함수
  const handleSetAdjacentNums = (num1, num2) => {
    let parsedNum1 = parseInt(num1);
    let parsedNum2 = parseInt(num2);

    // 사용자가 문자를 입력했을 때 '학생 이름으로 보기' 모드를 물어봄
    const isTextInput1 = isNaN(parsedNum1) && num1 !== '';
    const isTextInput2 = isNaN(parsedNum2) && num2 !== '';

    if (!showNameMode && (isTextInput1 || isTextInput2)) {
      const confirmSwitch = window.confirm(
        "'학생 이름으로 보기' 모드를 실행하시겠습니까?",
      );
      if (confirmSwitch) {
        setShowNameMode(true);
        setNum1('');
        setNum2('');
        return;
      }
    }
    // if (!showNameMode && (isNaN(parsedNum1) || isNaN(parsedNum2))) {
    //   const confirmSwitch = window.confirm(
    //     "'학생 이름으로 보기' 모드를 실행하시겠습니까?",
    //   );
    //   if (confirmSwitch) {
    //     setShowNameMode(true);
    //     setNum1('');
    //     setNum2('');

    //     return;
    //   }
    // }

    if (showNameMode) {
      if (isNaN(parsedNum1)) parsedNum1 = findStudentNumberByName(num1);
      if (isNaN(parsedNum2)) parsedNum2 = findStudentNumberByName(num2);

      const duplicates1 = students.filter((student) => student.name === num1);
      const duplicates2 = students.filter((student) => student.name === num2);

      if (duplicates1.length > 1 || duplicates2.length > 1) {
        setDuplicateStudents1(duplicates1);
        setDuplicateStudents2(duplicates2);
        setIsAdjacentModalOpen(true);

        return;
      }
    }

    if (!parsedNum1 || !parsedNum2) {
      alert('유효하지 않은 번호 또는 이름입니다.');
      setNum1('');
      setNum2('');
      return;
    }

    if (parsedNum1 === parsedNum2) {
      alert('서로 다른 두 개의 번호를 입력해주세요.');
      setNum1('');
      setNum2('');
      return;
    }

    if (!numberList.includes(parsedNum1) || !numberList.includes(parsedNum2)) {
      alert('입력된 번호 중 존재하지 않는 번호가 있습니다.');
      setNum1('');
      setNum2('');
      return;
    }
    if (
      notUsingNumbers.includes(parsedNum1) ||
      notUsingNumbers.includes(parsedNum2)
    ) {
      alert('사용할 수 없는 번호입니다. 학생 설정의 X표시를 확인해주세요.');
      setNum1('');
      setNum2('');
      return;
    }

    // 추가된 제한 조건: 최대 4쌍까지 추가 가능
    if (adjacentNums.length >= 4) {
      alert('가까이 앉을 학생은 최대 4쌍까지 추가할 수 있습니다.');
      return;
    }

    // 추가된 로직: frontNums와 cornerNums에서 번호 확인 후 경고
    if (
      frontNums.includes(parsedNum1) ||
      frontNums.includes(parsedNum2) ||
      cornerNums.includes(parsedNum1) ||
      cornerNums.includes(parsedNum2)
    ) {
      const conflictedNum =
        frontNums.includes(parsedNum1) || cornerNums.includes(parsedNum1)
          ? parsedNum1
          : parsedNum2;
      const location = frontNums.includes(conflictedNum)
        ? '앞에 앉을 학생'
        : '집중이 필요한 학생';

      alert(
        `'${location}'에 등록된 번호입니다. 기존 등록 내역을 삭제하고 진행해주세요.`,
      );
      setNum1('');
      setNum2('');
      return;
    }

    // preAssigningSeats와 seatPreAssignments에서 번호 확인 후 경고 및 삭제 처리
    const preAssignedSeat1 = Object.values(preAssigningSeats).includes(
      parsedNum1.toString(),
    );
    const preAssignedSeat2 = Object.values(preAssigningSeats).includes(
      parsedNum2.toString(),
    );
    const seatAssigned1 = Object.values(seatPreAssignments).includes(
      parsedNum1.toString(),
    );
    const seatAssigned2 = Object.values(seatPreAssignments).includes(
      parsedNum2.toString(),
    );

    if (
      preAssignedSeat1 ||
      preAssignedSeat2 ||
      seatAssigned1 ||
      seatAssigned2
    ) {
      const conflictedNum =
        preAssignedSeat1 || seatAssigned1 ? parsedNum1 : parsedNum2;
      const location =
        preAssignedSeat1 || preAssignedSeat2
          ? '미리 배치할 학생'
          : '미리 배치할 학생';

      alert(
        `'${location}'에 등록된 번호입니다. 기존 등록 내역을 삭제하고 진행해주세요.`,
      );
      setNum1('');
      setNum2('');
      return;
    }

    //

    const newPair = [
      Math.min(parsedNum1, parsedNum2),
      Math.max(parsedNum1, parsedNum2),
    ];
    const exists = adjacentNums.some(
      (pair) => pair[0] === newPair[0] && pair[1] === newPair[1],
    );

    if (!exists) {
      const updatedAdjacentNums = [...adjacentNums, newPair].sort(
        (a, b) => a[0] - b[0] || a[1] - b[1],
      );
      setAdjacentNums(updatedAdjacentNums);
      setNum1('');
      setNum2('');
    } else {
      alert('이미 존재하는 쌍입니다.');
      setNum1('');
      setNum2('');
    }
  };

  // useEffect(() => {
  //   console.log('frontNums:' + JSON.stringify(frontNums));
  //   console.log('adjacentNums:' + JSON.stringify(adjacentNums));
  //   console.log('cornerNums:' + JSON.stringify(cornerNums));
  // }, [frontNums, adjacentNums, cornerNums]);

  // 모달 동명이인 이름 선택-  두 학생 모두 선택된 경우 쌍으로 adjacentNums에 추가하는 함수
  const ModalHandleAdjacentNums = async () => {
    if (selectedNum1 && selectedNum2) {
      setNum1(selectedNum1.toString());
      setNum2(selectedNum2.toString());
      handleSetAdjacentNums(selectedNum1.toString(), selectedNum2.toString());
      setIsAdjacentModalOpen(false);
    }
  };

  // 중복 이름을 위한 선택 자동화 로직 추가
  useEffect(() => {
    if (duplicateStudents1.length === 1) {
      InAdjacentModalSelectController(duplicateStudents1[0], 1);
    }
    if (duplicateStudents2.length === 1) {
      InAdjacentModalSelectController(duplicateStudents2[0], 2);
    }
  }, [duplicateStudents1, duplicateStudents2]);

  // useEffect(() => {
  //   console.log(`num1: ${num1}, num2: ${num2}`);
  // }, [num1]);
  //모달에서 이름 선택 컨트롤
  const InAdjacentModalSelectController = (student, index) => {
    const number = student.number;
    const registeredPlace = isStudentNumAlreadyRegistered(number);

    if (registeredPlace) {
      alert(
        `'${registeredPlace}'에 등록된 번호입니다. 기존 등록 내역을 삭제하고 진행해주세요.`,
      );
      return;
    } else {
      // 등록된 내역이 없는 경우 바로 선택
      if (index === 1) {
        setSelectedNum1(student.number);
      }
      if (index === 2) {
        setSelectedNum2(student.number);
      }

      if (selectedNum1 && selectedNum2) {
        setIsConfirmationNeeded(true);
      }
    }
  };
  const onAdjacentModalClose = () => {
    setIsAdjacentModalOpen(false);
    setDuplicateStudents1([]);
    setDuplicateStudents2([]);
    setSelectedNum1(null);
    setSelectedNum2(null);
  };

  const handleRemoveAdjacentNum = (pairToRemove) => {
    const updatedAdjacentNums = adjacentNums.filter(
      (pair) => pair[0] !== pairToRemove[0] || pair[1] !== pairToRemove[1],
    );
    setAdjacentNums(updatedAdjacentNums);
  };

  // -------------------- 구석에 앉을 학생들 -------------------- //
  const [inputCornerNums, setInputCornerNums] = useState('');

  // useEffect(() => {
  //   console.log('notUsingNumbers:' + JSON.stringify(notUsingNumbers));
  //   console.log('cornerNums:' + JSON.stringify(cornerNums));
  // }, [notUsingNumbers, cornerNums]);

  const handleCornerInputChange = (e) => {
    setInputCornerNums(e.target.value);
  };

  const handleAddCornerNums = (selectedNumbers = null) => {
    // const inputValues = inputCornerNums.split(',').map((input) => input.trim());
    const inputValues = selectedNumbers
      ? selectedNumbers.map((num) => num.toString()) // 선택된 번호가 있을 경우 문자열로 변환
      : inputCornerNums.split(',').map((input) => input.trim()); // 인풋 값에서 처리

    // showNameMode가 false일 때 문자가 입력되었는지 확인
    const nonNumericInputs = inputValues.some((input) =>
      isNaN(parseInt(input)),
    );
    if (!showNameMode && nonNumericInputs) {
      const confirmSwitch = window.confirm(
        "'학생 이름으로 보기' 모드를 실행하시겠습니까?",
      );
      if (confirmSwitch) {
        setShowNameMode(true); // showNameMode를 true로 설정
        setInputCornerNums('');
        return;
      }
    }

    const parsedNums = inputValues
      .map((input) => {
        let parsedNum = parseInt(input);
        if (isNaN(parsedNum) && showNameMode) {
          parsedNum = findStudentNumberByName(input);
        }
        return parsedNum;
      })
      .filter(Boolean);

    const duplicates = students.filter((student) =>
      inputValues.includes(student.name),
    );
    if (duplicates.length > 1) {
      setDuplicateStudents(duplicates);
      setIsModalOpen(true);
      return;
    }

    // 추가된 로직: 입력한 이름이 students 리스트에 없는 경우 처리
    const invalidNames = inputValues.filter(
      (input) =>
        isNaN(parseInt(input)) &&
        !students.some((student) => student.name === input),
    );

    if (invalidNames.length > 0) {
      alert(`학생이 목록에 없는 이름입니다.`);
      setInputCornerNums('');
      return;
    }
    const invalidNumbers = parsedNums.filter(
      (num) => !numberList.includes(num),
    );
    if (invalidNumbers.length > 0) {
      alert(
        `${invalidNumbers.join(
          ', ',
        )}번은 없는 번호입니다. 학생 설정의 번호를 확인해주세요.`,
      );
      setInputCornerNums('');
      return;
    }

    //X표시된 학생 번호 확인
    const notUsableNums = parsedNums.filter((num) =>
      notUsingNumbers.includes(num),
    );
    if (notUsableNums.length > 0) {
      alert(
        `번호 ${notUsableNums.join(
          ', ',
        )}은(는) 사용할 수 없는 번호입니다. 학생 설정에서 X표시를 확인해주세요.`,
      );
      setInputCornerNums('');
      return;
    }

    // 이미 다른 곳에 등록된 번호 확인
    const numsInOtherAssignments = parsedNums.filter(
      (num) =>
        frontNums.includes(num) ||
        adjacentNums.some((pair) => pair.includes(num)) ||
        Object.values(seatPreAssignments).includes(String(num)) ||
        Object.values(preAssigningSeats).includes(String(num)),
    );

    if (numsInOtherAssignments.length > 0) {
      const conflictNum = numsInOtherAssignments[0]; // 충돌된 번호 중 첫 번째 번호 가져오기
      const location = frontNums.includes(conflictNum)
        ? '앞에 앉을 학생'
        : adjacentNums.some((pair) => pair.includes(conflictNum))
        ? '가까이 앉을 학생'
        : Object.values(seatPreAssignments).includes(String(conflictNum))
        ? '미리 배치할 학생'
        : '미리 배치할 학생';

      alert(
        `${location}에 등록된 번호입니다. 기존 등록 내역을 삭제하고 진행해주세요.`,
      );
      setInputCornerNums('');
      return;
    }

    const combinedNums = [...new Set([...cornerNums, ...parsedNums])];
    if (combinedNums.length >= 1 && combinedNums.length <= 4) {
      setCornerNums(combinedNums);
      setInputCornerNums('');
    } else {
      alert('번호는 1개에서 4개까지 입력할 수 있습니다.');
      setInputCornerNums('');
    }
  };

  //구석에 앉을 학생 번호 클릭 시 삭제
  const handleRemoveCornerNum = (numToRemove) => {
    setCornerNums((prevCornerNums) =>
      prevCornerNums.filter((num) => num !== numToRemove),
    );
  };

  // -------------------- 엔터 이벤트(adjacent, corner 학생 추가 ) -------------------- //

  const handleKeyPressCorner = (event) => {
    if (event.key === 'Enter') {
      handleAddCornerNums();
    }
  };

  const handleKeyPressAdjacent = (event) => {
    if (event.key === 'Enter') {
      handleSetAdjacentNums(num1, num2);
    }
  };

  // -------------------- 구석학생 모달 확인 버튼 -------------------- //
  const CornerModalSelectNum = (number) => {
    const registeredPlace = isStudentNumAlreadyRegistered(number);

    if (registeredPlace) {
      alert(
        `'${registeredPlace}'에 등록된 번호입니다. 기존 등록 내역을 삭제하고 진행해주세요.`,
      );
      // setCornerNums('');
      setIsModalOpen(false); // 모달 닫기
      setInputCornerNums(''); // 인풋 초기화
    } else {
      // setCornerNums((prevCornerNums) => [...prevCornerNums, number]);
      handleAddCornerNums([number]);
      setIsModalOpen(false); // 모달 닫기
      setInputCornerNums(''); // 인풋 초기화
    }
  };

  //이름으로 입력했을때, 모달에서 선택했을 때, 학생 번호가 이미 등록되어 있는지 확인하는 함수
  const isStudentNumAlreadyRegistered = (number) => {
    if (frontNums.includes(number)) {
      return '앞에 앉을 학생';
    }
    if (cornerNums.includes(number)) {
      return '집중이 필요한 학생';
    }
    if (adjacentNums.some((pair) => pair.includes(number))) {
      return '가까이 앉을 학생';
    }
    if (Object.values(seatPreAssignments).includes(number.toString())) {
      return '미리 배치할 학생';
    }
    return null;
  };

  // -------------------- 모달 닫기 -------------------- //

  const onAllModalClose = () => {
    setIsModalOpen(false);
    setIsAdjacentModalOpen(false);
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onAllModalClose(); // ESC 키를 누르면 모달 닫기
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onAllModalClose(); // 모달 외부를 클릭하면 모달 닫기
    }
  };

  return (
    <>
      <StudentSeatSetting
        num1={num1}
        setNum1={setNum1}
        num2={num2}
        setNum2={setNum2}
        handleSetAdjacentNums={handleSetAdjacentNums}
        adjacentNums={adjacentNums}
        setAdjacentNums={setAdjacentNums}
        handleRemoveAdjacentNum={handleRemoveAdjacentNum}
        saveAdjacentNums={saveAdjacentNums}
        // inputSeparateNums={inputSeparateNums}
        // handleSeparateInputChange={handleSeparateInputChange}
        // handleAddSeparateNums={handleAddSeparateNums}
        separateNums={separateNums}
        saveSeparateNums={saveSeparateNums}
        inputCornerNums={inputCornerNums}
        handleCornerInputChange={handleCornerInputChange}
        handleAddCornerNums={handleAddCornerNums}
        cornerNums={cornerNums}
        saveCornerNums={saveCornerNums}
        setCornerNums={setCornerNums}
        fetchAdjacentNums={fetchAdjacentNums}
        fetchCornerNums={fetchCornerNums}
        handleRemoveCornerNum={handleRemoveCornerNum}
        handleKeyPressCorner={handleKeyPressCorner}
        handleKeyPressAdjacent={handleKeyPressAdjacent}
        students={students}
        showNameMode={showNameMode}
        // isSelectingStudent={isSelectingStudent}
        // setIsSelectingStudent={setIsSelectingStudent}
        // duplicateStudents={duplicateStudents}
        // setDuplicateStudents={setDuplicateStudents}
        // selectedStudentNum={selectedStudentNum}
        // setSelectedStudentNum={setSelectedStudentNum}
      />{' '}
      {/* 집중이 필요한 학생 - 동명이인일 때 */}
      {isModalOpen && (
        <ModalWrapper onClick={handleOutsideClick}>
          <ModalContent>
            <ModalTitle>중복 이름 선택</ModalTitle>
            {duplicateStudents.map((student) => (
              <ModalButton
                key={student.number}
                onClick={() => CornerModalSelectNum(student.number)}
              >
                {student.number} {student.name}
              </ModalButton>
            ))}
            {/* <CancelButton onClick={onAllModalClose}>취소</CancelButton> */}
          </ModalContent>
        </ModalWrapper>
      )}
      {/* 가까이 앉을 학생 - 동명이인일 때 */}
      {isAdjacentModalOpen && (
        <ModalWrapper onClick={handleOutsideClick}>
          <ModalContent>
            <ModalTitle>중복 이름 선택</ModalTitle>
            <ModalDetail>
              {duplicateStudents1.length > 1 && (
                <>
                  <div>{duplicateStudents1[0].name}</div>
                  {duplicateStudents1.map((student) => (
                    <ModalBlackButton
                      key={student.number}
                      onClick={() =>
                        InAdjacentModalSelectController(student, 1)
                      }
                      isSelected={selectedNum1 === student.number}
                    >
                      {student.number} {student.name}
                    </ModalBlackButton>
                  ))}
                </>
              )}

              {duplicateStudents2.length > 1 && (
                <>
                  <ModalSubTitle>{duplicateStudents2[0].name}</ModalSubTitle>
                  {duplicateStudents2.map((student) => (
                    <ModalBlackButton
                      key={student.number}
                      onClick={() =>
                        InAdjacentModalSelectController(student, 2)
                      }
                      isSelected={selectedNum2 === student.number}
                    >
                      {student.number} {student.name}
                    </ModalBlackButton>
                  ))}
                </>
              )}
            </ModalDetail>

            <ModalButton onClick={ModalHandleAdjacentNums}>확인</ModalButton>
            <ModalButton onClick={onAdjacentModalClose}>취소</ModalButton>
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
};

export default StudentSeatSettingContainer;
