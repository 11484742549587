import React, { useContext, useEffect } from 'react';
import UserContext from '../contexts/userContext';
import axios from '../../node_modules/axios/index';
import kakaoLoginBtn from '../img/auth/kakao_login_medium_wide.png';
import kakaoLoginBtn2 from '../img/auth/kakao_login_medium_narrow.png';
import styled from 'styled-components';
import { borderRadius } from '../styles/commonStyle';
import { NewBadge } from '../styles/BasicStyle';
import {
  useLocation,
  useNavigate,
} from '../../node_modules/react-router-dom/dist/index';
import { media } from '../styles/media';
const KaKaoLoginContainer = styled.div`
  cursor: pointer;
  background-color: #fee500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${borderRadius.small};

  // ${media.mobile`
  //   height: 30px;
  //   `}
`;
const KakaoLoginButton = ({ handleAuthComplete }) => {
  const { state, actions } = useContext(UserContext);

  const {
    actions: { setUser },
  } = useContext(UserContext);
  const navigate = useNavigate(); // navigate 추가
  const location = useLocation(); // location 추가

  // 카카오 로그인 초기화 및 클릭 핸들러
  const initializeKakao = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init('538345a672d90aff76cb759427f038ff'); // Kakao SDK 초기화
    }
  };
  useEffect(() => {
    initializeKakao();
  }, []);
  const StyledImg = styled.img`
    height: 100%; // 부모 div 높이에 맞게 조정
    width: auto; // 비율 유지
    object-fit: contain; // 부모 크기에 맞추되 비율을 유지하여 맞춤
  `;

  const handleKakaoLogin = () => {
    // if (!window.Kakao.isInitialized()) {
    //   window.Kakao.init('538345a672d90aff76cb759427f038ff'); // 반드시 초기화 확인
    // }

    window.Kakao.Auth.login({
      success: function (authObj) {
        // 카카오 로그인 성공 후 사용자 정보 요청
        window.Kakao.API.request({
          url: '/v2/user/me',
          success: async function (res) {
            const userData = {
              userId: res.id, // 카카오 사용자 ID를 userId로 사용
              email: res.kakao_account.email,
              teacherName: res.kakao_account.profile.nickname,
              // teacherName: res.properties.nickname, // 사용자의 카카오톡 닉네임
              authType: 'kakao',
            };

            // // 로그인 상태 UserContext에 저장
            // setUser(userData);

            // 사용자 정보를 백엔드로 전송하여 데이터베이스에 저장
            try {
              // await axios.post('/api/kakaoLogin', userData); // 백엔드 API에 사용자 정보 전송
              const response = await axios.post(
                'https://teachernote.me/api/kakaoLogin',
                userData,
              );
              const token = response.data.token;
              localStorage.setItem('token', token);
              actions.setUser(response.data.user);
              handleAuthComplete(response.data.user.userId);
            } catch (error) {
              console.error(
                '서버에 사용자 정보를 저장하는 데 실패했습니다:',
                error,
              );
            }
          },
          fail: function (error) {
            console.error('사용자 정보 요청 실패:', error);
          },
        });
      },
      fail: function (err) {
        console.error('카카오 로그인 실패:', err);
      },
    });
  };

  return (
    <KaKaoLoginContainer onClick={handleKakaoLogin}>
      {/* <StyledImg */}
      <img
        src={kakaoLoginBtn2}
        alt="Kakao 로그인 버튼 - 티처노트"
        // style={{ maxHeight: '100%;' }}
      />
    </KaKaoLoginContainer>
  );
};

export default KakaoLoginButton;
