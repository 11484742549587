import styled from 'styled-components';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gray,
  margin,
  padding,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const AuthBox = styled.div`
  width: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AuthCardContainer_Mobile = styled.div`
  width: 70vw;
  height: 100%;
  padding: ${padding.mediumLarge};

  border-radius: ${borderRadius.medium};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
  background-color: ${gray.extralight};
`;

export const AuthLayout1 = styled.div`
  padding-bottom: ${padding.large};
  text-align: center;

  ${media.mobile`
  font-size: ${fontSize.small};
  padding:${padding.large} 0;
  `}
`;
export const AuthLayout2 = styled.div``;
export const AuthLayout3 = styled.div``;

export const Terms = styled.div`
  margin: 5px 0;
  ${media.mobile`
  font-size: ${fontSize.small};
  `}
`;
