import { useEffect, useRef, useState } from 'react';
import cornerSeatsImg from '../../../img/random-seat-assignment/random-seat-assignment-corner-seats.png';
import adjacentSeatsImg from '../../../img/random-seat-assignment/random-seat-assignment-adjacent-seats.png';
import {
  CornerNum,
  CurrentStudentSet,
  SeatDrawContentBox,
  SeatDrawDetailBox,
  SeatDrawPageContentBox,
  SeatDrawPageContentBox_left,
  SeatDrawPageContentBox_right,
  SeatDrawPageTitle,
  SeatDrawPageTitleBox,
  SeatDrawPageTitleBox_left,
  SeatDrawPageTitleDetail,
  StudentSettingInput_narrow,
  StudentSettingInput_wide,
} from '../../SeatDrawPageStyle';
import {
  DropdownMenu,
  MenuContainer,
  MenuItem,
} from '../../../components/DropdownMenu';
import { FiMoreVertical } from 'react-icons/fi';
import {
  DrawBox_Title,
  DrawBox_Title_Text,
  OneByOneButton,
} from '../../3drawOneByOnePage/OneByOneStyle';
import { BsX } from 'react-icons/bs';
const StudentSeatSetting = ({
  num1,
  setNum1,
  num2,
  setNum2,
  handleSetAdjacentNums,
  adjacentNums,
  handleRemoveAdjacentNum,
  saveAdjacentNums,
  inputCornerNums,
  handleCornerInputChange,
  handleAddCornerNums,
  cornerNums,
  handleRemoveCornerNum,
  handleKeyPressAdjacent,
  handleKeyPressCorner,
  fetchAdjacentNums,
  fetchCornerNums,
  setAdjacentNums,
  setCornerNums,
  students,
  showNameMode,

  // isSelectingStudent,
  // setIsSelectingStudent,
  // duplicateStudents,
  // setDuplicateStudents,
  // selectedStudentNum,
  // setSelectedStudentNum,
}) => {
  const [isMoreStudentSettingOpen, setIsMoreStudentSettingOpen] =
    useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMoreStudentSettingOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLoad = () => {
    fetchAdjacentNums();
    fetchCornerNums();
  };
  // useEffect(() => {
  //   console.log('students:' + JSON.stringify(students));
  // }, [students]);

  return (
    <>
      <DrawBox_Title>
        <DrawBox_Title_Text>세부 설정</DrawBox_Title_Text>
        <MenuContainer
          ref={dropdownRef}
          onClick={() => {
            setIsMoreStudentSettingOpen(!isMoreStudentSettingOpen);
          }}
        >
          <FiMoreVertical />
          <DropdownMenu $isOpen={isMoreStudentSettingOpen}>
            {' '}
            <MenuItem
              onClick={() => {
                setAdjacentNums([]);
                setCornerNums([]);
              }}
            >
              리셋
            </MenuItem>
            <MenuItem onClick={handleLoad}>불러오기</MenuItem>
          </DropdownMenu>
        </MenuContainer>
      </DrawBox_Title>

      <SeatDrawContentBox>
        <SeatDrawPageTitleBox>
          <SeatDrawPageTitleBox_left>
            <SeatDrawPageTitle>가까이 앉을 학생들</SeatDrawPageTitle>
            <SeatDrawPageTitleDetail>
              도움이 필요한 학생들이 서로 가까이 앉도록 할 수 있습니다.
            </SeatDrawPageTitleDetail>
          </SeatDrawPageTitleBox_left>
        </SeatDrawPageTitleBox>
        <SeatDrawPageContentBox>
          <SeatDrawPageContentBox_left>
            <img
              src={adjacentSeatsImg}
              style={{ width: '200px', height: 'auto' }}
              alt="교실 학급 랜덤 무작위 자리뽑기 설명1"
            />
          </SeatDrawPageContentBox_left>
          <SeatDrawPageContentBox_right>
            <CurrentStudentSet>
              <div style={{ paddingBottom: '10px' }}>
                <StudentSettingInput_narrow
                  // type="number"
                  type="text"
                  value={num1}
                  onChange={(e) => setNum1(e.target.value)}
                  onKeyPress={handleKeyPressAdjacent}
                  placeholder={
                    showNameMode ? '첫 번째 번호 또는 이름' : '첫 번째 번호'
                  }
                />
                <StudentSettingInput_narrow
                  // type="number"
                  type="text"
                  value={num2}
                  onChange={(e) => setNum2(e.target.value)}
                  onKeyPress={handleKeyPressAdjacent}
                  placeholder={
                    showNameMode ? '두 번째 번호 또는 이름' : '두 번째 번호'
                  }
                />
                <OneByOneButton
                  onClick={() => handleSetAdjacentNums(num1, num2)}
                  style={{ marginTop: '10px' }}
                >
                  추가
                </OneByOneButton>
              </div>{' '}
              {adjacentNums.map((pair, index) => {
                const student1 = students.find(
                  (student) => student.number === pair[0],
                );
                const student2 = students.find(
                  (student) => student.number === pair[1],
                );
                const displayText = showNameMode
                  ? `${pair[0]} ${student1?.name || ''}, ${pair[1]} ${
                      student2?.name || ''
                    }`
                  : pair.join(', ');

                return (
                  <CornerNum
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveAdjacentNum(pair)}
                  >
                    {displayText} <BsX />
                  </CornerNum>
                );
              })}
              {/* {adjacentNums.map((pair, index) => (
                <CornerNum
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleRemoveAdjacentNum(pair)}
                >
                  {pair.join(', ')} <BsX />
                </CornerNum>
              ))} */}
            </CurrentStudentSet>
          </SeatDrawPageContentBox_right>
        </SeatDrawPageContentBox>{' '}
      </SeatDrawContentBox>
      <SeatDrawContentBox>
        <SeatDrawPageTitleBox>
          <SeatDrawPageTitleBox_left>
            <SeatDrawPageTitle>집중이 필요한 학생들</SeatDrawPageTitle>
            <SeatDrawPageTitleDetail>
              장난, 잡담 등으로 집중이 필요한 학생들을 교실 끝쪽에 배치하여
              집중을 도울 수 있습니다.
            </SeatDrawPageTitleDetail>
          </SeatDrawPageTitleBox_left>
        </SeatDrawPageTitleBox>
        <SeatDrawPageContentBox>
          <SeatDrawPageContentBox_left>
            <img
              src={cornerSeatsImg}
              style={{ width: '200px', height: 'auto' }}
              alt="교실 학급 랜덤 무작위 자리뽑기 설명2"
            />
          </SeatDrawPageContentBox_left>
          <SeatDrawPageContentBox_right>
            {' '}
            <CurrentStudentSet>
              <div style={{ paddingBottom: '10px' }}>
                <StudentSettingInput_wide
                  type="text"
                  value={inputCornerNums}
                  onChange={handleCornerInputChange}
                  onKeyPress={handleKeyPressCorner}
                  placeholder={showNameMode ? '번호 또는 이름' : '번호'}
                />
                <OneByOneButton
                  //  onClick={handleAddCornerNums}
                  onClick={() =>
                    handleAddCornerNums(
                      inputCornerNums.split(',').map((num) => num.trim()),
                    )
                  }
                >
                  추가
                </OneByOneButton>
              </div>
              {cornerNums &&
                [...cornerNums]
                  .sort((a, b) => a - b)
                  .map((num, index) => {
                    const student = students.find(
                      (student) => student.number === num,
                    );
                    const displayText = showNameMode
                      ? `${num} ${student?.name || ''}`
                      : num;

                    return (
                      <CornerNum
                        key={index}
                        onClick={() => handleRemoveCornerNum(num)}
                      >
                        {displayText} <BsX />
                      </CornerNum>
                    );
                  })}
              {/* {cornerNums &&
                [...cornerNums]
                  .sort((a, b) => a - b)
                  .map((num, index) => (
                    <CornerNum
                      key={index}
                      onClick={() => handleRemoveCornerNum(num)}
                    >
                      {num} <BsX />
                    </CornerNum>
                  ))} */}
              {/* // .reduce((prev, curr) => [prev, ', ', curr])} */}
            </CurrentStudentSet>
          </SeatDrawPageContentBox_right>
        </SeatDrawPageContentBox>
        <SeatDrawDetailBox>
          <div>
            📌학생들은 A,B,C,D 구역 중 각기 다른 영역에 랜덤 배치됩니다.
            <br />
            📌끝쪽 자리가 자리 미사용(X) 설정, 미리 배치한 학생, 성별 설정
            등으로 전부 사용 불가능한 경우 다른 곳에 임의 배치됩니다.
          </div>
        </SeatDrawDetailBox>
      </SeatDrawContentBox>
    </>
  );
};

export default StudentSeatSetting;
