import styled, { css } from 'styled-components';
import {
  colors,
  fontWeight,
  gray,
  margin,
  objWidth,
  padding,
} from '../styles/commonStyle';

export const MobileMenuBar = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.background};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
  z-index: 1000;
`;

export const MobileTopSubMenubar = styled(MobileMenuBar)`
  border-top: 1px solid ${gray.light};
  top: 50px;
`;

export const MenuItemsContainer = styled.div`
  width: 100%;
  padding: ${padding.medium};

  display: flex;
  justify-content: space-around;
`;

export const GradeMenu = styled.div`
  width: ${objWidth.medium};

  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    color: ${colors.primary};
    font-weight: 600;
  }

  ${({ $isSelectedGrade }) =>
    $isSelectedGrade &&
    css`
      color: ${colors.primary};
      font-weight: 600;
    `}
`;
// -------------------- 메인 메뉴바 --------------------//
export const MainMenuItemsContainer = styled.div`
  height: 30px;
  position: relative;
  padding: ${padding.medium} ${padding.mediumLarge};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Left_MainMenu = styled.div`
  // display: flex;
  // align-items: center;
`;
export const Middle_MainMenu = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: ${fontWeight.bold};
  font-size: 1rem;
`;
export const Right_MainMenu = styled.div`
  font-size: 0.8rem;
`;

// -------------------- Article 메뉴바 (오른쪽 버튼) --------------------//

export const ArticleMenuItemsContainer = styled.div`
  padding: ${padding.medium} ${padding.mediumLarge};

  display: flex;
  justify-content: space-between;
  // background-color: yellow;
`;
export const ArticleMenubarSmall = styled.div`
  // width: 10%;
  // background-color: yellow;
`;
export const ArticleMenubarLarge = styled.div`
  flex: ;
  // background-color: green;
`;

export const ColorText = styled.div`
  color: ${colors.primary};
  font-weight: ${fontWeight.bold};
`;

// -------------------- classinfo 메뉴바 --------------------//

export const ClassinfoMenuItemsContainer = styled.div`
  width: 100%;
  padding: ${padding.medium};

  display: flex;
  justify-content: center;
`;
