import { BlogWritePageContainer } from './BlogWriteStyle';
import PageContainer from '../../pageLayout/PageContainer';
import BlogWriteContainer from './BlogWriteContainer';
import {
  PageBox,
  PageBox_Blog,
  PageSubheader,
} from '../../pageLayout/PageContainerStyle';
import ArticleMenuBar from '../../topSubMenuBar/ArticleMenuBar';
import { useMediaQuery } from 'react-responsive';
import MobileTopMenuBarArticle from '../../topMenuBarMobile/MobileTopMenuBarArticle';
import { BlogWriteHelmetContent } from '../../components/HelmetContent';
import { useContext } from 'react';
import UserContext from '../../contexts/userContext';
import { CenterAlign } from '../../styles/BasicStyle';

const BlogWritePage = () => {
  const { state: userState } = useContext(UserContext);
  const userId = userState?.user?.userId;
  return (
    <>
      {' '}
      <BlogWriteHelmetContent />
      {userId ? (
        <PageContainer>
          <PageSubheader>
            <ArticleMenuBar />
          </PageSubheader>
          <PageBox_Blog>
            <BlogWriteContainer />
          </PageBox_Blog>
        </PageContainer>
      ) : (
        <CenterAlign style={{ height: '100vh' }}>
          이 페이지는 로그인 상태에서 이용가능합니다.
        </CenterAlign>
      )}
    </>
  );
};

export default BlogWritePage;
