import styled, { css } from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  gray,
  height,
  margin,
  padding,
  pageWidth,
} from '../styles/commonStyle';
import {
  NavLink,
  useNavigate,
} from '../../node_modules/react-router-dom/dist/index';
import { media } from '../styles/media';
import { useEffect, useState } from 'react';
import axios from '../../node_modules/axios/index';
import { NewBadge } from '../styles/BasicStyle';

export const RandomDrawMenuBox = styled.div`
  ${media.desktop`
    height: ${height.header};
    width: ${pageWidth.desktop};
  `}

  ${media.tablet`
    height: ${height.headerTablet};    
    width: ${pageWidth.tablet};
  `}

  ${media.mobile`
    height: ${height.headerMobile};    
    width: ${pageWidth.mobile};
    display: none;
  `}
  height: ${height.header};
  padding: 0 ${padding.large};
  display: flex;
  align-items: center;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
  background-color: ${colors.background};
`;

// 조건부 스타일링을 위해 css helper 사용
const activeStyle = css`
  color: ${colors.primary};
  font-weight: ${fontWeight.bold};
`;

const inactiveStyle = css`
  color: black;
`;

export const RandomDrawMenu = styled(NavLink)`
  cursor: pointer;
  margin-right: ${margin.large};
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;

  &:hover {
    color: ${colors.primary};
    font-weight: ${fontWeight.bold};
  }

  &.active {
    color: ${colors.primary};
    font-weight: ${fontWeight.bold};
  }
`;
//

const RandomDrawMenuBar = () => {
  const navigate = useNavigate();

  return (
    <RandomDrawMenuBox>
      {' '}
      <RandomDrawMenu to="/random-number-picker" activeclassname="active" end>
        랜덤 숫자(발표자) 뽑기<NewBadge>N</NewBadge>
      </RandomDrawMenu>
      {/* <RandomDrawMenu to="/random-seat-assignment" activeclassname="active" end>
        자리뽑기
      </RandomDrawMenu> */}
    </RandomDrawMenuBox>
  );
};

export default RandomDrawMenuBar;
