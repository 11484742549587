import styled from 'styled-components';
import { borderRadius, colors, fontWeight, gray, padding } from './commonStyle';
import { media } from './media';

export const GreenButton = styled.button`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: ${padding.small} ${padding.medium};
  border-radius: ${borderRadius.medium};
`;

export const BorderButton = styled.button`
  border: 1px solid ${gray.darktext};
  // border: 1px solid ${colors.primary};
  // color: ${colors.primary};
  padding: ${padding.small} ${padding.medium};
  border-radius: ${borderRadius.medium};
  background-color: ${colors.background};
  font-weight: ${fontWeight.bold};
  ${media.mobile`
  padding: ${padding.extraSmall} ${padding.small};
    font-weight: ${fontWeight.medium};
    `}
  cursor: pointer;
`;
