import { useState } from 'react';
import {
  FeedbackHelmetContent,
  HelmetContent,
} from '../../components/HelmetContent';
import HeaderMenuBar from '../../pageLayout/HeaderMenuBar';
import PageContainer from '../../pageLayout/PageContainer';
import {
  PageContainerBox,
  PageHeader,
} from '../../pageLayout/PageContainerStyle';
import { pageWidth } from '../../styles/commonStyle';
import DrawPageContainer from '../../drawPageLayout/DrawPageContainer';
import { DrawPageMain } from '../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';

const UserCommentPage = () => {
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('menubar'); //menubar,bottombutton

  return (
    <>
      <FeedbackHelmetContent />

      <PageContainer>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeoEHQRhUYWfQlczq_pmE6de980K8LZQyinNTzJtwo-5xsMtg/viewform?embedded=true"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          scrolling="auto"
        >
          로드 중…
        </iframe>
      </PageContainer>
    </>
  );
};

export default UserCommentPage;
