import styled from 'styled-components';
import { Link } from '../../node_modules/react-router-dom/dist/index';
import { colors, fontSize, padding } from '../styles/commonStyle';
import { media } from '../styles/media';

export const AuthStyledLink = styled(Link)`
  text-decoration: none;
  color: gray;
  &:hover {
    color: ${colors.primary};
  }
`;

export const AuthStyledDiv = styled.div`
  text-decoration: none;
  color: gray;
  &:hover {
    color: ${colors.primary};
    cursor: pointer;
  }
  ${media.mobile`
    font-size: ${fontSize.small};
    `}
`;
export const AuthAlertText = styled.div`
  padding: ${padding.small} 0px ${padding.small} ${padding.small};
  font-size: 14px;
  color: red;

  ${media.mobile`
  font-size: ${fontSize.small};
  `}
`;
export const AuthRightText = styled.div`
  padding: ${padding.small} 0px ${padding.small} ${padding.small};
  font-size: 14px;
  color: ${colors.primary};

  ${media.mobile`
  font-size: ${fontSize.small};
  `}
`;
