import { useState } from 'react';
import PresenterPickerContainer from './3picker/PresenterPickerContainer';

import PageContainer from '../../pageLayout/PageContainer';
import { PageSubheader } from '../../pageLayout/PageContainerStyle';

import {
  DisplayColumn,
  DisplayRowSpaceBetween,
  PageBox,
  TitleContainer,
  TitleDetail,
  TitleText,
} from '../../styles/BasicStyle';

import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { useMediaQuery } from 'react-responsive';

import RandomDrawMenuBar from '../../topSubMenuBar/RandomDrawMenuBar';
import { MobileMessageContainer } from '../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import { RandomNumberHelmetContent } from '../../components/HelmetContent';

const PresenterPickerPage = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  // 학생 번호
  const [numberList, setNumberList] = useState(
    Array.from({ length: 30 }, (_, index) => index + 1),
  );
  const [studentCount, setStudentCount] = useState(30);
  const pageMarginTop = !isTablet ? '-30px' : '-15px';

  return (
    <>
      <RandomNumberHelmetContent />
      <PageContainer>
        {/* <PageSubheader>
          <RandomDrawMenuBar />
        </PageSubheader> */}

        <PageBox style={{ marginTop: `${pageMarginTop}` }}>
          <PresenterPickerContainer
            numberList={numberList}
            setNumberList={setNumberList}
            studentCount={studentCount}
            setStudentCount={setStudentCount}
          />
        </PageBox>
      </PageContainer>
      {/* {!isMobile ? (
        <PageContainer>
          <PageSubheader>
            <RandomDrawMenuBar />
          </PageSubheader>

          <PageBox>
            <PresenterPickerContainer
              numberList={numberList}
              setNumberList={setNumberList}
              studentCount={studentCount}
              setStudentCount={setStudentCount}
            />
          </PageBox>
        </PageContainer>
      ) : (
        <MobileMessageContainer>
          <p>
            이 페이지는 <br />
            태블릿 및 데스크탑에서 <br />
            이용가능합니다.
          </p>
        </MobileMessageContainer>
      )} */}
    </>
  );
};

export default PresenterPickerPage;
