import { useContext, useEffect, useState } from 'react';
import axios from '../../../node_modules/axios/index';
import GradeContext, { GradeConsumer } from '../../contexts/grade';
import ProgressTable from './ProgressTable';
import UserContext from '../../contexts/userContext';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { useMediaQuery } from 'react-responsive';
// import { useGradeContext } from './GradeContext';

export default function ProgressTableContainer({
  topics,
  classInfoByGrade,
  progressByTopicAndGrade,
  updateTopicTitle,
  handleProgressNote,
  progressNoteByGradeDB,
  afterSaveNote,
  setIsProgressNoteVisible,
  fetchAllprogressByTopicAndGrade,
  setShowNotification,
  setRegisterModalOpen,
}) {
  //user contxt 받아오기
  const { state: gradeState } = useContext(GradeContext);
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const grade = gradeState.currentGrade;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const navigate = useNavigate();
  //---------------- 진도(날짜표시) 관리  ----------------//

  //체크 버튼 클릭 시 진도 체크(현재 날짜)
  const checkProgress = async (grade, fullClassNum, topicId, date) => {
    if (!userId) {
      alert('로그인 후 이용가능합니다.');

      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      // progress 테이블의 topicNumber와 topic테이블의topicNumber가 일치하는 객체의
      //progress 테이블 인덱스 찾기
      const progress_obj = await progressByTopicAndGrade.find(
        (progress_obj) => progress_obj.topicNumber === topicId,
      );

      let saveDate;
      if (date) {
        //날짜 정보 입력 받은 경우-> delete progress는 일어나지 않아야 함.
        saveDate = await date.toISOString().split('T')[0];
      } else {
        //날짜 정보 입력 받지 않은 경우 - 현재 날짜로 설정
        saveDate = new Date().toISOString().split('T')[0];
      }

      if (progress_obj) {
        const ProgressTableId = progress_obj.id;
        //progress 테이블 인덱스의 classX 값 찾기
        const ProgressTableClass_byId = progressByTopicAndGrade.find(
          (item) => item.id === ProgressTableId,
        );
        const classX = fullClassNum;
        const classXValue = ProgressTableClass_byId
          ? ProgressTableClass_byId[classX]
          : null;

        if (ProgressTableClass_byId[classX] != null) {
          //진도가 이미 존재할 때
          if (date) {
            //날짜 정보 입력 받은 경우-> 날짜만 수정
            addProgress(ProgressTableId, fullClassNum, saveDate);
          } else {
            //날짜 정보 입력 받지 않은 경우 - 해당 기록 삭제
            deleteProgress(ProgressTableId, fullClassNum);
          }
        } else {
          //진도가 존재하지 않을 때
          addProgress(ProgressTableId, fullClassNum, saveDate);
        }
      } else {
        addProgressData(userId, grade, topicId, fullClassNum, saveDate);
      }
    }
  };

  //주제 진도 데이터 생성 -해당 topic에 대한 progressbytopic 데이터 없을 경우
  const addProgressData = async (
    userId,
    grade,
    topicNumber,
    fullClassNum,
    saveDate,
  ) => {
    if (!userId) {
      alert('로그인 후 이용가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      try {
        const progressData = {
          userId: userId,
          grade: grade,
          topicNumber: topicNumber,
          fullClassNum: fullClassNum,
          saveDate: saveDate,
        };
        await axios
          .post('https://teachernote.me/api/addprogressdata', progressData)
          .then(() => {
            fetchAllprogressByTopicAndGrade();
          });
        setShowNotification(true); // 알림 상태를 true로 변경
        setTimeout(() => {
          setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
        }, 1000);
      } catch (error) {
        console.log(':' + error);
      }
    }
  };

  //해당 topic에 대한 progressbytopic 데이터 존재할 경우
  const addProgress = async (id, value, saveDate) => {
    if (!userId) {
      alert('로그인 후 이용가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      try {
        //진도가 null일 경우 인풋
        await axios
          .put('https://teachernote.me/api/progressbytopic/' + id, {
            value: value,
            saveDate: saveDate,
          })
          .then(() => {
            fetchAllprogressByTopicAndGrade();
          });
        setShowNotification(true); // 알림 상태를 true로 변경
        setTimeout(() => {
          setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const deleteProgress = async (id, value) => {
    if (!userId) {
      alert('로그인 후 이용가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      try {
        //진도가 있을 경우 삭제
        await axios
          .put('https://teachernote.me/api/deleteprogressbytopic/' + id, {
            value: value,
          })
          .then(() => {
            fetchAllprogressByTopicAndGrade();
          });
        setShowNotification(true); // 알림 상태를 true로 변경
        setTimeout(() => {
          setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    }
  };
  //---------------- (진도 데이터 없을 경우)날짜 버튼 클릭 시 날짜 입력  ----------------//
  const saveProgressDate = (classNum, topicId, date) => {
    //진도 데이터 있을 경우 날짜만 업데이트
    //진도 데이터 없을 경우 날짜 추가
  };

  //---------------- 진도 노트 관리  ----------------//

  return (
    <ProgressTable
      topics={topics}
      classInfoByGrade={classInfoByGrade}
      progressByTopicAndGrade={progressByTopicAndGrade}
      checkProgress={checkProgress}
      updateTopicTitle={updateTopicTitle}
      handleProgressNote={handleProgressNote}
      progressNoteByGradeDB={progressNoteByGradeDB}
      afterSaveNote={afterSaveNote}
    />
  );
}
