import { useEffect, useState } from 'react';
import BlogViewContainer from './BlogViewContainer';
import axios from '../../../node_modules/axios/index';
import {
  Navigate,
  useNavigate,
  useParams,
} from '../../../node_modules/react-router-dom/dist/index';
import PageContainer from '../../pageLayout/PageContainer';
import {
  PageBox,
  PageBox_Blog,
  PageSubheader,
} from '../../pageLayout/PageContainerStyle';
import ArticleMenuBar from '../../topSubMenuBar/ArticleMenuBar';
import { useMediaQuery } from 'react-responsive';
import MobileTopMenuBarArticle from '../../topMenuBarMobile/MobileTopMenuBarArticle';
import BottomBlogList from './BottomBlogList';
import BottomBlogListMobile from './BottomBlogListMobile';
import { DisplayRow, DisplayRowSpaceBetween } from '../../styles/BasicStyle';
import { GoToList } from './BlogViewStyle';
import Main_MobileTopMenuBar from '../../topMenuBarMobile/Main_MobileTopMenuBar';
import { BlogViewHelmetContent } from '../../components/HelmetContent';

const BlogViewPage = () => {
  const [selectedBlog, setSelectedBlog] = useState([]);
  // const { paramBlogNum } = useParams();
  const { paramCategory, paramBlogNum } = useParams();
  const [blogDB, setBlogDB] = useState([]);
  const navigate = useNavigate(); // useNavigate 훅 선언

  // 화면 크기에 따라 보여줄 글 개수 설정
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const blogsToShow = isDesktop ? 3 : isTablet ? 3 : 2;
  useEffect(() => {
    const fetchSelectedBlog = async () => {
      // console.log(paramBlogNum);
      try {
        const res = await axios.get('https://teachernote.me/api/blog/', {
          params: { blogId: paramBlogNum }, // 올바른 요청 옵션 사용
        });
        setSelectedBlog(res.data);
        // console.log('Selectedblog:' + JSON.stringify(res.data));
      } catch (err) {
        console.log(err);
      }
    };
    fetchSelectedBlog();
  }, [paramBlogNum]);

  useEffect(() => {
    // Blog 목록 데이터를 가져옵니다
    const fetchBlogs = async () => {
      try {
        const res = await axios.get('https://teachernote.me/api/blogs/');
        // 최신순으로 정렬
        const sortedBlogs = res.data.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
        );
        setBlogDB(sortedBlogs);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBlogs();
  }, []);
  // 목록 보기 버튼 클릭 시 이동 경로 설정
  const handleGoToList = () => {
    // 현재 블로그의 카테고리를 기준으로 이동 경로 설정
    const categoryPath = selectedBlog.category || 'all'; // 카테고리가 없으면 기본으로 'all'
    navigate(`/education-resources-sharing/${categoryPath}`);
  };

  // 현재 블로그와 같은 카테고리의 글 필터링
  const relatedBlogs = blogDB.filter(
    (blog) =>
      blog.category === selectedBlog.category && blog.id !== selectedBlog.id,
  );

  return (
    <>
      <BlogViewHelmetContent
        title={selectedBlog.title}
        paramCategory={paramCategory}
        paramBlogNum={paramBlogNum}
      />
      <PageContainer>
        <PageSubheader>
          <ArticleMenuBar />
        </PageSubheader>
        {isMobile && <Main_MobileTopMenuBar />}
        <PageBox_Blog
        // style={{ backgroundColor: 'yellow' }}
        >
          <BlogViewContainer selectedBlog={selectedBlog} />
        </PageBox_Blog>
        <PageBox_Blog
          style={{
            // backgroundColor: 'red',
            marginTop: '-30px',
            paddingTop: '0px',
          }}
        >
          <DisplayRowSpaceBetween>
            <h3>관련 글보기</h3>
            <GoToList onClick={handleGoToList}>전체보기</GoToList>
          </DisplayRowSpaceBetween>

          {isMobile ? (
            <BottomBlogListMobile blogs={relatedBlogs.slice(0, blogsToShow)} />
          ) : (
            <BottomBlogList blogs={relatedBlogs.slice(0, blogsToShow)} />
          )}
        </PageBox_Blog>
      </PageContainer>
    </>
  );
};

export default BlogViewPage;
