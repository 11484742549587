import PageContainer from '../../../pageLayout/PageContainer';
import {
  DisplayColumn,
  DisplayRowSpaceBetween,
  GrayBadge,
  GreenBadge,
  PageBox_Padding,
  TitleContainer,
  TitleDetail,
  TitleDetail_Mobile,
  TitleText,
} from '../../../styles/BasicStyle';
import { colors, gap } from '../../../styles/commonStyle';
import seatdraw1 from '../../../img/mobile-random-seat-draw/mobile-classroom-seat-picker-random1.gif';
import seatdraw2 from '../../../img/mobile-random-seat-draw/mobile-classroom-seat-picker-random2.gif';
import seatdraw3 from '../../../img/mobile-random-seat-draw/mobile-classroom-seat-picker-random3.gif';
import seatdraw4 from '../../../img/mobile-random-seat-draw/mobile-classroom-seat-picker-random4.gif';
import {
  MobileGifImg,
  MobileSubTitle,
  MobileSubTitleBold,
} from './MobilePickerPageStyle';
import { RandomSeatHelmetContent } from '../../../components/HelmetContent';
import MainButton from '../../../components/MainButton';

const MobileRandomSeatPickerPage = () => {
  const handleShare = async () => {
    const shareData = {
      title: '랜덤 자리 뽑기',
      text: '학생들과 함께 할 수 있는 랜덤 자리 뽑기 기능을 확인해보세요!',
      url: window.location.href, // 현재 페이지의 URL
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(window.location.href);
        alert('URL이 복사되었습니다.');
      }
    } catch (error) {
      console.error('공유에 실패했습니다:', error);
    }
  };
  return (
    <>
      <RandomSeatHelmetContent />
      <PageContainer>
        {' '}
        <PageBox_Padding>
          <TitleContainer>
            <DisplayRowSpaceBetween>
              <DisplayColumn>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: `${gap.extraSmall}`,
                  }}
                >
                  <TitleText>랜덤 자리 뽑기</TitleText>{' '}
                  <GrayBadge>Desktop&Tablet ONLY</GrayBadge>
                </div>
                <TitleDetail_Mobile>
                  아직 손으로 제비뽑기를 만드신다구요? 많은 선생님들이 티처노트
                  자리뽑기를 사용해 바쁜 업무 속 부담을 덜고 있습니다.
                </TitleDetail_Mobile>
                <MainButton onClick={handleShare}>
                  📢선생님들에게 알리기
                </MainButton>
              </DisplayColumn>
            </DisplayRowSpaceBetween>
          </TitleContainer>
          <div>
            <MobileSubTitle>
              {' '}
              <MobileSubTitleBold>📗자리 설정</MobileSubTitleBold> 짝꿍 여부,
              성별 구분에 따라 설정 가능합니다.
            </MobileSubTitle>
            <MobileGifImg src={seatdraw1} />
            <MobileSubTitle>
              <MobileSubTitleBold>📗학생설정</MobileSubTitleBold> 전학생, 앞에
              앉을 학생, 이름으로 보기 설정 가능합니다.
            </MobileSubTitle>
            <MobileGifImg src={seatdraw2} />
            <MobileSubTitle>
              <MobileSubTitleBold>📗자리 뽑기</MobileSubTitleBold> 학생이 직접
              제비 뽑기, 한 번에 뽑기, 제비 섞기 선택 가능합니다.
            </MobileSubTitle>
            <MobileGifImg src={seatdraw3} />
            <MobileSubTitle>
              {' '}
              <MobileSubTitleBold>📗배치 결과</MobileSubTitleBold> 자리 교환 및
              배치 결과 엑셀 저장 가능합니다.
            </MobileSubTitle>
            <MobileGifImg src={seatdraw4} />
          </div>
          <MobileSubTitle>
            {' '}
            <MobileSubTitleBold>
              📗플러스 자리뽑기<GreenBadge>Beta</GreenBadge>
            </MobileSubTitleBold>{' '}
            회원 가입 후 선생님을 위한 시크릿 추가 기능을 이용해보세요.
          </MobileSubTitle>
          <MainButton onClick={handleShare}>📢선생님들에게 알리기</MainButton>
        </PageBox_Padding>
      </PageContainer>
    </>
  );
};

export default MobileRandomSeatPickerPage;
