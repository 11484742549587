import React, { useCallback, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  gray,
  iconSize,
  margin,
  padding,
} from '../styles/commonStyle';
import { Link, NavLink } from '../../node_modules/react-router-dom/dist/index';
import { IoHome } from 'react-icons/io5';
import { FaTable } from 'react-icons/fa';
import { FaNoteSticky } from 'react-icons/fa6';
import { PiNotepadBold } from 'react-icons/pi';
import { BsFillPersonFill } from 'react-icons/bs';
import SelectGradeMenuBar from '../topSubMenuBar/SelectGradeMenuBar';
import GradeContext from '../contexts/grade';
import {
  GradeMenu,
  MenuItemsContainer,
  MobileMenuBar,
  MobileTopSubMenubar,
} from './MobileTopMenuBarStyle';

const MobileTopMenuBar = ({}) => {
  const { state, actions } = useContext(GradeContext);
  const [selectedGrade, setSelectedGrade] = useState(state.currentGrade);

  const handleChangeGrade = (grade) => {
    actions.setGrade(grade);
    setSelectedGrade(grade);
  };

  return (
    <MobileTopSubMenubar>
      <MenuItemsContainer>
        {state.gradeInfoDB.length === 0 ? (
          <>
            <GradeMenu>전체보기</GradeMenu>
          </>
        ) : (
          <>
            {state.gradeInfoDB.map((gradeInfo) => (
              <GradeMenu
                key={gradeInfo.grade}
                onClick={() => {
                  handleChangeGrade(gradeInfo.grade);
                }}
                $isSelectedGrade={gradeInfo.grade === selectedGrade}
              >
                {gradeInfo.grade}학년
              </GradeMenu>
            ))}
          </>
        )}{' '}
      </MenuItemsContainer>
    </MobileTopSubMenubar>
  );
};

export default MobileTopMenuBar;
