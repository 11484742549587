import styled from 'styled-components';
import { media } from './media';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gray,
  margin,
  objHeight,
  objWidth,
  padding,
  pageWidth,
} from './commonStyle';

export const DisplayColumn = styled.div`
  width: 100%; //10.31수정
  height: 100%; //10.31수정
  display: flex;
  flex-direction: column;
`;
export const DisplayRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const DisplayRowSpaceBetween = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  // background-color: red;
`;

export const CenterAlign = styled.div`
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  // background-color: red;
`;

export const AlignLeft = styled.div`
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const PageBox = styled.div`
  ${media.desktop` 
  width: ${pageWidth.desktop};  
  margin: ${margin.large} 0px ${margin.small} 0px;
  padding: ${padding.medium};
  `}

  ${media.tablet`
  width: ${pageWidth.tablet};
  margin: ${margin.large} 0px ${margin.small} 0px;
  padding: ${padding.medium};

      `}

  ${media.mobile`
  // width:90%;
  `}
`;

export const PageBox_Padding = styled.div`
  padding: ${padding.mediumLarge};
  margin-bottom: 55px;
`;
export const PageBox_noMargin = styled.div`
  ${media.desktop` 
  width: ${pageWidth.desktop};  
  padding-bottom: ${padding.medium};
  `}

  ${media.tablet`
      width: ${pageWidth.tablet};
  padding: ${padding.medium};
      `}

  ${media.mobile`
  `}
`;

export const PageWidth = styled.div`
  // background-color: red;
  ${media.desktop` 
  width: ${pageWidth.desktop};  
  `}

  ${media.tablet`
      width: ${pageWidth.tablet};
      `}

  ${media.mobile`
  `}
`;

export const NewBadge = styled.span`
  color: ${colors.background};
  background-color: red;
  font-size: ${fontSize.extraSmall};
  font-weight: ${fontWeight.extraBold};
  vertical-align: middle;

  padding: 1px 5px;
  border-radius: ${borderRadius.large};
  text-align: center;
`;

export const YellowBadge = styled(NewBadge)`
  background-color: ${colors.yellow};
  color: black;
`;
export const GreenBadge = styled(NewBadge)`
  background-color: ${colors.primary};
`;

export const GrayBadge = styled(NewBadge)`
  background-color: ${gray.light};
  color: black;
`;
// export const GreenBadge = styled.span`
//   color: ${colors.background};
//   background-color: ${colors.lightGreen};
//   font-size: ${fontSize.medium};
//   font-weight: ${fontWeight.extraBold};
//   vertical-align: middle;

//   padding: 0px 5px;
//   border-radius: ${borderRadius.medium};
// `;

export const RedBadge_OnItem = styled.div`
  position: absolute;
  z-index: 2;

  padding: ${padding.small} ${padding.mediumLarge};
  margin: ${margin.small};
  border-radius: ${borderRadius.small};
  // border-top-left-radius: ${borderRadius.small};
  // border-bottom-right-radius: ${borderRadius.medium};

  color: ${colors.background};
  background-color: red;

  // border-right: 2px solid red;
  // border-bottom: 1px solid red;

  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.bold};
  ${media.mobile`
 padding: ${padding.extraSmall} ${padding.small} ;    
   font-size: ${fontSize.small};

 `}
`;

export const TitleContainer = styled.div`
  padding-bottom: ${padding.mediumLarge};
`;

export const TitleText = styled.div`
  font-size: ${fontSize.categoryLarge};
  font-weight: ${fontWeight.bold};
`;

export const TitleDetail = styled.div`
  color: ${gray.darktext};
  font-size: ${fontSize.medium};
  ${media.mobile`display: none`};
`;

export const TitleDetail_Mobile = styled.div`
  color: ${gray.darktext};
  font-size: ${fontSize.medium};
`;

export const TitleRightDiv = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;
`;

// -------------------- 인풋 -------------------- //
export const InputSmall = styled.input`
  padding: 8px;
  border: 1px solid ${gray.medium};
  border-radius: ${borderRadius.small};
  color: ${gray.darktext};

  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* 부드러운 애니메이션 효과 추가 */

  &:focus {
    border-color: ${colors.primary}; /* 포커스 시 강조된 테두리 색상 */
    box-shadow: 0 0 5px ${colors.primary}; /* 포커스 시 약간의 그림자 효과 */
    outline: none; /* 기본 아웃라인 제거 */
  }

  &:hover {
    border-color: ${gray.dark}; /* 마우스를 올릴 때 테두리 색상 변경 */
  }

  &::placeholder {
    color: ${gray.mediumtext}; /* 플레이스홀더 텍스트 색상 */
  }
`;

// -------------------- 탭 -------------------- //

export const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: ${borderRadius.small}; /* 전체 컨테이너에 둥근 테두리 적용 */
  border: 1px solid ${gray.medium}; /* 전체 테두리 추가 */
  overflow: hidden; /* 자식 요소가 테두리를 벗어나지 않도록 설정 */
`;

export const TabButton = styled.button`
  width: 160px;
  color: ${(props) => (props.active ? `${colors.background}` : '#333')};
  background-color: ${(props) =>
    props.active ? `${colors.lightGreen}` : '#fff'};
  // font-weight: ${(props) =>
    props.active ? `${fontWeight.bold}` : `${fontWeight.medium}`};
  border: none;

  padding: ${padding.small} ${padding.medium};
  cursor: pointer;
  font-size: ${fontSize.large};

  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    // color: ${colors.lightGreen};
    font-weight: ${fontWeight.bold};
  }

  &:not(:last-child) {
    border-right: 1px solid ${gray.medium}; /* 마지막 버튼 외에는 오른쪽 테두리 추가 */
  }
`;

// -------------------- 버튼 -------------------- //

export const PrimaryButton = styled.button`
  padding: 8px ${padding.mediumLarge};
  border-radius: ${borderRadius.small};
  border: 1px solid ${colors.primary};
  color: ${colors.background};
  // color: ${colors.primary};
  // background-color: ${colors.background};
  background-color: ${colors.primary};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  cursor: pointer;

  &:hover {
    font-weight: ${fontWeight.bold};
    background-color: ${colors.primarydark};
  }
`;

// --------------------- 모달 -------------------- //

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); // 배경 반투명
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // 모달이 다른 요소들 위에 나타나도록 z-index 설정
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: ${padding.extraLarge};
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const ModalTitle = styled.h3`
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
`;

export const ModalDetail = styled.div`
  padding-bottom: ${padding.medium};
`;

export const ModalSubTitle = styled.div`
  padding-top: ${padding.medium};
`;
export const ModalButton = styled.button`
  margin-top: ${margin.medium};
  margin-right: ${margin.medium};
  padding: ${padding.medium} ${padding.mediumLarge};
  border: 1px solid ${colors.primary};
  border-radius: ${borderRadius.small};

  background-color: ${({ isSelected }) =>
    isSelected ? colors.primarylight : colors.background};
  color: ${colors.primary};
  font-size: 1rem;
  font-weight: ${fontWeight.bold};
  cursor: pointer;

  &:hover {
    background-color: ${gray.light};
  }
`;

export const ModalBlackButton = styled(ModalButton)`
  color: ${gray.darktext};
  border: 1px solid ${gray.darktext};
`;
export const CancelButton = styled(ModalButton)`
  color: ${gray.dark};

  margin: ${margin.medium};
  border: 1px solid ${gray.dark};

  &:hover {
    background-color: ${gray.light};
  }
`;
