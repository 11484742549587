import { useContext, useEffect, useState } from 'react';
import AllClassMode from './AllClassMode';
import AllClassModeHeader from './AllClassModeHeader';
import {
  AddNoteMobile,
  BlueText,
  NoProgressNoteDiv,
  NoteContainer,
  PrimaryText,
  ProgressNoteCard,
} from '../progress/ProgressNoteStyle';
import { AddNote } from '../../topSubMenuBar/ClassProgressnoteMenu';
import { EmptyTopicList } from '../topic/TopicStyle';
import { StyledCard } from '../../components/StyledCard';
import { IoAdd } from 'react-icons/io5';
import { colors } from '../../styles/commonStyle';
import ScrollToTop from '../../components/ScrollToTop';
import UserContext from '../../contexts/userContext';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';

const AllClassModeContainer = ({
  sortedProgressNoteByGrade,
  topics,
  deleteProgressNote,
  updateRecord,
  setShowAddWindow,
  showAddWindow,
  setShowSampleNotification,
  setRegisterModalOpen,
}) => {
  // sortedProgressNoteByGrade를 classNum으로 오름차순으로 정렬합니다.
  const sortedNotes = sortedProgressNoteByGrade.sort(
    (a, b) => a.classNum - b.classNum,
  );

  // classNum이 같은 항목들을 묶어서 반환하는 함수를 정의합니다.
  const groupByClassNum = (notes) => {
    const grouped = {};
    notes.forEach((note) => {
      const { classNum } = note;
      if (!grouped[classNum]) {
        grouped[classNum] = [];
      }
      grouped[classNum].push(note);
    });
    return grouped;
  };

  // classNum으로 묶인 객체를 가져옵니다.
  const groupedNotes = groupByClassNum(sortedNotes);

  useEffect(() => {
    // console.log(JSON.stringify(Object));
  }, []);

  const sampleNote = {
    id: 1,
    userId: 'sample',
    grade: 2,
    classNum: 1,
    topicNum: 1,
    record:
      '샘플 수업 기록입니다. 진도노트 추가 버튼을 눌러서 진도노트를 기록할 수 있습니다.',
    createdAt: '2024-05-14T11:54:38.000Z',
    updatedAt: '2024-05-14T15:00:00.000Z',
  };

  const sampleFunction = () => {
    setShowSampleNotification(true); // 알림 상태를 true로 변경
    setTimeout(() => {
      setShowSampleNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
    }, 1000);
  };

  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;

  const navigate = useNavigate();
  const roundAddButtonFunction = () => {
    if (userId) {
      setShowAddWindow(!showAddWindow);
      ScrollToTop();
    } else {
      alert('로그인 후 이용할 수 있습니다.');
      setRegisterModalOpen(true);
    }
  };

  return (
    <>
      {/* <AddNoteMobile
        $isvisible={showAddWindow.toString()}
        onClick={() => roundAddButtonFunction()}
      >
        <IoAdd color={`${colors.background}`} size={24} />
      </AddNoteMobile> */}
      {Object.keys(groupedNotes).length > 0 ? (
        Object.entries(groupedNotes).map(([classNum, notes]) => (
          <NoteContainer key={`class-${classNum}`}>
            <StyledCard>
              <AllClassModeHeader
                key={`header-${classNum}`}
                classNum={classNum}
                deleteProgressNote={deleteProgressNote}
              />

              {notes.map((note) => {
                const topic = topics.find(
                  (topic) => topic.topicId === note.topicNum,
                );
                return (
                  <AllClassMode
                    key={`note-${note.id}`}
                    note={note}
                    topicTitle={
                      topic ? topic.topicTitle : '주제를 입력해주세요'
                    }
                    topicDetail={
                      topic ? topic.topicDetail : '설명을 입력해주세요'
                    }
                    deleteProgressNote={deleteProgressNote}
                    updateRecord={updateRecord}
                  />
                );
              })}
            </StyledCard>
          </NoteContainer>
        ))
      ) : (
        <>
          {/* <NoteContainer>
            <StyledCard>
              <AllClassModeHeader classNum="1" />
              <AllClassMode
                note={sampleNote}
                topicTitle="샘플 수업 주제"
                topicDetail="샘플 수업 주제"
                deleteProgressNote={sampleFunction}
                updateRecord={sampleFunction}
              />
            </StyledCard>
          </NoteContainer> */}
          {!showAddWindow && (
            <>
              <EmptyTopicList>진도노트를 추가해보세요!</EmptyTopicList>
              <PrimaryText
                onClick={() => {
                  setShowAddWindow(true);
                }}
              >
                추가하기
              </PrimaryText>
            </>
          )}{' '}
        </>
      )}
    </>
  );
};

export default AllClassModeContainer;

// import { useEffect, useState } from 'react';
// import AllClassMode from './AllClassMode';
// import AllClassModeHeader from './AllClassModeHeader';
// import {
//   NoProgressNoteDiv,
//   ProgressNoteCard,
// } from '../progress/ProgressNoteStyle';

// const AllClassModeContainer = ({
//   sortedProgressNoteByGrade,
//   topics,
//   deleteProgressNote,
//   updateRecord,
// }) => {
//   // sortedProgressNoteByGrade를 classNum으로 오름차순으로 정렬합니다.
//   const sortedNotes = sortedProgressNoteByGrade.sort(
//     (a, b) => a.classNum - b.classNum,
//   );

//   // classNum이 같은 항목들을 묶어서 반환하는 함수를 정의합니다.
//   const groupByClassNum = (notes) => {
//     const grouped = {};
//     notes.forEach((note) => {
//       const { classNum } = note;
//       if (!grouped[classNum]) {
//         grouped[classNum] = [];
//       }
//       grouped[classNum].push(note);
//     });
//     return grouped;
//   };

//   // classNum으로 묶인 객체를 가져옵니다.
//   const groupedNotes = groupByClassNum(sortedNotes);
//   useEffect(() => {
//     // console.log(JSON.stringify(Object));
//   }, [Object]);

//   return (
//     <>
//       {Object.keys(groupedNotes).length > 0 ? (
//         Object.entries(groupedNotes).map(([classNum, notes]) => (
//           <ProgressNoteCard key={`class-${classNum}`}>
//             <AllClassModeHeader
//               key={`header-${classNum}`}
//               classNum={classNum}
//             />
//             {notes.map((note) => (
//               <AllClassMode
//                 key={`note-${note.id}`}
//                 note={note}
//                 topicTitle={
//                   topics.find((topic) => topic.topicId === note.topicNum)
//                     .topicTitle
//                 }
//                 topicDetail={
//                   topics.find((topic) => topic.topicId === note.topicNum)
//                     .topicDetail
//                 }
//                 deleteProgressNote={deleteProgressNote}
//                 updateRecord={updateRecord}
//               />
//             ))}
//           </ProgressNoteCard>
//         ))
//       ) : (
//         <NoProgressNoteDiv>진도노트를 추가해보세요!</NoProgressNoteDiv>
//       )}
//     </>
//   );
// };

// export default AllClassModeContainer;
