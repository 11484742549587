import { useNavigate } from 'react-router-dom';
import TruncateText from '../../commonFunction/TruncateText';
import styled from 'styled-components';
import logo from '../../img/teachernote-logo/teachernote-logo-green.png';
import { BlogListImage } from '../blogList/BlogListStyle';
import {
  borderRadius,
  fontWeight,
  gap,
  imgSize,
  margin,
  padding,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

// Styled Components
const BlogListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gap.small};
  padding-top: ${padding.small};
`;

const BlogCard = styled.div`
  display: flex;
  gap: ${gap.medium};
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  ${media.mobile`
    flex: 1 1 100%; /* Mobile에서는 카드 하나가 화면 전체 너비를 차지 */
    max-width: 100%;

    height: 100%;
    align-items: center;
 

  `}
`;
const BlogTitle = styled.div`
  font-size: 1.1em;
  margin-bottom: ${margin.medium};
  font-weight: ${fontWeight.bold};
`;

const BlogContent = styled.p`
  font-size: 0.9em;
  color: #555;
`;

const BlogImage = styled.img`
  width: 150px;
  height: auto;
  aspect-ratio: 1 / 1; /* 정사각형 비율 유지 */
  object-fit: cover; /* 이미지가 박스 안에 꽉 차도록 설정 */
  border-radius: ${borderRadius.medium};
`;

const BottomBlogListMobile = ({ blogs }) => {
  const navigate = useNavigate();

  const extractImageUrlFromContent = (content) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const imgTag = tempDiv.querySelector('img');
    return imgTag ? imgTag.src : logo; // 이미지가 있으면 그 URL, 없으면 로고
  };
  const handleCardClick = (postId, postCategory) => {
    window.scrollTo(0, 0); // 화면을 맨 위로 스크롤
    navigate(`/education-resources-sharing/${postCategory}/${postId}`);
  };
  return (
    <BlogListContainer>
      {blogs.map((post) => (
        <BlogCard
          key={post.id}
          onClick={() => handleCardClick(post.id, post.category)}
        >
          <div>
            <BlogImage
              src={extractImageUrlFromContent(post.content)}
              alt={post.title}
            />
          </div>
          <div>
            <BlogTitle>{post.title}</BlogTitle>
            <BlogContent>
              {TruncateText(post.content.replace(/<\/?[^>]+(>|$)/g, ''), 50)}
            </BlogContent>
          </div>
        </BlogCard>
      ))}
    </BlogListContainer>
  );
};

export default BottomBlogListMobile;
