import { useEffect, useState } from 'react';
import { PrivacyPolicyHelmetContent } from '../../components/HelmetContent';
import { margin, padding } from '../../styles/commonStyle';
import {
  PrivacyPolicy_Container,
  PrivacyPolicy_ContentWrapper,
  PrivacyPolicy_PreContent,
  PrivacyPolicy_Title,
} from './PrivacyPolicyStyle';

const TermsOfService = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 모바일 기준 너비 설정
    };

    handleResize(); // 첫 렌더링 시 확인
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <PrivacyPolicy_Container>
      <PrivacyPolicy_ContentWrapper isMobile={isMobile}>
        <PrivacyPolicyHelmetContent />
        <PrivacyPolicy_Title isMobile={isMobile}>
          서비스 이용약관
        </PrivacyPolicy_Title>
        <PrivacyPolicy_PreContent isMobile={isMobile}>
          {`
제1조 (목적)
본 약관은 티처노트(이하 ‘회사’)가 제공하는 서비스의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

제2조 (용어의 정의)
"서비스"라 함은 회사가 제공하는 랜덤 자리 뽑기, 랜덤 숫자 뽑기, 수업 진도표 관리, 교육 자료 공유 등 회사의 웹사이트와 관련된 모든 서비스를 의미합니다.
"회원"이라 함은 본 약관에 동의하고 서비스를 이용하는 개인 및 단체를 말합니다.
"아이디(ID)"라 함은 회원 식별 및 서비스 이용을 위해 회원이 설정하고 회사가 승인한 이메일 주소나 소셜 로그인 계정을 의미합니다.

제3조 (약관의 효력 및 변경)
본 약관은 회원이 서비스에 가입함과 동시에 효력이 발생합니다.
회사는 합리적인 사유가 발생할 경우, 관련 법령에 위배되지 않는 범위 내에서 본 약관을 개정할 수 있습니다. 변경된 약관은 공지 사항을 통해 공지되며, 회원이 변경된 약관에 동의하지 않을 경우, 회원 탈퇴를 요청할 수 있습니다.

제4조 (회원 가입 및 관리)
회원은 회사가 정한 절차에 따라 회원 가입을 신청하며, 회사는 이를 승인함으로써 회원 가입이 완료됩니다.
회원 가입 시 제공된 정보에 변경이 있을 경우, 회원은 즉시 해당 정보를 수정하여야 합니다.

제5조 (서비스 이용)
서비스 이용은 회사의 운영 정책에 따라 정해진 시간 동안 제공됩니다. 회사는 서비스 제공 시간 및 내용에 대해 사전 공지를 통해 변경할 수 있습니다.
회사는 회원의 편의를 위해 AI 기반의 맞춤형 서비스와 빅데이터 분석을 통한 서비스 개선 등을 제공합니다.

제6조 (회원의 의무)
회원은 서비스 이용 시 관련 법령, 약관의 규정, 회사가 사전에 공지한 주의사항 등을 준수하여야 하며, 타인의 권리와 회사의 운영을 방해하는 행위를 해서는 안 됩니다.
회원은 자신의 아이디와 비밀번호의 관리 책임이 있으며, 이를 제3자에게 제공하거나 공유할 수 없습니다.
회원은 허위 정보를 제공하거나 타인의 정보를 무단으로 도용하는 행위를 할 수 없습니다.

제7조 (서비스 이용의 제한 및 중단)
회사는 다음 각 호의 경우 회원의 서비스 이용을 제한하거나 중단할 수 있습니다.
회원이 본 약관의 의무를 위반한 경우
서비스 운영에 중대한 영향을 미치는 버그, 장애 발생 시
법령에 의해 서비스 제공이 제한되거나 금지된 경우
회사는 상기 사유로 서비스 이용을 제한하거나 중단하는 경우, 회원에게 사전 또는 사후 통지합니다.

제8조 (개인정보 보호)
회사는 회원의 개인정보를 보호하기 위해 관련 법령과 개인정보 처리방침에 따라 보호 조치를 강구합니다.
회사는 회원의 개인정보를 본인의 동의 없이 제3자에게 제공하지 않으며, 개인정보의 보호 및 이용에 관한 자세한 내용은 개인정보 처리방침을 따릅니다.

제9조 (서비스의 변경 및 종료)
회사는 상당한 이유가 있는 경우, 서비스의 전부 또는 일부를 변경하거나 종료할 수 있습니다.
서비스 종료 시 회사는 회원에게 사전 통지를 하며, 통지일로부터 30일 이후 서비스가 종료됩니다.

제10조 (책임의 한계)
회사는 회원 간 또는 회원과 제3자 간의 거래로 인해 발생하는 문제에 대해 책임을 지지 않습니다.
회사는 천재지변, 전쟁, 폭동 등 불가항력적 사유로 인해 발생한 손해에 대해 책임을 지지 않습니다.
회사는 회원의 귀책사유로 인해 발생한 서비스 이용 장애에 대해서는 책임을 지지 않습니다.

제11조 (저작권 및 이용 제한)
서비스 내에서 제공되는 콘텐츠의 저작권은 회사에 있으며, 회원은 회사의 승인 없이 이를 무단으로 복제, 배포할 수 없습니다.
회원이 본 약관을 위반하여 발생하는 모든 문제에 대한 책임은 해당 회원에게 있습니다.

제12조 (분쟁 해결)
본 약관과 관련하여 회사와 회원 간에 분쟁이 발생한 경우, 회사와 회원은 원만한 해결을 위해 성실히 협의합니다.
협의가 이루어지지 않을 경우, 관련 법령에 따라 관할 법원에 소를 제기할 수 있습니다.
부칙

본 약관은 2024년 11월 3일부터 적용됩니다.
        `}
        </PrivacyPolicy_PreContent>
      </PrivacyPolicy_ContentWrapper>
    </PrivacyPolicy_Container>
  );
};

export default TermsOfService;
