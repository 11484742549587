import {
  MobileMenuMiddleBox,
  MobileMenuBox,
  MobileMenuContainerInner,
  MobileMenuTopBox,
  MobileOverlay,
  MobileSlideMenu,
  MobileMenuBottomBox,
} from './MobileMenuStyle';
import { useNavigate } from 'react-router-dom';
import logoHorizontalSmall from '../../../img/teachernote-logo/teachernote-logo-horizontal-small.png';
import { GreenBadge, NewBadge, YellowBadge } from '../../../styles/BasicStyle';
import { gray, padding } from '../../../styles/commonStyle';
import { IoIosArrowBack } from 'react-icons/io';
import { useContext } from 'react';
import UserContext from '../../../contexts/userContext';

const MobileMenu = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
    window.scrollTo(0, 0);
  };
  const { state: userState } = useContext(UserContext);

  const userId = userState && userState.user ? userState.user.userId : null;
  const myInfo = userId ? '/m-my-page' : '/m-sign-up';

  return (
    <>
      {/* <MobileOverlay isOpen={isOpen} onClick={() => setIsOpen(false)} /> */}
      <MobileSlideMenu isOpen={isOpen}>
        {/* <MobileMenuContainer> */}
        <MobileMenuContainerInner>
          <div
            onClick={() => setIsOpen(false)}
            style={{
              position: 'fixed',
              top: '10px',
              left: '10px',
              zIndex: 1000,
              padding: `${padding.medium}`,
              backgroundColor: 'white',
              border: '1px solid #ddd',
              borderRadius: '50%',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IoIosArrowBack size={20} />
          </div>
          <MobileMenuTopBox>
            <img
              src={logoHorizontalSmall}
              style={{ width: '130px' }}
              alt="티처노트 가로형 소형 로고"
              onClick={() => handleNavigation('/')}
            />
          </MobileMenuTopBox>
          <MobileMenuMiddleBox style={{ borderTop: `1px solid ${gray.light}` }}>
            <MobileMenuBox
              onClick={() => handleNavigation('/m-random-number-picker')}
            >
              랜덤 숫자뽑기<NewBadge>NEW</NewBadge>
            </MobileMenuBox>
            <MobileMenuBox
              onClick={() => handleNavigation('/m-random-seat-assignment')}
            >
              자리뽑기<YellowBadge>UP</YellowBadge>
            </MobileMenuBox>

            <MobileMenuBox
              onClick={() => handleNavigation('/m-talk-education-sharing')}
            >
              커뮤니티<YellowBadge>UP</YellowBadge>
            </MobileMenuBox>
            <MobileMenuBox
              onClick={() =>
                handleNavigation(
                  '/education-resources-sharing/classroom-learning-activities',
                )
              }
            >
              수업·평가<YellowBadge>UP</YellowBadge>
            </MobileMenuBox>
          </MobileMenuMiddleBox>
          <MobileMenuMiddleBox>
            <MobileMenuBox
              onClick={() => handleNavigation('/lesson-progress-tracker')}
            >
              진도표
            </MobileMenuBox>
            <MobileMenuBox
              onClick={() => handleNavigation('/progress-notes/by-class')}
            >
              진도노트
            </MobileMenuBox>
            <MobileMenuBox
              onClick={() => handleNavigation('/class-management')}
            >
              학급 설정
            </MobileMenuBox>
            <MobileMenuBox
              onClick={() => handleNavigation('/topic-management')}
            >
              수업주제 설정
            </MobileMenuBox>
          </MobileMenuMiddleBox>
          <MobileMenuMiddleBox
            style={{ borderBottom: 'none', paddingBottom: '0px' }}
          >
            <MobileMenuBox onClick={() => handleNavigation(myInfo)}>
              마이페이지
            </MobileMenuBox>
          </MobileMenuMiddleBox>
        </MobileMenuContainerInner>
      </MobileSlideMenu>
    </>
  );
};

export default MobileMenu;
