import React, { useState, useContext, useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from '../../../node_modules/axios/index';
import UserContext from '../../contexts/userContext';
import Notification from '../../components/Notification';
import {
  AdditionalSection,
  AdditionalSectionTitle,
  BlogSubmitContainer,
  BlogSubmitContainerLayout1,
  BlogSubmitContainerLayout2,
  BlogTitleInput,
  BlogWriteBox,
  FileButton,
  QuillBox,
  SelectBlogCategory,
  ToolbarContainer,
} from './BlogWriteStyle';
import { BlogBtn } from '../blogList/BlogListStyle';
import { useMediaQuery } from 'react-responsive';
import MobileTopMenuBarArticle from '../../topMenuBarMobile/MobileTopMenuBarArticle';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import InsertTag from './InsertTag';
import { BsXLg } from 'react-icons/bs';
import { AlignLeft, DisplayColumn } from '../../styles/BasicStyle';
import { OneByOneButton } from '../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import { colors, margin } from '../../styles/commonStyle';

const QuillComponent = ({}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState.user.userId;
  const [showNotification, setShowNotification] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState([]); // 태그 저장 상태
  // const [fileUrl, setFileUrl] = useState(''); // 파일 URL 상태 관리
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const quillRef = useRef(null); // useRef로 ref 생성
  const [fileName, setFileName] = useState(''); // 파일 이름 상태 추가

  const handleChange = (content) => {
    setContent(content);
  };

  // 파일 삭제 함수
  const removeFile = () => {
    setFile(null); // 파일 상태 초기화
    setFileName(''); // 파일 이름 상태 초기화
  };

  // 파일 핸들러 함수
  const fileHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const selectedFile = input.files[0];
      setFile(selectedFile); // 파일을 상태에 저장
      setFileName(selectedFile.name); // 파일 이름을 상태에 저장
    };
  }, []);
  // const fileHandler = () => {
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.click();

  //   input.onchange = async () => {
  //     const file = input.files[0];
  //     const formData = new FormData();
  //     formData.append('file', file);

  //     try {
  //       const res = await axios.post(
  //         'https://teachernote.me/api/upload',
  //         formData,
  //       );
  //       const uploadedFileUrl = res.data.fileUrl;
  //       setFileUrl(uploadedFileUrl); // URL을 상태에 저장
  //     } catch (error) {
  //       console.error('File upload failed', error);
  //     }
  //   };
  // };

  // 이미지 파일 업로드 핸들러

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await axios.post(
          'https://teachernote.me/api/uploadImage', // 프록시 설정으로 상대 경로 사용
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );
        const imageUrl = response.data.url;
        // console.log('Image URL:', JSON.stringify(imageUrl));

        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        editor.insertEmbed(range.index, 'image', imageUrl);

        // MutationObserver로 이미지가 완전히 로딩된 후 스타일 적용
        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
              const img = editor.root.querySelector(`img[src="${imageUrl}"]`);
              if (img) {
                img.style.display = 'block';
                img.style.margin = '0 auto';
                observer.disconnect(); // 스타일 적용 후 Observer 해제
              }
            }
          }
        });

        // 에디터의 DOM 변화 감지
        observer.observe(editor.root, { childList: true, subtree: true });
      } catch (error) {
        console.error('Image upload failed:', error);
      }
    };
  }, []);
  // const imageHandler = useCallback(() => {
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');
  //   input.click();

  //   input.onchange = async () => {
  //     const file = input.files[0];
  //     const formData = new FormData();
  //     formData.append('image', file);

  //     try {
  //       const response = await axios.post(
  //         'https://teachernote.me/api/uploadImage', // 프록시 설정으로 상대 경로 사용
  //         formData,
  //         {
  //           headers: { 'Content-Type': 'multipart/form-data' },
  //         },
  //       );
  //       const imageUrl = response.data.url;
  //       console.log('Image URL:', JSON.stringify(imageUrl));

  //       const editor = quillRef.current.getEditor();
  //       const range = editor.getSelection();
  //       editor.insertEmbed(range.index, 'image', imageUrl);

  //       // 삽입된 이미지를 자동으로 가운데 정렬
  //       setTimeout(() => {
  //         const img = editor.root.querySelector(`img[src="${imageUrl}"]`);
  //         if (img) {
  //           img.style.display = 'block';
  //           img.style.margin = '0 auto';
  //         }
  //       }, 0);
  //     } catch (error) {
  //       console.error('Image upload failed:', error);
  //     }
  //   };
  // }, []);

  // 툴바에 커스텀 버튼을 직접 렌더링하는 컴포넌트 생성
  const CustomToolbar = () => (
    <ToolbarContainer id="toolbar">
      <OneByOneButton onClick={fileHandler} className="ql-file">
        파일찾기
      </OneByOneButton>
      {fileName && (
        <span
          onClick={removeFile}
          style={{ cursor: 'pointer', color: `${colors.lightGreen}` }}
        >
          {fileName} <BsXLg />
        </span>
      )}{' '}
      {/* 선택한 파일 이름 표시 */}
    </ToolbarContainer>
  );

  //quill component 관련

  const modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        ['clean'],
        ['file'],
      ],
      handlers: {
        file: fileHandler, // 핸들러 파일 핸들러 등록
        image: imageHandler, // 이미지 업로드 핸들러 연결
      },
    },
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'align',
    'link',
    'image',
    'video',
    'color',
    'background',
  ];

  const handleTitleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setTitle(value);
      // setTitleExceedLimit(false); // 글자 수 초과 상태 초기화
    } else {
      // setTitleExceedLimit(true); // 글자 수 초과 시 상태 변경
    }
  };

  const handleSubmit = async () => {
    if (!title.trim() || !content.trim()) {
      alert('제목과 본문을 입력해주세요.');
      return;
    }

    let fileUrl = ''; // 기본값을 빈 문자열로 설정

    // 파일이 있는 경우 파일 업로드 후 URL 받기
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const fileResponse = await axios.post(
          'https://teachernote.me/api/upload',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );
        fileUrl = fileResponse.data.fileUrl;
      } catch (error) {
        console.error('File upload failed:', error);
        return;
      }
    }

    // 블로그 글 데이터 전송
    const dataToSend = {
      userId,
      category,
      title,
      content: encodeURIComponent(content), // content를 인코딩하여 전송
      tags,
      fileUrl, // 파일 URL 추가
    };

    // 서버로 데이터 전송
    try {
      await axios.post('https://teachernote.me/api/blogwrite', dataToSend, {
        headers: { 'Content-Type': 'application/json' },
      });
      navigate('/education-resources-sharing');
    } catch (error) {
      console.error('Error posting blog:', error);
    }
  };

  // const handleSubmit = async () => {
  //   if (!title.trim() || !content.trim()) {
  //     alert('제목과 본문을 입력해주세요.');
  //     return;
  //   }

  //   const dataToSend = {
  //     userId,
  //     category,
  //     title,
  //     content: encodeURIComponent(content), // content를 인코딩하여 전송
  //     tags,
  //   };

  //   if (file) {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     formData.append('data', JSON.stringify(dataToSend)); // 데이터를 문자열로 변환 후 첨부

  //     try {
  //       await axios.post('https://teachernote.me/api/blogwrite', formData, {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //       });
  //       navigate('/bloglist');
  //     } catch (error) {
  //       console.error('Error posting blog:', error);
  //     }
  //   } else {
  //     try {
  //       await axios.post('https://teachernote.me/api/blogwrite', dataToSend);
  //       navigate('/bloglist');
  //     } catch (error) {
  //       console.error('Error posting blog:', error);
  //     }
  //   }
  // };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  //체크박스
  const [category, setCategory] = useState('talk');

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setCategory(value);
  };

  // const categories = [
  //   { id: 'talk', label: 'TALK' },
  //   { id: 'announcement', label: '공지사항' },
  //   { id: 'materials', label: '수업자료' },
  //   { id: 'activities', label: '수업활동' },
  //   { id: 'evaluation', label: '수행평가' },
  //   { id: 'classactivity', label: '학급활동' },
  //   { id: 'decoration', label: '교실꾸미기' },
  //   { id: 'studentitem', label: '학생 아이템 추천' },
  //   { id: 'teacheritem', label: '교사 아이템 추천' },
  //   { id: 'workmaterials', label: '업무자료' },
  //   { id: 'teachertraining', label: '교원연수' },
  //   { id: 'magazine', label: '교육 매거진' },
  // ];
  const categories = [
    { id: 'talk-community', label: 'TALK' },
    { id: 'announcement', label: '공지사항' },
    { id: 'lesson-materials', label: '수업자료' },
    { id: 'classroom-learning-activities', label: '수업활동' },
    { id: 'performance-evaluation', label: '수행평가' },
    { id: 'classroom-group-activities', label: '학급활동' },
    { id: 'classroom-decoration', label: '교실꾸미기' },
    { id: 'student-recommendations', label: '학생 아이템 추천' },
    { id: 'teacher-recommendations', label: '교사 아이템 추천' },
    { id: 'work-materials', label: '업무자료' },
    { id: 'teacher-training', label: '교원연수' },
    { id: 'education-magazine', label: '교육 매거진' },
  ];

  return (
    <>
      {/* {isMobile && <MobileTopMenuBarArticle handleSubmit={handleSubmit} />} */}
      <BlogWriteBox>
        <Notification
          message="블로그가 업로드되었습니다."
          isVisible={showNotification}
        />
        {!isMobile && (
          <BlogSubmitContainer>
            <BlogSubmitContainerLayout1>
              {categories.map((categoryItem) => (
                <SelectBlogCategory key={categoryItem.id}>
                  <label>
                    <input
                      type="radio" // 체크박스를 라디오 버튼으로 변경
                      name="category"
                      value={categoryItem.id}
                      checked={categoryItem.id === category}
                      onChange={handleCheckboxChange}
                    />
                    {categoryItem.label}
                  </label>
                </SelectBlogCategory>
              ))}
              {/* <SelectBlogCategory>카테고리</SelectBlogCategory>
              <SelectBlogCategory>카테고리1 </SelectBlogCategory>
              <SelectBlogCategory>카테고리2 </SelectBlogCategory> */}
            </BlogSubmitContainerLayout1>
            <BlogSubmitContainerLayout2>
              <BlogBtn onClick={() => handleSubmit()}>올리기</BlogBtn>
            </BlogSubmitContainerLayout2>
          </BlogSubmitContainer>
        )}

        <BlogTitleInput
          value={title}
          type="text"
          placeholder="제목을 입력하세요"
          name="title"
          onChange={handleTitleChange}
        />

        <QuillBox>
          <ReactQuill
            // id="react-quill"
            ref={quillRef} // ref 추가
            value={content}
            onChange={handleChange}
            modules={modules}
            formats={formats}
          />
        </QuillBox>
        <AlignLeft>
          <AdditionalSection>
            <AdditionalSectionTitle>파일 첨부</AdditionalSectionTitle>
            <div>
              <CustomToolbar />
            </div>
          </AdditionalSection>
          <AdditionalSection>
            <AdditionalSectionTitle>태그 설정</AdditionalSectionTitle>
            <div>
              <InsertTag tags={tags} setTags={setTags} />
            </div>
          </AdditionalSection>

          {isMobile && (
            <BlogBtn style={{ marginTop: `${margin.medium}` }}>올리기</BlogBtn>
          )}
        </AlignLeft>
      </BlogWriteBox>
    </>
  );
};

export default QuillComponent;
