import { useState, useEffect, useContext, useCallback } from 'react';
import axios from '../../../node_modules/axios/index';
import TopicList from './TopicList';
import TopicInput from './TopicInput';
import GradeContext from '../../contexts/grade';
import UserContext from '../../contexts/userContext';
import { EmptyTopicList } from './TopicStyle';
import PageContainer from '../../pageLayout/PageContainer';
import { PageBox, PageSubheader } from '../../pageLayout/PageContainerStyle';
import SelectGradeMenuBar from '../../topSubMenuBar/SelectGradeMenuBar';
import { useMediaQuery } from 'react-responsive';
import MobileTopMenuBar from '../../topMenuBarMobile/MobileTopMenuBar';
import Notification from '../../components/Notification';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import {
  DisplayColumn,
  DisplayRowSpaceBetween,
  TitleContainer,
  TitleDetail,
  TitleText,
} from '../../styles/BasicStyle';
import { OneByOneButton } from '../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import RegisterModalComponent from '../registerModal/RegisterModalComponent';
import LoginModalComponent from '../loginModal/LoginModalComponent';
import { TopicSettingHelmetContent } from '../../components/HelmetContent';

const TopicPage = () => {
  //학년 정보 받아오기
  const { state: gradeState } = useContext(GradeContext);
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const grade = gradeState.currentGrade;

  const [showNotification, setShowNotification] = useState(false);

  //mysqlDB데이터 받아오기- 학년별 수업 주제
  const [topicsDB, setTopicsDB] = useState([]);
  const [showAddBtn, setShowAddBtn] = useState(true);
  const fetchTopicsByGrade = useCallback(async () => {
    if (!userId) {
      setTopicsDB([
        {
          id: 6025,
          userId: 'teachernote',
          grade: 2,
          topicId: 1,
          topicTitle: '비유와 상징의 표현 효과',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6026,
          userId: 'teachernote',
          grade: 2,
          topicId: 2,
          topicTitle: '갈등의 진행과 해결 과정',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6027,
          userId: 'teachernote',
          grade: 2,
          topicId: 3,
          topicTitle: '문학과 삶의 성찰',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6028,
          userId: 'teachernote',
          grade: 2,
          topicId: 4,
          topicTitle: '언어의 본질과 국어의 어휘 체계',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6029,
          userId: 'teachernote',
          grade: 2,
          topicId: 5,
          topicTitle: '국어의 품사와 특성',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6030,
          userId: 'teachernote',
          grade: 2,
          topicId: 6,
          topicTitle: '예측하며 읽기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6031,
          userId: 'teachernote',
          grade: 2,
          topicId: 7,
          topicTitle: '요약하며 읽기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6032,
          userId: 'teachernote',
          grade: 2,
          topicId: 8,
          topicTitle: '자료 활용하며 읽기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6033,
          userId: 'teachernote',
          grade: 2,
          topicId: 9,
          topicTitle: '경험을 담은 글과 통일성을 갖춘 글 쓰기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6034,
          userId: 'teachernote',
          grade: 2,
          topicId: 10,
          topicTitle: ' 매체 특성에 따른 표현하기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6035,
          userId: 'teachernote',
          grade: 2,
          topicId: 11,
          topicTitle: '면담하기와 배려하는 말하기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
        {
          id: 6036,
          userId: 'teachernote',
          grade: 2,
          topicId: 12,
          topicTitle: '토의하기와 판단하며 듣기',
          topicDetail: null,
          createdAt: '2024-07-02T03:40:43.000Z',
          updatedAt: '2024-07-02T03:40:43.000Z',
        },
      ]);
    } else {
      try {
        const res = await axios.get(
          'https://teachernote.me/api/topicsbygrade/' + userId,
          {
            params: { grade: grade },
          },
        );
        setTopicsDB(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  }, [grade, userId]);

  useEffect(() => {
    fetchTopicsByGrade();
    setNewTopic((prev) => ({
      ...prev,
      grade: gradeState.currentGrade,
    }));
  }, [grade, userId]);

  //수업 주제 삭제
  const navigate = useNavigate();
  const handleDelete = async (e, id) => {
    e.preventDefault();
    if (!userId) {
      alert('로그인 후 이용 가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      try {
        await axios
          .delete('https://teachernote.me/api/topics/' + id)
          .then(() => {
            fetchTopicsByGrade();
          });
        setShowNotification(true); // 알림 상태를 true로 변경
        setTimeout(() => {
          setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });

  //수업 주제 번호 추가(topic list 맨 아래 버튼)
  const [newTopic, setNewTopic] = useState({
    userId: userId,
    grade: grade,
    topicId: '',
    topicTitle: '',
  });

  useEffect(() => {
    if (topicsDB.length > 0) {
      const maxTopicId = topicsDB.reduce(
        (max, topic) => (topic.topicId > max ? topic.topicId : max),
        topicsDB[0].topicId,
      );
      if (maxTopicId >= 36) {
        setShowAddBtn(false);
      } else {
        setShowAddBtn(true);
      }
      setNewTopic((prev) => ({ ...prev, topicId: maxTopicId + 1 }));
    }
  }, [topicsDB]);

  const addTopic = async (e) => {
    if (!userId) {
      alert('로그인 후 이용 가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      if (newTopic.topicId < 37) {
        try {
          await axios
            .post('https://teachernote.me/api/topics', newTopic)
            .then(() => {
              fetchTopicsByGrade();
            });
          setShowNotification(true);
          setTimeout(() => {
            setShowNotification(false);
          }, 1000);
        } catch (err) {
          console.error(err);
        }
      }
    }
  };
  //--------------------- 로그인/회원가입 --------------------//
  //회원가입 모달
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('bottombutton'); //menubar,bottombutton

  const handleAuthComplete = async (userId) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  const openRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const openLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const closeAuthModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  return (
    <>
      <TopicSettingHelmetContent />
      <RegisterModalComponent
        isRegisterModalOpen={isRegisterModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        openRegisterModal={openRegisterModal}
        openLoginModal={openLoginModal}
        closeModal={closeAuthModal}
        handleAuthComplete={handleAuthComplete}
        modalSource={modalSource}
      />
      {isLoginModalOpen ? (
        <LoginModalComponent
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          openRegisterModal={openRegisterModal}
          openLoginModal={openLoginModal}
          closeAuthModal={closeAuthModal}
          handleAuthComplete={handleAuthComplete}
          modalSource={modalSource}
        />
      ) : (
        <></>
      )}
      <PageContainer>
        <Notification
          message="변경사항 저장 완료"
          isVisible={showNotification}
        />
        <PageSubheader>
          <SelectGradeMenuBar />
        </PageSubheader>
        {isMobile && <MobileTopMenuBar />}

        <PageBox>
          <TitleContainer>
            <DisplayRowSpaceBetween>
              <DisplayColumn style={{ width: 'auto' }}>
                {' '}
                <TitleText>수업 주제 설정</TitleText>{' '}
                <TitleDetail>
                  진도표에 사용할 차시별 수업 주제를 관리할 수 있습니다.
                </TitleDetail>
              </DisplayColumn>
              {!isMobile && (
                <OneByOneButton
                  onClick={() => {
                    navigate('/lesson-progress-tracker');
                  }}
                >
                  진도표 바로가기{' '}
                </OneByOneButton>
              )}
            </DisplayRowSpaceBetween>
          </TitleContainer>
          <TopicInput
            topics={topicsDB}
            topicNumber={topicsDB.length}
            fetchTopicsByGrade={fetchTopicsByGrade}
            setShowNotification={setShowNotification}
            setRegisterModalOpen={setRegisterModalOpen}
          />
          {topicsDB.length === 0 ? (
            <EmptyTopicList>
              {grade}학년 수업 주제를 추가해보세요!
            </EmptyTopicList>
          ) : (
            <TopicList
              topics={topicsDB}
              onRemove={handleDelete}
              fetchTopicsByGrade={fetchTopicsByGrade}
              addTopic={addTopic}
              showAddBtn={showAddBtn}
              setShowNotification={setShowNotification}
              setRegisterModalOpen={setRegisterModalOpen}
            />
          )}
        </PageBox>
      </PageContainer>
    </>
  );
};

export default TopicPage;
