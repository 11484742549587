import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import {
  AuthCardContainer,
  AuthCardTitle,
} from '../../components/AuthCardContainer';
import { AuthStyledLink } from '../../components/AuthPageStyle';
import KakaoLoginButton from '../../components/KakaoLoginButton';
import LinkTo from '../../components/LinkTo';
import MainButton from '../../components/MainButton';
import Input from '../../components/StyledInput';
import { AuthBox, AuthLayout1 } from '../register2/Register2Style';
import logo_horizontal from '../../img/teachernote-logo/teachernote-logo-horizontal.png';
import { GreenBadge } from '../../styles/BasicStyle';
const Login2 = ({
  handleChange,
  handleLogin,
  loginInfo,
  rememberMe,
  handleRememberMeChange,
}) => {
  const navigate = useNavigate();
  const handleAuthComplete = async (userId) => {
    navigate('/login-confirm');
  };

  return (
    <AuthBox>
      <AuthLayout1>
        <div>
          <img
            src={logo_horizontal}
            style={{ width: '300px' }}
            alt="티처노트 로고 - 가로형"
          />
        </div>
        티처노트에 오신 것을 환영합니다!
      </AuthLayout1>
      <AuthCardContainer>
        <form className="form" onSubmit={handleLogin}>
          <div>
            <Input
              type="id"
              placeholder="아이디"
              onChange={handleChange}
              name="userId"
              value={loginInfo.userId}
            />
          </div>

          <div>
            <Input
              type="password"
              placeholder="비밀번호"
              onChange={handleChange}
              name="password"
              value={loginInfo.password}
            />
          </div>

          <div style={{ margin: '5px 0' }}>
            <label style={{ cursor: 'pointer' }}>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={handleRememberMeChange}
                style={{ cursor: 'pointer' }}
              />
              로그인 정보 저장
            </label>
          </div>
          <MainButton onClick={handleLogin}>로그인</MainButton>
          {/* <KakaoLoginButton handleAuthComplete={handleAuthComplete} /> */}
          <LinkTo>
            <AuthStyledLink to="/sign-up">
              아직 아이디가 없으신가요?
            </AuthStyledLink>
          </LinkTo>
        </form>
      </AuthCardContainer>
    </AuthBox>
  );
};

export default Login2;
