import styled from 'styled-components';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gap,
  gray,
  margin,
  padding,
} from '../../../styles/commonStyle';
import { media } from '../../../styles/media';

export const MobileUserSettingContainer = styled.div`
  // width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MobileUserSetting_TopArea = styled.div`
  width: 100%;
  margin: -20px -40px 0 -40px;
  // padding: ${margin.bottomMobile} 0;
  padding: 9vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary};
  color: white;
  // border-bottom-left-radius: 10%;
  // border-bottom-right-radius: 10px;
`;
export const MobileUserSetting_BottomArea = styled.div`
  padding: 3vh 0;
  margin: 20px -20px -20px -20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${gap.medium};
`;

export const MobileUserSetting_LogoutContainer = styled.div`
  position: fixed;
  top: 60px;
  right: 10px;
  z-index: 1000;
  font-size: ${fontSize.extraSmall};
  color: black;
`;

// -------------------- Top Area -------------------- //
export const AvatarContainer = styled.div`
  width: 50vw;
  aspect-ratio: 1/1;
  padding: ${padding.medium};

  border-radius: 50%;
  background-color: ${colors.lightGreenBorder};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Nickname = styled.div`
  font-size: ${fontSize.extraLarge};
  font-weight: ${fontWeight.bold};
`;

// -------------------- Bottom Area -------------------- //

export const MobileUserSetting_MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gap.extraSmall};
  justify-content: center;
  align-items: center;
  font-size: ${fontSize.extraSmall};
`;
export const MobileUserSetting_Menu = styled.div`
  width: 15vw;
  aspect-ratio: 1/1;
  background-color: white;

  border-radius: ${borderRadius.medium};
  background-color: ${colors.lightGreenBackground};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileUserSetting_Img = styled.img`
  width: 50%;
`;
