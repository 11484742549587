import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules';
import styled from 'styled-components';
import { colors, gray, objHeight } from '../../styles/commonStyle';
import {
  CardContainer_LargeCard,
  CarouselWrapper,
  StyledSwiper_LargeCard,
  CardCarouselImage,
  LargeCardTitle,
} from './CardCarouselStyle';
import { extractImageUrlFromContent } from '../../pages/main/Main';
import TruncateText from '../../commonFunction/TruncateText';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { RedBadge_OnItem } from '../../styles/BasicStyle';
import { useMediaQuery } from 'react-responsive';

const LargeCardCarousel = ({ cards }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const showLargeCardNum = isMobile ? 1 : 2;

  const handleCardClick = (cardId, cardCategory) => {
    navigate(`/education-resources-sharing/${cardCategory}/${cardId}`);
  };

  return (
    <CarouselWrapper>
      <StyledSwiper_LargeCard
        modules={[Navigation]}
        navigation
        spaceBetween={30}
        slidesPerView={showLargeCardNum}
        slidesPerGroup={showLargeCardNum}
        loop={true}
      >
        {cards.map((card) => (
          <SwiperSlide key={card.id}>
            <ResizableCard
              onClick={() => handleCardClick(card.id, card.category)}
              card={card}
            />
          </SwiperSlide>
        ))}
      </StyledSwiper_LargeCard>
    </CarouselWrapper>
  );
};

// CardContainer_LargeCard를 위한 별도의 컴포넌트 생성
const ResizableCard = ({ card, onClick }) => {
  const [height, setHeight] = useState(300); // 기본 높이 설정
  const containerRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setHeight(width * 1); // 1:1.4 비율로 계산된 높이
      }
    };

    updateHeight(); // 초기 높이 설정
    const resizeObserver = new ResizeObserver(updateHeight);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <CardContainer_LargeCard
      ref={containerRef}
      height={height}
      onClick={onClick}
    >
      {card.isNew && <RedBadge_OnItem>NEW</RedBadge_OnItem>}
      <CardCarouselImage
        src={extractImageUrlFromContent(card.description)}
        alt={card.title}
        style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
      />
      <LargeCardTitle>{TruncateText(card.title, 40)}</LargeCardTitle>
    </CardContainer_LargeCard>
  );
};

export default LargeCardCarousel;

// import React, { useEffect, useRef, useState } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import { Navigation } from 'swiper/modules'; // Pagination 모듈을 제거
// import styled from 'styled-components';
// import { colors, gray, objHeight } from '../../styles/commonStyle';
// import {
//   CardContainer_LargeCard,
//   CardDescription,
//   CarouselWrapper,
//   StyledSwiper_LargeCard,
//   CardTitle,
//   CardCarouselImage,
//   LargeCardTitle,
// } from './CardCarouselStyle';
// import { extractImageUrlFromContent } from '../../pages/main/Main';
// import TruncateText from '../../commonFunction/TruncateText';
// import { stripHtmlTags } from '../../commonFunction/StripHtmlTags';
// import KoreanDate from '../../commonFunction/KoreanDate';
// import { DisplayRight } from '../../seatdrawpages/3drawOneByOnePage/3-1seatSetting/SeatSettingStyle';
// import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
// import { RedBadge_OnItem } from '../../styles/BasicStyle';
// import { useMediaQuery } from 'react-responsive';

// const LargeCardCarousel = ({ cards }) => {
//   const navigate = useNavigate();

//   const handleCardClick = (cardId) => {
//     navigate(`/blog/${cardId}`);
//   };

//   const isMobile = useMediaQuery({ maxWidth: 767 });
//   const showLargeCardNum = isMobile ? 1 : 2;
//   const [height, setHeight] = useState(300);
//   const containerRef = useRef(null); // div의 참조 생성

//   useEffect(() => {
//     const updateHeight = () => {
//       if (containerRef.current) {
//         const width = containerRef.current.offsetWidth;
//         setHeight(width * 1); // 1:1.4 비율로 계산된 높이
//       }
//     };

//     updateHeight(); // 초기 높이 설정
//     const resizeObserver = new ResizeObserver(updateHeight);
//     if (containerRef.current) {
//       resizeObserver.observe(containerRef.current);
//     }

//     return () => {
//       if (containerRef.current) {
//         resizeObserver.unobserve(containerRef.current);
//       }
//     };
//   }, []);

//   return (
//     <CarouselWrapper>
//       <StyledSwiper_LargeCard
//         modules={[Navigation]} // Pagination 모듈 제거
//         navigation
//         spaceBetween={30}
//         slidesPerView={showLargeCardNum}
//         slidesPerGroup={showLargeCardNum} // 한 번에 이동할 슬라이드 수
//         loop={true}
//       >
//         {cards.map((card) => (
//           <SwiperSlide key={card.id}>
//             <CardContainer_LargeCard
//               onClick={() => handleCardClick(card.id)}
//               ref={containerRef}
//               height={height}
//             >
//               {card.isNew && <RedBadge_OnItem>NEW</RedBadge_OnItem>}
//               <CardCarouselImage
//                 src={extractImageUrlFromContent(card.description)}
//                 alt={card.title}
//                 style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
//               />
//               <LargeCardTitle>{TruncateText(card.title, 40)}</LargeCardTitle>
//             </CardContainer_LargeCard>
//           </SwiperSlide>
//         ))}
//       </StyledSwiper_LargeCard>
//     </CarouselWrapper>
//   );
// };

// export default LargeCardCarousel;
