import React from 'react';
import styled from 'styled-components';
import { borderRadius, colors, padding, fontSize } from '../styles/commonStyle';
import { media } from '../styles/media';

// Auth(회원가입, 로그인 페이지) 전용
const StyledInput = styled.input`
  width: 95%;
  margin: 5px 0px;
  padding: ${padding.medium};
  border: 1px solid #ccc;
  border-radius: ${borderRadius.small};
  font-size: 16px;
  outline: none; /* 포커스 효과 제거 */
  transition: border-color 0.3s ease; /* 전환 효과 */

  /* 포커스 했을 때 스타일 */
  &:focus {
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px rgba(30, 144, 255, 0.2); /* 포커스 효과 추가 */
  }
  ${media.mobile`
    
    padding:${padding.small} 0 ${padding.small} ${padding.medium};
    font-size: ${fontSize.small};
  `}
`;

const Input = (props) => {
  return <StyledInput {...props} />;
};

export default Input;
