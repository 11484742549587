import styled from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  gray,
  margin,
  objHeight,
} from '../../styles/commonStyle';
import { Swiper, SwiperSlide } from 'swiper/react';
import { media } from '../../styles/media';

// -------------------- 캐러셀 -------------------- //
export const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;
export const StyledSwiper = styled(Swiper)`
  .swiper-button-next,
  .swiper-button-prev {
    color: ${colors.primary}; /* 원하는 색상 */
    width: 25px; /* 화살표 크기 */
    height: 25px; /* 화살표 크기 */
    // top: 60%; /* 화살표를 세로 중앙에 위치 */
    transform: translateY(-50%); /* 정확히 중앙에 배치 */
    background: white;
    padding: 5px;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 18px; /* 화살표 아이콘 크기 */
  }
`;

export const StyledSwiper_LargeCard = styled(StyledSwiper)`
  .swiper-button-next,
  .swiper-button-prev {
    top: 55%; /* 화살표를 세로 중앙에 위치 */
  }
`;
export const StyledSwiper_OneCard = styled(StyledSwiper)`
  .swiper-button-next,
  .swiper-button-prev {
    top: 60%; /* 화살표를 세로 중앙에 위치 */
  }
`;

// -------------------- 카드 컨테이너 -------------------- //

export const CardContainer = styled.div`
  // height: 400px;
  margin: 10px 0;
  position: relative;

  &:hover {
    cursor: pointer;
    transform: translateY(-8px); /* Y축으로 10px 위로 이동 */
  }
  transition: transform 0.3s ease; /* 부드러운 hover 효과를 위한 transition 추가 */
`;
export const CardContainer_OneCard = styled(CardContainer)`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;
export const CardContainer_RoundCard = styled.div`
  width: 70%;
  padding: 10px 30px 0px 10px;
  text-align: center;
  // background-color: red;
  &:hover {
    cursor: pointer;
    transform: translateY(-8px); /* Y축으로 10px 위로 이동 */
  }
  transition: transform 0.3s ease; /* 부드러운 hover 효과를 위한 transition 추가 */
`;

export const CardContainer_LargeCard = styled(CardContainer)`
  height: ${({ height }) => height}px;
  // height: ${objHeight.largeCard};
  //  width: 80%; /* 화면 크기에 따라 동적으로 변화 */
  // min-height: 120%;
  // aspect-ratio: 1:1.4;
  // overflow: hidden; /* aspect-ratio를 벗어난 콘텐츠는 숨기기 */

  padding: 30px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &:hover {
    // border: 1px solid #ddd;
  }

  ${media.tablet`
      //  height: ${objHeight.mobileChill};
      `}
  ${media.mobile`
      //  height: 350px;
      `}
`;

// -------------------- 카드 타이틀 -------------------- //

export const CardTitle = styled.div`
  width: 100%;
  // height: 50px;
  font-size: 1.1rem;
  text-align: justify;

  ${media.mobile`
      font-size: 0.85rem;
      `}
`;

export const LargeCardTitle = styled.div`
  font-size: 1.1rem;
  ${media.mobile`
      font-size: 0.85rem;
      `}
`;

// -------------------- 카드 설명 -------------------- //

export const CardDescription = styled.div`
  font-size: 1em;
  color: #666;
`;

export const CardDescription_SmallRoundCard = styled.div`
  width: 100%;
  font-size: 0.8em;
  color: #666;
  // background-color: red;
`;

// -------------------- 카드 이미지 -------------------- //
export const CardCarouselImage = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1; /* 가로:세로 비율 3:2 설정 */
  object-fit: cover; /* 이미지 비율을 유지하면서 부모 영역을 덮음 */
  border-radius: 8px;
  margin-bottom: ${margin.small};
  // border: 1px solid ${gray.light};
`;

export const CardImage_RoundCard = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 5px;
  // background-color: ${colors.lightGreenBorder};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

  ${media.mobile`
       width: 50px;
       height: 50px;
      `}
`;
export const CardImage_SmallRoundCard = styled.img`
  width: 60px;
  height: 60px;
  ${media.mobile`
       width: 30px;
       height: 30px;
      `}
  border-radius: 20%; /* 이미지를 동그랗게 만듬 */
  object-fit: cover; /* 이미지가 왜곡되지 않도록 */
  // margin-bottom: 3px;
`;
