import { useState } from 'react';
import {
  AddButton,
  InnerBorder,
  InnerCircle,
  Input,
  InputContainer,
  NumberSlice,
  Result,
  RouletteContainer,
  RouletteLeft,
  RoulettePageContainer,
  RouletteRight,
  RouletteWheel,
  Section,
  Slice,
  SpinButton,
  StartButton,
  StartText,
  WheelContainer,
} from '../PresenterPickerStyle';
import {
  PickerModal,
  PickerModalCloseButton,
  PickerModalContent,
} from './PickerModal';
import { OneByOneButton } from '../../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import logo_horizontal from '../../../img/teachernote-logo/teachernote-logo-horizontal.png';
import location from '../../../img/random-number-picker-1roulette/random-number-picker-roulette-pointer.png';
import logo_sound from '../../../sound/teachernote.mp3';
import {
  colors,
  fontSize,
  fontWeight,
  gray,
} from '../../../styles/commonStyle';
import { useMediaQuery } from 'react-responsive';

const Roulette = ({ numberList, setNumberList, pickPresenter }) => {
  const sliceAngle = 360 / numberList.length; // 각 조각의 크기 각도
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const [spinAngle, setSpinAngle] = useState(0); // 초기 회전 각도 설정
  const [hasSpun, setHasSpun] = useState(false); // 사용자 휠 회전 start했는지 여부
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태 추가

  //휠 사이즈
  // 화면 크기에 따른 휠 크기 설정
  const isDesktop = useMediaQuery({ minWidth: 1025 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 480 });

  // 휠 크기를 화면 크기에 따라 설정
  const wheelSize = isDesktop ? 600 : isTablet ? 500 : 300;

  const handleSpin = () => {
    setSpinning(true);
    setHasSpun(true);
    setTimeout(() => {
      const totalSpins = 360 * 4;
      const randomAngle = Math.random() * 360;
      const newSpinAngle = totalSpins + randomAngle;
      setSpinAngle((prevAngle) => prevAngle + newSpinAngle);

      const handleTransitionEnd = () => {
        const finalAngle = (spinAngle + randomAngle) % 360;
        const indexAtPointer =
          Math.floor((360 - finalAngle) / sliceAngle) % numberList.length; // 삼각형이 상단 중앙에 맞는 위치로 변경

        // Math.floor((360 - finalAngle + 90) / sliceAngle) % numberList.length;
        setSelectedNumber(numberList[indexAtPointer]);
        setSpinning(false);
        setIsModalOpen(true);

        document
          .querySelector('.wheel-container')
          .removeEventListener('transitionend', handleTransitionEnd);
      };

      document
        .querySelector('.wheel-container')
        .addEventListener('transitionend', handleTransitionEnd);
    }, 10); // 약간의 지연을 주어 상태 변경 후 회전이 일어나도록 설정
  };

  const sections = numberList.map((number, index) => {
    const sliceAngle = 360 / numberList.length;
    const startAngle = sliceAngle * index - 90;
    const endAngle = startAngle + sliceAngle;
    const radius = 50;
    const x1 = 50 + radius * Math.cos((Math.PI * startAngle) / 180);
    const y1 = 50 + radius * Math.sin((Math.PI * startAngle) / 180);
    const x2 = 50 + radius * Math.cos((Math.PI * endAngle) / 180);
    const y2 = 50 + radius * Math.sin((Math.PI * endAngle) / 180);
    const largeArcFlag = sliceAngle > 180 ? 1 : 0;
    //무지개색
    // const bgColor = `hsl(${(index * 35) % 360}, 90%, 50%)`;

    // 두 가지 색상이 번갈아 나오도록 조건 설정
    // const bgColor = index % 2 === 0 ? '#347928' : '#FCCD2A'; // 예: 빨간색과 파란색

    // 두 가지 색상이 번갈아 나옴+홀수일 때 마지막은 다른색
    // let bgColor;
    // if (numberList.length % 2 === 1 && index === numberList.length - 1) {
    //   bgColor = `${colors.primary}`; // 마지막 칸에만 다른 색상 적용
    // } else {
    //   bgColor =
    //     index % 2 === 0
    //       ? `${colors.lightGreenBackground}`
    //       : `${colors.lightGreen}`; // 기존 색상 유지
    // }

    // 3가지 색상을 번갈아 사용: 초록색, 밝은 초록색, 회색
    let bgColor;
    if ((numberList.length - 1) % 3 === 0) {
      // 2가지 색상을 번갈아 사용
      bgColor =
        index % 2 === 0
          ? `${colors.lightGreenBackground}`
          : `${colors.lightGreen}`;
    } else {
      // 기본 3가지 색상 반복
      if (index % 3 === 0) {
        bgColor = `${colors.lightGreen}`; // 첫 번째 색상
      } else if (index % 3 === 1) {
        bgColor = `${colors.lightGreenBackground}`; // 두 번째 색상
      } else {
        bgColor = `${colors.lightGreenBorder}`; // 세 번째 색상
      }
    }

    let fontSize;
    if (numberList.length > 50) {
      fontSize = 2; // 50 초과일 때 작은 글꼴 크기
    } else if (numberList.length > 30) {
      fontSize = 3; // 30 초과일 때 중간 글꼴 크기
    } else {
      fontSize = 4; // 기본 글꼴 크기
    }
    return (
      <g key={number}>
        <path
          d={`M 50 50 L ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`}
          fill={bgColor}
          // stroke="#ffffff"
          // strokeWidth="0.1"
        />
        <text
          x={
            50 +
            radius *
              0.7 *
              Math.cos((Math.PI * (startAngle + sliceAngle / 2)) / 180)
          }
          y={
            50 +
            radius *
              0.7 *
              Math.sin((Math.PI * (startAngle + sliceAngle / 2)) / 180)
          }
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={fontSize}
          // fontSize="4"
          fontWeight="bold"
          fill="#fff" // 숫자를 검정색으로 설정하여 가독성 향상
          stroke="#4caf50" // 흰색 테두리
          strokeWidth="1" // 테두리 두께 설정
          paintOrder="stroke" // 테두리가 먼저 그려지도록 설정
          transform={`rotate(${startAngle + sliceAngle / 2 + 90}, ${
            50 +
            radius *
              0.7 *
              Math.cos((Math.PI * (startAngle + sliceAngle / 2)) / 180)
          }, ${
            50 +
            radius *
              0.7 *
              Math.sin((Math.PI * (startAngle + sliceAngle / 2)) / 180)
          })`} // 각도 90도 회전 추가
        >
          {number}
        </text>
      </g>
    );
  });

  const closeModal = () => {
    setIsModalOpen(false); // 모달 닫기
  };

  return (
    <RoulettePageContainer>
      <RouletteLeft style={{ position: 'relative', display: 'inline-block' }}>
        <WheelContainer
          className="wheel-container"
          spinning={spinning}
          isModalOpen={isModalOpen}
          hasSpun={hasSpun}
          wheelSize={wheelSize}
          style={{
            transform: `rotate(${spinAngle}deg)`,
            transition: spinning
              ? 'transform 5s cubic-bezier(0.1, 0.7, 0.1, 1)' // 느려지는 효과를 더 강하게 조정            : 'none',
              : 'none',
          }}
        >
          <InnerBorder />
          <svg width={wheelSize} height={wheelSize} viewBox="0 0 100 100">
            {/* <svg width="700" height="700" viewBox="0 0 100 100"> */}
            {sections}
          </svg>
          <InnerCircle onClick={handleSpin} disabled={spinning} />
        </WheelContainer>
        <StartButton
          onClick={handleSpin}
          // style={{
          //   position: 'absolute',
          //   top: '50%',
          //   left: '50%',
          //   transform: 'translate(-50%, -50%)',
          //   color: `${colors.primary}`,
          //   fontSize: `${fontSize.extraLarge}`,
          //   fontWeight: 'bold',
          //   pointerEvents: 'none', // 클릭 이벤트가 InnerCircle로 전달되도록 설정
          //   textAlign: 'center',
          // }}
          // className="start-button"
        >
          START
          {/* <StartText>START</StartText> */}
        </StartButton>
        {/* 고정된 포인터 삼각형 */}
        {/* <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '98%',
            transform: 'translate(-50%, -50%) rotate(-90deg)',
            width: 0,
            height: 0,
            borderLeft: '20px solid transparent',
            borderRight: '20px solid transparent',
            borderBottom: '90px solid ', // 삼각형 색상
            borderRadius: '5px', // 모서리 둥글게
          }}
        /> */}
        {/* <div
          style={{
            position: 'absolute',
            top: '0%', // 상단에 위치시킴
            left: '50%', // 가로 중앙에 위치시킴
            transform: 'translate(-50%, -50%) rotate(-180deg)', // 회전하지 않고 그대로 표시
            width: 0,
            height: 0,
            borderLeft: '20px solid transparent',
            borderRight: '20px solid transparent',
            borderBottom: '80px solid ', // 삼각형 색상
            borderRadius: '5px', // 모서리 둥글게
          }}
        /> */}
        <div
          style={{
            position: 'absolute',
            top: '1%', // 상단에 위치시킴
            left: '50%', // 가로 중앙에 위치시킴
            transform: 'translate(-50%, -50%)', // 위치 조정
          }}
        >
          <img
            src={location} // location 이미지 사용
            alt="랜덤 숫자 뽑기 룰렛 포인터"
            style={{
              width: '110px',
              height: '110px',
              filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.4))', // 이미지 형태 그대로 그림자 추가
            }}
          />
        </div>
      </RouletteLeft>{' '}
      {/* <RouletteRight>ㅋ컬러</RouletteRight> */}
      {isModalOpen && (
        <PickerModal
          onClick={(e) => e.target === e.currentTarget && closeModal()}
        >
          <PickerModalContent>
            <img
              src={logo_horizontal}
              width="100px"
              style={{ opacity: 0.5 }}
              alt="티처노트 가로형 로고"
            />
            <h1>{selectedNumber}번 당첨!🎉</h1>
            <OneByOneButton onClick={closeModal}>닫기</OneByOneButton>
          </PickerModalContent>
        </PickerModal>
      )}
    </RoulettePageContainer>
  );
};

export default Roulette;
