import { useContext, useEffect, useState } from 'react';
import axios from '../../../../node_modules/axios/index';
import Notification from '../../../components/Notification';
import UserContext from '../../../contexts/userContext';
import inputName from '../../../img/random-seat-assignment/random-seat-assignment-description.png';
import {
  SeatDrawPageContentBox_left,
  SeatDrawPageContentBox_right,
  SeatDrawPageContentBox_title,
} from '../../SeatDrawPageStyle';
import {
  SelectFrontNumContainer,
  StudentSettingTextarea,
  StudentSettingTitle,
} from './StudentSettingStyle';
import {
  DisplayRow,
  DisplayRowSpaceBetween,
  NewBadge,
} from '../../../styles/BasicStyle';
import { gap } from '../../../styles/commonStyle';
const InputStudentName = ({
  students,
  setStudents,
  setNumberList,
  numberList,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState.user.userId;
  const [textareaValue, setTextareaValue] = useState(''); // textarea 값을 관리
  const [showNotification, setShowNotification] = useState(false);

  // 학생 목록을 업데이트하는 함수 (붙여넣기와 입력 수정 모두에서 호출)
  const updateStudentList = (text) => {
    // 줄바꿈(\n 또는 \r)과 탭(\t), 공백(\\s+)을 기준으로 데이터를 분리
    const lines = text.split(/\n|\r|\t|\s+/).filter(Boolean); // 공백, 탭, 줄바꿈을 기준으로 분리

    const parsedStudents = [];

    // 두 개씩 묶어서 번호와 이름을 하나의 row로 처리
    for (let i = 0; i < lines.length; i += 2) {
      const number = lines[i] ? lines[i].trim() : '';
      const name = lines[i + 1] ? lines[i + 1].trim() : '';

      // 번호가 숫자인지 확인
      if (!/^\d+$/.test(number)) {
        alert(`번호는 숫자여야 합니다: ${number}`);
        setTextareaValue(''); // textarea 값 비우기
        setStudents([]); // 학생 목록 비우기
        return;
      }

      // 공백이 있으면 alert을 띄우고 textarea 비우기
      if (!name) {
        alert('번호와 이름에 공백이 있으면 안 됩니다.');
        setTextareaValue(''); // textarea 값 비우기
        setStudents([]); // 학생 목록 비우기
        return;
      }

      // 유효한 데이터만 저장, number를 숫자 형식으로 변환하여 저장
      parsedStudents.push({ number: parseInt(number, 10), name });
    }

    setStudents(parsedStudents);

    if (parsedStudents.length === 0) {
      const defaultNumberList = Array.from({ length: 30 }, (_, i) =>
        (i + 1).toString(),
      );
      setNumberList(defaultNumberList);
    } else {
      const copiedNumberList = parsedStudents.map((student) => student.number);
      setNumberList(copiedNumberList);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setTextareaValue(value); // 사용자가 입력한 내용을 textareaValue 상태로 저장
    updateStudentList(value); // 사용자가 입력한 값으로 학생 목록 업데이트
  };

  const handlePaste = (event) => {
    event.preventDefault(); // onChange 이벤트가 중복으로 호출되지 않도록 방지
    const pastedData = event.clipboardData.getData('Text');
    setTextareaValue(pastedData); // textarea에 붙여넣은 값 저장
    updateStudentList(pastedData); // 붙여넣은 값으로 학생 목록 업데이트
  };

  return (
    <>
      <div>
        <Notification
          message="변경사항 저장 완료"
          isVisible={showNotification}
        />
        <SelectFrontNumContainer>
          <StudentSettingTitle>
            학생 이름 일괄 입력
            <NewBadge style={{ marginLeft: `${gap.extraSmall}` }}>NEW</NewBadge>
          </StudentSettingTitle>
          <DisplayRowSpaceBetween>
            <SeatDrawPageContentBox_left>
              {' '}
              <img
                src={inputName}
                style={{ width: '200px', height: 'auto' }}
                alt="교실 학급 랜덤 무작위 자리뽑기 설명4"
              />
            </SeatDrawPageContentBox_left>
            <SeatDrawPageContentBox_right>
              {' '}
              <StudentSettingTextarea
                onPaste={handlePaste}
                onChange={handleChange} // 사용자가 직접 입력할 때 값이 업데이트되도록 이벤트 핸들러 추가
                value={textareaValue}
                placeholder="엑셀 또는 한글에서 표를 복사하여 붙여넣으세요"
                rows="10"
                cols="50"
              ></StudentSettingTextarea>
              {/* <ul>
              {students.map((student, index) => (
                <li key={index}>
                  {student.number}번 {student.name}
                </li>
              ))}
            </ul>
            <button onClick={handleSave}>저장하기</button> */}
            </SeatDrawPageContentBox_right>
          </DisplayRowSpaceBetween>
        </SelectFrontNumContainer>
      </div>
    </>
  );
};

export default InputStudentName;
