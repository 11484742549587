import styled from 'styled-components';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gap,
  gray,
  objHeight,
  objWidth,
  padding,
} from '../../styles/commonStyle';

export const VideoDetailPageBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gap.medium};
`;
export const VideoMainContent = styled.div`
  display: flex;
  gap: ${gap.medium};
`;

export const VideoContentBox = styled.div``;
export const VideoContentInfoBox = styled.div`
  padding: ${padding.mediumLarge};
  display: flex;
  flex-direction: column;
  gap: ${padding.medium};

  border: 1px solid ${gray.medium};
  border-radius: ${borderRadius.medium};
`;

export const VideoContentTitle = styled.div`
  font-size: ${fontSize.extraLarge};
`;

export const VideoContentTagBox = styled.div`
  display: flex;
  gap: ${padding.medium};
`;

export const VideoContentTag = styled.div`
  padding: 0 ${padding.small};
  font-size: ${fontSize.small};
  //   color: ${colors.background};
  //   background-color: green;
  color: green;
  border: 1px solid green;
  border-radius: ${borderRadius.large};
`;

export const VideoContentRatingBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RatingItem = styled.div`
  display: flex;
  gap: ${padding.medium};
  font-size: ${fontSize.medium};
`;
export const RatingSubject = styled.div`
  //   width: ${objWidth.mediumLarge};
  color: ${colors.lightGreen};
  font-weight: ${fontWeight.bold};
`;
export const RatingTitle = styled.div``;
export const RatingStars = styled.div`
  display: flex;
`;

export const TabContainer = styled.div`
  width: 100%;
  //   max-width: 600px;
  margin: 0 auto;
`;

export const TabMenu = styled.div`
  display: flex;
  border-bottom: 2px solid #ddd;
`;

export const TabButton = styled.button`
  flex: 1;
  padding: 10px 20px;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  color: #555;
  border-bottom: 3px solid transparent;
  transition: all 0.3s ease;

  ${({ active }) =>
    active &&
    `
      font-weight: bold;
      color: #000;
      border-bottom: 3px solid #000;
    `}

  &:hover {
    color: #000;
  }
`;

export const TabContents = styled.div`
  padding: 20px;
  //   border: 1px solid #ddd;
  border-top: none;
  display: flex;
  flex-direction: column;
  gap: ${padding.large};
`;

export const TabTitle = styled.div`
  font-size: ${fontSize.extraLarge};
  font-weight: ${fontWeight.bold};
  //   color: ${colors.primary};
`;

export const TabBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${padding.small};
`;
export const VideoDetailBox = styled.div`
  display: flex;
  gap: ${padding.medium};
  align-items: center;
`;
export const VideoDetailSubject = styled.div`
  padding: ${padding.small} ${padding.medium};
  background-color: ${colors.lightGreenBackground};
  border-radius: ${borderRadius.medium};
`;
export const VideoDetailContent = styled.div``;
