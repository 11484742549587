import ClassInfoInput from './ClassInfoInput';
import ClassInfoList from './ClassInfoList';
import { useState, useEffect, useContext, useCallback } from 'react';
import axios from '../../../node_modules/axios/index';
import UserContext from '../../contexts/userContext';
import GradeContext from '../../contexts/grade';
import PageContainer from '../../pageLayout/PageContainer';
import { PageBox, PageSubheader } from '../../pageLayout/PageContainerStyle';
import { useMediaQuery } from 'react-responsive';
import MobileTopMenuBarArticle from '../../topMenuBarMobile/MobileTopMenuBarArticle';
import ClassInfoMenuBar from '../../topSubMenuBar/ClassInfoMenuBar';
import Notification from '../../components/Notification';
import MobileTopMenuBarClassinfo from '../../topMenuBarMobile/MobileTopMenuBarClassinfo';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import {
  DisplayColumn,
  DisplayRowSpaceBetween,
  TitleContainer,
  TitleDetail,
  TitleText,
} from '../../styles/BasicStyle';
import { OneByOneButton } from '../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import RegisterModalComponent from '../registerModal/RegisterModalComponent';
import LoginModalComponent from '../loginModal/LoginModalComponent';
import MobileTopMenuBar from '../../topMenuBarMobile/MobileTopMenuBar';
import { ClassSettingHelmetContent } from '../../components/HelmetContent';

const ClassInfoPage = () => {
  //현재 학년, user context 받아오기
  const { state: gradeState, actions: gradeActions } = useContext(GradeContext);
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const grade = gradeState.currentGrade;
  const [classInfo, setClassInfo] = useState({
    grade: '',
    class: '',
  });
  const [classInfoDB, setClassInfoDB] = useState([]);

  const [gradeError, setGradeError] = useState(false);
  const [classError, setClassError] = useState(false);
  const [inputError, setInputError] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  //+버튼 연속적으로 눌렀을 때 함수 중복 실행 막음
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();

  const fetchAllClassInfo = useCallback(async () => {
    if (!userId) {
      setClassInfoDB([
        { id: 1698, userId: 'teachernote', grade: '1', class: 1 },
        { id: 1704, userId: 'teachernote', grade: '1', class: 2 },
        { id: 1703, userId: 'teachernote', grade: '1', class: 3 },
        { id: 1705, userId: 'teachernote', grade: '1', class: 4 },
        { id: 1699, userId: 'teachernote', grade: '1', class: 5 },
        { id: 1706, userId: 'teachernote', grade: '2', class: 1 },
        { id: 1700, userId: 'teachernote', grade: '2', class: 2 },
        { id: 1701, userId: 'teachernote', grade: '2', class: 3 },
        { id: 1702, userId: 'teachernote', grade: '2', class: 4 },
        { id: 1712, userId: 'teachernote', grade: '2', class: 5 },
        { id: 1707, userId: 'teachernote', grade: '3', class: 1 },
        { id: 1710, userId: 'teachernote', grade: '3', class: 2 },
        { id: 1708, userId: 'teachernote', grade: '3', class: 3 },
        { id: 1711, userId: 'teachernote', grade: '3', class: 4 },
        { id: 1709, userId: 'teachernote', grade: '3', class: 5 },
      ]);
    } else {
      try {
        const res = await axios.get(
          'https://teachernote.me/api/classinfo/' + userId,
        );
        const sortedData = res.data.sort((a, b) => {
          if (a.grade === b.grade) {
            return a.class - b.class;
          }
          return a.grade - b.grade;
        });
        setClassInfoDB(sortedData);
      } catch (err) {
        console.log(err);
      }
    }
  }, [userId]);

  useEffect(() => {
    fetchAllClassInfo();
  }, [userId]);

  //classInfoInput 값 검증,  classInfo 저장
  const handleChange = (e) => {
    const { name, value } = e.target;
    setClassInfo((prev) => ({ ...prev, [name]: value }));
    setInputError('');
  };

  const handleGradeChange = (e) => {
    const grade = parseInt(e.target.value);
    if (grade < 1 || grade > 3) {
      setGradeError(true);
    } else {
      setGradeError(false);
    }
    handleChange(e);
  };

  const handleClassChange = (e) => {
    const classNumber = parseInt(e.target.value);
    if (classNumber < 1 || classNumber > 15) {
      setClassError(true);
    } else {
      setClassError(false);
    }
    handleChange(e);
  };

  //학급 정보 중복 확인 값 true or false로 반환
  const doubleCheck = (grade, clazz) => {
    const filteredItems = classInfoDB.filter(
      (item) => item.grade == grade && item.class == clazz,
    );
    if (filteredItems.length == 0) {
      return true;
    } else {
      return false;
    }
  };
  const findDoubleCheck = doubleCheck(classInfo.grade, classInfo.class);

  // 플러스 버튼 눌러서 학급 추가
  const addNextClass = (grade) => {
    // grade가 1인 항목들을 필터링하여 새로운 배열로 반환
    if (saving) {
      // console.log('saving');
      return;
    }
    setSaving(true);

    const gradeItems = classInfoDB.filter((item) => item.grade == grade);
    // grade가 1인 항목들 중에서 가장 큰 class 값 찾기
    let maxClass = 0;
    gradeItems.forEach((item) => {
      if (item.class > maxClass) {
        maxClass = item.class;
      }
    });

    if (maxClass + 1 < 16) {
      const newClassInfo = { grade: grade, class: maxClass + 1 };
      saveClassInfo(newClassInfo);
    } else {
      // alert('학급은 15반까지 추가 가능합니다');
      setSaving(false);
    }
  };

  //classInfoInput에서 classInfo 저장
  const saveClassInfoFromInput = (e) => {
    e.preventDefault();
    setInputError(''); // 기존 오류 메시지 초기화
    if (!classInfo.grade || !classInfo.class) {
      setInputError('학년과 학급을 모두 입력해주세요.');
      return;
    }
    if (!gradeError && !classError) {
      saveClassInfo(classInfo);
      setClassInfo({ grade: '', class: '' }); // 입력 필드 초기화
    }
  };

  //학급 정보 저장
  const saveClassInfo = async (classInfo) => {
    // e.preventDefault();
    if (!userId) {
      alert('로그인 후 이용가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      // 처음 생성되는 학년인지 확인
      const isExistGrade = classInfoDB.find(
        (item) => item.grade === String(classInfo.grade),
      );
      // 현재 해당 학급이 존재하지 않는다면 새로 추가
      if (findDoubleCheck) {
        try {
          await axios
            .post('https://teachernote.me/api/classinfo/' + userId, classInfo)
            .then(() => {
              fetchAllClassInfo();
            })
            .then(setSaving(false));
          //처음 생성되는 학년일 경우 토픽 1~16 생성
          if (!isExistGrade) {
            // console.log('토픽 생성');
            try {
              await axios
                .post('https://teachernote.me/api/defaulttopics/' + userId, {
                  grade: classInfo.grade,
                })
                .then(gradeActions.fetchGradeInfo())
                .then(setSaving(false));
            } catch {}
          }
        } catch {}
        setShowNotification(true); // 알림 상태를 true로 변경
        setTimeout(() => {
          setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
        }, 1000);
      } else {
        setSaving(false);
      }
    }
  };

  //학급 정보 삭제
  const handleDelete = async (classInfoId, grade, classNum) => {
    if (!userId) {
      alert('로그인 후 이용가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      const result = window.confirm(
        '학급 정보를 삭제하시면 해당 학급에 대한 진도 기록 및 수업 노트가 삭제됩니다. 학급 정보를 삭제하시겠습니까?',
      );

      if (result) {
        try {
          await axios
            .delete('https://teachernote.me/api/classinfo/' + userId, {
              data: { classInfoId, grade: parseInt(grade), classNum },
            })
            .then(() => {
              fetchAllClassInfo();
              setShowNotification(true); // 알림 상태를 true로 변경
              setTimeout(() => {
                setShowNotification(false); // 3초 후에 알림 상태를 false로 변경하여 알림 사라지게 함
              }, 1000);
            });
        } catch (err) {
          console.log(err);
        }
      } else {
      }
    }
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  //--------------------- 로그인/회원가입 --------------------//
  //회원가입 모달
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('bottombutton'); //menubar,bottombutton

  const handleAuthComplete = async (userId) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  const openRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const openLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const closeAuthModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };
  return (
    <>
      <ClassSettingHelmetContent />{' '}
      <RegisterModalComponent
        isRegisterModalOpen={isRegisterModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        openRegisterModal={openRegisterModal}
        openLoginModal={openLoginModal}
        closeModal={closeAuthModal}
        handleAuthComplete={handleAuthComplete}
        modalSource={modalSource}
      />
      {isLoginModalOpen ? (
        <LoginModalComponent
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          openRegisterModal={openRegisterModal}
          openLoginModal={openLoginModal}
          closeAuthModal={closeAuthModal}
          handleAuthComplete={handleAuthComplete}
          modalSource={modalSource}
        />
      ) : (
        <></>
      )}
      <PageContainer>
        <PageSubheader>
          <ClassInfoMenuBar />
        </PageSubheader>
        {/* {isMobile && <MobileTopMenuBarClassinfo />} */}
        {isMobile && <ClassInfoMenuBar />}

        <PageBox>
          <Notification
            message="변경사항 저장 완료"
            isVisible={showNotification}
          />
          <TitleContainer>
            <DisplayRowSpaceBetween>
              <DisplayColumn style={{ width: 'auto' }}>
                {' '}
                <TitleText>담당 학급 관리</TitleText>{' '}
                <TitleDetail>
                  진도표에서 사용할 학년별 담당 학급을 관리할 수 있습니다.
                </TitleDetail>
              </DisplayColumn>

              {!isMobile && (
                <div>
                  <OneByOneButton
                    onClick={() => {
                      navigate('/lesson-progress-tracker');
                    }}
                  >
                    진도표 바로가기{' '}
                  </OneByOneButton>
                </div>
              )}
            </DisplayRowSpaceBetween>
          </TitleContainer>
          <ClassInfoInput
            handleGradeChange={handleGradeChange}
            handleClassChange={handleClassChange}
            gradeError={gradeError}
            classError={classError}
            saveClassInfoFromInput={saveClassInfoFromInput}
            findDoubleCheck={findDoubleCheck}
            classInfo={classInfo}
            inputError={inputError}
          />
          <ClassInfoList
            classInfos={classInfoDB}
            onRemove={handleDelete}
            addNextClass={addNextClass}
            setShowNotification={setShowNotification}
            fetchAllClassInfo={fetchAllClassInfo}
            setRegisterModalOpen={setRegisterModalOpen}
          />
        </PageBox>
      </PageContainer>
    </>
  );
};

export default ClassInfoPage;
