import {
  AuthCardContainer,
  AuthCardTitle,
} from '../../components/AuthCardContainer';
import { AuthStyledDiv, AuthStyledLink } from '../../components/AuthPageStyle';
import KakaoLoginButton from '../../components/KakaoLoginButton';
import LinkTo from '../../components/LinkTo';
import MainButton from '../../components/MainButton';
import Input from '../../components/StyledInput';
import { AuthBox, AuthLayout1 } from '../register2/Register2Style';
import logo_horizontal from '../../img/teachernote-logo/teachernote-logo-horizontal.png';
import { AuthCardContainer_Mobile } from '../registerModal/Register2Style';

const LoginModal_Mobile = ({
  handleChange,
  handleLogin,
  openRegisterModal,
  loginInfo,
  rememberMe,
  handleRememberMeChange,
  handleAuthComplete,
}) => {
  return (
    <AuthBox>
      <AuthCardContainer_Mobile>
        <AuthLayout1>
          <div>
            <img
              src={logo_horizontal}
              style={{ width: '150px' }}
              alt="티처노트 로고 - 가로형"
            />
          </div>
          {/* 선생님이 편해지는 곳, <br /> */}
          티처노트에 오신 것을 환영합니다!
        </AuthLayout1>
        <form className="form" onSubmit={handleLogin}>
          <div>
            <Input
              type="id"
              placeholder="아이디"
              onChange={handleChange}
              name="userId"
              value={loginInfo.userId} // 입력 필드의 초기 값으로 loginInfo.userId 설정
            />
          </div>

          <div>
            <Input
              type="password"
              placeholder="비밀번호"
              onChange={handleChange}
              name="password"
              value={loginInfo.password} // 입력 필드의 초기 값으로 loginInfo.password 설정
            />
          </div>

          <div style={{ margin: '5px 0' }}>
            <label style={{ cursor: 'pointer' }}>
              <input
                type="checkbox"
                checked={rememberMe} // 체크박스의 상태를 rememberMe로 설정
                onChange={handleRememberMeChange} // 체크박스 상태 변경 시 호출
                style={{ cursor: 'pointer' }}
              />
              로그인 정보 저장
            </label>
          </div>

          <MainButton type="submit">로그인</MainButton>
          {/* <KakaoLoginButton handleAuthComplete={handleAuthComplete} /> */}
          <LinkTo>
            <AuthStyledDiv
              onClick={() => {
                openRegisterModal();
              }}
            >
              아직 아이디가 없으신가요?
            </AuthStyledDiv>
          </LinkTo>
        </form>
      </AuthCardContainer_Mobile>
    </AuthBox>
  );
};

export default LoginModal_Mobile;
