import styled, { keyframes, css } from 'styled-components';
import { colors, gray, margin, padding } from '../../../styles/commonStyle';
import { StartDrawButton } from '../../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import { media } from '../../../styles/media';

// 공이 들어가는 기계 컨테이너
export const MachineContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  justify-content: center;

  //   background-color: red;
  /* 아주 연한 노랑색 배경 */
  background-color: #fff9e6;

  /* 하얀 도트무늬 패턴 */
  background-image: radial-gradient(transparent 10px, white 1px);
  background-size: 20px 20px; /* 도트 간격 조정 */
`;

export const DrawMachine = styled.img`
  // width: 100%;
  // height: auto;
  height: 750px; //머신 높이
  ${media.tablet`
    height: 700px;`}
  position: absolute;
  pointer-events: none;
  z-index: 1;
`;

export const BallContainer = styled.div`
  margin-top: 140px;
  width: 420px;
  height: 300px;
  ${media.tablet`
    margin-top: 110px;
    width: 400px;
    height: 300px;
    `}
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  // background-color: yellow;
`;

export const JellyStartButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const JellyStartButton = styled(StartDrawButton)`
  padding: ${padding.medium} ${padding.large};
  margin-top: 70px;
  font-size: 1.5rem;
  position: absolute;
  z-index: 2;

  &:hover {
    animation: none;
  }
  ${media.tablet`
   padding: ${padding.small} ${padding.large};
   font-size: 1.5rem;

  `}
`;

export const ModalJellyAndBallDiv = styled.div`
  margin-bottom: ${margin.mediumlarge};
`;
// 랜덤 값 생성 함수
const getRandomValue = (min, max) => Math.random() * (max - min) + min;

// 공들이 움직이도록 설정하는 bounce 및 rotate 애니메이션
const bounce = (bounceHeight, rotateAngle) => keyframes`
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-${bounceHeight}px) rotate(${rotateAngle}deg); /* Y축 및 회전 애니메이션 추가 */
  }
`;

export const Ball = styled.img`
  position: absolute;
  width: 75px;
  height: 75px;
  transform: translateZ(0); /* GPU 가속을 사용하여 성능 향상 */

  animation: ${({ duration, bounceHeight, rotateAngle }) =>
    css`
      ${bounce(bounceHeight, rotateAngle)} ${duration}s infinite ease-in-out
    `};
`;

// 공들이 움직이도록 설정하는 bounce 애니메이션 (랜덤 값 없이)

// const bounce = keyframes`
//   0%, 100% {
//     transform: translateY(0);
//   }
//   50% {
//     transform: translateY(+10px); /* 기본 Y축 움직임 */
//   }
// `;

// export const Ball = styled.img`
//   position: absolute;
//   width: 75px;
//   height: 75px;
//   transform: translateZ(0);

//   animation: ${bounce} 2s infinite ease-in-out; /* 살짝 움직이는 애니메이션 추가 */
// `;

// 각 공의 위치와 움직임을 랜덤하게 설정
export const BallRed = styled(Ball)`
  top: ${() => getRandomValue(100, 200)}px;
  left: ${() => getRandomValue(50, 200)}px;
`;

export const BallYellow = styled(Ball)`
  top: ${() => getRandomValue(100, 200)}px;
  left: ${() => getRandomValue(50, 200)}px;
`;

export const BallGreen = styled(Ball)`
  top: ${() => getRandomValue(100, 200)}px;
  left: ${() => getRandomValue(50, 200)}px;
`;

export const BallBlue = styled(Ball)`
  top: ${() => getRandomValue(100, 200)}px;
  left: ${() => getRandomValue(50, 200)}px;
`;

export const BallViolet = styled(Ball)`
  top: ${() => getRandomValue(100, 200)}px;
  left: ${() => getRandomValue(50, 200)}px;
`;

export const BallGray = styled(Ball)`
  top: ${() => getRandomValue(100, 200)}px;
  left: ${() => getRandomValue(50, 200)}px;
`;

export const BallPink = styled(Ball)`
  top: ${() => getRandomValue(100, 200)}px;
  left: ${() => getRandomValue(50, 200)}px;
`;
