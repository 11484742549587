import './App.css';
import { Routes, Route } from 'react-router-dom';
import TopicPage from './pages/topic/TopicPage';
import ClassInfoPage from './pages/classInfo/ClassInfoPage';
import GradeContext, { GradeProvider } from './contexts/grade';
import { useEffect, useState } from 'react';
import UserContext, { UserProvider } from './contexts/userContext';
import ProgressPage from './pages/progress/ProgressPage';
import ByClassProgressNotePage from './pages/progressnoteByClass/ByClassProgressNotePage';
import ByTopicProgressNotePage from './pages/progressnoteByTopic/ByTopicProgressNotePage';
import DateSelector from './commonFunction/DateSelector';
import { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';

import { HelmetContent } from './components/HelmetContent';
import BlogWritePage from './pages/blogWrite/BlogWritePage';
import BlogViewPage from './pages/blogView/BlogViewPage';
import BlogGridPage from './pages/blogGrid/BlogGridPage';
import BlogListPage from './pages/blogList/BlogListPage';
import Register2Page from './pages/register2/Register2Page';
import AuthPage from './pages/register2/Register2Page';
import Login2Page from './pages/login2/Login2Page';
import GlobalStyle from './styles/GlobalStyle';
import SeatDraw1 from './seatdrawpages/seatDrawTemp/SeatDraw1';
import SeatDraw3 from './seatdrawpages/seatDrawTemp/SeatDraw3';
import SeatDraw2 from './seatdrawpages/seatDrawTemp/SeatDraw2';
import SeatBeforeSettingPage from './seatdrawpages/1seatBeforePage/SeatBeforeSettingPage';
import PrivacyPolicy from './pages/siteRules/PrivacyPolicy';
import OneByOnePage from './seatdrawpages/3drawOneByOnePage/OneByOnePage';
import SeatDrawPage1 from './seatdrawpages/2seatDrawPage1/SeatDrawPage1';
import DrawSettingPage from './seatdrawpages/1drawSettingPage/DrawSettingPage';
import UserCommentPage from './pages/onePage/UserCommentPage';
import InstagramPage from './pages/onePage/InstagramPage';
import Login2ConfirmPage from './pages/login2-confirm/Login2ConfirmPage';
import UseOnDesktopPage from './pages/onePage/UseOnDesktopPage';
import NotFoundPage from './pages/onePage/NotFoundPage';
import ErrorBoundary from './pageLayout/ErrorBoundary';
import VideoDetailPage from './sharevideopages/detailPage/VideoDetailPage';
import MainPage from './pages/main/MainPage';
import InputStudentInfoPage from './studentInfoPages/studentInfoPage/InputStudentInfoPage';
import Teachernote from './pages/introduce/IntroducePage';
import { LocationProvider } from './contexts/LocationContext';
import { WeatherProvider } from './contexts/WeatherContext';
import PresenterPickerPage from './randomDrawPages/pickPresenter/PresenterPickerPage';
import UpLoadTopCarousel from './pages/main/upLoadTopCarousel';
import MobileMenuPage from './pages/mobilePage/mobileMenu/MobileMenuPage';
import MobileUserSettingPage from './pages/mobilePage/mobileMyPage/MobileUserSettingPage';
import MobileNumberPickerPage from './pages/mobilePage/mobilePickerPages/MobileRandomNumberPickerPage';
import MobileRandomSeatPickerPage from './pages/mobilePage/mobilePickerPages/MobileSeatPickerPage';
import MobileRegisterPage from './pages/mobilePage/mobileRegister/MobileRegisterPage';
import MobileLogin from './pages/mobilePage/mobileLogin/MobileLogin';
import MobileLoginPage from './pages/mobilePage/mobileLogin/MobileLoginPage';
import MobileTalkBlogListPage from './pages/blogList/MobileTalkBlogListPage';
import TermsOfService from './pages/siteRules/TermsOfService';

registerLocale('ko', ko);

const App = () => {
  return (
    <>
      <GlobalStyle />
      <LocationProvider>
        <WeatherProvider>
          <UserProvider>
            <GradeProvider>
              {/* <ErrorBoundary> */}
              <Routes>
                <Route path="/" element={<MainPage />} />
                {/* 진도표 */}
                <Route
                  path="/lesson-progress-tracker"
                  element={<ProgressPage />}
                />
                <Route path="/수업-진도표" element={<ProgressPage />} />
                <Route path="/class-management" element={<ClassInfoPage />} />
                <Route path="/topic-management" element={<TopicPage />} />
                <Route
                  path="/progress-notes/by-class/:paramClassNum"
                  element={<ByClassProgressNotePage />}
                />
                <Route
                  path="/progress-notes/by-class"
                  element={<ByClassProgressNotePage />}
                />
                {/* 커뮤니티 */}
                <Route
                  path="/education-resources-sharing"
                  element={<BlogListPage />}
                />
                <Route
                  path="/post-education-resource"
                  element={<BlogWritePage />}
                />
                <Route
                  path="/education-resources-sharing/:paramCategory"
                  element={<BlogListPage />}
                />
                <Route
                  path="/education-resources-sharing/:paramCategory/:paramBlogNum"
                  element={<BlogViewPage />}
                />
                <Route
                  path="/m-talk-education-sharing"
                  element={<MobileTalkBlogListPage />}
                />{' '}
                <Route path="/교육자료-공유" element={<BlogListPage />} />
                <Route
                  path="/교육자료-공유/:paramCategory"
                  element={<BlogListPage />}
                />
                <Route
                  path="/모바일-교육자료-공유"
                  element={<MobileTalkBlogListPage />}
                />
                {/*자리 뽑기 */}
                <Route
                  path="/random-seat-assignment"
                  element={<OneByOnePage />}
                />
                <Route
                  path="/random-seat-assignment-setup"
                  element={<DrawSettingPage />}
                />
                <Route
                  path="/m-random-seat-assignment"
                  element={<MobileRandomSeatPickerPage />}
                />
                <Route path="/랜덤-자리뽑기" element={<OneByOnePage />} />
                <Route
                  path="/랜덤-자리뽑기-설정"
                  element={<DrawSettingPage />}
                />
                {/* 랜덤 숫자 뽑기 */}
                <Route
                  path="/random-number-picker"
                  element={<PresenterPickerPage />}
                />
                <Route
                  path="/m-random-number-picker"
                  element={<MobileNumberPickerPage />}
                />
                <Route
                  path="/랜덤-숫자뽑기"
                  element={<PresenterPickerPage />}
                />
                {/* 마이 페이지 */}
                <Route path="/m-my-page" element={<MobileUserSettingPage />} />
                {/* 회원가입,로그인 */}
                {/* <Route path="/register" element={<Register2Page />} /> */}
                <Route path="/sign-up" element={<Register2Page />} />
                <Route path="/login" element={<Login2Page />} />
                <Route path="/login-confirm" element={<Login2ConfirmPage />} />
                <Route path="/m-sign-up" element={<MobileRegisterPage />} />
                <Route path="/m-login" element={<MobileLoginPage />} />
                {/* 기타 */}
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/feedback" element={<UserCommentPage />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/404" element={<NotFoundPage />} />
                {/* <Route
                  path="/download-desktop-guide"
                  element={<UseOnDesktopPage />}
                />{' '} */}
                {/* 사용하지 않는 페이지  */}
                {/* 
                <Route
                  path="/progress-notes/by-topic/:paramTopicNum"
                  element={<ByTopicProgressNotePage />}
                />
                <Route path="/videodetail" element={<VideoDetailPage />} />
                <Route path="/loveteachernote" element={<Teachernote />} />
                <Route
                  path="/inputStudentInfo"
                  element={<InputStudentInfoPage />}
                />
             
                <Route path="/useOnDesktop" element={<UseOnDesktopPage />} /> */}
                {/* <Route
                  path="/uploadTopCarousel"
                  element={<UpLoadTopCarousel />}
                /> */}
              </Routes>{' '}
              {/* </ErrorBoundary> */}
            </GradeProvider>
          </UserProvider>{' '}
        </WeatherProvider>
      </LocationProvider>
    </>
  );
};
export default App;
