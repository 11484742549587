// -------------------- 공간 -------------------- //

export const pageWidth = {
  desktop: '1000px',
  tablet: '700px',
  mobile: '350px',

  draw: '1200px',
  // tablet: '991px',
};

// 자리세팅페이지 페이지 구분 포인트
export const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1400px',
};

export const blogListLeftWidth = {
  desktop: '700px',
  tablet: '500px',
  mobile: '240px',
};
export const height = {
  header: '66px',
  headerTablet: '42px',
  headerMobile: '30px',
  subheaderLarge: '100px',

  noteSubheader: '90px',
  progressnotePageGap: '45px', //noteSubheader-header + 10px
  footer: '200px',
};

export const objWidth = {
  linkSmall: '20px', //StyledLink
  extraSmall: '30px', //SelectClassBox
  small: '40px', //SelectClassBox
  smallMedium: '50px',
  medium: '65px', //ProgressNoteByClassTopic, ProgressNoteClassRecord
  mediumLarge: '120px', //AddNote
  large: '200px', //TopicCellTitle
  extraLarge: '400px', //CardContainerBox

  seat: '90px',
  seatLarge: '100px',
  seatLargeMore: '120px',
};

export const objHeight = {
  small: '10px',
  medium: '15px', //CheckNoteDiv
  mediumLarge: '40px', //AddProgressNoteCardLayout3
  large: '45px', //TopicListStyledItem
  largeMore: '60px',
  classinfoGrade: '110px',
  extraLarge: '150px', //ProgressRecordTextarea
  mobileChill: '350px',
  carousel: '400px', //ProgressRecordTextarea
  largeCard: '490px',
};

export const imgSize = {
  small: '100px',
  medium: '150px',
  large: '200px',
};

export const margin = {
  extraSmall: '3px',
  small: '5px',
  medium: '10px',
  mediumlarge: '20px',
  large: '40px',
  extraLarge: '60px',
  top1: '67px', //header높이+1
  top2: '107px', //header+subheader높이
  top2Tablet: '84px', //height.header(65)+height.headerTablet(42)
  top3: '35px', //학급 진도노트 높이 double header - 원래 header
  bottomMobile: '80px',
};
export const padding = {
  extraSmall: '3px',
  small: '5px',
  medium: '10px',
  mediumLarge: '15px',
  large: '30px',
  extraLarge: '50px', //AuthLayout1
  largeMore: '70px',
};

export const gap = {
  extraSmall: '5px',
  small: '15px',
  medium: '20px',

  large: '30px',
  extraLarge: '50px',
};
// -------------------- 요소간 비율 -------------------- //
export const ratio = {
  inner: '95%',
  large: '85%',
  small: '15%',

  small20: '20%',
  large80: '80%',
};

// -------------------- 컬러 -------------------- //

export const colors = {
  primary: '#00704A',
  primarylight: '#ccffee',
  primarydark: '#004C36',
  lightGreen: '#4caf50',
  lightGreenBorder: '#CBE8CD',
  lightGreenBackground: '#F3F9F3',
  lightGreenDark: '#198B1E',

  //blue
  // primary: 'dodgerblue',
  // primarylight: '#e0f8f7',
  // primarydark: '#00bfff',

  background: 'white',
  male: '#ccccff',
  maleMedium: '#e9e9ff',
  maleLight: '#f7f7ff',
  female: '#ffcccc',
  femaleMedium: '#ffe9e9',
  femaleLight: '#fff7f7',

  red: '#f87272',
  yellow: '#facc15',
};

export const mainColors = {
  main1: '#DAEAE5',
  main2: '#B6D6CB',
  main3: '#91C1B1',
  main4: '#6DAD97',
  main5: '#48987D',
  main6: '#248463',
  main7: '#00704A',
};

// export const mainColor = {
//   main1: '#B6D6CB',
//   main2: '#97C5B5',
//   main3: '#79B4A0',
//   main4: '#5BA38A',
//   main5: '#3C9275',
//   main6: '#1E815F',
//   main7: '#00704A',
// };

export const gray = {
  extralight: '#f9f9f9',
  light: '#e9ecef',
  medium: '#BDBDBD',
  grayText: '#8D8C8C',
  darktext: '#555555',
  dark: 'A4A4A4',
  extradark: 'darkgray',
};

export const fontColors = {
  alert: 'red',
  confirm: 'green',
};

// -------------------- 폰트 -------------------- //
export const fontSize = {
  extraSmall: '10px',
  small: '12px',
  medium: '14px',
  large: '16px',
  categoryLarge: '20px',
  extraLarge: '27px',
};
export const fontWeight = {
  medium: '400',
  bold: '600',
  extraBold: '800',
};
// -------------------- 아이콘 사이즈 -------------------- //
export const iconSize = {
  middle: '18',
  large: '20',
  extraLarge: '27',
};
// -------------------- 객체 보더  -------------------- //

export const borderRadius = {
  small: '5px',
  medium: '10px',
  large: '15px',
};
// -------------------- carousel, 다양한 랜덤 컬러  -------------------- //

export const ColorPalette = [
  // 부드러운 비비드 컬러
  '#FF6F61', // 따뜻한 코랄 핑크
  '#6B5B95', // 차분한 퍼플
  '#88B04B', // 청량한 그린
  '#FFA177', // 은은한 오렌지
  '#FFD662', // 따뜻한 옐로우
  '#009688', // 매력적인 민트
  '#D94F70', // 포근한 딥핑크
  '#FFAA4C', // 상큼한 망고 오렌지
  '#3E7CB1', // 차분한 블루

  // 파스텔톤 컬러
  '#FFC1CC', // 부드러운 핑크
  '#A7C7E7', // 하늘색에 가까운 블루
  '#FFE5B4', // 크림빛 베이지
  '#C8E6C9', // 라이트 그린
  '#FFFACD', // 레몬빛 노랑
  '#FFEBE5', // 연한 피치
  '#D1C4E9', // 연보라

  // 자연스럽고 감각적인 컬러
  '#A5D6A7', // 싱그러운 그린
  '#B39DDB', // 라벤더 퍼플
  '#FFAB91', // 부드러운 피치
  '#81D4FA', // 연한 아쿠아 블루
  '#FFF59D', // 라이트 옐로우
  '#CFD8DC', // 소프트 그레이 블루
  '#B3E5FC', // 라이트 블루
];
