import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/userContext';
import { useNavigate } from '../../../../node_modules/react-router-dom/dist/index';
import axios from '../../../../node_modules/axios/index';
import MobileLogin from './MobileLogin';
import { CenterAlign } from '../../../styles/BasicStyle';
import { IoIosArrowBack } from 'react-icons/io';
import { padding } from '../../../styles/commonStyle';
import { LoginHelmetContent } from '../../../components/HelmetContent';

const MobileLoginPage = () => {
  //userContext
  const { state, actions } = useContext(UserContext);
  const navigate = useNavigate();

  //로그인 정보 정의
  const [loginInfo, setLoginInfo] = useState({
    userId: '',
    password: '',
  });

  //-------------------- 로그인 정보 로드 --------------------//

  // 로그인 정보를 저장할지 여부를 위한 상태 추가
  const [rememberMe, setRememberMe] = useState(false);

  // 자동 입력 기능: 저장된 로그인 정보가 있을 경우 로드
  useEffect(() => {
    const savedUserId = localStorage.getItem('savedUserId');
    const savedPassword = localStorage.getItem('savedPassword');

    // 불러온 값을 콘솔에 출력하여 확인
    // console.log('불러온 savedUserId:', savedUserId);
    // console.log('불러온 savedPassword:', savedPassword);

    if (savedUserId || savedPassword) {
      setLoginInfo({
        userId: savedUserId || '',
        password: savedPassword || '',
      });
      setRememberMe(true); // 저장된 정보가 있다면 체크박스도 체크된 상태로 설정
    }
  }, []);
  //-------------------- 로그인 정보 저장 --------------------//

  //input입력 시 값 설정
  const handleChange = (e) => {
    setLoginInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    // console.log(loginInfo);
  };

  // 체크박스 상태 변경 시 호출되는 함수
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };
  //-------------------- 로그인  --------------------//

  //가입 정보 DB 저장(암호화는 backend에서)
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://teachernote.me/api/login',
        loginInfo,
      );
      // 토큰 저장 (예: localStorage)
      const token = response.data.token;
      localStorage.setItem('token', token);

      //받아온 user data 확인
      const user = response.data.user;
      // console.log('user:' + JSON.stringify(user));
      localStorage.setItem('user', JSON.stringify(user));

      // localStorage.setItem('user', user);

      //userContext에 받아온 user data 넣기
      actions.setUser(user);

      // 로그인 정보 저장 (체크박스가 체크된 경우에만)
      if (rememberMe) {
        localStorage.setItem('savedUserId', loginInfo.userId);
        localStorage.setItem('savedPassword', loginInfo.password);
      } else {
        // 체크되지 않은 경우 저장된 로그인 정보 삭제
        localStorage.removeItem('savedUserId');
        localStorage.removeItem('savedPassword');
      }

      // navigate('/random-seat-assignment-setup');
      window.location.href = '/';
    } catch (error) {
      console.error('Error logging in:', error);
      alert('로그인에 실패하였습니다.');
    }
  };
  return (
    <>
      {' '}
      <LoginHelmetContent />
      <CenterAlign style={{ height: '100vh' }}>
        <div
          onClick={() => navigate(-1)}
          style={{
            position: 'fixed',
            top: '10px',
            left: '10px',
            zIndex: 1000,
            padding: `${padding.medium}`,
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '50%',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IoIosArrowBack size={20} />
        </div>
        <MobileLogin
          handleLogin={handleLogin}
          handleChange={handleChange}
          loginInfo={loginInfo} // loginInfo를 전달
          rememberMe={rememberMe}
          handleRememberMeChange={handleRememberMeChange} // 체크박스 상태 변경 함수 전달
        />
      </CenterAlign>
    </>
  );
};

export default MobileLoginPage;
