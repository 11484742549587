import { useEffect, useState } from 'react';
import {
  CornerNum,
  CurrentStudentSet,
  SeatDrawContentBox,
  SeatDrawPageContentBox,
  SeatDrawPageContentBox_left,
  SeatDrawPageContentBox_right,
  SeatDrawPageTitle,
  SeatDrawPageTitleBox,
  SeatDrawPageTitleBox_left,
  SeatDrawPageTitleBox_right,
  SeatDrawPageTitleDetail,
  SeatDrawThirdButton,
  StudentSettingInput_wide,
} from '../../SeatDrawPageStyle';
import {
  SeatDrawButton,
  SelectFrontNumContainer,
  StudentSettingTitle,
} from './StudentSettingStyle';
import frontSeatsImg from '../../../img/random-seat-assignment/random-seat-assignment-front-seats.png';
import Tooltip from '../../../components/Tooltip';
import { OneByOneButton } from '../OneByOneStyle';
import { BsX } from 'react-icons/bs';
import { colors, gray } from '../../../styles/commonStyle';
import {
  CancelButton,
  DisplayRow,
  DisplayRowSpaceBetween,
  ModalButton,
  ModalContent,
  ModalTitle,
  ModalWrapper,
} from '../../../styles/BasicStyle';

const SelectFrontNum = ({
  numberList,
  frontNums,
  setFrontNums,
  notUsingNumbers,
  adjacentNums,
  cornerNums,
  setAdjacentNums,
  setCornerNums,
  preAssigningSeats,
  setPreAssigningSeats,
  seatPreAssignments,
  setSeatPreAssignments,
  showNameMode,
  setStudents,
  students,
  setShowNameMode,
  duplicateStudents,
  setDuplicateStudents,
  selectedStudentNum,
  setSelectedStudentNum,
}) => {
  const [inputFrontNums, setInputFrontNums] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputFrontChange = (e) => {
    setInputFrontNums(e.target.value);
  };

  const handleAddFrontNums = (numsToAdd = []) => {
    const inputValues =
      numsToAdd.length > 0
        ? numsToAdd
        : inputFrontNums
            .split(',')
            .map((value) => value.trim()) // 공백 제거
            .filter((value) => value !== ''); // 공백이 아닌 항목만 필터링

    const nums = inputValues
      .map((value) => {
        if (showNameMode && isNaN(value)) {
          // 이름 모드일 때 이름으로 번호 찾기
          const matchingStudents = students.filter(
            (student) => student.name === value,
          );

          if (matchingStudents.length > 1) {
            // 중복된 이름이 있는 경우
            setDuplicateStudents(matchingStudents);
            setIsModalOpen(true); // 학생 선택 창 열기
            return null; // NaN 대신 null을 반환하여 경고 메시지가 뜨지 않도록 함
          }
          const student = matchingStudents[0];
          return student ? student.number : NaN; // 이름이 없을 경우 NaN 반환
        }
        return Number(value); // 숫자로 입력한 경우 그대로 번호로 처리
      })
      .filter((num) => num !== null);

    // 중복 학생 선택 창이 열려 있어도 실행되도록 수정
    if (numsToAdd.length === 0 && (isModalOpen || nums.includes(null))) {
      return;
    }

    // showNameMode가 false일 때 이름을 입력한 경우 경고 메시지 표시
    if (!showNameMode && inputValues.some((value) => isNaN(value))) {
      const confirmShowNameMode = window.confirm(
        '학생 이름으로 보기 모드를 실행하시겠습니까?',
      );
      if (confirmShowNameMode) {
        setShowNameMode(true);
      }
      setInputFrontNums('');
      return;
    }

    // showNameMode가 true일 때 입력된 이름이 존재하지 않는 경우 경고 메시지 표시
    if (showNameMode && nums.some(isNaN)) {
      alert('존재하지 않는 이름입니다. 이름을 다시 확인해주세요');
      setInputFrontNums('');
      return;
    }

    // 이미 frontNums에 있는 숫자와 새로 입력된 nums의 중복 숫자 찾기
    const duplicateNums = nums.filter((num) => frontNums.includes(num));
    if (duplicateNums.length > 0) {
      alert(`${duplicateNums.join(', ')}번은 이미 등록된 번호입니다.`);
      setInputFrontNums('');
      return;
    }

    // 사용 불가능한 번호 확인
    const invalidNums = nums.filter((num) => notUsingNumbers.includes(num));
    if (invalidNums.length > 0) {
      alert(
        `${invalidNums.join(
          ', ',
        )}번은 사용할 수 없는 번호입니다. 사용하시려면 표의 X표시를 해제한 후 다시 추가해주세요.`,
      );
      setInputFrontNums('');
      return;
    }

    // adjacentNums, cornerNums, preAssigningSeats 또는 seatPreAssignments에 이미 포함된 번호가 있는지 확인
    const numsInExistingAssignments = nums.filter(
      (num) =>
        adjacentNums.some((pair) => pair.includes(num)) ||
        cornerNums.includes(num) ||
        Object.values(preAssigningSeats).includes(String(num)) ||
        Object.values(seatPreAssignments).includes(String(num)),
    );
    if (numsInExistingAssignments.length > 0) {
      numsInExistingAssignments.forEach((conflictedNum) => {
        const location = adjacentNums.some((pair) =>
          pair.includes(conflictedNum),
        )
          ? '가까이 앉을 학생'
          : cornerNums.includes(conflictedNum)
          ? '집중이 필요한 학생'
          : Object.values(preAssigningSeats).includes(String(conflictedNum))
          ? '미리 배치 학생'
          : '미리 배치 학생';

        alert(`기존 등록 내역을 삭제하고 진행해주세요.`);
      });
      setInputFrontNums('');
      return;
    }

    const filteredNums = nums.filter(
      (num) => !isNaN(num) && !frontNums.includes(num),
    );

    const invalidFilteredNums = filteredNums.filter(
      (num) => !numberList.map(Number).includes(num),
    );

    if (invalidFilteredNums.length > 0) {
      alert(
        '해당 번호는 존재하지 않습니다. 학생 번호에서 번호를 먼저 추가해주세요.',
      );
      setInputFrontNums('');
      return;
    }

    // 앞에 앉을 학생 수를 5명으로 제한
    if (frontNums.length + filteredNums.length > 5) {
      alert('앞에 앉을 학생은 최대 5명까지 가능합니다.');
      setInputFrontNums('');
      return;
    }
    setFrontNums((prevFrontNums) => [...prevFrontNums, ...filteredNums]);
    setInputFrontNums('');
  };

  const handleRemoveFrontNum = (numToRemove) => {
    setFrontNums((prevFrontNums) =>
      prevFrontNums.filter((num) => num !== numToRemove),
    );
  };

  //이름 중복일 때 모달에서 확인 클릭
  const CornerModalSelectNumion = (studentNum) => {
    const selectedStudent = students.find(
      (student) => student.number === studentNum,
    );
    if (selectedStudent) {
      setSelectedStudentNum(studentNum);
      setIsModalOpen(false);
      setDuplicateStudents([]);
      setInputFrontNums(''); // 입력 필드 초기화

      handleAddFrontNums([String(studentNum)]); // 학생 번호를 전달
      // handleAddFrontNums([selectedStudent.name]); // 학생 이름을 전달
    } else {
      alert('학생을 찾을 수 없습니다.');
    }
    // setSelectedStudentNum(studentNum);
    // alert('[studentNum]:' + JSON.stringify([studentNum]));
    // setIsModalOpen(false);
    // handleAddFrontNums([studentNum]); // 클릭한 번호를 인자로 전달
    // setDuplicateStudents([]);
    // setInputFrontNums(''); // 입력 필드 초기화
  };

  //ESC로 모달 꺼지게
  // ESC 키로 모달을 닫는 로직 추가
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setIsModalOpen(false); // ESC 키가 눌리면 모달 닫기
      }
    };

    // 이벤트 리스너 추가
    document.addEventListener('keydown', handleKeyDown);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // 빈 배열을 전달하여 컴포넌트 마운트 시 한 번만 실행
  return (
    <>
      <SelectFrontNumContainer>
        <StudentSettingTitle>앞에 앉을 학생 지정</StudentSettingTitle>
        <DisplayRowSpaceBetween>
          <SeatDrawPageContentBox_left>
            {' '}
            <img
              src={frontSeatsImg}
              style={{ width: '200px', height: 'auto' }}
              alt="교실 학급 랜덤 무작위 자리뽑기 설명5"
            />
          </SeatDrawPageContentBox_left>
          <SeatDrawPageContentBox_right>
            <div>
              <Tooltip text="삭제하려면 번호 클릭">
                <CurrentStudentSet>
                  앞 2줄에 앉을 학생들:
                  {frontNums.map((num, index) => {
                    const student = students.find(
                      (student) => student.number === num,
                    );
                    const displayText =
                      showNameMode && student
                        ? `${num} ${student.name || ''}`
                        : num;
                    return (
                      <CornerNum
                        key={index}
                        onClick={() => handleRemoveFrontNum(num)} // 번호 클릭 시 삭제 기능
                      >
                        {displayText} <BsX color={`${gray.dark}`} />
                      </CornerNum>
                    );
                  })}
                </CurrentStudentSet>
                {/* <CurrentStudentSet>
              앞 2줄에 앉을 학생들:{' '}
              {frontNums.map((num, index) => (
                <CornerNum
                  key={index}
                  onClick={() => handleRemoveFrontNum(num)} // 번호 클릭 시 삭제 기능
                >
                  {num} <BsX color={`${gray.dark}`} />
                </CornerNum>
              ))}
            </CurrentStudentSet> */}
              </Tooltip>
            </div>
            <div>
              <StudentSettingInput_wide
                type="text"
                value={inputFrontNums}
                onChange={handleInputFrontChange}
                // placeholder="번호를 콤마로 구분하여 입력"
                placeholder={showNameMode ? '번호 또는 이름' : '번호'}
                onKeyDown={(e) => {
                  // if (e.key === 'Enter') {
                  //   handleAddFrontNums(e);
                  // }
                  if (e.key === 'Enter') {
                    handleAddFrontNums(
                      inputFrontNums.split(',').map((value) => value.trim()),
                    );
                  }
                }}
              />
              <OneByOneButton
                // onClick={handleAddFrontNums}
                onClick={() =>
                  handleAddFrontNums(
                    inputFrontNums.split(',').map((value) => value.trim()),
                  )
                }
              >
                추가
              </OneByOneButton>
            </div>
          </SeatDrawPageContentBox_right>
        </DisplayRowSpaceBetween>
      </SelectFrontNumContainer>
      {isModalOpen && (
        <ModalWrapper
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setIsModalOpen(false); // 모달 바깥을 클릭했을 때 모달 닫기
            }
          }}
        >
          <ModalContent>
            <ModalTitle>학생 이름 중복</ModalTitle>
            <div>중복된 학생 이름이 있습니다. 학생 번호를 선택해주세요.</div>
            <div>
              {duplicateStudents.map((student) => (
                <ModalButton
                  key={student.number}
                  onClick={() => CornerModalSelectNumion(student.number)}
                >
                  {student.number} {student.name}
                </ModalButton>
              ))}
            </div>
            {/* <CancelButton onClick={() => setIsModalOpen(false)}>
              취소
            </CancelButton> */}
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
};

export default SelectFrontNum;
