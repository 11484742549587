import { TiArrowSync } from 'react-icons/ti';
import GradeContext, { GradeConsumer } from '../../contexts/grade';
import { useContext } from 'react';
import UserContext from '../../contexts/userContext';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors, height, padding } from '../../styles/commonStyle';

const SelectGradeBox = styled.div`
  // height: ${height.subHeader};
  background-color: white;
  padding: ${padding.mediumLarge} 0px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 999;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #effbfb; /* 호버 시 배경색 변경 */
    color: ${colors.primary};
  }
`;

export default function SelectGrade() {
  const { state: gradeState, actions: gradeActions } = useContext(GradeContext);
  const grade = gradeState.currentGrade;
  const navigate = useNavigate();

  const handleGradeChange = () => {
    gradeActions.changeGrade();

    // 현재 경로를 확인하여 새로운 경로를 결정
    if (window.location.pathname.includes('/progress-notes/by-class/')) {
      const newPath = window.location.pathname.replace(
        /\/progress-notes\/by-class\/\d+/,
        '/progress-notes/by-class',
      );
      navigate(newPath);
    } else if (window.location.pathname.includes('/progress-notes/by-topic/')) {
      const newPath = window.location.pathname.replace(
        /\/progress-notes\/by-topic\/\d+/,
        '/progress-notes/by-topic',
      );
      navigate(newPath);
    }
  };

  return (
    <GradeConsumer>
      {({ state, actions }) => (
        <>
          <SelectGradeBox onClick={handleGradeChange}>
            <div>{grade}학년</div>
          </SelectGradeBox>
        </>
      )}
    </GradeConsumer>
  );
}
