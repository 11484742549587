import React from 'react';
import styled from 'styled-components';
import {
  borderRadius,
  gray,
  margin,
  objWidth,
  padding,
} from '../styles/commonStyle';
import { media } from '../styles/media';

// 카드 컨테이너 스타일
const CardContainerBox = styled.div`
  width: ${objWidth.extraLarge};
  height: auto;
  padding: ${padding.large};
  // margin-bottom: ${margin.large};

  ${media.mobile`
  max-width: 70vw;
  // max-height: 70vh;
  // padding: ${padding.mediumLarge};
  margin-top: 50px;
  `}

  border-radius: ${borderRadius.medium};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
  background-color: ${gray.extralight};
`;

const CardTitleBox = styled.h3`
  width: 100%;
  margin: 10px 0;
  text-align: center;
`;
const AuthCardContainer = ({ children }) => {
  return <CardContainerBox>{children}</CardContainerBox>;
};

const AuthCardTitle = ({ children }) => {
  return <CardTitleBox>{children}</CardTitleBox>;
};
export { AuthCardContainer, AuthCardTitle }; // 각각의 컴포넌트를 내보냄
