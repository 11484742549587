import { fontWeight, gap, gray, padding } from '../../../styles/commonStyle';
import styled from 'styled-components';

// -------------------- 메뉴 영역 및 overlay  -------------------- //
export const MobileSlideMenu = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease-in-out;

  background-color: white;
`;
// export const MobileOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background-color: rgba(0, 0, 0, 0.5); // 검정색 반투명 배경
//   z-index: 9998;

//   opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
//   pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
//   transition: opacity 0.3s ease;
// `;

// -------------------- 메뉴 레이아웃 -------------------- //
// export const MobileMenuContainer = styled.div`
//   // width: 80vw;
//   // height: 100vh;
// `;

export const MobileMenuContainerInner = styled.div`
  width: 80vw;
  height: 70vh;
  // padding: ${padding.large};

  display: flex;
  flex-direction: column;
  justify-content: center;

  // background-color: yellow;
`;

export const MobileMenuTopBox = styled.div`
  // min-height: 20vh;
  padding: 0 0 ${padding.extraLarge} 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: green;
`;

export const MobileMenuMiddleBox = styled.div`
  padding: ${padding.medium} 0 ${padding.large} 0;
  gap: ${gap.medium};

  // border-top: 1px solid ${gray.light};
  border-bottom: 1px solid ${gray.light};
`;

export const MobileMenuBottomBox = styled.div`
  padding: ${padding.large};
`;

export const MobileMenuBox = styled.div`
  padding-top: ${padding.mediumLarge};
  font-size: 0.8rem;
  font-weight: ${fontWeight.bold};
  display: flex;
  align-items: center;
  gap: ${gap.extraSmall};
`;
