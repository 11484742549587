import styled, { keyframes, css } from 'styled-components';
import {
  colors,
  fontSize,
  gap,
  gray,
  objHeight,
  padding,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const SelectPickModeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  // background-color: red;
`;
export const SelectPickMode = styled.div`
  // flex-grow: 1;

  padding: ${padding.mediumLarge} ${padding.large};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${media.tablet`
    flex-direction:column;
    `}
`;

export const PresenterPick = styled.div`
  border-top: 1px solid ${gray.light};
  display: flex;
  justify-content: center;
  // background-color: blue;
  ${media.desktop`
    height: 750px;
`}
  ${media.tablet`
  height: 700px;
  `}
   ${media.mobile`
  height: 500px;
  `}
`;
export const PickerSelect = styled.div`
  ${media.desktop`
  padding-right: ${gap.medium}`}
`;
export const StudentNumber = styled.div`
  width: 100%;
  height: 100%;
  gap: ${gap.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: yellow;
  ${media.tablet`
    padding-top: ${padding.medium};
    `}
`;

// --------------------  회전 룰렛  -------------------- //
export const RoulettePageContainer = styled.div`
  padding: ${padding.mediumLarge};

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding-top: ${padding.large};
  /* 아주 연한 노랑색 배경 */
  background-color: #fff9e6;

  /* 하얀 도트무늬 패턴 */
  background-image: radial-gradient(transparent 10px, white 1px);
  background-size: 20px 20px; /* 도트 간격 조정 */
`;

export const RouletteLeft = styled.div`
  // background-color: red;
`;

export const RouletteRight = styled.div`
  // width: 10%;
  // background-color: yellow;
`;

// 회전 애니메이션 정의
export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// 천천히 회전하는 애니메이션 정의
const slowSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// 휠 컨테이너 스타일
export const WheelContainer = styled.div`
  position: relative;
  // width: 700px;
  // height: 700px;
  width: ${({ wheelSize }) => `${wheelSize}px`}; // wheelSize prop 적용
  height: ${({ wheelSize }) => `${wheelSize}px`};
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); // 그림자 추가
  border: 30px solid white;
  overflow: hidden;

  // 자동 회전 조건: spinning이 false이고 isModalOpen이 false일 때만 slowSpin 애니메이션 실행
  ${({ spinning, isModalOpen, hasSpun }) =>
    !spinning &&
    !isModalOpen &&
    !hasSpun &&
    css`
      animation: ${slowSpin} 20s linear infinite;
    `}
`;

// 천천히 회전하는 애니메이션 추가
// animation: ${slowSpin} 20s linear infinite;

// ${({ spinning }) =>
//   spinning &&
//   css`
//     animation: none; // 사용자가 클릭할 때는 애니메이션을 멈춤
//   `}
// ${({ spinning }) =>
//     //     spinning &&
//     //     css`
//     //       animation: ${spin} 4s ease-out forwards;
//     //     `}
export const InnerBorder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 99%; // border를 안쪽으로 위치시키기 위한 크기 조정
  height: 99%;
  // border: 5px solid; // 상징 색상
  box-shadow: inset 0px 6px 12px rgba(0, 0, 0, 0.1); // 내부 그림자 추가

  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

// 휠 섹션 스타일
export const Slice = styled.div`
  width: 50%; // 각 조각의 너비를 조정
  height: 100%;
  background-color: ${(props) => props.bgColor};
  clip-path: polygon(0 0, 100% 0, 50% 100%); // 피자 조각 형태
  transform-origin: 0% 50%; // 왼쪽 중심에서 회전하도록 설정
`;

// export const Slice = styled.path.attrs((props) => ({
//   d: `
//     M 50 50
//     L ${50 + 50 * Math.cos((Math.PI * props.angle) / 180)}
//       ${50 + 50 * Math.sin((Math.PI * props.angle) / 180)}
//     A 50 50 0 ${props.sliceAngle > 180 ? 1 : 0} 1
//       ${50 + 50 * Math.cos((Math.PI * (props.angle + props.sliceAngle)) / 180)}
//       ${50 + 50 * Math.sin((Math.PI * (props.angle + props.sliceAngle)) / 180)}
//     Z
//   `,
//   fill: props.bgColor,
// }))``;

export const InnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110px; // 원하는 크기로 설정
  height: 110px;
  background-color: white;
  border-radius: 50%;
  border: 20px solid ${gray.light};
  transform: translate(-50%, -50%);
  z-index: 1;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); // 그림자 추가

  cursor: pointer;
`;

// 선택된 결과 표시
export const Result = styled.div`
  margin-top: 20px;
  font-size: 24px;
  color: #00704a;
`;

// 버튼 스타일
export const SpinButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #00704a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const AddButton = styled.button`
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #00704a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

// 반짝이는 효과와 확대/축소 애니메이션 정의
const shine = keyframes`
  0% {
    box-shadow: 0px 0px 10px rgba(255, 223, 0, 0.8);
  }
  100% {
    box-shadow: 0px 0px 0px rgba(255, 223, 0, 0);
  }
`;

export const StartText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: 'translate(-50%, -50%)
  &:hover {
    transform: scale(1.2) !important; // 호버 시 확대 효과
    color: #ffdf00; // 호버 시 색상 변경
    text-shadow: 0px 0px 8px rgba(255, 223, 0, 0.8);
  }

  &:active {
    animation: ${shine} 0.5s forwards; // 클릭 시 반짝임 효과
  }
`;

// Styled div component
export const StartButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${colors.primary};
  font-size: ${fontSize.extraLarge};
  font-weight: bold;
  // pointer-events: none;
  text-align: center;
  transition: color 0.3s ease, transform 0.3s ease; /* 부드러운 효과 */
  cursor: pointer;
  &:hover {
    color: ${colors.secondary}; /* 마우스 오버 시 색상 변경 */
    transform: translate(-50%, -50%) scale(1.1); /* 마우스 오버 시 크기 살짝 확대 */
  }
`;
