import { useState } from 'react';
import RegisterModalComponent from '../registerModal/RegisterModalComponent';
import LoginModalComponent from '../loginModal/LoginModalComponent';
import PageContainer from '../../pageLayout/PageContainer';
import { MainPageWidthBox } from '../../pageLayout/PageContainerStyle';
import {
  OneByOne_Footer,
  OneByOne_Footer_Content_Right,
} from '../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import {
  CenterAlign,
  DisplayColumn,
  TitleContainer,
  TitleDetail,
  TitleText,
} from '../../styles/BasicStyle';
import { borderRadius, padding } from '../../styles/commonStyle';

import logo from '../../img/teachernote-logo/teachernote-logo-green.png';
// import intro from '../../img/intro.png';
// import intro1 from '../../img/intro1.png';
import { BlogListImage } from '../blogList/BlogListStyle';
import styled from 'styled-components';
const RoundImage = styled.img`
  width: 330px;
  border-radius: 50%;
  padding: 20px;
`;
const Teachernote = () => {
  const [showNotification, setShowNotification] = useState(false);

  //--------------------- 로그인/회원가입 --------------------//
  //회원가입 모달
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [modalSource, setModalSource] = useState('bottombutton'); //menubar,bottombutton

  const handleAuthComplete = async (userId) => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  const openRegisterModal = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(true);
  };

  const openLoginModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(true);
  };

  const closeAuthModal = () => {
    setRegisterModalOpen(false);
    setLoginModalOpen(false);
  };

  const navigate = useNavigate();

  return (
    <>
      {' '}
      <RegisterModalComponent
        isRegisterModalOpen={isRegisterModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        openRegisterModal={openRegisterModal}
        openLoginModal={openLoginModal}
        closeModal={closeAuthModal}
        handleAuthComplete={handleAuthComplete}
        modalSource={modalSource}
      />
      {isLoginModalOpen ? (
        <LoginModalComponent
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          openRegisterModal={openRegisterModal}
          openLoginModal={openLoginModal}
          closeAuthModal={closeAuthModal}
          handleAuthComplete={handleAuthComplete}
          modalSource={modalSource}
        />
      ) : (
        <></>
      )}
      <PageContainer>
        <MainPageWidthBox>
          <TitleContainer>
            <TitleText>OUR TEACHERNOTE</TitleText>
            <TitleDetail></TitleDetail>
          </TitleContainer>
          <CenterAlign
          // style={{ fontWeight: 'bold', padding: `${padding.medium}` }}
          >
            {/* <RoundImage src={intro1} alt="티처노트 스토리" /> */}
            <DisplayColumn>
              <TitleText>지혜로운 스승, 아테나</TitleText>
              아테나는 지혜의 여신이자 정의롭고 지혜로운 스승이자 문명의
              수호자로서 다양한 방면에서 인간의 삶을 인도하고 보호했습니다.
              아테네 시민들에게 올리브 나무를 선물해 풍요를 주었습니다. 아테나의
              가르침이 고대 문명에 문화와 철학, 예술의 큰 변화를 일으킨 것처럼,
              선생님들의 지도와 헌신은 학생들에게 삶의 방향과 의미를 깨우쳐주며
              미래를 열어가도록 이끌고 있습니다.
            </DisplayColumn>
          </CenterAlign>
          {/* <CenterAlign>
            {' '}그
              가르침은 문화와 철학, 예술에 큰 영향을 미쳤으며, 오늘날에도 지혜와
              정의의 상징으로 널리 존경받고 있습니다.
            <DisplayColumn>
              <TitleText>LOVE TEACHERNOTE</TitleText>
              티처노트의 상징색은 올리브의 짙은 초록과 대한민국 초중고
              선생님들의 바쁜 일상 속에서도 편안함과 안식을 느낄 수 있도록 하는
              마음을 담았습니다. 언제나 열정과 헌신으로 가득 찬 선생님들의
              따스한 쉼터이자 함께하는 커뮤니티가 되어드리고자 합니다.
            </DisplayColumn>{' '}
            <RoundImage src={logo} alt="로고" />
          </CenterAlign> */}
          <CenterAlign>
            <DisplayColumn>
              <TitleText>EDUCATIONAL COMMUNITY</TitleText>
              아테네의 신전, 특히 파르테논 주위는 다양한 사람들이 모여 활기찬
              교류가 이루어지는 장면이 펼쳐집니다. 햇빛이 따사롭게 내리쬐는 신전
              앞 광장에는 시민들, 철학자들, 예술가들, 상인들이 모여 서로의
              생각과 지식을 나누고 있어요. 돌로 만들어진 웅장한 신전 기둥들
              사이로 사람들이 삼삼오오 모여 작은 모임을 만들고, 어떤 이들은 넓은
              계단에 앉아 담소를 나누며 자연스럽게 토론이 이어집니다.
            </DisplayColumn>
            {/* <RoundImage src={intro} alt="티처노트 스토리" /> */}
          </CenterAlign>
        </MainPageWidthBox>
      </PageContainer>
    </>
  );
};

export default Teachernote;
