// LocationContext.js
import React, { createContext, useState, useEffect } from 'react';

const LocationContext = createContext({
  location: { lat: null, lon: null },
  city: '',
});

const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState({ lat: null, lon: null });
  const [city, setCity] = useState('');

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lon: longitude });
          //지역 테스트
          // const latitude = 36.4804;
          // const longitude = 127.2894;
          fetch(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=ko`,
          )
            .then((response) => response.json())
            .then((data) => {
              let cityName = data.principalSubdivision || '';

              // '특별시', '광역시', '도' 제거 및 축약형으로 변환
              cityName = cityName
                .replace(/(특별시|광역시|도|특별자치시|특별자치도)$/, '')
                .replace('경상북', '경북')
                .replace('경상남', '경남')
                .replace('전라북', '전북')
                .replace('전라남', '전남')
                .replace('충청북', '충북')
                .replace('충청남', '충남');

              setCity(cityName);
              // let cityName = data.city || data.locality || '';
              // cityName = cityName.replace(/(특별시|광역시|시)$/, '');
              // setCity(cityName);
            })
            .catch((err) => console.error('위치 정보 가져오기 오류:', err));
        },
        (error) => console.error('Geolocation API 오류:', error),
      );
    } else {
      alert('이 브라우저에서는 위치 정보가 지원되지 않습니다.');
    }
  }, []);

  return (
    <LocationContext.Provider value={{ location, city }}>
      {children}
    </LocationContext.Provider>
  );
};

export { LocationProvider };
export default LocationContext;
