import {
  FooterContainer,
  FooterLeft,
  FooterRight,
  FooterSnsImg,
} from './FooterStyle';
import facebookIcon from '../img/teachernote-social-icons/teachernote-facebook-icon.png';
import instaIcon from '../img/teachernote-social-icons/teachernote-instagram-icon.png';
import youtubeIcon from '../img/teachernote-social-icons/teachernote-youtube-icon.png';
import blogIcon from '../img/teachernote-social-icons/teachernote-blog-icon.png';
import { useNavigate } from '../../node_modules/react-router-dom/dist/index';

const Footer = () => {
  const navigate = useNavigate();
  const handleOpenNewTab = (path) => {
    const url = window.location.origin + path; // 현재 도메인에 해당 경로 추가
    window.open(url, '_blank');
  };
  return (
    <FooterContainer>
      <FooterLeft></FooterLeft>
      <FooterRight>
        {/* <p>서비스 이용약관 ｜ 개인정보 처리방침</p> */}
        <p>
          (주)티처노트 ｜ 대표 박진현 ｜{' '}
          <span
            onClick={() => handleOpenNewTab('/terms-of-service')}
            style={{ cursor: 'pointer' }}
          >
            서비스 이용약관
          </span>
          ｜{' '}
          <span
            onClick={() => handleOpenNewTab('/privacy-policy')}
            style={{ cursor: 'pointer' }}
          >
            개인정보 처리방침
          </span>
        </p>
        <p>
          주소 : 서울 성동구 아차산로7길 15-1 제이제이빌딩 ｜ 문의:
          teachernoteofficial@gmail.com
        </p>
        <p>
          <a
            href="https://blog.naver.com/teachernote"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterSnsImg src={blogIcon} />
          </a>
          <a
            href="https://instagram.com/ourteachernote"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterSnsImg src={instaIcon} />
          </a>
          <a
            href="https://www.youtube.com/@teachernote"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterSnsImg src={youtubeIcon} />
          </a>
          {/* <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
          <FooterSnsImg src={facebookIcon} />
          </a> */}
        </p>
        <p>Copyright ⓒ 2024 Teachernote Inc. All rights reserved.</p>
      </FooterRight>
    </FooterContainer>
  );
};

export default Footer;
