import styled, { keyframes, css } from 'styled-components';
import snowbackground from '../../../img/random-number-picker-3snowman/random-number-picker-snowman-background.jpg';
import { borderRadius, fontWeight, padding } from '../../../styles/commonStyle';
import { media } from '../../../styles/media';

export const SnowViewContainer = styled.div`
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  border-bottom-left-radius: ${borderRadius.medium};
  border-bottom-right-radius: ${borderRadius.medium};

  background-image: url(${snowbackground}); /* 배경 이미지 설정 */
  background-size: cover; /* 배경 이미지가 컨테이너를 덮도록 설정 */
  background-position: center; /* 이미지 위치를 가운데로 설정 */
`;

export const SunContainer = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: ${padding.extraLarge};
  margin-right: 100px;
  // background-color: red;
`;

export const Sun = styled.div`
  width: 130px;
  height: 130px;
  ${media.tablet`
    width: 100px;
    height: 100px;
    font-size: 1.3rem;
    `}

  border-radius: 50%;

  background: radial-gradient(circle, #ff8c00 30%, #ffd700 70%, #fffacd 100%);

  box-shadow: 0 0 20px 10px rgba(255, 255, 153, 0.6),
    /* 투명도를 약간 낮춤 */ 0 0 40px 20px rgba(255, 204, 51, 0.5),
    /* 투명도를 낮춰서 밝기 줄임 */ 0 0 60px 30px rgba(255, 153, 0, 0.3); /* 전체적으로 빛의 밝기를 낮춤 */
  // background: radial-gradient(circle, orange 30%, yellow 70%, #ffffe0 100%);
  // box-shadow: 0 0 25px 15px rgba(255, 255, 153, 0.8),
  //   0 0 50px 30px rgba(255, 204, 51, 0.6), 0 0 75px 45px rgba(255, 153, 0, 0.4);
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
    // box-shadow: 0 0 30px 20px rgba(255, 255, 153, 1),
    //   0 0 60px 40px rgba(255, 204, 51, 0.8),
    //   0 0 90px 60px rgba(255, 153, 0, 0.6);
    /* 오버 시 어두운 주황(#e07b00), 연한 노랑(#e6c200), 밝은 크림(#f7e5b5) */
    box-shadow: 0 0 30px 20px rgba(255, 255, 153, 1),
      0 0 60px 40px rgba(255, 204, 51, 0.8),
      0 0 90px 60px rgba(255, 153, 0, 0.6);
  }

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.7em;
  font-weight: ${fontWeight.extraBold};
`;
export const SnowManContainer = styled.div`
  position: relative;
  // display: inline-block;
  width: 800px;
  height: 800px;
  ${media.tablet`
    width: 600px;
    height: 600px;
    `}

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const SnowmanWrapper = styled.div`
  z-index: 2;
`;

export const SnowmanImage = styled.img`
  width: 600px;
  ${media.tablet`
    width: 550px;
    `}
  ${(props) =>
    props.melt &&
    css`
      animation: ${meltAnimation} 7s forwards;
    `}
`;
export const PresenterWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;
export const PresenterNumber = styled.div`
  font-size: 14rem;
  ${media.tablet`
    font-size: 10rem;
    `}
  font-weight: bold;
  color: #ffffff; /* 눈처럼 하얀 글자 */
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2),
    /* 부드러운 그림자 효과 */ 0 0 15px rgba(255, 255, 255, 0.8); /* 눈처럼 빛나는 효과 */
  font-family: 'Snowburst One', cursive; /* 눈사람과 어울리는 둥근 폰트 */
  padding: 10px;
  // -webkit-text-stroke: 10px #87ceeb;
`;

// 눈사람과 해가 살짝 늘어났다 줄어드는 애니메이션
const pulseAnimation = keyframes`
  0% {
    transform: scale(1.00);
  }
  50% {
    transform: scale(1.01); /* 살짝 확대 */
  }
  100% {
    transform: scale(1.00);
  }
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(-1deg);
    transform: scale(1.00);
  }
  50% {
    transform: rotate(1deg); /* 오른쪽으로 살짝 회전 */
    transform: scale(1.01); /* 살짝 확대 */
  }
  100% {
    transform: rotate(-1deg); /* 다시 원래 위치로 돌아옴 */
    transform: scale(1.00);
  }
`;

// export const SunImage = styled.img`
//   width: 200px;
//   cursor: pointer;
//   transition: transform 0.5s ease, box-shadow 0.5s ease;
//   //   animation: ${pulseAnimation} 1s ease-in-out infinite;
//   animation: ${rotateAnimation} 1s ease-in-out infinite;

//   &:hover {
//     // transform: scale(1.1);
//     // filter: drop-shadow(0 0 50px rgba(255, 223, 0, 0.7));
//   }

//   &::after {
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     width: 200px;
//     height: 400px;
//     background: radial-gradient(
//       circle at center,
//       rgba(255, 223, 0, 0.7),
//       rgba(255, 223, 0, 0) 70%
//     );
//     transform: translate(-50%, -50%) scale(1);
//     transition: transform 0.5s ease, opacity 0.5s ease;
//     opacity: 0;
//   }

//   ${(props) =>
//     props.clicked &&
//     css`
//       filter: drop-shadow(0 0 50px rgba(255, 223, 0, 0.7));
//       //   transform: scale(1.5);

//       &::after {
//         // transform: translate(-50%, -50%) scale(2); /* 빛이 더 넓게 퍼지도록 확장 */
//         opacity: 1;
//       }
//     `}
// `;

// 눈사람이 단계별로 녹는 애니메이션
// const meltAnimation = (stage) => keyframes`
//   0% {
//     clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
//   }
//   100% {
//     clip-path: polygon(${stage * 5}% ${stage * 5}%, ${100 - stage * 5}% ${
//   stage * 5
// }%, 100% 100%, 0% 100%);
//   }
// `;

// 각 단계별로 눈사람이 녹는 정도를 미리 정의
// const meltStages = [
//   'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', // 0단계 (녹지 않음)
//   'polygon(0% 10%, 100% 10%, 100% 100%, 0% 100%)', // 1단계
//   'polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%)', // 2단계
//   'polygon(0% 30%, 100% 30%, 100% 100%, 0% 100%)', // 3단계
//   'polygon(0% 40%, 100% 40%, 100% 100%, 0% 100%)', // 4단계
//   'polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%)', // 5단계
//   'polygon(0% 60%, 100% 60%, 100% 100%, 0% 100%)', // 6단계
//   'polygon(0% 70%, 100% 70%, 100% 100%, 0% 100%)', // 7단계
//   'polygon(0% 80%, 100% 80%, 100% 100%, 0% 100%)', // 8단계
//   'polygon(0% 90%, 100% 90%, 100% 100%, 0% 100%)', // 9단계
//   'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)', // 10단계 (완전히 녹음)
// ];

// 눈사람 이미지 스타일
// export const SnowmanImage = styled.img`
//   width: 800px;
//   clip-path: ${(props) =>
//     meltStages[props.meltStage]}; /* 녹는 단계에 따른 clip-path 적용 */
//   transition: clip-path 0.5s ease; /* 자연스러운 변화 */
//   animation: ${pulseAnimation} 1s ease-in-out infinite;
// `;

// export const SnowmanImage = styled.img`
//   width: 800px;
//   ${(props) =>
//     props.meltStage > 0 &&
//     css`
//       animation: ${meltAnimation(props.meltStage)} 0.5s forwards;
//     `}
// `;

//눈 내리는 효과

// falling 애니메이션 정의
const falling = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: translateY(95vh);
    opacity: 0;
  }
`;
export const Snowflake = styled.div`
  position: absolute;
  top: 10px;
  left: ${(props) => props.left}%;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  opacity: 0.8;
  animation: ${falling} ${(props) => props.duration}s linear infinite;
`;

// 불규칙하고 속도가 변하는 녹아내림 애니메이션
const meltAnimation = keyframes`
  0% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  5% {
    clip-path: polygon(3% 3%, 97% 3%, 100% 100%, 0% 100%);
  }
  10% {
    clip-path: polygon(7% 8%, 93% 7%, 100% 100%, 0% 100%);
  }
  15% {
    clip-path: polygon(10% 12%, 90% 10%, 100% 100%, 0% 100%);
  }
  20% {
    clip-path: polygon(12% 18%, 88% 15%, 100% 100%, 0% 100%);
  }
  25% {
    clip-path: polygon(15% 22%, 85% 20%, 100% 100%, 0% 100%);
  }
  30% {
    clip-path: polygon(18% 28%, 82% 25%, 100% 100%, 0% 100%);
  }
  35% {
    clip-path: polygon(20% 34%, 80% 30%, 100% 100%, 0% 100%);
  }
  40% {
    clip-path: polygon(23% 38%, 77% 35%, 100% 100%, 0% 100%);
  }
  45% {
    clip-path: polygon(25% 42%, 75% 40%, 100% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(28% 48%, 72% 45%, 100% 100%, 0% 100%);
  }
  55% {
    clip-path: polygon(30% 52%, 70% 50%, 100% 100%, 0% 100%);
  }
  60% {
    clip-path: polygon(33% 58%, 67% 55%, 100% 100%, 0% 100%);
  }
  65% {
    clip-path: polygon(35% 62%, 65% 60%, 100% 100%, 0% 100%);
  }
  70% {
    clip-path: polygon(38% 68%, 62% 65%, 100% 100%, 0% 100%);
  }
  75% {
    clip-path: polygon(40% 72%, 60% 70%, 100% 100%, 0% 100%);
  }
  80% {
    clip-path: polygon(43% 78%, 57% 75%, 100% 100%, 0% 100%);
  }
  85% {
    clip-path: polygon(45% 82%, 55% 80%, 100% 100%, 0% 100%);
  }
  90% {
    clip-path: polygon(48% 88%, 52% 85%, 100% 100%, 0% 100%);
  }
  95% {
    clip-path: polygon(50% 92%, 50% 90%, 100% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(50% 100%, 50% 95%, 100% 100%, 0% 100%);
  }
`;
