import styled from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  gap,
  gray,
  objHeight,
  padding,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const BlogViewContainer = styled.div`
  width: 100%;
  height: 100%;
  // background-color: yellow;
`;
export const BlogViewBox = styled.div`
  width: 100%;
  height: 100%;
  // background-color: green;
`;
export const BlogInfo = styled.div`
  width: 100%;
  // height: ${objHeight.largeMore};
  padding-bottom: ${padding.mediumLarge};

  font-size: ${fontSize.medium};
  color: ${gray.medium};

  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: blue;
`;

export const BlogInfoMobile = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: ${padding.mediumLarge};

  font-size: ${fontSize.medium};
  color: ${gray.medium};

  display: flex;
  flex-direction: column;

  // align-items: flex-start;
  // justify-content: space-between;
  // align-items: center;
`;

export const BlogTitle = styled.div`
  padding: ${padding.medium} 0 ${padding.medium} 0;
  width: 100%; /* 필요한 경우 너비 조정 */

  word-wrap: break-word; /* 길이가 긴 단어를 줄바꿈 */
  word-break: break-all; /* 길이가 긴 단어를 잘라서 줄바꿈 */
  white-space: normal; /* 기본 줄바꿈 방식으로 설정 */
  ${media.mobile`
  padding: ${padding.small} 0;
  `}

  font-size: ${fontSize.categoryLarge};
  font-weight: ${fontWeight.bold};
  color: ${gray.darktext};

  display: flex;
  align-items: center;
`;

export const BlogInfoLeft = styled.div`
  height: 100%;
`;
export const BlogInfoRight = styled.div`
  height: 100%;
  gap: ${gap.small};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const GoToList = styled.div`
  color: ${gray.darktext};
  font-weight: ${fontWeight.bold};
  cursor: pointer;
`;

export const BlogViewTitleHr = styled.hr`
  border: none;
  border-top: 2px solid ${(props) => props.color || gray.light};
`;

export const AttachedFile = styled.div`
  padding-top: ${padding.medium};
  display: flex;
  // justify-content: flex-end;
`;
export const BlogContent = styled.div`
  width: 100%;
  img {
    max-width: 100%;
    height: auto;
  }

  padding: ${padding.mediumLarge} 0;
  ${media.mobile`
  padding: ${padding.small} 0;
  `}
  word-wrap: break-word; /* 길이가 긴 단어를 줄바꿈 */
  word-break: break-all; /* 길이가 긴 단어를 잘라서 줄바꿈 */
  white-space: normal; /* 기본 줄바꿈 방식으로 설정 */
  overflow-y: auto;
  color: ${gray.darktext};
  // background-color: ${colors.background};
  // background-color: red;
`;
