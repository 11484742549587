import { NavLink } from '../../node_modules/react-router-dom/dist/index';
import LogoutComponent from '../components/Logout';
import { GreenBadge, NewBadge, YellowBadge } from '../styles/BasicStyle';
import { SubheaderBarContainer } from './HeaderMenuBarStyle';

export const SubHeaderMenubar_SeatDraw = () => {
  return (
    <>
      <SubheaderBarContainer>
        <NavLink to="/random-seat-assignment">
          자리뽑기<YellowBadge>UP</YellowBadge>
        </NavLink>
        <NavLink to="/random-seat-assignment-setup">
          자리뽑기 <GreenBadge>Beta</GreenBadge>
          <YellowBadge>UP</YellowBadge>
        </NavLink>
      </SubheaderBarContainer>
    </>
  );
};

export const SubHeaderMenubar_Progress = () => {
  return (
    <>
      {' '}
      <SubheaderBarContainer>
        <NavLink to="/lesson-progress-tracker">진도표</NavLink>
        <NavLink to="/progress-notes/by-class">진도노트</NavLink>
        <NavLink to="/topic-management">수업주제 설정</NavLink>
        <NavLink to="/class-management">담당학급 설정</NavLink>
      </SubheaderBarContainer>
    </>
  );
};
export const SubHeaderMenubar_User = () => {
  return (
    <>
      {' '}
      <SubheaderBarContainer>
        {/* <NavLink to="/download-desktop-guide">다운로드</NavLink> */}
        <NavLink>
          <LogoutComponent />
        </NavLink>
      </SubheaderBarContainer>
    </>
  );
};
