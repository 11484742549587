import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules'; // Pagination 모듈을 제거
import styled from 'styled-components';
import { colors } from '../../styles/commonStyle';
import {
  CardContainer_RoundCard,
  CardDescription_SmallRoundCard,
  CardImage_SmallRoundCard,
  CarouselWrapper,
  StyledSwiper,
} from './CardCarouselStyle';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { useMediaQuery } from 'react-responsive';

// 001.png부터 050.png까지 아이콘 경로 배열 생성
const icons = Array.from({ length: 50 }, (_, i) =>
  require(`../../img/edu-issue-keywords/${String(i + 1).padStart(3, '0')}.png`),
);

// 서로 다른 9개의 랜덤 아이콘 선택 함수
const getRandomIcons = (itemsToDisplay) => {
  const shuffledIcons = icons.sort(() => 0.5 - Math.random()); // 아이콘 배열 섞기
  return shuffledIcons.slice(0, itemsToDisplay);
};

const SmallRoundCardCarousel = () => {
  const [tags, setTags] = useState([]);
  const [latestPosts, setLatestPosts] = useState({});
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const itemsToDisplay = isMobile ? 5 : 9;

  useEffect(() => {
    fetch('https://teachernote.me/api/tags')
      .then((response) => response.json())
      .then((data) => {
        const randomIcons = getRandomIcons(itemsToDisplay); // 9개의 랜덤 아이콘 선택
        const sortedTags = data
          .sort((a, b) => b.id - a.id)
          .slice(0, itemsToDisplay)
          .map((tag, index) => ({
            id: tag.id,
            imageUrl: randomIcons[index],
            description: tag.tag_name,
          }));
        setTags(sortedTags);

        // 각 태그의 최신 게시글을 미리 가져와 상태에 저장
        sortedTags.forEach((tag) => {
          fetch(`https://teachernote.me/api/blogs/tag/${tag.description}`)
            .then((response) => response.json())
            .then((posts) => {
              if (posts.length > 0) {
                setLatestPosts((prevPosts) => ({
                  ...prevPosts,
                  [tag.description]: posts[0], // 각 태그의 최신 글 저장
                }));
              }
            })
            .catch((error) =>
              console.error(
                `Error fetching latest post for ${tag.description}:`,
                error,
              ),
            );
        });
      })
      .catch((error) => console.error('Error fetching tags:', error));
  }, []);

  // 아이콘 클릭 시 최신 블로그로 이동하는 함수
  const handleIconClick = (tagName) => {
    const latestPost = latestPosts[tagName];
    if (latestPost) {
      navigate(
        `/education-resources-sharing/${latestPost.category}/${latestPost.id}`,
      ); // 저장된 최신 글의 ID로 이동
    } else {
      alert('해당 태그의 게시글이 없습니다.');
    }
  };

  return (
    <CarouselWrapper>
      <StyledSwiper
        modules={[Navigation]} // Pagination 모듈 제거
        navigation
        spaceBetween={1}
        slidesPerView={itemsToDisplay}
        slidesPerGroup={itemsToDisplay} // 한 번에 이동할 슬라이드 수
        loop={false}
      >
        {tags.map((tag) => (
          <SwiperSlide key={tag.id}>
            <CardContainer_RoundCard
              onClick={() => handleIconClick(tag.description)}
              style={{ cursor: 'pointer' }}
            >
              <CardImage_SmallRoundCard
                src={tag.imageUrl}
                alt={tag.description}
              />
              <CardDescription_SmallRoundCard>
                #{tag.description}
              </CardDescription_SmallRoundCard>
            </CardContainer_RoundCard>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </CarouselWrapper>
  );
};

export default SmallRoundCardCarousel;
