import { useContext } from 'react';
import axios from '../../node_modules/axios/index';
import UserContext from '../contexts/userContext';
import { useNavigate } from '../../node_modules/react-router-dom/dist/index';
// import { useUser } from '../contexts/userContext';

const LogoutComponent = () => {
  // alert('로그아웃');
  const token = localStorage.getItem('token');
  const { actions } = useContext(UserContext); // UserContext에서 actions 가져오기
  const logout = () => {
    // console.log('로그아웃 token:' + token);
    // 로그아웃을 위해 서버에 토큰전달(블랙리스트 추가)
    axios
      .post(
        'https://teachernote.me/api/logout',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          actions.setUser(null); // UserContext의 상태 업데이트
          window.location.href = '/';
        } else {
          console.error('로그아웃 실패');
        }
      })
      .catch((error) => {
        console.error('로그아웃 요청 실패:', error);
      });
  };
  return <div onClick={logout}>로그아웃</div>;
};
export default LogoutComponent;
