import styled from 'styled-components';
import { Link } from '../../node_modules/react-router-dom/dist/index';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gray,
  margin,
  objHeight,
  padding,
  ratio,
} from '../styles/commonStyle';
import { media } from '../styles/media';

//pages/progress/ProgressNote.js파일에 대한 스타일

export const MarginRightText = styled.div`
  margin-right: ${margin.medium};
  text-align: center;
`;

export const ProgressNoteLink = styled(Link)`
  padding: ${padding.small} ${padding.medium};
  margin-right: ${margin.medium};

  ${media.mobile`
    display: none;
  `}

  border: 1px solid ${gray.darktext};
  border-radius: ${borderRadius.medium};
  text-align: center;

  // color: ${gray.light};
  color: ${gray.darktext};
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
  text-decoration: none;
  // background-color: ${colors.primary};
  background-color: ${colors.background};
  &:hover {
    // background-color: ${gray.medium};
    background-color: ${colors.lightGreenBackground};
  }
`;

export const ProgressNoteTitleNum = styled(Link)`
  padding: ${padding.small} ${padding.medium};
  margin-right: ${margin.medium};

  border-radius: ${borderRadius.medium};
  text-align: center;

  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.medium};
  text-decoration: none;
  // background-color: ${colors.primary};
  // color: ${gray.light};
  color: ${colors.background};
  background-color: ${gray.medium};

  cursor: default;
`;

export const Left = styled.div`
  width: 10%;

  ${media.mobile`
  width: 21%;
  display:flex;
  justify-content: center;
  `}
`;

export const Middle = styled.div`
  width: 80%;

  ${media.mobile`
  width:79%;
  `}
`;
export const Right = styled.div`
  width: 10%;
  ${media.mobile`
  display: none;
  `}

  color: ${colors.primary};
  font-weight: ${fontWeight.bold};
  cursor: pointer;

  display: flex;
  justify-content: right;
`;
export const ProgressNoteTopicDiv = styled.div`
  padding: ${padding.mediumLarge} ${padding.large};

  ${media.mobile`
  padding: ${padding.medium};
  `}
  border-bottom: 1px solid ${gray.light};
  cursor: default;

  display: flex;
  align-items: center;
`;
export const ProgressNoteTextAreaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const ProgressNoteTextArea = styled.textarea`
  width: ${ratio.inner};
  height: ${objHeight.extraLarge};  

    padding: ${padding.medium} ${padding.medium} ${padding.medium}
  0;

  font-size: ${fontSize.large};
  font-family: 'Noto Sans KR', sans-serif;

  resize: none;
  border: none;
  outline: none; /* 포커스시 테두리 제거 */
  autofocus;
`;
