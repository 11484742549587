import { useEffect, useState } from 'react';
import {
  AuthBox,
  AuthCardContainer_Mobile,
  AuthLayout1,
  AuthLayout2,
  AuthLayout3,
  Terms,
} from './Register2Style';
import { AuthCardContainer } from '../../components/AuthCardContainer';
import Input from '../../components/StyledInput';
import SubButton from '../../components/SubButton';
import {
  AuthAlertText,
  AuthRightText,
  AuthStyledDiv,
  AuthStyledLink,
} from '../../components/AuthPageStyle';
import MainButton from '../../components/MainButton';
import LinkTo from '../../components/LinkTo';
import KakaoLoginButton from '../../components/KakaoLoginButton';
import { GreenBadge } from '../../styles/BasicStyle';
import logo_horizontal from '../../img/teachernote-logo/teachernote-logo-horizontal.png';

const RegisterModal_Mobile = ({
  saveRegisterInfo,
  isValidUserId,
  isValidPassword,
  isValidPasswordConfirm,
  isValidEmail,
  isValidRegister,
  handleUserIdChange,
  handleEmailChange,
  handlePasswordChange,
  handlePasswordConfirmChange,
  findSameId,
  isFirstUserId,
  didUserIdCheck,
  openLoginModal,
  handleAuthComplete,
  handleTermsCheck,
  isTermsChecked,
}) => {
  const [showUserIdAvailableMessage, setShowUserIdAvailableMessage] =
    useState(false);

  useEffect(() => {
    if (isFirstUserId !== null && isFirstUserId) {
      setShowUserIdAvailableMessage(true);
      const timer = setTimeout(() => {
        setShowUserIdAvailableMessage(false);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isFirstUserId]);

  return (
    <AuthBox>
      <AuthCardContainer_Mobile>
        <AuthLayout1>
          <div>
            <img
              src={logo_horizontal}
              style={{ width: '150px' }}
              alt="티처노트 가로형 로고"
            />
          </div>
          자리뽑기<GreenBadge>Beta</GreenBadge>와 다양한 기능들을 사용해보세요!{' '}
        </AuthLayout1>
        <form className="form" onSubmit={saveRegisterInfo}>
          <div>
            <Input
              type="id"
              placeholder="아이디"
              onChange={handleUserIdChange}
              name="userId"
            />
          </div>
          <div>
            <SubButton onClick={findSameId}>중복확인</SubButton>
          </div>
          {!isValidUserId && (
            <AuthAlertText>
              아이디는 5글자 이상 16글자 이하여야 하며, 영문 소문자와 숫자만
              허용됩니다.
            </AuthAlertText>
          )}

          {showUserIdAvailableMessage && (
            <AuthRightText>사용 가능한 아이디입니다.</AuthRightText>
          )}
          {isFirstUserId !== null && !isFirstUserId && (
            <AuthAlertText>이미 사용중인 아이디입니다.</AuthAlertText>
          )}
          <div>
            <Input
              type="mail"
              placeholder="메일주소"
              onChange={handleEmailChange}
              name="email"
            />
          </div>

          <div>
            <Input
              type="password"
              placeholder="비밀번호"
              onChange={handlePasswordChange}
              name="password"
            />
          </div>
          {!isValidPassword && (
            <AuthAlertText style={{ color: 'red' }}>
              비밀번호는 9~15자, 영문과 숫자를 포함해야 합니다.
            </AuthAlertText>
          )}
          <div>
            <Input
              type="password"
              placeholder="비밀번호 확인"
              onChange={handlePasswordConfirmChange}
              name="passwordConfirm"
            />
          </div>
          {!isValidPasswordConfirm && (
            <AuthAlertText style={{ color: 'red' }}>
              비밀번호와 같지 않습니다.
            </AuthAlertText>
          )}
          <Terms>
            <input
              type="checkbox"
              onChange={handleTermsCheck}
              checked={isTermsChecked}
            />
            <label>
              <a
                href="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                이용약관{' '}
              </a>
              및{' '}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                개인정보 처리방침
              </a>
              에 동의합니다.
            </label>
          </Terms>
          <MainButton onClick={saveRegisterInfo}>가입하고 이용하기</MainButton>
          {/* didUserIdCheck: {JSON.stringify(didUserIdCheck)} */}
          {didUserIdCheck !== null && !didUserIdCheck && (
            <AuthAlertText>아이디 중복 체크를 해주세요.</AuthAlertText>
          )}
          {!isValidRegister && (
            <AuthAlertText>회원가입 양식을 확인해주세요.</AuthAlertText>
          )}
        </form>
        {/* <KakaoLoginButton handleAuthComplete={handleAuthComplete} /> */}
        <LinkTo>
          <AuthStyledDiv
            onClick={() => {
              openLoginModal();
            }}
          >
            {/* 🤔 */}
            이미 아이디가 있으신가요?
          </AuthStyledDiv>
        </LinkTo>
      </AuthCardContainer_Mobile>
    </AuthBox>
  );
};

export default RegisterModal_Mobile;
