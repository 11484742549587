import styled from 'styled-components';
import {
  borderRadius,
  fontSize,
  gray,
  objWidth,
  padding,
} from '../styles/commonStyle';

export const MenuContainer = styled.div`
  position: absolute;
  right: 0; /* 메뉴 아이콘을 맨 오른쪽에 배치 */
  display: inline-block;
  z-index: 100;
  padding: ${padding.small} ${padding.mediumLarge} 0 ${padding.mediumLarge};
  // background-color: yellow;
  // display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DropdownMenu = styled.div`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  transform: translateX(-80%);

  min-width: ${objWidth.mediumLarge};
  padding: ${padding.small};

  background-color: white;
  //   border: 1px solid #ccc;
  border-radius: ${borderRadius.medium};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const MenuItem = styled.div`
  padding: ${padding.small} ${padding.mediumLarge};
  border-radius: ${borderRadius.medium};

  font-size: ${fontSize.medium};

  &:hover {
    background-color: ${gray.light};
  }
`;
