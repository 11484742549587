import styled from 'styled-components';
import {
  fontSize,
  fontWeight,
  margin,
  pageWidth,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const MainContentBox = styled.div`
  ${media.desktop`
width: ${pageWidth.desktop};  
margin: ${margin.extraLarge} 0px;
  `}

  ${media.tablet`
width: ${pageWidth.tablet};
margin: ${margin.extraLarge} 0px;
      `}

  ${media.mobile`
  width:90%;
   margin: ${margin.mediumlarge} 0px;

  `}
`;

export const MainContentTitle = styled.div`
  font-size: ${fontSize.extraLarge};
  font-weight: ${fontWeight.bold};
  margin-bottom: ${margin.medium};
  ${media.mobile`
  font-size: ${fontSize.categoryLarge};
  `}
`;

export const SeeMore = styled.div`
  display: flex;
  justify-contents: center;
  align-items: center;
`;
