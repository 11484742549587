import styled from 'styled-components';
import { media } from '../../styles/media';

export const CarouselContainer = styled.div`
  width: 100%;
  margin: 0;
  overflow: hidden;
  position: relative;
`;

export const CarouselWrapper = styled.div`
  // position: relative;
  width: 100%;
  height: 400px;
  ${media.mobile`
    height: 230px;
    `}
`;

export const CarouselSlide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${media.mobile`
    padding: 0px 30px;
    `}
  // background-color: yellow;

  background-color: ${({ bgColor }) => bgColor || '#ddd'};
  // background-image: ${({ bgImage }) => `url(${bgImage})`};
  // background-size: cover; /* 이미지가 div의 너비에 맞게 조정됩니다 */
  // background-size: auto; /* 이미지가 div의 너비에 맞게 조정됩니다 */
  // background-repeat: no-repeat; /* 이미지 반복을 방지합니다 */
  // background-position: center; /* 이미지가 가운데에 위치하도록 설정합니다 */

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  cursor: pointer;
  // color: white;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  z-index: ${({ isActive }) => (isActive ? 1 : 0)};

  img {
    width: 100%; /* 부모 요소의 너비에 맞춰 이미지를 조절 */
    height: auto; /* 이미지의 비율을 유지하면서 조절 */
    max-width: calc(100% - 60px); /* 좌우 padding을 뺀 너비로 설정 */
  }
`;
export const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  font-size: 1rem;
  padding: 10px;
  border-radius: 200px;
  cursor: pointer;
  z-index: 2;

  ${({ direction }) => (direction === 'prev' ? 'left: 10px;' : 'right: 10px;')}
`;

export const NavigationButton_Large = styled(NavigationButton)`
  font-size: 2.5rem;
`;
