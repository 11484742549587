import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules'; // Pagination 모듈을 제거
import styled from 'styled-components';
import { colors, gray, margin } from '../../styles/commonStyle';
import {
  CardContainer,
  CardDescription,
  CarouselWrapper,
  StyledSwiper,
  CardTitle,
  CardCarouselImage,
} from './CardCarouselStyle';
import { extractImageUrlFromContent } from '../../pages/main/Main';
import TruncateText from '../../commonFunction/TruncateText';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { RedBadge_OnItem, YellowBadge } from '../../styles/BasicStyle';
import { MdAttachFile } from 'react-icons/md';

const CardCarousel = ({ cards, viewNum }) => {
  const navigate = useNavigate();

  const handleCardClick = (cardId, cardCategory) => {
    navigate(`/education-resources-sharing/${cardCategory}/${cardId}`);
  };

  return (
    <CarouselWrapper>
      <StyledSwiper
        modules={[Navigation]} // Pagination 모듈 제거
        navigation
        spaceBetween={30}
        slidesPerView={viewNum}
        slidesPerGroup={viewNum} // 한 번에 이동할 슬라이드 수
        loop={true}
      >
        {cards.map((card) => (
          <SwiperSlide key={card.id}>
            <CardContainer
              onClick={() => handleCardClick(card.id, card.category)}
              style={{ cursor: 'pointer' }}
            >
              {' '}
              {card.isNew && <RedBadge_OnItem>NEW</RedBadge_OnItem>}
              <CardCarouselImage
                src={extractImageUrlFromContent(card.description)}
                alt={card.title}
                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
              />
              <CardTitle>
                {TruncateText(card.title, 40)}
                {card.fileUrl && (
                  <>
                    <YellowBadge style={{ marginRight: `${margin.small}` }}>
                      <MdAttachFile />
                      공유
                    </YellowBadge>
                  </>
                )}
              </CardTitle>
              {/* <CardDescription>{card.description}</CardDescription> */}
            </CardContainer>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </CarouselWrapper>
  );
};

export default CardCarousel;
