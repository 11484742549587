import { useCallback, useContext, useEffect, useState } from 'react';
import ProgressNote from './ProgressNote';
import axios from '../../../node_modules/axios/index';
import UserContext from '../../contexts/userContext';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { useMediaQuery } from 'react-responsive';

const ProgressNoteContainer = (props) => {
  const {
    grade,
    classNum,
    topicNum,
    afterSaveNote,
    topicTitle,
    handleProgressNote,
    setIsProgressNoteVisible,
    fetchProgressNoteByGrade,
    setRegisterModalOpen,
  } = props;

  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  //해당 진도 노트 데이터 가져와서 보여주기
  const [progressNoteOneDB, setProgressNoteOneDB] = useState([]);
  const [noteId, setNoteId] = useState();
  useEffect(() => {
    const fetchProgressNoteOne = async () => {
      if (!userId) {
        try {
          const res = await axios.get(
            'https://teachernote.me/api/progressnoteone/teachernote',
            {
              params: { grade: 2, classNum: classNum, topicNum: topicNum }, // 올바른 요청 옵션 사용
            },
          );
          setProgressNoteOneDB(res.data);
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          const res = await axios.get(
            'https://teachernote.me/api/progressnoteone/' + userId,
            {
              params: { grade: grade, classNum: classNum, topicNum: topicNum }, // 올바른 요청 옵션 사용
            },
          );
          setProgressNoteOneDB(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchProgressNoteOne();
  }, [props]);

  useEffect(() => {
    if (progressNoteOneDB.length > 0) {
      setNoteId(progressNoteOneDB[0].id);
      // console.log('1' + noteId);
    }
  }, [progressNoteOneDB]);

  //진도 노트 저장하기

  const navigate = useNavigate();
  const saveProgressNote = async (e, value) => {
    if (!userId) {
      alert('로그인 후 이용가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      if (!value || value.trim() === '') {
        alert('노트를 입력해주세요.');
        return;
      }
      // e.preventDefault();
      const noteInfo = {
        grade: parseInt(grade),
        classNum: classNum,
        topicNum: topicNum,
        record: value,
      };

      if (progressNoteOneDB && progressNoteOneDB.length > 0) {
        if (progressNoteOneDB[0].record === value) {
          alert('변화한 내용이 없습니다.');
          return;
        }
      }

      if (progressNoteOneDB && progressNoteOneDB.length > 0) {
        // 레코드 있는 경우 업데이트
        try {
          await axios
            .put(
              'https://teachernote.me/api/progressnoteone/' + userId,
              noteInfo,
            )
            .then(() => {
              fetchProgressNoteByGrade();
            });
          afterSaveNote();
        } catch (err) {
          console.log(err);
        }
      } else {
        // 레코드 없는 경우 추가
        try {
          await axios
            .post(
              'https://teachernote.me/api/progressnoteone/' + userId,
              noteInfo,
            )
            .then(() => {
              fetchProgressNoteByGrade();
            });
          afterSaveNote();
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  //진도 노트 삭제하기
  const deleteProgressNote = () => {
    if (!userId) {
      alert('로그인 후 이용가능합니다.');
      if (!isMobile) {
        setRegisterModalOpen(true);
      } else {
        navigate('/m-sign-up');
      }
    } else {
      const userConfirmed = window.confirm('진도 노트를 삭제하시겠습니까?');
      if (userConfirmed) {
        // console.log('2' + noteId);

        try {
          axios
            .delete('https://teachernote.me/api/deleteprogressnote/' + userId, {
              data: { noteId: noteId },
            })
            .then(() => {
              fetchProgressNoteByGrade();
              setIsProgressNoteVisible(false);
            });
        } catch (err) {
          console.log(err);
        }
      } else {
        // 사용자가 취소를 선택한 경우
      }
    }
  };

  return (
    <ProgressNote
      grade={grade}
      classNum={classNum}
      topicNum={topicNum}
      topicTitle={topicTitle}
      progressNoteOne={progressNoteOneDB}
      saveProgressNote={saveProgressNote}
      afterSaveNote={afterSaveNote}
      handleProgressNote={handleProgressNote}
      deleteProgressNote={deleteProgressNote}
    />
  );
};

export default ProgressNoteContainer;
