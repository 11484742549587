import { useState } from 'react';
import {
  DrawBox,
  DrawBox_Bottom,
  DrawBox_Content_100,
  DrawBox_Main,
  DrawBox_Title,
  DrawBox_Title_Text,
  OneByOneButton,
} from '../../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import {
  DisplayRow,
  DisplayRowSpaceBetween,
  InputSmall,
  PrimaryButton,
  TabButton,
  TabContainer,
  TabContent,
} from '../../../styles/BasicStyle';
import {
  PickerSelect,
  PresenterPick,
  SelectPickMode,
  SelectPickModeContainer,
  StudentNumber,
} from '../PresenterPickerStyle';
import Roulette from './Roulette';
import SnowmanMelt from './SnowMan';
import Jelly from './JellyPicker';
import JellyPicker from './JellyPicker';
import { useMediaQuery } from 'react-responsive';
import { padding } from '../../../styles/commonStyle';

const PresenterPicker = ({
  numberList,
  setNumberList,
  studentCount,
  setStudentCount,
}) => {
  const [presenter, setPresenter] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState('roulette');
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const pickPresenter = () => {
    if (numberList.length > 0) {
      const randomIndex = Math.floor(Math.random() * numberList.length);
      setPresenter(numberList[randomIndex]);
    }
  };

  // 학생 수 업데이트 함수
  const handleStudentCountChange = (e) => {
    setStudentCount(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      updateNumberList();
    }
  };

  // 학생 수 확인 버튼 클릭 시 numberList 업데이트 및 발표자 뽑기
  const updateNumberList = () => {
    const newCount = parseInt(studentCount, 10);
    if (newCount > 50) {
      alert('학생 수는 50명 미만이어야 합니다.');
      setStudentCount('');

      return; // 100명 이상일 경우 함수 종료
    }
    if (newCount > 0) {
      setNumberList(Array.from({ length: newCount }, (_, index) => index + 1));
      pickPresenter(); // 발표자 뽑기 함수 호출
    }
  };

  return (
    <>
      <DrawBox>
        <DrawBox_Content_100>
          <SelectPickModeContainer>
            <SelectPickMode>
              <PickerSelect>
                <TabContainer>
                  <TabButton
                    active={selectedStyle === 'roulette'}
                    onClick={() => setSelectedStyle('roulette')}
                  >
                    회전 룰렛
                  </TabButton>
                  <TabButton
                    active={selectedStyle === 'jelly'}
                    onClick={() => setSelectedStyle('jelly')}
                  >
                    100%당첨 젤리뽑기
                  </TabButton>
                  <TabButton
                    active={selectedStyle === 'snowman'}
                    onClick={() => setSelectedStyle('snowman')}
                  >
                    눈사람 녹이기
                  </TabButton>
                </TabContainer>
              </PickerSelect>
              <StudentNumber>
                <div>
                  <span style={{ paddingRight: `${padding.small}` }}>
                    학생수
                  </span>
                  <InputSmall
                    type="number"
                    value={studentCount}
                    onChange={handleStudentCountChange}
                    onKeyPress={handleKeyPress}
                    min="1"
                  />
                  명
                </div>
                <div>
                  <OneByOneButton onClick={updateNumberList}>
                    학생수 변경
                  </OneByOneButton>
                </div>
              </StudentNumber>
            </SelectPickMode>
          </SelectPickModeContainer>

          <PresenterPick>
            {selectedStyle === 'roulette' && (
              <Roulette
                numberList={numberList}
                pickPresenter={pickPresenter}
                setNumberList={setNumberList}
              />
            )}
            {selectedStyle === 'jelly' && (
              <JellyPicker
                numberList={numberList}
                pickPresenter={pickPresenter}
                presenter={presenter}
              />
            )}
            {selectedStyle === 'snowman' && (
              <SnowmanMelt
                presenter={presenter}
                pickPresenter={pickPresenter}
              />
            )}
          </PresenterPick>

          {/* <DrawBox_Bottom>
        {' '}
        {presenter && <p>오늘의 발표자: {presenter}번 학생</p>}
      </DrawBox_Bottom> */}
        </DrawBox_Content_100>
      </DrawBox>
    </>
  );
};

export default PresenterPicker;
