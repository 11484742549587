import { useCallback, useEffect, useRef, useState } from 'react';
import PreAssignment from './PreAssignment';
import {
  ModalButton,
  ModalContent,
  ModalTitle,
  ModalWrapper,
} from '../../../styles/BasicStyle';

const PreAssignmentContainer = ({
  setSeats,
  setBlockedSeats,
  setPairMode,
  pairMode,
  setGenderMode,
  genderMode,
  seats,
  blockedSeats,
  preAssigningSeats,
  genderMap,
  genderSeatRule,
  //   handleSeatClick,
  //   handlePreAssignInput,
  setGenderSeatRule,
  setPreAssigningSeats,
  numberList,
  notUsingNumbers,
  setNumberList,
  savePreAssignment,
  // isChangeSeatMode,
  // setIsChangeSeatMode,
  seatPreAssignments,
  setSeatPreAssignments,
  fetchPreAssignments,
  cornerNums,
  adjacentNums,
  setCornerNums,
  setAdjacentNums,
  showNameMode,
  setShowNameMode,
  students,

  isSelectingStudent,
  setIsSelectingStudent,
  duplicateStudents,
  setDuplicateStudents,
  selectedStudentNum,
  setSelectedStudentNum,
  frontNums,
}) => {
  const [isPreAssignMode, setIsPreAssignMode] = useState(true);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState([]);

  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setSelectedSeat(null);
      }
    };

    if (selectedSeat) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSeat]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        !event.target.closest('.modal-content')
      ) {
        setSelectedSeat(null);
        // inputRef.current이 존재하고 selectedSeat이 있을 때 handlePreAssignInput 호출
        if (selectedSeat) {
          handlePreAssignInput({ target: inputRef.current });
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedSeat]);

  // --------------------  사전 자리 세팅  -------------------- //

  //미리 번호 배치하는 모드면 번호 배치, 그게 아니면 사용 여부 X 토글
  const handleSeatClick = (rowIdx, colIdx) => {
    const seatKey = `${rowIdx}-${colIdx}`;

    if (isPreAssignMode) {
      if (blockedSeats.has(seatKey)) {
        return; // blockedSeats에 포함된 좌석이면 클릭 무시
      }
      // seatPreAssignments에 미리 배치된 번호가 있으면 삭제
      if (seatPreAssignments[seatKey] || preAssigningSeats[seatKey]) {
        const updatedPreAssignedSeats = { ...seatPreAssignments };
        delete updatedPreAssignedSeats[seatKey];
        setPreAssigningSeats(updatedPreAssignedSeats);
        setSeatPreAssignments(updatedPreAssignedSeats);
        return;
      }
      // 클릭한 자리에 번호 배치
      setSelectedSeat(seatKey);
    } else {
      // 다른 모드일 때 동작 (필요 시 추가)
    }
  };

  //미리 번호 배치
  const handlePreAssignInput = (event) => {
    let { value } = event.target;
    value = value.trim();

    if (value === '') {
      return;
    }
    if (!isNaN(value)) {
      value = value.toString();
    } else if (showNameMode) {
      const matchedStudents = students.filter(
        (student) => student.name === value,
      );
      if (matchedStudents.length > 1) {
        setDuplicateStudents(matchedStudents); // 중복된 학생 설정
        setIsSelectingStudent(true); // 모달 열기
        return;
      } else if (matchedStudents.length === 1) {
        value = matchedStudents[0].number.toString(); // 이름을 번호로 변환
      } else {
        alert('존재하지 않는 이름입니다.');
        setSelectedSeat(null);
        return;
      }
    } else {
      alert('유효하지 않은 번호 또는 이름입니다.');
      setSelectedSeat(null);
      return;
    }

    if (selectedSeat) {
      const [rowIdx, colIdx] = selectedSeat.split('-').map(Number);
      const seatGender = genderMap[value];

      const isGenderMismatch = genderSeatRule
        ? (seatGender === '여' && colIdx % 2 === 0) ||
          (seatGender === '남' && colIdx % 2 !== 0)
        : (seatGender === '여' && colIdx % 2 !== 0) ||
          (seatGender === '남' && colIdx % 2 === 0);

      const isAlreadyAssigned =
        Object.values(preAssigningSeats).includes(value) ||
        Object.values(seatPreAssignments).includes(value);

      const isNotUsingNumber = notUsingNumbers.includes(parseInt(value));

      const checkInGroups = [
        { group: frontNums, name: '앞에 앉을 학생' },
        { group: cornerNums, name: '집중이 필요한 학생' },
        {
          group: adjacentNums ? adjacentNums.flat() : [],
          name: '가까이 앉을 학생',
        },
      ];

      // group이 undefined가 아닌지 확인
      const foundGroup = checkInGroups.find(
        ({ group }) => group && group.includes(parseInt(value)),
      );

      if (foundGroup) {
        alert(
          `'${foundGroup.name}'에 등록된 번호입니다. 기존 등록 내역을 삭제하고 진행해주세요.`,
        );
        event.target.value = ''; // input 창 비우기
        setSelectedSeat(null);
        return;
      }

      const assignedSeatCount = Object.keys(seatPreAssignments).filter(
        (key) => seatPreAssignments[key] !== null,
      ).length;

      if (assignedSeatCount >= 4) {
        alert('미리 배치할 수 있는 번호는 최대 4개입니다.');
        setSelectedSeat(null);
        return;
      }

      if (seatGender && isGenderMismatch && genderMode) {
        alert('선택한 번호의 성별과 자리가 맞지 않습니다.');
        setSelectedSeat(null);
      } else if (isNotUsingNumber) {
        alert('사용할 수 없는 번호입니다. 학생 설정에서 X표시를 확인해주세요.');
        setSelectedSeat(null);
      } else if (isAlreadyAssigned) {
        const confirmChange = window.confirm(
          '이미 배치된 번호입니다. 이 자리로 바꾸시겠습니까?',
        );
        if (confirmChange) {
          const updatedPreAssignedSeats = Object.fromEntries(
            Object.entries(preAssigningSeats).map(([key, val]) =>
              val === value ? [key, null] : [key, val],
            ),
          );

          const updatedSeatAssignments = Object.fromEntries(
            Object.entries(seatPreAssignments).map(([key, val]) =>
              val === value ? [key, null] : [key, val],
            ),
          );

          setPreAssigningSeats({
            ...updatedPreAssignedSeats,
            [selectedSeat]: value,
          });

          setSeatPreAssignments(updatedSeatAssignments);
        }
        setSelectedSeat(null);
      } else {
        setPreAssigningSeats((prev) => ({
          ...prev,
          [selectedSeat]: value,
        }));
        setSelectedSeat(null);
      }
    }
  };

  // --------------------  중복 학생 선택 핸들러  -------------------- //
  // 중복 학생 선택 핸들러 추가
  const CornerModalSelectNumion = (studentNumber) => {
    setSelectedStudentNum(studentNumber); // 선택된 번호 설정

    // 선택된 학생 번호를 바탕으로 handlePreAssignInput 호출
    handlePreAssignInput({ target: { value: studentNumber.toString() } });

    setIsSelectingStudent(false); // 모달 닫기
    // setSelectedSeat(null); // 자리 선택 해제
  };

  // --------------------  자리 리셋, 불러오기  -------------------- //

  //자리 리셋
  const handleReset = () => {
    setPreAssigningSeats({});
    setSeatPreAssignments({});
  };

  //자리 불러오기
  const handleLoad = () => {
    setBlockedSeats(blockedSeats);
    fetchPreAssignments();
  };

  return (
    <>
      <PreAssignment
        pairMode={pairMode}
        genderMode={genderMode}
        setIsPreAssignMode={setIsPreAssignMode}
        isPreAssignMode={isPreAssignMode}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        seats={seats}
        blockedSeats={blockedSeats}
        preAssigningSeats={preAssigningSeats}
        genderMap={genderMap}
        genderSeatRule={genderSeatRule}
        selectedSeat={selectedSeat}
        handleSeatClick={handleSeatClick}
        handlePreAssignInput={handlePreAssignInput}
        savePreAssignment={savePreAssignment}
        inputRef={inputRef}
        seatPreAssignments={seatPreAssignments}
        handleReset={handleReset}
        handleLoad={handleLoad}
        showNameMode={showNameMode}
        students={students}
        isSelectingStudent={isSelectingStudent}
        setIsSelectingStudent={setIsSelectingStudent}
        duplicateStudents={duplicateStudents}
        setDuplicateStudents={setDuplicateStudents}
        selectedStudentNum={selectedStudentNum}
        setSelectedStudentNum={setSelectedStudentNum}
      />
      {/* 이름 중복 시 모달 표시 */}
      {isSelectingStudent && (
        <ModalWrapper
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setIsSelectingStudent(false); // 모달 바깥 클릭 시 닫기
            }
          }}
        >
          <ModalContent className="modal-content">
            <ModalTitle>학생 이름 중복</ModalTitle>
            <div>중복된 학생 이름이 있습니다. 학생 번호를 선택해주세요.</div>
            <div>
              {duplicateStudents.map((student) => (
                <ModalButton
                  key={student.number}
                  onClick={() => CornerModalSelectNumion(student.number)}
                >
                  {student.number} {student.name}
                </ModalButton>
              ))}
            </div>
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
};
export default PreAssignmentContainer;
