import { useState } from 'react';
import {
  AddTagButton,
  RemoveTagButton,
  Tag,
  TagInput,
  TagInputContainer,
  TagList,
} from './BlogWriteStyle';
import { OneByOneButton } from '../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';

const MAX_TAG_LENGTH = 16; // 최대 태그 길이

const InsertTag = ({ tags, setTags }) => {
  const [tagInput, setTagInput] = useState(''); // 태그 입력 상태

  const handleTagInput = (e) => {
    let inputValue = e.target.value;
    if (inputValue.length > MAX_TAG_LENGTH) {
      inputValue = inputValue.slice(0, MAX_TAG_LENGTH); // 16자까지만 유지하고 나머지 제거
    }
    setTagInput(inputValue);
  };

  // Enter 키를 눌렀을 때 태그 추가
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Enter 키의 기본 동작을 막음
      handleAddTag();
    }
  };

  const handleAddTag = () => {
    if (tagInput.trim()) {
      if (tagInput.trim().length > MAX_TAG_LENGTH) {
        alert(`태그는 최대 ${MAX_TAG_LENGTH}자까지 입력 가능합니다.`);
        setTagInput(''); // 입력 값 초기화
        return;
      }

      if (tags.includes(tagInput.trim())) {
        alert('중복된 태그입니다.');
        setTagInput(''); // 중복된 태그일 때 입력 값 초기화
        return;
      }

      setTags([...tags, tagInput.trim()]); // 새로운 태그 추가
      setTagInput(''); // 태그 입력창 초기화
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  return (
    <>
      <TagInputContainer>
        <TagInput
          type="text"
          value={tagInput}
          onChange={handleTagInput}
          onKeyDown={handleKeyDown}
          placeholder="태그를 입력하세요"
        />
        <OneByOneButton onClick={handleAddTag}>태그 추가</OneByOneButton>
      </TagInputContainer>

      <TagList>
        {tags.map((tag) => (
          <Tag key={tag}>
            {tag}
            <RemoveTagButton onClick={() => handleRemoveTag(tag)}>
              ✕
            </RemoveTagButton>
          </Tag>
        ))}
      </TagList>
    </>
  );
};

export default InsertTag;
