import React from 'react';
import styled, { keyframes } from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;

  color: lightgray;
  // background-color: yellow;
  //   position: relative;
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: 9999; /* 다른 요소들 위에 표시되도록 설정 */
`;

const LoadingSpinner = () => {
  return <SpinnerContainer> I ♥ TEACHERNOTE</SpinnerContainer>;
};

export default LoadingSpinner;
