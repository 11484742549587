import React, { useEffect, useState, useRef, useContext } from 'react';
import { HelmetContent } from '../../components/HelmetContent';
import PageContainer from '../../pageLayout/PageContainer';
import { CenterAlign, PageBox } from '../../styles/BasicStyle';
import styled from 'styled-components';
import { borderRadius, gap, margin, padding } from '../../styles/commonStyle';
import { PageSubheader } from '../../pageLayout/PageContainerStyle';
import ArticleMenuBar from '../../topSubMenuBar/ArticleMenuBar';
import axios from '../../../node_modules/axios/index';
import { BlogBtn } from '../blogList/BlogListStyle';
import Input from '../../components/StyledInput';
import UserContext from '../../contexts/userContext';
import KakaoLoginButton from '../../components/KakaoLoginButton';

const InstaContents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InstaContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${margin.mediumlarge};
`;

const StyledInput = styled(Input)`
  width: 90%;
`;

const InstagramPage = () => {
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;

  const [posts, setPosts] = useState([]); //db에서 가져온 포스트 URL
  const [postUrl, setPostUrl] = useState(''); // 입력받을 포스트 URL 상태

  const [visibleContentCount, setVisibleContentCount] = useState(1); // 초기에는 1개의 콘텐츠만 표시
  const observerRef = useRef(null);
  //----------------- 포스트 저장 -----------------//

  const handlePostUrlChange = (e) => {
    setPostUrl(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axios.post('https://teachernote.me/api/save-insta-url', {
        postUrl,
      });
      alert('URL이 저장되었습니다.');
      setPostUrl(''); // 입력 후 초기화
    } catch (error) {
      console.error('포스트 저장 중 에러 발생', error);
      alert('저장에 실패했습니다.');
    }
  };

  //----------------- 저장된 포스트 표시 -----------------//

  useEffect(() => {
    // DB에서 저장된 포스트 URL 불러오기
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          'https://teachernote.me/api/get-insta-url',
        );

        // 배열을 랜덤하게 섞는 로직 추가
        const shuffledPosts = response.data.sort(() => Math.random() - 0.5);
        // console.log('셔플 후:', shuffledPosts);

        setPosts(shuffledPosts);
        // const reversedPosts = response.data.reverse();
        // console.log('정렬 후:', reversedPosts);
        // setPosts(reversedPosts);
      } catch (error) {
        console.error('포스트 가져오기 중 에러 발생', error);
      }
    };

    fetchPosts();
  }, []);

  // useEffect(() => {
  //   console.log('posts:' + JSON.stringify(posts));
  // }, [posts]);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = '//www.instagram.com/embed.js';
    script.onload = () => {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [posts]); // posts가 변경될 때마다 스크립트를 로드 및 처리

  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process(); // 새로 추가된 콘텐츠에 대해 Instagram Embed 처리
    }
  }, [visibleContentCount]); // visibleContentCount가 변경될 때마다 실행

  //----------------- 포스트 1개씩 보여주기 -----------------//

  useEffect(() => {
    let observer;

    const observeLastContent = () => {
      // 기존 옵저버가 있으면 해제
      if (observerRef.current) observerRef.current.disconnect();

      observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && visibleContentCount < posts.length) {
          setVisibleContentCount((prevCount) =>
            Math.min(prevCount + 1, posts.length),
          );
        }
      });

      // 마지막 콘텐츠를 타겟으로 관찰
      const lastContent = document.querySelector(
        `#instaContent-${visibleContentCount - 1}`,
      );
      if (lastContent) {
        observer.observe(lastContent);
      }

      observerRef.current = observer;
    };

    // IntersectionObserver가 너무 빨리 동작하지 않도록 500ms 지연
    const timeoutId = setTimeout(observeLastContent, 500);

    return () => {
      clearTimeout(timeoutId);
      if (observer) observer.disconnect();
    };
  }, [visibleContentCount, posts.length]); // posts.length 의존성 추가

  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process(); // 새로 추가된 콘텐츠에 대해 Instagram Embed 처리
    }
  }, [visibleContentCount, posts]); // visibleContentCount와 posts에 의존

  return (
    <PageContainer>
      <PageSubheader>
        <ArticleMenuBar />
      </PageSubheader>
      <PageBox>
        {/* <KakaoLoginButton /> */}
        <CenterAlign style={{ marginBottom: '20px' }}>
          <StyledInput
            type="text"
            placeholder="Instagram 포스트 URL을 입력해 선생님들과 공유해보세요"
            value={postUrl}
            onChange={handlePostUrlChange}
          />
          <BlogBtn onClick={handleSubmit}>공유하기</BlogBtn>
        </CenterAlign>
        <InstaContents>
          {posts.slice(0, visibleContentCount).map((post, index) => (
            <InstaContent
              key={index}
              id={`instaContent-${index}`}
              dangerouslySetInnerHTML={{
                __html: `
              <blockquote class="instagram-media" 
                data-instgrm-permalink="${post.postUrl}"
              data-instgrm-version="14" 
              style=" background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);">
                <div style="padding:16px;"> 
                  <a href="${post.postUrl}" style=" color:#000; font-family:Arial,sans-serif; font-size:14px; font-style:normal; font-weight:normal; line-height:17px; text-decoration:none;" target="_blank"> 
                    A post shared by Instagram</a>
                </div> 
              </blockquote>
            `,
              }}
            />
          ))}
        </InstaContents>
      </PageBox>
    </PageContainer>
  );
};

export default InstagramPage;
