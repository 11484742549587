import { useContext, useEffect, useState } from 'react';
import UserContext from '../../contexts/userContext';
import axios from 'axios';
import LoginModal from './LoginModal';
import { useMediaQuery } from 'react-responsive';
import LoginModal_Mobile from './LoginModal_Mobile';

const LoginModalContainer = ({
  openRegisterModal,
  handleAuthComplete,
  modalSource,
}) => {
  // userContext
  const { state, actions } = useContext(UserContext);

  // 로그인 정보 정의
  const [loginInfo, setLoginInfo] = useState({
    userId: '',
    password: '',
  });
  //-------------------- 로그인 정보 로드 --------------------//

  // 로그인 정보를 저장할지 여부를 위한 상태 추가
  const [rememberMe, setRememberMe] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // 자동 입력 기능: 저장된 로그인 정보가 있을 경우 로드
  useEffect(() => {
    const savedUserId = localStorage.getItem('savedUserId');
    const savedPassword = localStorage.getItem('savedPassword');

    // 불러온 값을 콘솔에 출력하여 확인
    // console.log('불러온 savedUserId:', savedUserId);
    // console.log('불러온 savedPassword:', savedPassword);

    if (savedUserId || savedPassword) {
      setLoginInfo({
        userId: savedUserId || '',
        password: savedPassword || '',
      });
      setRememberMe(true); // 저장된 정보가 있다면 체크박스도 체크된 상태로 설정
    }
  }, []);
  //-------------------- 로그인 정보 저장 --------------------//

  // input 입력 시 값 설정
  const handleChange = (e) => {
    setLoginInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // 체크박스 상태 변경 시 호출되는 함수
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };
  //-------------------- 로그인  --------------------//

  // 로그인 정보 DB 저장 및 자동 입력 정보 저장
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://teachernote.me/api/login',
        loginInfo,
      );
      // 토큰 저장 (예: localStorage)
      const token = response.data.token;
      localStorage.setItem('token', token);

      // 받아온 user data 확인
      const user = response.data.user;
      localStorage.setItem('user', JSON.stringify(user));

      // userContext에 받아온 user data 넣기
      actions.setUser(user);

      // 로그인 정보 저장 (체크박스가 체크된 경우에만)
      if (rememberMe) {
        localStorage.setItem('savedUserId', loginInfo.userId);
        localStorage.setItem('savedPassword', loginInfo.password);
      } else {
        // 체크되지 않은 경우 저장된 로그인 정보 삭제
        localStorage.removeItem('savedUserId');
        localStorage.removeItem('savedPassword');
      }

      handleAuthComplete(user.userId);
    } catch (error) {
      console.error('Error logging in:', error);
      alert('로그인에 실패하였습니다.');
    }
  };

  return (
    <>
      {!isMobile ? (
        <LoginModal
          handleLogin={handleLogin}
          handleChange={handleChange}
          openRegisterModal={openRegisterModal}
          loginInfo={loginInfo} // loginInfo를 전달
          rememberMe={rememberMe}
          handleRememberMeChange={handleRememberMeChange} // 체크박스 상태 변경 함수 전달
          handleAuthComplete={handleAuthComplete}
        />
      ) : (
        <LoginModal_Mobile
          handleLogin={handleLogin}
          handleChange={handleChange}
          openRegisterModal={openRegisterModal}
          loginInfo={loginInfo} // loginInfo를 전달
          rememberMe={rememberMe}
          handleRememberMeChange={handleRememberMeChange} // 체크박스 상태 변경 함수 전달
          handleAuthComplete={handleAuthComplete}
        />
      )}
    </>
  );
};

export default LoginModalContainer;
