import styled from 'styled-components';
import { media } from '../../../styles/media';
import {
  colors,
  fontSize,
  fontWeight,
  padding,
} from '../../../styles/commonStyle';

export const Mobile_AuthBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Mobile_AuthLayout1 = styled.div`
  padding: ${padding.large} 0;
  font-size: ${fontSize.large};
  text-align: center;
  //   background-color: red;
`;
export const Mobile_AuthLayout2 = styled.div`
  width: 70vw;
  font-size: ${fontSize.small};
  //   background-color: yellow;
`;
export const Mobile_AuthLayout3 = styled.div``;
