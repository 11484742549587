import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gray,
  margin,
  objHeight,
  objWidth,
  padding,
  pageWidth,
  ratio,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const TopicBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmptyTopicList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${padding.large} 0 ${padding.medium} 0;
  color: ${gray.medium};
  font-weight: ${fontWeight.bold};
`;

export const TopicCard = styled.div`
  height: auto;
  width: 100%;
  margin-bottom: ${margin.mediumlarge};
  border-radius: ${borderRadius.large};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  font-size: ${fontSize.medium};
  ${media.mobile`
    font-size: ${fontSize.small};

  `}
  border: 1px solid ${gray.light};
`;

export const TopicListStyledItem = styled.div`
  width: 100%;
  height: ${objHeight.large};
  // background-color: red;

  ${media.mobile`
  height: auto;
  border-bottom: 1px solid ${gray.extralight};
  // padding: ${padding.small} 0;
  `}
  color: ${gray.darktext};
  font-weight: ${fontWeight.bold};
  &:hover {
    background-color: ${gray.extralight};
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopicListItemLayout1 = styled.div`
  width: ${ratio.large};
    height: 100%;


  margin-left: ${margin.medium};
  color: ${gray.darktext}


  word-wrap: break-word; /* 길이가 긴 단어를 줄바꿈 */
  word-break: break-all; /* 길이가 긴 단어를 잘라서 줄바꿈 */
  white-space: normal; /* 기본 줄바꿈 방식으로 설정 */

  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover{
  color: ${colors.primary};
  }
`;
export const TopicListItemLayout2 = styled.div`
  width: ${ratio.small};
  height: ${objHeight.large};

  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: yellow;

  &:hover {
    // background-color: ${gray.medium};
    background-color: ${colors.lightGreenBorder};

    cursor: pointer;
  }
  &:hover svg {
    // color: ${colors.background};
    transform: scale(1.1);
  }
`;

export const TopicStyleTag = styled.p`
  min-width: ${objWidth.linkSmall};

  padding: ${padding.small} ${padding.medium};
  margin: ${margin.small} ${margin.medium} ${margin.small} ${margin.small};
  border-radius: ${borderRadius.medium};

  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.small};
  color: ${gray.darktext};
  text-decoration: none;

  // background-color: ${gray.light};
  background-color: ${colors.lightGreenBackground};

  cursor: default;

  // display: inline-block; /* 또는 inline */
  display: flex;
  justify-content: center;
`;

export const TopicListTitle = styled.div``;

export const TopicInputBox1 = styled.div`
  width: ${ratio.small};
  height: ${objHeight.large};
`;
export const TopicInputBox2 = styled.div`
  width: ${ratio.large};
  height: ${objHeight.large};
`;

export const TopicStyledInput1 = styled.input`
  height: 95%;
  width: 95%;
  border: none;
  margin-right: ${margin.small};

  font-size: ${fontSize.medium};
  ${media.mobile`
    font-size: ${fontSize.small};

  `}

  outline: none; /* 포커스 효과 제거 */
  transition: border-color 0.3s ease;
  text-align: center;

  &:focus {
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px rgba(30, 144, 255, 0.2);
  }
`;
export const TopicStyledInput2 = styled.input`
  width: 95%;
  height: 95%;
  padding: 0px ${padding.medium};

  border: none;
  font-size: ${fontSize.medium};
  ${media.mobile`
    font-size: ${fontSize.small};

  `}
  outline: none; /* 포커스 효과 제거 */
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px rgba(30, 144, 255, 0.2);
  }
`;

export const AddBtn = styled.div`
  width: 100%;
  height: 100%;

  cursor: pointer;
  color: ${colors.primary};
  // background-color: ${gray.light};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${colors.primary};
  }
  ${media.mobile`
  // height: auto;
  // border-bottom: 1px solid ${gray.extralight};
  padding: ${padding.medium} 0;
  height: ${objHeight.medium};
  `}
`;
