import PresenterPicker from './PresenterPicker';

const PresenterPickerContainer = ({
  numberList,
  setNumberList,
  studentCount,
  setStudentCount,
}) => {
  return (
    <PresenterPicker
      numberList={numberList}
      setNumberList={setNumberList}
      studentCount={studentCount}
      setStudentCount={setStudentCount}
    />
  );
};

export default PresenterPickerContainer;
