import styled from 'styled-components';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gray,
  padding,
} from '../../../styles/commonStyle';

export const StudentStatusContainer = styled.div`
  //   background-color: ${colors.lightGreenBackground};
  padding: ${padding.mediumLarge};
  border: 1px solid ${colors.lightGreenBorder};
  border-radius: ${borderRadius.medium};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StudentStatus_Title = styled.div`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
  padding: 0 0 ${padding.medium} 0;
`;

export const StudentStatus_Td = styled.td`
  padding: 8px;
  border-radius: ${borderRadius.medium};

  color: ${({ isNotUsing }) =>
    isNotUsing ? gray.darktext : `${gray.darktext}`};
  background-color: ${({ isNotUsing }) =>
    isNotUsing ? gray.light : '#ffffff'};
  position: relative; // 자식 요소인 CircleOverlay가 td 안에서 절대 위치를 가질 수 있도록 설정
`;

export const CircleOverlay = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  // border: 5px solid ${colors.lightGreenBorder};
  border: 5px solid ${colors.primary};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; // 클릭 등의 이벤트가 아래 숫자에 전달되도록 설정
`;
export const StudentStatus_Cell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
