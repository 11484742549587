import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import {
  CarouselContainer,
  CarouselSlide,
  CarouselWrapper,
  NavigationButton,
  NavigationButton_Large,
} from './CarouselStyle';
import { PageWidth } from '../../styles/BasicStyle';
import { ColorPalette } from '../../styles/commonStyle';

function CarouselComponent() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slides, setSlides] = useState([]);
  const maxImages = 3;

  useEffect(() => {
    const fetchCarouselImages = async () => {
      try {
        const response = await axios.get(
          'https://teachernote.me/api/main-top-carousel-images',
        );
        const shuffledImages = response.data.sort(() => 0.5 - Math.random());
        const selectedImages = shuffledImages
          .slice(0, maxImages)
          .map((image) => ({
            ...image,
            bgColor:
              ColorPalette[Math.floor(Math.random() * ColorPalette.length)],
          }));
        setSlides(selectedImages);
      } catch (error) {
        console.error('Failed to fetch carousel images:', error);
      }
    };
    fetchCarouselImages();
  }, []);

  const slideCount = slides.length;

  const goToNext = () => {
    if (slideCount > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slideCount);
    }
  };

  const goToPrev = () => {
    if (slideCount > 0) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? slideCount - 1 : prevIndex - 1,
      );
    }
  };

  const handleSlideClick = (linkUrl) => {
    // window.open(linkUrl, '_blank', 'noopener');// 새 창에서 이동
    window.location.href = linkUrl; // 현재 창에서 이동
    // window.location.replace(linkUrl); // 현재 창에서 바로 이동하여 중간 지연을 최소화// 효과없음
  };

  useEffect(() => {
    if (slideCount > 1) {
      const interval = setInterval(goToNext, 5000);
      return () => clearInterval(interval);
    }
  }, [slideCount]);

  useEffect(() => {
    if (currentIndex >= slideCount) {
      setCurrentIndex(0);
    }
  }, [slideCount, currentIndex]);

  return (
    <CarouselContainer>
      <CarouselWrapper>
        {slides.map((slide, index) => (
          <CarouselSlide
            key={slide.id}
            // bgImage={`https://teachernote.me${slide.image_url}`}
            bgColor={slide.bgColor} // 랜덤 파스텔톤 색상
            isActive={index === currentIndex}
            onClick={() => handleSlideClick(slide.link_url)}
          >
            {' '}
            <PageWidth>
              <img
                src={`https://teachernote.me${slide.image_url}`}
                alt={`Slide ${index}`}
                // style={{ maxWidth: '100%', height: 'auto' }}
              />
            </PageWidth>
          </CarouselSlide>
        ))}
      </CarouselWrapper>
      {slideCount > 1 && (
        <>
          <NavigationButton_Large direction="prev" onClick={goToPrev}>
            &#10094;
          </NavigationButton_Large>
          <NavigationButton_Large direction="next" onClick={goToNext}>
            &#10095;
          </NavigationButton_Large>
        </>
      )}
    </CarouselContainer>
  );
}

export default CarouselComponent;
