import React, { useCallback, useContext, useEffect, useState } from 'react';
import GradeContext from '../contexts/grade';
import {
  useNavigate,
  useParams,
} from '../../node_modules/react-router-dom/dist/index';
import SelectGradeMenuBar, {
  GradeMenu,
  SelectGradeBox,
} from './SelectGradeMenuBar';
import {
  AddNote,
  ClassProgressnoteMenuBox,
  ProgressnoteMenuBar,
  ProgressnoteMenuBarLeft,
  ProgressnoteMenuBarMiddle,
  ProgressnoteMenuBarRight,
  SelectClassBox,
  SelectClassInner,
  SelectGradeContainer,
} from './ClassProgressnoteMenuStyle';
import axios from '../../node_modules/axios/index';
import UserContext from '../contexts/userContext';

const ClassProgressnoteMenu = ({
  classInfo,
  showAddWindow,
  setShowAddWindow,
}) => {
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const { state, actions } = useContext(GradeContext);
  const [selectedGrade, setSelectedGrade] = useState(state.currentGrade);

  const navigate = useNavigate();

  const [uniqueClasses, setUniqueClasses] = useState({});
  const { paramClassNum } = useParams();
  const [loading, setLoading] = useState(true);

  //gradeinfodb직접 가져오기
  const [gradeInfoDB, setGradeInfoDB] = useState([]);
  const fetchGradeInfo = useCallback(async () => {
    try {
      const res = await axios.get(
        `https://teachernote.me/api/gradeinfo/${userId}`,
      );
      const sortedGradeInfo = res.data.sort((a, b) => a.grade - b.grade);
      setGradeInfoDB(sortedGradeInfo);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // 데이터 로딩 완료 시 로딩 상태 변경
    }
  }, [userId]);
  useEffect(() => {
    fetchGradeInfo();
  }, [userId, fetchGradeInfo]);

  useEffect(() => {
    const sortedClassInfo = [...classInfo].sort((a, b) => {
      if (a.grade === b.grade) {
        return a.class - b.class;
      }
      return a.grade - b.grade;
    });

    const groupedClasses = sortedClassInfo.reduce((acc, item) => {
      if (!acc[item.grade]) {
        acc[item.grade] = [];
      }
      acc[item.grade].push(item.class);
      return acc;
    }, {});

    setUniqueClasses(groupedClasses);
  }, [userId, classInfo]);

  const handleChangeGrade = (grade) => {
    actions.setGrade(grade);
    setSelectedGrade(grade);

    navigate(`/progress-notes/by-class/`);
  };

  const handleChangeClass = (grade, classNum) => {
    setShowAddWindow(false);
    actions.setGrade(grade);
    navigate(`/progress-notes/by-class/${classNum}`);
  };

  const ToggleAddWindow = useCallback(() => {
    setShowAddWindow((prev) => !prev);
  }, [setShowAddWindow]);

  const handleAddProgressNote = () => {
    navigate(`/addprogressnote`);
  };

  return (
    <SelectGradeBox>
      {!loading && (
        <>
          {gradeInfoDB.length === 0 ? (
            <>
              <GradeMenu>전체보기</GradeMenu>
            </>
          ) : (
            <ClassProgressnoteMenuBox>
              <ProgressnoteMenuBarLeft>
                {gradeInfoDB.map((gradeInfo) => (
                  <GradeMenu
                    key={gradeInfo.grade}
                    onClick={() => handleChangeGrade(gradeInfo.grade)}
                    $isSelectedGrade={gradeInfo.grade === selectedGrade}
                  >
                    {gradeInfo.grade}학년
                  </GradeMenu>
                ))}
              </ProgressnoteMenuBarLeft>
              <ProgressnoteMenuBarMiddle>
                {selectedGrade && (
                  <SelectClassBox>
                    {uniqueClasses[selectedGrade] &&
                      uniqueClasses[selectedGrade].map((classNum, index) => (
                        <SelectClassInner
                          key={`select class-${selectedGrade}-${index}`}
                          onClick={() =>
                            handleChangeClass(selectedGrade, classNum)
                          }
                          $isSelected={
                            selectedGrade === state.currentGrade &&
                            classNum.toString() === paramClassNum
                          }
                        >
                          {selectedGrade}-{classNum}
                        </SelectClassInner>
                      ))}
                  </SelectClassBox>
                )}
              </ProgressnoteMenuBarMiddle>
              <ProgressnoteMenuBarRight onClick={ToggleAddWindow}>
                {showAddWindow ? '진도노트 보기' : '진도노트 추가'}
              </ProgressnoteMenuBarRight>
            </ClassProgressnoteMenuBox>
          )}
        </>
      )}
    </SelectGradeBox>
  );
};

export default ClassProgressnoteMenu;
