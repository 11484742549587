import drawmachine from '../../../img/random-number-picker-2jelly/random-number-picker-machine.png';

import ball_red from '../../../img/random-number-picker-2jelly/random-number-picker-ball-red.png';
import ball_yellow from '../../../img/random-number-picker-2jelly/random-number-picker-ball-yellow.png';
import ball_green from '../../../img/random-number-picker-2jelly/random-number-picker-ball-green.png';
import ball_blue from '../../../img/random-number-picker-2jelly/random-number-picker-ball-blue.png';
import ball_violet from '../../../img/random-number-picker-2jelly/random-number-picker-ball-violet.png';
import ball_gray from '../../../img/random-number-picker-2jelly/random-number-picker-ball-gray.png';
import ball_pink from '../../../img/random-number-picker-2jelly/random-number-picker-ball-pink.png';

import openedball_red from '../../../img/random-number-picker-2jelly/random-number-picker-opened-ball-red.png';
import openedball_yellow from '../../../img/random-number-picker-2jelly/random-number-picker-opened-ball-yellow.png';
import openedball_green from '../../../img/random-number-picker-2jelly/random-number-picker-opened-ball-green.png';
import openedball_blue from '../../../img/random-number-picker-2jelly/random-number-picker-opened-ball-blue.png';
import openedball_violet from '../../../img/random-number-picker-2jelly/random-number-picker-opened-ball-violet.png';
import openedball_gray from '../../../img/random-number-picker-2jelly/random-number-picker-opened-ball-gray.png';
import openedball_pink from '../../../img/random-number-picker-2jelly/random-number-picker-opened-ball-pink.png';

import bearjelly_red from '../../../img/random-number-picker-2jelly/random-number-picker-bear-jelly-red.png';
import bearjelly_yellow from '../../../img/random-number-picker-2jelly/random-number-picker-bear-jelly-yellow.png';
import bearjelly_green from '../../../img/random-number-picker-2jelly/random-number-picker-bear-jelly-green.png';
import bearjelly_blue from '../../../img/random-number-picker-2jelly/random-number-picker-bear-jelly-blue.png';
import bearjelly_violet from '../../../img/random-number-picker-2jelly/random-number-picker-bear-jelly-violet.png';
import bearjelly_gray from '../../../img/random-number-picker-2jelly/random-number-picker-bear-jelly-gray.png';
import bearjelly_pink from '../../../img/random-number-picker-2jelly/random-number-picker-bear-jelly-pink.png';
import {
  Ball,
  BallBlue,
  BallContainer,
  BallGray,
  BallGreen,
  BallPink,
  BallRed,
  BallViolet,
  BallYellow,
  DrawMachine,
  MachineContainer,
  JellyStartButton,
  JellyStartButtonContainer,
  ModalJellyAndBallDiv,
} from './JellyPickerStyle';
import { useEffect, useRef, useState } from 'react';
import { keyframes } from 'styled-components';
import {
  CongratText,
  PickerModal,
  PickerModalCloseButton,
  PickerModalContent,
} from './PickerModal';
import {
  OneByOneButton,
  StartDrawButton,
} from '../../../seatdrawpages/3drawOneByOnePage/OneByOneStyle';
import logo_horizontal from '../../../img/teachernote-logo/teachernote-logo-horizontal.png';
import { useMediaQuery } from 'react-responsive';

const JellyPicker = ({ numberList, pickPresenter, presenter }) => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBall, setSelectedBall] = useState(null);
  const [selectedOpenedBallImage, setSelectedOpenedBallImage] = useState(null); // 랜덤으로 선택된 공 이미지
  const [selectedBearJellyImage, setSelectedBearJellyImage] = useState(null); // 랜덤으로 선택된 공 이미지

  const getRandomValue = (min, max) => Math.random() * (max - min) + min;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  // BallContainer의 ref를 통해 실제 너비와 높이를 가져옴
  const ballContainerRef = useRef(null);
  const [ballContainerSize, setBallContainerSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (ballContainerRef.current) {
      const { offsetWidth, offsetHeight } = ballContainerRef.current;
      setBallContainerSize({ width: offsetWidth, height: offsetHeight });
    }
  }, []);

  // const ballSize = 75;
  const ballSize = isMobile ? 35 : isTablet ? 55 : 60;
  const margin = 30;

  const spinDuration = isSpinning ? 0.5 : 2; // 빠른 회전 적용

  const ballImages = [
    ball_red,
    ball_yellow,
    ball_green,
    ball_blue,
    ball_violet,
    ball_gray,
    ball_pink,
  ];

  const openedBallImages = [
    openedball_red,
    openedball_yellow,
    openedball_green,
    openedball_blue,
    openedball_violet,
    openedball_gray,
    openedball_pink,
  ];

  const bearJellyImages = [
    bearjelly_red,
    bearjelly_yellow,
    bearjelly_green,
    bearjelly_blue,
    bearjelly_violet,
    bearjelly_gray,
    bearjelly_pink,
  ];

  const startSpin = () => {
    setIsSpinning(true); // 공이 빠르게 회전 시작
    setTimeout(() => {
      const randomIndex = Math.floor(Math.random() * numberList.length);
      setSelectedBall(numberList[randomIndex]); // 무작위 결과 선택
      const selectedIndex = Math.floor(Math.random() * openedBallImages.length);
      setSelectedOpenedBallImage(openedBallImages[selectedIndex]); // 무작위 openedball 이미지 선택
      setSelectedBearJellyImage(bearJellyImages[selectedIndex]); // 같은 색상의 bearjelly 이미지 선택

      setIsSpinning(false); // 회전 종료
      setIsModalOpen(true); // 모달 오픈
    }, 3000); // 3초 후 결과 출력
  };
  const closeModal = () => {
    setIsModalOpen(false); // 모달 닫기
  };

  return (
    <>
      {' '}
      <MachineContainer>
        <JellyStartButtonContainer>
          <JellyStartButton onClick={startSpin}>
            티처노트 젤리뽑기 START
          </JellyStartButton>
        </JellyStartButtonContainer>
        <DrawMachine src={drawmachine} />
        <BallContainer ref={ballContainerRef}>
          {ballContainerSize.width > 0 &&
            numberList.map((_, index) => {
              return (
                <Ball
                  key={index}
                  src={ballImages[index % ballImages.length]}
                  alt="ball"
                  // loading="lazy"
                  style={{
                    top: `${getRandomValue(
                      ballContainerSize.height * 0.3, // 컨테이너 하단 70%~100% 영역에 분포
                      ballContainerSize.height - ballSize - margin,
                    )}px`,
                    left: `${getRandomValue(
                      margin,
                      ballContainerSize.width - ballSize - margin,
                    )}px`,
                    width: `${ballSize}px`,
                    height: `${ballSize}px`,
                  }}
                  duration={spinDuration} // 회전 속도 조절
                  // duration={getRandomValue(1.5, 3)} // 공마다 다른 애니메이션 속도 설정
                  bounceHeight={getRandomValue(
                    isSpinning ? 50 : 0,
                    isSpinning ? 120 : 20,
                  )}
                  rotateAngle={getRandomValue(
                    isSpinning ? 0 : 0,
                    isSpinning ? 180 : 60,
                  )}
                />
              );
            })}
        </BallContainer>
      </MachineContainer>
      {isModalOpen && (
        <PickerModal
          onClick={(e) => e.target === e.currentTarget && closeModal()}
        >
          <PickerModalContent>
            <img
              src={logo_horizontal}
              width="100px"
              style={{ opacity: 0.5 }}
              alt="티처노트 가로형 로고"
            />
            <CongratText>
              {/* <h1> */}
              {selectedBall}번 당첨!{' '}
              <img
                src={selectedOpenedBallImage}
                alt="랜덤 숫자 뽑기 열린 공"
                width="30px"
              />
              {/* </h1>{' '} */}
            </CongratText>
            <ModalJellyAndBallDiv>
              <img
                src={selectedBearJellyImage}
                alt="랜덤 숫자 뽑기 젤리"
                width="150px"
              />
            </ModalJellyAndBallDiv>
            <OneByOneButton onClick={closeModal}>닫기</OneByOneButton>
          </PickerModalContent>
        </PickerModal>
      )}
    </>
  );
};

export default JellyPicker;
