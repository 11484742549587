import styled from 'styled-components';
import { Link } from '../../../node_modules/react-router-dom/dist/index';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gap,
  gray,
  objWidth,
  objHeight,
  padding,
  pageWidth,
  blogListLeftWidth,
  imgSize,
  ratio,
} from '../../styles/commonStyle';
import { media } from '../../styles/media';

export const BlogListPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const BlogListPageBox = styled.div`
  width: 100%;
`;

export const BlogPageLayout1 = styled.div`
  ${media.desktop`
  padding-bottom: ${padding.large};
  `}

  ${media.tablet`
  padding-bottom: ${padding.large};
  `}

  ${media.mobile`
  padding-bottom: ${padding.mediumLarge};
  `}
  font-size: ${fontSize.categoryLarge};
  font-weight: ${fontWeight.bold};
  display: flex;
  justify-content: space-between;
`;

export const BlogPageCategory = styled.div`
  display: flex;
  align-items: center;
  // background-color: yellow;
`;

export const BlogBtn = styled.button`
  width: ${ratio.small};
  min-width: ${objWidth.mediumLarge};
  padding: ${padding.medium} ${padding.mediumLarge};
  ${media.mobile`
  padding: ${padding.small} ${padding.medium};
  width: 100%; 
  `}

  background-color: ${colors.primary};
  color: ${colors.background};
  border: none;
  border-radius: ${borderRadius.small};
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${colors.primarydark};
  }
`;

export const BlogListCotentContainer = styled.div`
  width: 100%;
  gap: ${gap.medium};
  ${media.mobile`
  gap: ${gap.small};
  `}
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
export const BlogListCotentBox = styled.div`
  height: ${objHeight.extraLarge};
  padding: ${padding.large};
  gap: ${padding.large};
  // ${media.mobile`
  //   height: ${imgSize.small};
  //   padding:  ${padding.medium};
  //   gap: ${padding.medium};
  // `}

  border: 1px solid ${gray.light};
  border-radius: ${borderRadius.medium};
  text-decoration: none;

  display: flex;
  align-items: start;
  // background-color: red;

  &: hover {
    cursor: pointer;
    background-color: ${gray.extralight};s
    // transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

export const BlogListContentBox_Mobile = styled(BlogListCotentBox)`
  flex-direction: column;
  height: 100%;
  padding: 15px;
  gap: ${padding.medium};
`;

export const BlogListRight = styled.div`
  width: 100%;
  gap: ${padding.medium};
  ${media.mobile`
    gap: ${padding.small};
  `}

  // background-color: yellow;
  // height: ${imgSize.medium};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
`;

export const BlogListLeft = styled.div`
  height: ${imgSize.medium};
  ${media.mobile`
      height: ${imgSize.small};
  `}

  display: flex;
  justify-content: left;
  align-items: center;
  // background-color: gray;
`;
export const BlogListTitle = styled.div`
  width: 100%;

  font-size: ${fontSize.large};
  ${media.mobile`
  font-size: ${fontSize.medium};
`}
  font-weight: ${fontWeight.extraBold};
  color: ${gray.darktext};
`;
export const BlogListContent = styled.div`
  width: 100%;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.large};
  ${media.mobile`
  font-size: ${fontSize.medium};
`}

  color: ${gray.darktext};
  // background-color: blue;
`;
export const BlogListImage = styled.img`
  // width: 100%;
  border-radius: ${borderRadius.medium};
  height: ${imgSize.medium};

  ${media.mobile`
    width: 100%;
    height: 100%; 
    // height: ${imgSize.small};
  `}
`;
