import {
  HelmetContent,
  SignUpHelmetContent,
} from '../../components/HelmetContent';
import PageContainer from '../../pageLayout/PageContainer';
import Register2Container from './Register2Container';

//이 페이지는 자리뽑기 beta 로그인 시 사용
const AuthPage = () => {
  return (
    <>
      <SignUpHelmetContent />
      <PageContainer>
        <Register2Container />
      </PageContainer>
    </>
  );
};

export default AuthPage;
