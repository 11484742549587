import { useCallback, useEffect, useState } from 'react';
import PageContainer from '../../pageLayout/PageContainer';
import PageContainerWithoutSubheader from '../../pageLayout/PageContainerWithoutSubheader';
import CarouselComponent from './CarouselComponent';
import MainContainer from './MainContainer';
import axios from '../../../node_modules/axios/index';
import { MainHelmetContent } from '../../components/HelmetContent';
import VisitorCounter from '../../components/VisitorCounter';

const MainPage = () => {
  const [blogDB, setBlogDB] = useState([]);
  const [cards, setCards] = useState([]);
  const [cardsByCategory, setCardsByCategory] = useState({});

  const fetchBlogs = useCallback(async () => {
    try {
      const res = await axios.get('https://teachernote.me/api/blogs/');
      const now = new Date();

      const formattedCards = res.data
        .map((item) => {
          const isNew =
            (now - new Date(item.updatedAt)) / (1000 * 60 * 60 * 24) <= 7;
          return {
            id: item.id,
            category: item.category,
            title: item.title,
            description: item.content,
            writer: item.userId,
            fileUrl: item.fileUrl,
            updatedAt: item.updatedAt,
            isNew, // 3일 이내라면 true로 설정
          };
        })
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      const groupedByCategory = formattedCards.reduce((acc, card) => {
        const { category } = card;
        if (!acc[category]) acc[category] = [];
        acc[category].push(card);
        return acc;
      }, {});

      setCards(formattedCards); // 전체 블로그 카드 설정
      setCardsByCategory(groupedByCategory);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <MainHelmetContent />
      <VisitorCounter />
      <PageContainerWithoutSubheader>
        <CarouselComponent />
        <MainContainer cardsByCategory={cardsByCategory} cards={cards} />
      </PageContainerWithoutSubheader>
    </>
  );
};

export default MainPage;
