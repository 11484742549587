import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  colors,
  fontSize,
  fontWeight,
  gray,
  iconSize,
  objWidth,
  padding,
} from '../styles/commonStyle';
import {
  Link,
  NavLink,
  useLocation,
} from '../../node_modules/react-router-dom/dist/index';
import { IoHome } from 'react-icons/io5';
import { FaTable, FaPen } from 'react-icons/fa';
import { FaSchoolFlag } from 'react-icons/fa6';
import { PiNotepadBold } from 'react-icons/pi';
import { MdOutlineBorderColor, MdPeopleAlt } from 'react-icons/md';
import { FaSlideshare } from 'react-icons/fa';
import { LuFolderHeart } from 'react-icons/lu';
import { BsCalendar2DayFill } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';

import { BsFillPersonFill } from 'react-icons/bs';
import { RiKakaoTalkFill } from 'react-icons/ri';
import { FaMugHot } from 'react-icons/fa';
import UserContext from '../contexts/userContext';
import ScrollToTop from '../commonFunction/ScrollToTop';

const MobileMenuBar = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${colors.background};
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const MenuItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${padding.small};
`;

const MenuItem = styled(NavLink)`
  // width: ${objWidth.medium};
  width: 20%;
  text-align: center;
  padding: ${padding.small};
  color: ${gray.darktext};
  font-size: ${fontSize.extraSmall};
  font-weight: ${fontWeight.bold};
  cursor: pointer;
  text-decoration: none;

  &.active {
    color: ${colors.primary};
  }
`;

//2024년 10월 말 ~ 11.초 업데이트
const MobileBottomMenuBar_ver2 = () => {
  const { state: userState } = useContext(UserContext);
  const userId = userState && userState.user ? userState.user.userId : null;
  const myInfo = userId ? '/m-my-page' : '/m-sign-up';
  const location = useLocation();
  const currentPath = location.pathname;
  // '수업·평가' 메뉴 활성화 조건
  const isActivitiesActive =
    currentPath.startsWith('/education-resources-sharing/lesson-materials') ||
    currentPath.startsWith(
      '/education-resources-sharing/classroom-learning-activities',
    );

  // 'TALK' 메뉴 활성화 조건
  const isTalkActive =
    currentPath.startsWith('/education-resources-sharing') &&
    !currentPath.startsWith('/education-resources-sharing/lesson-materials') &&
    !currentPath.startsWith(
      '/education-resources-sharing/classroom-learning-activities',
    );

  const isProgressActive =
    currentPath.startsWith('/lesson-progress-tracker') ||
    currentPath.startsWith('/class-management') ||
    currentPath.startsWith('/progress-notes/by-class') ||
    currentPath.startsWith('/topic-management');
  return (
    <MobileMenuBar>
      <MenuItemsContainer>
        <MenuItem
          to="/m-talk-education-sharing"
          end
          onClick={ScrollToTop}
          className={isTalkActive ? 'active' : ''}
        >
          <FaMugHot size={`${iconSize.large}`} /> <br />
          TALK
        </MenuItem>
        <MenuItem
          to="/education-resources-sharing/classroom-learning-activities"
          end
          onClick={ScrollToTop}
          className={isActivitiesActive ? 'active' : ''}
        >
          <LuFolderHeart size={`${iconSize.large}`} />
          <br />
          수업·평가
        </MenuItem>{' '}
        <MenuItem to="/" onClick={ScrollToTop}>
          <IoHome size={`${iconSize.large}`} />
          <br />홈
        </MenuItem>
        <MenuItem
          to="/lesson-progress-tracker"
          onClick={ScrollToTop}
          className={isProgressActive ? 'active' : ''}
        >
          <BsCalendar2DayFill size={`${iconSize.large}`} />
          <br />
          진도표
        </MenuItem>
        <MenuItem to={myInfo} onClick={ScrollToTop}>
          <FaUserCircle size={`${iconSize.large}`} />
          <br />
          내정보
        </MenuItem>
      </MenuItemsContainer>
    </MobileMenuBar>
  );
};

export default MobileBottomMenuBar_ver2;
