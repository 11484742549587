import styled from 'styled-components';
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gap,
  gray,
  height,
  margin,
  objHeight,
  objWidth,
  padding,
  pageWidth,
} from '../styles/commonStyle';
import { media } from '../styles/media';
import { NavLink } from '../../node_modules/react-router-dom/dist/index';

export const HeaderMenuBarContainer = styled.div`
  // width: ${pageWidth.desktop};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
  background-color: ${colors.background};

  ${media.desktop`
width: ${pageWidth.desktop};
  height: ${height.header};

  `}

  ${media.tablet`
width: ${pageWidth.tablet};
  height: ${height.header};

  `}

  ${media.mobile`
width: ${pageWidth.mobile};
  height: ${height.headerMobile};

  `}
  padding: 0 ${padding.large};
`;
export const HeaderLeft = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;

  > a {
    height: 100%;
    display: flex;
    align-items: center;

    text-decoration: none;
    color: black;
    &:hover {
      color: ${colors.primary};
    }
  }

  > a.active {
    height: 100%;
    display: flex;
    align-items: center;
    color: ${colors.primary};
    box-sizing: border-box;

    border-bottom: 3px solid ${colors.primary};
  }
`;
export const HeaderRight = styled.div`
  cursor: pointer;
  color: ${colors.primary};
  // background-color: red;
  display: flex;
  align-items: center;
  gap: ${gap.small};
`;

export const NowInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${gray.darktext};
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.small};
  cursor: default;
`;

export const TopMenuLogo = styled.div`
  color: ${colors.primary};
  margin-right: ${margin.large};
  font-weight: ${fontWeight.bold};
  // &:hover {
  //   color: ${colors.primarydark};
  // }
`;

export const TopMenuDownload = styled.div`
  color: ${colors.primary};
  margin-right: ${margin.large};

  ${media.tablet`
    display: none;
  `}
`;
export const TopMenu = styled.div`
  &:hover {
    color: ${colors.primary};
  }
`;
export const SubheaderBarContainer = styled.div`
  width: ${objWidth.mediumLarge};
  top: 100%; /* 부모 요소 바로 아래에 위치 */
  left: 50%;
  transform: translateX(-50%); /* 왼쪽으로 50% 이동 */
  background-color: ${colors.background};
  border-radius: ${borderRadius.medium};

  text-align: center;
  padding: ${padding.mediumLarge};
  gap: ${gap.small};

  > a {
    text-decoration: none;
    color: black;
    &:hover {
      color: ${colors.primary};
    }
  }
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const MenuItem = styled.div`
  position: relative;
  height: ${height.header};
  // background-color: red;
  display: flex;
  align-items: center;
  margin-right: ${margin.mediumlarge};
  // background-color: yellow;
`;

export const StyledNavLink = styled(NavLink).attrs((props) => ({
  isActive: (match, location) => {
    return location.pathname.startsWith('/education-resources-sharing');
  },
}))`
  text-decoration: none;
  color: black;
  height: 100%; /* 부모 요소의 높이에 맞추기 위해 100% 설정 */
  line-height: ${height.header}; /* header 높이에 맞게 line-height 설정 */
  // margin-right: ${margin.mediumlarge};
  &:hover {
    color: ${colors.primary};
  }

  &.active {
    color: ${colors.primary} !important;
    border-bottom: 3px solid ${colors.primary} !important;
  }
`;
